import React, {
    Suspense,
    lazy,
    useEffect,
    useState,
    ComponentType,
    LazyExoticComponent,
    useTransition
} from "react";
import { Loader } from "../components/Loader/Loader";

interface ResponsiveOptions {
    mobileImport: () => Promise<{ default: ComponentType<any> }>;
    desktopImport: () => Promise<{ default: ComponentType<any> }>;
    breakpoint?: string;
}

export function withResponsiveWrapper<P>({
                                             mobileImport,
                                             desktopImport,
                                             breakpoint = "(max-width: 940px)",
                                         }: ResponsiveOptions): LazyExoticComponent<ComponentType<P>> {
    const ResponsiveComponent: React.FC<P> = (props: P) => {
        const [isPending, startTransition] = useTransition();
        const [isMobile, setIsMobile] = useState(window.matchMedia(breakpoint).matches);
        const fallback = <Loader/>;
        const [Component, setComponent] = useState<ComponentType<P> | null>(null);

        useEffect(() => {
            const importComponent = isMobile ? mobileImport : desktopImport;

            startTransition(() => {
                importComponent().then(module => {
                    setComponent(() => module.default);
                });
            });
        }, [isMobile]);

        useEffect(() => {
            const mediaQuery = window.matchMedia(breakpoint);
            const handler = (e: MediaQueryListEvent) => {
                startTransition(() => {
                    setIsMobile(e.matches);
                });
            };

            if (mediaQuery.addEventListener) {
                mediaQuery.addEventListener("change", handler);
            } else {
                mediaQuery.addListener(handler);
            }

            return () => {
                if (mediaQuery.removeEventListener) {
                    mediaQuery.removeEventListener("change", handler);
                } else {
                    mediaQuery.removeListener(handler);
                }
            };
        }, [breakpoint]);

        if (!Component) return <>{fallback}</>;
        return (
            <Suspense fallback={fallback}>
                {isPending ? fallback :<Component {...(props as P & JSX.IntrinsicAttributes)} />}
            </Suspense>
        );
    };

    return lazy(() => Promise.resolve({ default: ResponsiveComponent }));
}
