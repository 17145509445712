// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.architect-coming-soon-block{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 175px;
    background-color: var(--background-light);
    box-shadow: 0px 0px 2px 2px rgba(88,255,186,0.5);
    border-radius: 15px;
}

.architect-coming-soon-text{
    font-family: var(--font-roboto),sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: var(--basic-btn-color);
}`, "",{"version":3,"sources":["webpack://./src/Page/Project Assistance/ProjectAssistance.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IAGzC,gDAAgD;IAChD,mBAAmB;AACvB;;AAEA;IACI,0CAA0C;IAC1C,eAAe;IACf,iBAAiB;IACjB,6BAA6B;AACjC","sourcesContent":[".architect-coming-soon-block{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 175px;\n    background-color: var(--background-light);\n    -webkit-box-shadow: 0px 0px 2px 2px rgba(88,255,186,0.5);\n    -moz-box-shadow: 0px 0px 2px 2px rgba(88,255,186,0.5);\n    box-shadow: 0px 0px 2px 2px rgba(88,255,186,0.5);\n    border-radius: 15px;\n}\n\n.architect-coming-soon-text{\n    font-family: var(--font-roboto),sans-serif;\n    font-size: 28px;\n    font-weight: bold;\n    color: var(--basic-btn-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
