import React, { useCallback, useEffect, useState } from 'react';
import { IconSettingsStep2Products } from "../../../IconComponents/IconComponents";
import { BOMItemType, BOMProject } from "../../../types/ProjectBOMInterface";
import { Fragment, ItemType, TItem } from "../../../types/TemplateType";
import { addItemsToFragmentsByLink, get, postBomEditItems } from "../../../api/APIWraper";
import FragmentComponentModal from "./FragmentComponentModal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface FragmentsComponentProps {
    fragments: Fragment[];
    onToggle: (fragment: string) => void;
    replace: boolean;
    selectedItemsStep3: { [key: string]: BOMItemType };
    selectedItems: { [key: string]: any };
    setProjectBom: React.Dispatch<React.SetStateAction<BOMProject | null>>;
    onSelectedFragmentsChange: (selectedFragments: Fragment[]) => void;
}

const FragmentsComponent: React.FC<FragmentsComponentProps> = ({
                                                                   fragments,
                                                                   onToggle,
                                                                   replace,
                                                                   setProjectBom,
                                                                   onSelectedFragmentsChange
                                                               }) => {
    const [fragment, setFragment] = useState<Fragment[]>(fragments);
    const [itemsFragment, setItemsFragment] = useState<TItem[]>([]);
    const [itemTypeId, setItemTypeId] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortDir, setSortDir] = useState<string>('asc');
    const [page, setPage] = useState<number>(1);
    const [isModalOpenFragment, setIsModalOpenFragment] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalSearchResults, setTotalSearchResults] = useState<number>(0);
    const [selectedItemsByItemType, setSelectedItemsByItemType] = useState<{ [key: number]: TItem[] }>({});
    const { link } = useParams<{ link: string }>();
    const [itemTypeQtyByFragment, setItemTypeQtyByFragment] = useState<number>(0)
    const [selectedFragments, setSelectedFragments] = useState<Fragment[]>([]);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [totalPrices, setTotalPrices] = useState<{ [key: string]: number }>({});


    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (itemTypeId !== null && isModalOpenFragment) {
            fetchItems();
        }
    }, [page, searchTerm, sortDir, itemTypeId, isModalOpenFragment]);

    useEffect(() => {
        const storageKey = 'selectedFragmentBom';
        const data = JSON.parse(localStorage.getItem(storageKey) || '[]');
        setSelectedFragments(data);
    }, [onSelectedFragmentsChange]);

    useEffect(() => {
        const initialSelectedItems: { [key: number]: TItem[] } = {};

        fragments.forEach(fragment => {
            if (fragment.data && fragment.data.item_types) {
                (fragment.data.item_types as unknown as ItemType[]).forEach(itemType => {
                    if (itemType.items && itemType.items.length > 0) {
                        initialSelectedItems[itemType.id] = itemType.items;
                    }
                });
            }
        });

        setSelectedItemsByItemType(initialSelectedItems);
    }, [fragments]);

    const handleOpenModal = async (id: number, qty: number) => {
        setItemTypeId(id);
        setItemTypeQtyByFragment(qty)
        setIsModalOpenFragment(true);

        const fragment = fragments.find(f => (f.data.item_types as unknown as ItemType[]).some(it => it.id === id));
        if (fragment) {
            const itemType = (fragment.data.item_types as unknown as ItemType[]).find(it => it.id === id);
            if (itemType) {
                setItemsFragment(itemType.items || []);

                const previouslySelectedItems = selectedItemsByItemType[id] || [];
                setItemsFragment(previouslySelectedItems);
            }
        }
    };

    const handleCloseModal = () => {
        setIsModalOpenFragment(false);
    };

    const fetchItems = useCallback(async () => {
        if (itemTypeId === null) {
            return;
        }

        let url = '';
        url = searchTerm
            ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5&title=${encodeURIComponent(searchTerm)}&sortOrder=${sortDir}`
            : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5&sortOrder=${sortDir}`;

        setItemsFragment([]);

        try {
            const response = await get(url);
            setItemsFragment(response.data.items.data);
            setTotalItems(response.data.items.pagination.lastPage);
            setTotalSearchResults(response.data.items.pagination.total);
        } catch (error) {
            console.error("Error when including elements\n:", error);
        }
    }, [page, searchTerm, sortDir, itemTypeId]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setPage(1);
    };

    const handleSortChange = () => {
        setSortDir(prevSortDir => (prevSortDir === 'asc' ? 'desc' : 'asc'));
        setPage(1);
    };

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    const handleSelectItems = (selectedItems: TItem[]) => {
        if (itemTypeId !== null) {
            setSelectedItemsByItemType((prev) => ({
                ...prev,
                [itemTypeId]: selectedItems,
            }));
        }

        handleCloseModal();
    };

    const addItemToFragment = async () => {
        const fragmentsToSave: {
            fragment_id: number;
            fragment_index: number;
            item_type_id: number;
            item_type_index: number;
            items: { id: number; qty: number }[];
        }[] = [];

        fragment.forEach((fragmentData, fragmentIndex) => {
            if (fragmentData.data && fragmentData.data.item_types) {
                (fragmentData.data.item_types as unknown as ItemType[]).forEach((itemType, itemTypeIndex) => {
                    const selectedItems = selectedItemsByItemType[itemType.id] || [];

                    const items = selectedItems.map((item) => ({
                        id: item.id,
                        qty: item.qty,
                    }));

                    if (items.length > 0) {
                        fragmentsToSave.push({
                            fragment_id: fragmentData.id,
                            fragment_index: fragmentIndex,
                            item_type_id: itemType.id,
                            item_type_index: itemTypeIndex,
                            items,
                        });
                    }
                });
            }
        });

        const body = {
            fragments: fragmentsToSave,
        };

        return await addItemsToFragmentsByLink(link, body)
    };


    const handleSave = async () => {
        const responseAddItemFragment = await addItemToFragment()
        if (responseAddItemFragment.status === 200 || responseAddItemFragment.status === 201) {


            const fragmentsToSave: {
                fragment_id: number;
                fragment_index: number;
                item_type_id: number;
                item_type_index: number;
                item_id: number;
                ordered_qty: number;
            }[] = [];

            fragment.forEach((fragmentData, fragmentIndex) => {
                if (fragmentData.data && fragmentData.data.item_types) {
                    (fragmentData.data.item_types as unknown as ItemType[]).forEach((itemType, itemTypeIndex) => {
                        const selectedItems = selectedItemsByItemType[itemType.id] || [];

                        selectedItems.forEach((item) => {
                            fragmentsToSave.push({
                                fragment_id: fragmentData.id,
                                fragment_index: fragmentIndex,
                                item_type_id: itemType.id,
                                item_type_index: itemTypeIndex,
                                item_id: item.id,
                                ordered_qty: item.ordered_qty || 0,
                            });
                        });
                    });
                }
            });

            const body = {
                fragments: fragmentsToSave,
            };

            try {
                const response = await postBomEditItems(body, link);
                if (response.status === 200) {
                    setFragment(prevFragment => {
                        return prevFragment.map((fragment) => {
                            return {
                                ...fragment,
                                data: {
                                    ...fragment.data,
                                    item_types: (fragment.data.item_types as unknown as ItemType[]).map((itemType) => ({
                                        ...itemType,
                                        items: selectedItemsByItemType[itemType.id] || itemType.items,
                                    })),
                                },
                            } as unknown as Fragment;
                        });
                    });
                    setProjectBom(response.data)
                    toast.success('You have successfully replaced the products in fragments');
                }
            } catch (error) {
                console.error("Error saving items:", error);
                toast.error('An error occurred while saving the items.');
            }
        } else {
            console.error('Failed to save project\n')
        }
    };


    const handleToggleFragment = () => {
        setFragment([...fragments]);

        const initialSelectedItems: { [key: number]: TItem[] } = {};

        fragments.forEach(fragment => {
            if (fragment.data && fragment.data.item_types) {
                (fragment.data.item_types as unknown as ItemType[]).forEach(itemType => {
                    if (itemType.items && itemType.items.length > 0) {
                        initialSelectedItems[itemType.id] = itemType.items;
                    }
                });
            }
        });

        setSelectedItemsByItemType(initialSelectedItems);

        onToggle('Fragment');
    };


    const recalculateTotalPrice = (existingData: Fragment[]) => {
        const prices: { [key: string]: number } = {};

        existingData.forEach((dataFragment) => {
            const globalFragment = fragments.find(
                (fragment) => fragment.id === dataFragment.id
            );

            if (globalFragment) {
                const key = `${globalFragment.id}-${fragments.indexOf(globalFragment)}`;

                prices[key] = (dataFragment.data?.item_types as unknown as ItemType[] || []).reduce(
                    (itemTypeAcc: number, itemType: ItemType) => {
                        const selectedItems = itemType.items || [];
                        return itemTypeAcc + selectedItems.reduce((itemAcc: number, item: TItem) => {
                            return itemAcc + ((item.qty || 0) * parseFloat(String(item.price || 0)));
                        }, 0);
                    },
                    0
                );
            }
        });

        setTotalPrices(prices);
    };



    const updateLocalStorage = (item: TItem, fragmentId: number, itemTypeId: number, checked: boolean) => {
        const storageKey = 'selectedFragmentBom';
        const existingData = JSON.parse(localStorage.getItem(storageKey) || '[]');

        const fragmentIndex = existingData.findIndex((f: Fragment) => f.id === fragmentId);

        if (checked) {
            if (fragmentIndex === -1) {
                const newFragment = fragments.find(f => f.id === fragmentId);
                if (newFragment) {
                    const updatedFragment = {
                        ...newFragment,
                        data: {
                            ...newFragment.data,
                            item_types: (newFragment.data.item_types as unknown as ItemType[]).map((type: ItemType) =>
                                type.id === itemTypeId ? { ...type, items: [item] } : { ...type, items: [] }
                            ),
                        },
                    };
                    existingData.push(updatedFragment);
                }
            } else {
                const fragment = existingData[fragmentIndex];
                const updatedItemTypes = (fragment.data.item_types as unknown as ItemType[]).map((type: ItemType) => {
                    if (type.id === itemTypeId) {
                        const updatedItems = [...type.items.filter((i: TItem) => i.id !== item.id), item];
                        return { ...type, items: updatedItems };
                    }
                    return { ...type };
                });
                existingData[fragmentIndex] = { ...fragment, data: { ...fragment.data, item_types: updatedItemTypes } };
            }
        } else {
            if (fragmentIndex !== -1) {
                const fragment = existingData[fragmentIndex];
                const updatedItemTypes = (fragment.data.item_types as unknown as ItemType[])
                    .map((type: ItemType) => {
                        if (type.id === itemTypeId) {
                            const updatedItems = type.items.filter((i: TItem) => i.id !== item.id);
                            return { ...type, items: updatedItems };
                        }
                        return type;
                    })
                    .filter((type: ItemType) => type.items.length > 0);

                if (updatedItemTypes.length === 0) {
                    existingData.splice(fragmentIndex, 1);
                } else {
                    existingData[fragmentIndex] = { ...fragment, data: { ...fragment.data, item_types: updatedItemTypes } };
                }
            }
        }

        localStorage.setItem(storageKey, JSON.stringify(existingData));
        setSelectedFragments([...existingData]);
        onSelectedFragmentsChange([...existingData]);
        recalculateTotalPrice(existingData);
    };


    const handleAddProductsToCart = useCallback(
        (fragment: Fragment) => {
            const storageKey = 'selectedFragmentBom';
            const existingData: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

            let updatedFragments = [...existingData];
            const fragmentIndex = existingData.findIndex((f: Fragment) => f.id === fragment.id);

            if (fragmentIndex === -1) {
                updatedFragments.push(fragment);
                toast.success(`Products from fragment ${fragment.title} added to cart.`);
            } else {
                updatedFragments.splice(fragmentIndex, 1);
                toast.info(`Products from fragment ${fragment.title} removed from cart.`);
            }

            localStorage.setItem(storageKey, JSON.stringify(updatedFragments));
            setSelectedFragments(updatedFragments);
            recalculateTotalPrice(updatedFragments);
        },
        [setSelectedFragments]
    );

    useEffect(() => {
        recalculateTotalPrice(fragment);
    }, [fragment, selectedItemsByItemType]);

    useEffect(() => {
        recalculateTotalPrice(selectedFragments);
    }, [selectedFragments]);

    const isItemChecked = (fragmentId: number, itemTypeId: number, itemId: number) => {
        const fragment = selectedFragments.find((f: Fragment) => f.id === fragmentId);
        if (!fragment) return false;

        const itemType = (fragment.data.item_types as unknown as ItemType[]).find((type: ItemType) => type.id === itemTypeId);
        if (!itemType) return false;

        return itemType.items.some((i: TItem) => i.id === itemId);
    };

    const renderHeaderList = () => (
        <div className='step2-bom-header-info'>
            <p className='step2-bom-item-title-checkbox-info'>Product</p>
            <p className='step2-bom-qty-ordered-info'>QTY ordered</p>
            <p className='step2-bom-calculated-qty-info'>Calculated QTY</p>
            <p className='step2-bom-price-info'>Unit price</p>
            <p className='step2-bom-total-info'>Total</p>
            <p className='step2-bom-total-orderd-info'>Total ordered</p>
        </div>
    );

    return (
        <>
            {fragment.map((fragment, fragmentIndex) => {
                const totalPriceKey = `${fragment.id}-${fragmentIndex}`;
                return (
                    <div className='step2-content-block' key={totalPriceKey}>
                        <div className='step2-bom-bom-project-title'>
                            <p className='step2-bom-title'>{fragment.title}</p>
                            <div onClick={handleToggleFragment} className='material-setting-block'>
                                <div><IconSettingsStep2Products /></div>
                                <p>Select Products</p>
                            </div>
                        </div>

                        {renderHeaderList()}

                        {(fragment.data.item_types as unknown as ItemType[]).map((itemType: ItemType, itemTypeIndex: number) => (
                            <div key={`${fragmentIndex}-${itemTypeIndex}`}>
                                <div className='step2-bom-items'>
                                    <div className='step2-bom-item-title-checkbox'>
                                        {replace ? (
                                            <div
                                                className='step2-bom-item-title-replace'
                                                onClick={() => handleOpenModal(itemType.id, itemType.qty)}
                                            >
                                                <p className='step2-bom-item-title'>{itemType.title}</p>
                                                <span className={`arrow-right`}></span>
                                            </div>
                                        ) : (
                                            <p className='step2-bom-item-title'>{itemType.title}</p>
                                        )}
                                    </div>
                                    <p className='step2-bom-calculated-qty'>{itemType.ordered_qty || 0}</p>
                                    <p className='step2-bom-qty-ordered'>{itemType.qty}</p>
                                    <p className='step2-bom-price'>-</p>
                                    <p className='step2-bom-total'>-</p>
                                    <p className='step2-bom-total-orderd'>-</p>
                                </div>

                                {(selectedItemsByItemType[itemType.id] || []).map((item, itemIndex) => {
                                    return (
                                        <div key={`${fragmentIndex}-${itemTypeIndex}-${itemIndex}`}
                                             className='step2-bom-items'>
                                            <div className='step2-bom-item-title-checkbox'>
                                                {replace ? (
                                                    <p className='step2-bom-item-title'>{item.title}</p>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="checkbox"
                                                            className="step2-bom-input-checkbox"
                                                            checked={isItemChecked(fragment.id, itemType.id, item.id)}
                                                            onChange={(e) =>
                                                                updateLocalStorage(item, fragment.id, itemType.id, e.target.checked)
                                                            }
                                                        />

                                                        <p className='step2-bom-item-title'>{item.title}</p>
                                                    </>
                                                )}
                                            </div>
                                            <p className='step2-bom-calculated-qty'>{item.ordered_qty || 0}</p>
                                            <p className='step2-bom-qty-ordered'>{item.qty || 0}</p>
                                            <p className='step2-bom-price'>R {parseFloat(String(item.price)).toFixed(2)}</p>
                                            <p className='step2-bom-total'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                            <p className='step2-bom-total-orderd'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                        <div className='step3-bom-bottom-total-block-price'>
                            <p>Total:</p>
                            {isSmallScreen && (<span className="dashed-line"></span>)}
                            <span>R {(totalPrices[totalPriceKey] || 0).toFixed(2)}</span>
                            <button
                                onClick={() => handleAddProductsToCart(fragment)}
                                className='step2-bottom-block-btn-cart-list'>
                                Add products to cart
                            </button>
                        </div>
                    </div>
                );
            })}
            <FragmentComponentModal
                isOpen={isModalOpenFragment}
                onClose={handleCloseModal}
                items={itemsFragment}
                currentPage={page}
                totalItems={totalItems}
                itemsPerPage={5}
                onPageChange={setPage}
                onSelectItems={handleSelectItems}
                onSortChange={handleSortChange}
                onSearch={handleSearchChange}
                onNextPage={handleNextPage}
                onPrevPage={handlePrevPage}
                itemTypeQtyByFragment={itemTypeQtyByFragment}
                totalSearchResults={totalSearchResults}
                initialSelectedItems={selectedItemsByItemType[itemTypeId ? itemTypeId : 0] || []}
            />
            {replace && (
                <div className='save-button-step2bom-block'>
                    <button onClick={handleSave} className='save-button-step2bom'>Save</button>
                </div>
            )}
        </>
    );
};

export default FragmentsComponent;
