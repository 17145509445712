import React, {useState, useEffect, ChangeEvent} from 'react';
import Modal from 'react-modal';
import {TItem} from "../../../types/TemplateType";
import './ItemTypeBomModal.css';
import 'react-toastify/dist/ReactToastify.css';
import {toastItemTrue} from "../../../components/Toast/AssisCreateToastNotification";
import {IconArrowBottom, IconArrowTop, IconReload, IconSearch} from "../../../IconComponents/IconComponents";
import {Loader} from "../../../components/Loader/Loader";
import PaginationInModal from "../../../components/PaginationAssistance/paginationInModal";
import {toast} from "react-toastify";

interface ItemsBomModalProps {
    isOpen: boolean;
    onClose: () => void;
    items: TItem[];
    loading: boolean;
    onReload?: () => void;
    currentPage: number;
    totalItems: number;
    itemsPerPage: number;
    onPageChange: (page: number) => void;
    onSearch?: (query: string) => void;
    onSortChange?: (direction: 'asc' | 'desc') => void;
    sortDirection?: 'asc' | 'desc';
    totalSearchResults?: number;
    onSelectItems: (selectedItems: TItem[]) => void;
    initialSelectedItems?: TItem[];
    itemTypeQtyByFragment: number;
}

const FragmentItemsBomModal: React.FC<ItemsBomModalProps> = ({
                                                                 isOpen,
                                                                 onClose,
                                                                 items = [],
                                                                 loading,
                                                                 onReload,
                                                                 currentPage,
                                                                 totalItems,
                                                                 itemsPerPage,
                                                                 onPageChange,
                                                                 onSearch,
                                                                 onSortChange,
                                                                 totalSearchResults,
                                                                 onSelectItems,
                                                                 initialSelectedItems = [],
                                                                 itemTypeQtyByFragment
                                                             }) => {
    const [addedItems, setAddedItems] = useState<TItem[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            setAddedItems(initialSelectedItems);
        } else {
            document.body.style.overflow = '';
            resetState();
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen, initialSelectedItems]);

    const resetState = () => {
        setAddedItems([]);
        setSearchTerm('');
    };

    const handleConfirmSelection = () => {
        if (!addedItems.length) {
            toast.error(`You must select at least one product.`);
            return;
        }
        if(totalQty !== itemTypeQtyByFragment){
            toast.error(`You will need to fill out the field completely. ${itemTypeQtyByFragment-totalQty} units remaining.`);
            return;
        }

        onSelectItems(addedItems);
        onClose();
    };

    const handleAddItemClick = (item: TItem) => {
        const isAlreadyAdded = addedItems.some(addedItem => addedItem.id === item.id);
        if (isAlreadyAdded) {
            toast.error("This item is already added.");
            return;
        }

        setAddedItems(prev => [...prev, {...item, qty: 0}]);
        toastItemTrue(`${item.title} added`);
    };

    const handleRemoveItemClick = (item: TItem) => {
        setAddedItems(prev => prev.filter(addedItem => addedItem.id !== item.id));
        toast.error(`${item.title} removed`);
    };

    const handleClearItemQty = (itemId: number) => {
        setAddedItems(prev =>
            prev.map(item =>
                item.id === itemId ? {...item, qty: 0} : item
            )
        );
    };

    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchTerm(query);
        if (searchTimer) {
            clearTimeout(searchTimer);
        }
        const timer = setTimeout(() => {
            if (onSearch) {
                onSearch(query);
            }
        }, 500);
        setSearchTimer(timer);
    };

    const calculateTotalQty = () => {
        return addedItems.reduce((acc, item) => acc + (item.qty || 0), 0);
    };

    const calculateTotalCost = () => {
        return addedItems.reduce((acc, item) => acc + (item.qty || 0) * (item.price || 0), 0);
    };

    const handleQtyChange = (itemId: number, newQty: number) => {
        const totalQty = calculateTotalQty();
        const foundItem = addedItems.find(item => item.id === itemId);
        const remainingQty = foundItem ? itemTypeQtyByFragment - totalQty + foundItem.qty : itemTypeQtyByFragment - totalQty;

        setAddedItems(prev =>
            prev.map(item =>
                item.id === itemId ? { ...item, qty: Math.min(newQty, remainingQty) } : item
            )
        );
    };


    const handleFillOutItemQty = (itemId: number) => {
        const totalQty = calculateTotalQty();
        const remainingQty = itemTypeQtyByFragment - totalQty;

        if (remainingQty > 0) {
            setAddedItems(prev =>
                prev.map(item =>
                    item.id === itemId ? {...item, qty: (item.qty || 0) + remainingQty} : item
                )
            );
        }
    };

    const highlightMatch = (text: string, match: string) => {
        if (!match) return text;
        const regex = new RegExp(`(${match})`, 'gi');
        return text.replace(regex, (matched) => `<span class="item-selection-modal-highlight">${matched}</span>`);
    };

    const totalQty = calculateTotalQty();
    const totalCost = calculateTotalCost();

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="item-selection-modal-content"
            overlayClassName="item-selection-modal-overlay"
            ariaHideApp={false}
        >
            <section className="item-selection-modal-section">
                <div className='item-selection-modal-header'>
                    <div className='item-selection-modal-header-block-text'>
                        <h2 className="item-selection-modal-title">Please, select your product</h2>
                    </div>
                    <button className="item-selection-modal-close-button" onClick={onClose}>
                        &#x2715;
                    </button>
                </div>
                <div className='item-selection-modal-content-block'>
                    <div className='item-selection-modal-search'>
                        <div className='item-selection-modal-search-icon'>
                            <IconSearch/>
                        </div>
                        <input
                            className='item-selection-modal-search-input'
                            placeholder='Search'
                            type='search'
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                        />
                    </div>
                    <div className='item-selection-modal-content-header-total'>
                        <p className='item-selection-modal-header-price-p'>Total products selected: <span
                            className='item-selection-modal-header-price'>R {totalCost.toFixed(2)}</span></p>
                        <div
                            className="item-selection-modal-header-calc-qty">
                            <p>Calculated QTY: </p>
                            <p><strong>{totalQty}</strong></p>
                            <p><strong>{itemTypeQtyByFragment}</strong></p>
                        </div>
                    </div>
                </div>
                <div className='item-selection-modal-sort-icons'>
                    <div className='item-selection-modal-sort-buttons'>
                        <div className='item-selection-modal-sort-button' onClick={onReload}>
                            <IconReload/>
                        </div>
                        <div className='item-selection-modal-sort-button'
                             onClick={() => onSortChange && onSortChange('desc')}>
                            <IconArrowTop/>
                        </div>
                        <div className='item-selection-modal-sort-button'
                             onClick={() => onSortChange && onSortChange('asc')}>
                            <IconArrowBottom/>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader/>
                ) : (
                    <ul className="item-selection-modal-list">
                        {items.map((item: TItem) => {
                            const isAdded = addedItems.some(addedItem => addedItem.id === item.id);
                            const currentItem = addedItems.find(addedItem => addedItem.id === item.id);
                            const currentQty = currentItem ? currentItem.qty : 0;
                            const isDisabled = totalQty >= itemTypeQtyByFragment;

                            return (
                                <li key={item.id} className="item-selection-modal-list-item">
                                    <section className="item-type-bom-modal-list-item-content">
                                        <div className="item-selection-modal-list-item-image">
                                            <img
                                                className="item-selection-modal-image"
                                                src={
                                                    item.merchant_info?.image ||
                                                    (item.galleries?.[0]?.medias?.[0]?.url) ||
                                                    '/img_vendor/item.png'
                                                }
                                                alt="Item"
                                            />
                                            <section className="item-selection-modal-list-item-info">
                                                <p
                                                    className="item-selection-modal-item-title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: highlightMatch(item.title, searchTerm)
                                                    }}
                                                />
                                                <p className="item-selection-modal-item-price">R {item.price ? Number(item.price).toFixed(2) : '0.00'}</p>
                                            </section>
                                        </div>

                                        {isAdded && (
                                            <div className="item-selection-modal-quantity-input">
                                                <button
                                                    className="item-selection-modal-qty-button"
                                                    onClick={() => handleQtyChange(item.id, Math.max(0, currentQty - 1))}
                                                    disabled={isDisabled && currentQty === 0}
                                                >
                                                    -
                                                </button>
                                                <input
                                                    type="number"
                                                    value={currentQty}
                                                    onChange={(e) =>
                                                        handleQtyChange(item.id, Math.max(0, Number(e.target.value)))
                                                    }
                                                    className="item-selection-modal-qty-input-field"
                                                    disabled={isDisabled && currentQty === 0}
                                                />
                                                <button
                                                    className="item-selection-modal-qty-button"
                                                    onClick={() =>
                                                        handleQtyChange(item.id, currentQty + 1)
                                                    }
                                                    disabled={isDisabled && currentQty === 0}
                                                >
                                                    +
                                                </button>

                                            </div>
                                        )}
                                        {isAdded && (
                                            <>
                                                <button
                                                    onClick={() => handleFillOutItemQty(item.id)}
                                                    className={`item-selection-modal-button ${currentQty >= itemTypeQtyByFragment ? 'remove-item' : 'add-item'}`}
                                                    disabled={totalQty >= itemTypeQtyByFragment}
                                                >
                                                    Fill Out
                                                </button>
                                                <button
                                                    onClick={() => handleClearItemQty(item.id)}
                                                    className="item-selection-modal-button add-item"
                                                >
                                                    Clear
                                                </button>
                                            </>
                                        )}


                                        <div className='item-type-bom-modal-action-block'>
                                            {isAdded ? (
                                                <button
                                                    onClick={() => handleRemoveItemClick(item)}
                                                    className="item-selection-modal-button remove-item"
                                                >
                                                    Remove
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => handleAddItemClick(item)}
                                                    className="item-selection-modal-button add-item"
                                                    disabled={isDisabled}
                                                >
                                                    Add
                                                </button>
                                            )}
                                        </div>
                                    </section>
                                </li>
                            );
                        })}
                    </ul>
                )}
                <div className='item-selection-modal-save-button-block'>
                    <div>
                        <PaginationInModal
                            perPage={itemsPerPage}
                            total={searchTerm ? totalSearchResults || 0 : totalItems}
                            paginate={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                    <button
                        onClick={handleConfirmSelection}
                        className="item-selection-modal-save-button"
                    >
                        Confirm selection
                    </button>
                </div>
            </section>
        </Modal>
    );
}

export default FragmentItemsBomModal;
