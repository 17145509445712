import React, { useState, useEffect, ChangeEvent } from 'react';
import Modal from 'react-modal';
import {Fragment} from "../../../types/TemplateType";
import './ItemTypeBomModal.css';
import 'react-toastify/dist/ReactToastify.css';
import {
    toastItemCheckboxFalse,
    toastItemTrue
} from "../../../components/Toast/AssisCreateToastNotification";
import { IconArrowBottom, IconArrowTop, IconReload, IconSearch } from "../../../IconComponents/IconComponents";
import { Loader } from "../../../components/Loader/Loader";
import PaginationInModal from "../../../components/PaginationAssistance/paginationInModal";
import ModalCalculateFragment from "./ModalCalculateFragment";

interface ItemTypeBomModalProps {
    handleSaveProject:()=>void
    isOpen: boolean,
    onClose: () => void,
    fragments: Fragment[],
    loading: boolean,
    onReload?: () => void,
    currentPage: number,
    totalItems: number,
    itemsPerPage: number,
    onPageChange: (page: number) => void,
    onSearch?: (query: string) => void,
    onSortChange?: (direction: 'asc' | 'desc') => void,
    sortDirection?: 'asc' | 'desc',
    totalSearchResults?: number,
    onSelectItems: (selectedItems: Fragment[]) => void;
    initialSelectedItems?: Fragment[];
    onFragmentCountChange: (itemId: number, count: number) => void;
    fragmentCount: { [key: number]: number };
}


const FragmentBomModal: React.FC<ItemTypeBomModalProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               fragments = [],
                                                               loading,
                                                               onReload,
                                                               currentPage,
                                                               totalItems,
                                                               itemsPerPage,
                                                               onPageChange,
                                                               onSearch,
                                                               onSortChange,
                                                               totalSearchResults,
                                                               onSelectItems,
                                                               initialSelectedItems,
                                                               onFragmentCountChange,
                                                               fragmentCount,
                                                           }) => {
    const [selectedItemTypesState, setSelectedItemTypesState] = useState<{ [id: number]: Fragment[] }>({});
    const [isCalculateModalOpen, setIsCalculateModalOpen] = useState<boolean>(false);
    const [updatedFragments, setUpdatedFragments] = useState<Fragment[]>([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
    const [selectedFragment, setSelectedFragment] = useState<Fragment | null>(null);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
            resetState();
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);


    useEffect(() => {
        if (isOpen && initialSelectedItems) {
            const newSelectedItemTypes: { [id: number]: Fragment[] } = {};

            initialSelectedItems.forEach(itemType => {
                newSelectedItemTypes[itemType.id] = newSelectedItemTypes[itemType.id] || [];
                newSelectedItemTypes[itemType.id].push(itemType);
            });

            setSelectedItemTypesState(newSelectedItemTypes);
        }
    }, [isOpen, initialSelectedItems]);



    const handleCloseCalculateModal = (updatedFragment: Fragment | null) => {
        setIsCalculateModalOpen(false);
        if (updatedFragment) {
            setUpdatedFragments((prevFragments) => {
                const fragmentIndex = prevFragments.findIndex(f => f.id === updatedFragment.id);
                if (fragmentIndex !== -1) {
                    return prevFragments.map(f =>
                        f.id === updatedFragment.id ? updatedFragment : f
                    );
                } else {
                    return [...prevFragments, updatedFragment];
                }
            });
        } else if (selectedFragment) {
            setSelectedItemTypesState(prevState => {
                const newState = { ...prevState };
                delete newState[selectedFragment.id];
                return newState;
            });
            toastItemCheckboxFalse(`Removed ${selectedFragment.title}`);
            onFragmentCountChange(selectedFragment.id, -1);
        }

        setSelectedFragment(null);
    };



    const resetState = () => {
        setSelectedItemTypesState({});
        setSearchTerm('');
    };

    const handleConfirmSelection = async () => {
        let selectedItems: Fragment[] = Object.values(selectedItemTypesState).flat();
        updatedFragments.forEach(updatedFragment => {
            const index = selectedItems.findIndex(item => item.id === updatedFragment.id);
            if (index !== -1) {
                selectedItems[index] = updatedFragment;
            } else {
                selectedItems.push(updatedFragment);
            }
        });
        onSelectItems(selectedItems);
            onClose();
    };

    const handleAddFragmentClick = (fragment: Fragment) => {
        setSelectedFragment(fragment);

        setIsCalculateModalOpen(true);
        setSelectedItemTypesState(prevState => ({
            ...prevState,
            [fragment.id]: [...(prevState[fragment.id] || []), { ...fragment }]
        }));
        toastItemTrue(`${fragment.title} added`);
        onFragmentCountChange(fragment.id, 1);
    };


    const handleRemoveFragmentClick = (fragment: Fragment) => {
        setSelectedItemTypesState(prevState => {
            const currentArray = prevState[fragment.id] || [];
            if (currentArray.length === 0) return prevState;

            return {
                ...prevState,
                [fragment.id]: currentArray.slice(0, -1)
            };
        });
        toastItemCheckboxFalse(`Removed ${fragment.title}`);
        onFragmentCountChange(fragment.id, -1);
    };



    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchTerm(query);
        if (searchTimer) {
            clearTimeout(searchTimer);
        }
        const timer = setTimeout(() => {
            if (onSearch) {
                onSearch(query);
            }
        }, 500);
        setSearchTimer(timer);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="item-selection-modal-content"
            overlayClassName="item-selection-modal-overlay"
            ariaHideApp={false}
        >
            <section className="item-selection-modal-section">
                <div className='item-selection-modal-header'>
                    <div className='item-selection-modal-header-block-text'>
                        <h2 className="item-selection-modal-title">Please, select your fragment</h2>
                    </div>
                    <button className="item-selection-modal-close-button" onClick={onClose}>
                        &#x2715;
                    </button>
                </div>
                <div className='item-selection-modal-search'>
                    <div className='item-selection-modal-search-icon'>
                        <IconSearch />
                    </div>
                    <input
                        className='item-selection-modal-search-input'
                        placeholder='Search'
                        type='search'
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                </div>
                <div className='item-selection-modal-sort-icons'>
                    <div className='item-selection-modal-sort-buttons'>
                        <div className='item-selection-modal-sort-button' onClick={onReload}>
                            <IconReload />
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => onSortChange && onSortChange('desc')}>
                            <IconArrowTop />
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => onSortChange && onSortChange('asc')}>
                            <IconArrowBottom />
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <ul className="item-selection-modal-list">
                        {fragments.map((fragment: Fragment) => {
                            const currentCount = fragmentCount[fragment.id] || 0;

                            return (
                                <li key={fragment.id} className="item-selection-modal-list-item">
                                    <section className="item-type-bom-modal-list-item-content">
                                        <div className="item-selection-modal-list-item-image">
                                            {fragment.galleries.length > 0 && fragment.galleries[0].medias[fragment.galleries[0].medias.length - 1]?.url ? (
                                                <img
                                                    className="item-selection-modal-image"
                                                    src={fragment.galleries[0].medias[fragment.galleries[0].medias.length - 1].url}
                                                    alt="Item"
                                                />
                                            ) : (
                                                <img
                                                    className="item-selection-modal-image"
                                                    src="/img_vendor/item.png"
                                                    alt="Item"
                                                />
                                            )}
                                            <section className="item-selection-modal-list-item-info">
                                                <p className="item-selection-modal-item-title">
                                                    {fragment.title}
                                                </p>
                                            </section>
                                        </div>

                                        <div className='item-type-bom-modal-action-count-block'>
                                            {currentCount > 0 && (
                                                <span className="item-type-bom-modal-action-count">{currentCount}</span>
                                            )}
                                        </div>

                                        <div className='item-type-bom-modal-action-block'>
                                            <button
                                                onClick={() => handleAddFragmentClick(fragment)}
                                                className="item-selection-modal-button add-item"
                                                style={{
                                                    cursor: 'pointer',
                                                    marginRight: '8px'
                                                }}
                                            >
                                                Add
                                            </button>
                                            <button
                                                onClick={() => handleRemoveFragmentClick(fragment)}
                                                className="item-selection-modal-button remove-item"
                                                disabled={currentCount === 0}
                                                style={{
                                                    cursor: currentCount === 0 ? 'not-allowed' : 'pointer'
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </section>
                                </li>
                            );
                        })}
                    </ul>

                )}
                <div className='item-selection-modal-save-button-block'>
                    <div>
                        <PaginationInModal
                            perPage={itemsPerPage}
                            total={searchTerm ? totalSearchResults || 0 : totalItems}
                            paginate={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                    {selectedFragment && (
                        <ModalCalculateFragment
                            isOpen={isCalculateModalOpen}
                            onClose={handleCloseCalculateModal}
                            fragment={selectedFragment}
                        />
                    )}
                    <button onClick={handleConfirmSelection} className="item-selection-modal-save-button">Confirm
                        selection
                    </button>
                </div>
            </section>
        </Modal>
    );

}

export default FragmentBomModal;
