import React, { useEffect, useRef, useState} from 'react';
import './PageHome.css'
import './PageHomeMobile.css'
import {clearLocalStorageExceptToken} from "../../../utils/ClearLocalStorage";
import {clearTemplates} from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import {useDispatch, useSelector} from "react-redux";

import CountInfoSection from "../../../components/LandingPageComponent/CountInfoSection";
import InfoBlockBlog from "../../../components/LandingPageComponent/InfoBlockBlog";
import {IconCheckboxLanding, IconCheckBoxLandingHome} from "../../../IconComponents/IconComponents";
import FAQSection from "../../../components/LandingPageComponent/FAQSection";
import SupportForm from "../../../components/LandingPageComponent/SupportForm";
import ScrollToTopButton from "../../../components/ScrollArrowUp/ScrollToTopButton";
import {get} from "../../../api/APIWraper";
import {
    notifySuccessCustom
} from "../../../components/Toast/AssisCreateToastNotification";
import {selectUser, setUser} from "../../../Slice/tokenSlice/userSlice";
import OurPartners from "../OurPartnersComponent/OurPartners";
import {RootState} from "../../../store";
import useIsSmallScreen from "../../../Hooks/SmallScreen/useIsSmallScreen";
import {useSmartNavigate} from "../../../Hooks/useNavigationHook";

const ARCHITECT = process.env.REACT_APP_ARCHITECT === "true";

function PageHome() {
    const roleCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const formRef = useRef<HTMLDivElement | null>(null);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const smartNavigate = useSmartNavigate();

    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [isMonthly, setIsMonthly] = useState(true);
    const isSmallScreen = useIsSmallScreen();

    const [isImageVisible, setIsImageVisible] = useState(true);

    const handleImageClick = () => {
        setIsImageVisible(false);
    };

    const handleToggle = () => {
        setIsMonthly(!isMonthly);
    };

    const navigateToAssistance = () => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());

            if (isAuthenticated) {
                smartNavigate("/assistance");
            } else {
                smartNavigate("/auth/login");
            }
    };

    const handleBuy = async (role: 'Contractor' | 'Architect') => {
        smartNavigate('/pricing')
    };

    const startRoleCheckInterval = () => {
        if (roleCheckIntervalRef.current) {
            clearInterval(roleCheckIntervalRef.current);
        }

        roleCheckIntervalRef.current = setInterval(async () => {
            try {
                const responseMe = await get(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/my_profile_info`);
                const serverRole = responseMe.data.role.role;
                const localRole = user?.role?.role;

                if (localRole !== serverRole) {
                    dispatch(setUser(responseMe.data))

                    clearInterval(roleCheckIntervalRef.current as NodeJS.Timeout);
                    roleCheckIntervalRef.current = null;

                    notifySuccessCustom(`You have successfully purchased a "${serverRole}" plan`);
                    setTimeout(() => {
                        window.location.href = '/assistance';
                    }, 2500);
                }
            } catch (error) {
                console.error("Error fetching user role:", error);
            }
        }, 5000);
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            const redirectOnReturn = localStorage.getItem('redirectOnReturn');
            if (redirectOnReturn === 'true') {
                localStorage.removeItem('redirectOnReturn');
                startRoleCheckInterval();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (roleCheckIntervalRef.current) {
                clearInterval(roleCheckIntervalRef.current);
                roleCheckIntervalRef.current = null;
            }
        };
    }, []);


    const structuralCard = () => {
        return (
            <>
                <div className="card-title">Structural</div>
                <div className='card-title-desc'>
                    Manual Measurement Insertion
                    Users can select projects, input measurements of required building project, customize
                    materials, and receive a comprehensive cost breakdown.
                </div>
                <div className="card-price">$12.50<span className='card-price-time'>/month</span></div>
                <div className="card-description">Unlimited Measuring</div>
                <ul className='card-list'>
                    <li><IconCheckboxLanding/> Project creation</li>
                    <li><IconCheckboxLanding/> Template selection</li>
                    <li><IconCheckboxLanding/> Measurement insertion</li>
                    <li><IconCheckboxLanding/> BoM creation</li>
                    <li><IconCheckboxLanding/> Product selection from builders’ merchants</li>
                    <li><IconCheckboxLanding/> Contractors’ workspace</li>
                </ul>

            </>
        )
    }

    const foundationCard = () => {
        return (
            <div
                className="card card-green hover-effect"
                style={!isMonthly ? {filter: 'blur(8px)'} : undefined}

            >
                <div className="card-title">Foundation</div>
                <div className='card-title-desc'>Access to basic DIY templates, change your measurements and get
                    a BoM
                </div>
                <p className='card-title-info-free'>Pay $10 per measurement on more advanced templates</p>
                <div className="card-price">Free</div>
                <div className="card-description">Manual Measurement Insertion</div>
                <ul className='card-list'>
                    <li><IconCheckboxLanding/> Project creation</li>
                    <li><IconCheckboxLanding/> Template selection</li>
                    <li><IconCheckboxLanding/> Measurement insertion</li>
                    <li><IconCheckboxLanding/> BoM creation</li>
                    <li><IconCheckboxLanding/> Product selection from builders’ merchants</li>

                </ul>
                <button className="card-button card-btn-default">Read more</button>
            </div>
        )
    }

    const arhitectCard = () => {
        return (
            <>
                <div className="card-title">Architect</div>
                <div className="card-title-desc">
                    3D digital Revit architectural plans Integration: Architects can upload 3D digital Revit
                    architectural plans and instantly receive detailed cost estimates.
                </div>
                {ARCHITECT ? (
                    <div className="card-price">$1000<span className="card-price-time">/year</span></div>
                ) : (
                    <div className="pricing-architect-card">Coming soon...</div>
                )}
                <div className="card-description">Unlimited Measuring</div>
                <ul className="card-list">
                    <li><IconCheckboxLanding/> Project creation</li>
                    <li><IconCheckboxLanding/> Plan uploading</li>
                    <li><IconCheckboxLanding/> Measurement insertion</li>
                    <li><IconCheckboxLanding/> BoM creation</li>
                    <li><IconCheckboxLanding/> Product selection from builders’ merchants</li>
                    <li><IconCheckboxLanding/> Contractors’ workspace</li>
                </ul>
            </>
        )
    }

    return (
        <main className='landing-page'>
            <section className='landing-upper-section'>
                <div className='upper-block-text-top'>
                    <p className='upper-text-bold-home'>
                        Revolutionizing home design: from Pro Blueprints to DIY dreams
                    </p>
                    <p className='upper-text-info'>
                        Welcome to the future of home improvement and architectural planning. Whether you're a seasoned
                        architect uploading complex 3D plans from AutoCAD or Revit, or a DIY enthusiast measuring up
                        your next home project, our innovative platform has you covered.
                    </p>
                    {isSmallScreen && (
                        <div className='upper-block-image'>
                            <img className='upper-block-image-screen0' src='/img_landing/tablet1.png' alt='teablet'/>
                        </div>
                    )}
                    <div className='landing-page-home-upper'>
                        <div><IconCheckBoxLandingHome/></div>
                        <p className='landing-page-home-upper-text'>For professionals, we instantly transform your
                            designs into comprehensive Bills of Materials with competitive pricing.</p>
                    </div>
                    <div className='landing-page-home-upper'>
                        <div><IconCheckBoxLandingHome/></div>
                        <p className='landing-page-home-upper-text'>For homeowners, we turn your measurements into
                            detailed project plans and shopping lists.</p>
                    </div>
                    <div className='upper-block-btn'>
                        <button onClick={navigateToAssistance} className='upper-get-started'>Get Started</button>
                        <button className='upper-see-works font-reg'> How it works</button>
                    </div>
                </div>
                {!isSmallScreen && (
                    <div className='upper-block-image'>
                        <img className='upper-block-image-screen0' src='/img_landing/tablet1.png' alt='teablet'/>
                    </div>
                )}
            </section>
            <div className='landing-middle-info-nav'>
                <p>Decades of Expertise, One Revolutionary Solution</p>
            </div>
            <CountInfoSection iconVisible={false}/>
            <InfoBlockBlog isSmallScreen={isSmallScreen}/>
            <OurPartners isSmallScreen={isSmallScreen}/>


            <section className='landing-video-section'>
                {isSmallScreen && (
                    <p className='upper-text-bold'>
                        Streamline Your Construction Planning with GoBuild BoM
                    </p>
                )}
                <div className='upper-block-video'>
                    <iframe
                        width="760"
                        height="427"
                        src="https://drive.google.com/file/d/1uIKGRljIErLZCn_iYAWV-Y9EMB7IPSmO/preview"
                        title="Google Drive video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    {isImageVisible && (
                        <div
                            className="video-overlay"
                            onClick={handleImageClick}
                        >
                            <img title='Play video' src="/img_landing/home.png" alt="Play Video"
                                 className="page-home-image"/>
                        </div>
                    )}
                </div>
                <div className='upper-block-text'>
                    {!isSmallScreen && (
                        <p className='upper-text-bold'>
                            Streamline Your Construction Planning with GoBuild BoM
                        </p>
                    )}
                    <p className='landing-video-section-text-info'>
                        GoBuild BoM (Bill of Materials) revolutionizes the way you approach construction projects.
                        Whether you're an architect with complex 3D plans or a DIY enthusiast with basic measurements,
                        our innovative tool simplifies the process of material estimation and cost calculation.
                    </p>
                    <div className='upper-section-btn'>
                        <div className='upper-block-btn'>
                            <button className='upper-get-started'>Read More</button>
                            <button className='upper-see-works'> Watch video</button>
                        </div>
                    </div>
                </div>
            </section>

            <div className='landing-middle-info-nav'>
                <p className='landing-middle-info-nav-get-start'>Let's get started</p>
                <p className='landing-middle-info-nav-info'>Choose the best plan that fit for you.</p>
            </div>

            <div className="landing-middle-info-switcher">
                <span className={`landing-middle-info-switcher-option `}>
                    Monthly
                </span>
                <div className={`landing-middle-info-switcher-toggle ${isMonthly ? '' : 'active'}`}
                     onClick={handleToggle}>
                    <div className={`landing-middle-info-switcher-knob ${isMonthly ? '' : 'active'}`}></div>
                </div>
                <span className={`landing-middle-info-switcher-option`}>
                    Yearly
                </span>
            </div>

            {!isSmallScreen ? (
                <section className='section-with-card'>
                    <div className="card-container">
                        {foundationCard()}
                        <div className="card card-green card-recommended">
                            <div className="card-recommended-label">Recommended</div>
                            {isMonthly ? structuralCard() : arhitectCard()}
                            <button
                                onClick={() => handleBuy(isMonthly ? 'Contractor' : 'Architect')}
                                className="card-button ">Read more
                            </button>
                        </div>


                        <div
                            className={`card card-orange ${!isMonthly ? 'hover-effect' : ''}`}
                            style={{filter: 'blur(8px)'}}
                        >
                            {!isMonthly ? structuralCard() : arhitectCard()}
                            <button
                                className="card-button card-btn-default"
                                style={{pointerEvents: 'none', opacity: 0.5}}
                            >
                                Read more
                            </button>
                        </div>

                    </div>
                </section>
            ) : (
                <section className='section-with-card'>
                    <div className="card-container">

                        <div className="card card-green card-recommended">
                            <div className="card-recommended-label">Recommended</div>
                            {isMonthly ? structuralCard() : arhitectCard()}
                            <button
                                onClick={() => handleBuy(isMonthly ? 'Contractor' : 'Architect')}
                                className={`card-button ${!isMonthly && 'card-btn-default'}`}>Read more
                            </button>
                        </div>
                        {isMonthly && foundationCard()}
                    </div>
                </section>
            )}


            {/*<ReviewSlider variant={'detailed'}/>*/}

            <div className='landing-middle-info-support'>
                <p className='landing-middle-info-nav-support'>Support</p>
                {!isSmallScreen ? (
                    <p className='support-form-text'>Please submit a support request by completing the form below.
                        Our team will endeavour to get back to you within 48hrs.</p>
                ) : (
                    <>
                        <p className='support-form-text'>Please submit a support request by completing the form below.</p>
                        <p className='support-form-text'> Our team will endeavour to get back to you within
                            48hrs.</p>
                    </>
                )}

            </div>
            <SupportForm ref={formRef}/>

            <FAQSection formRef={formRef}/>


            <ScrollToTopButton/>
        </main>
    );
}

export default PageHome;
