import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setSelectedLabor, clearSelectedItems } from '../../../Slice/SelectedItems/SelectedItemsSlice';
import '../Step2Styles/projectUpdateItems.css';
import { Vendor, vendorData } from "../../../Enums/VendorEnum";
import { IconEyesStep3 } from "../../../IconComponents/IconComponents";
import {
    notifySuccessCustom,
    notifyWarningCustom,
} from "../../../components/Toast/AssisCreateToastNotification";
import ILaborItemType from "../../../Interfaces/ProjectInterfaces/IlaborItemType";
import { TItem } from "../../../types/TemplateType";
import { formatNumber } from "../../../utils/FormatNumber";
import { generateItemKey, parseItemKey, useErrorMessages, useQtyHandler, useVisitedItems } from "../../../Hooks/Step2Customer/Step2Hooks";

interface LaborItemTypeListProps {
    laborItemTypes: ILaborItemType[];
    orderAllTrigger: number | null;
    setOrderAllTrigger: (value: null) => void;
    orderAllBooleanTrigger:boolean;
    isTriggeredByParent:boolean
}

const generateLaborKey = (itemType: ILaborItemType) => {
    return `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;
};

const LaborItemTypeList: React.FC<LaborItemTypeListProps & PropsFromRedux> = ({
                                                                                  laborItemTypes,
                                                                                  setSelectedLabor,
                                                                                  orderAllTrigger,
                                                                                  setOrderAllTrigger,
                                                                                  orderAllBooleanTrigger,
                                                                                  isTriggeredByParent
                                                                              }) => {
    const { handleLogoClick, isVisited } = useVisitedItems();
    const { errorMessages, setError, clearError } = useErrorMessages();
    const { debouncedOrderedQty, handleQtyChange } = useQtyHandler(setError, clearError);

    const [selectedLaborItems, setSelectedLaborItemsState] = useState<{ [key: string]: boolean }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isButtonDisabledOrderFragment, setIsButtonDisabledOrderFragment] = useState(false);

    const filteredLaborItemTypes = laborItemTypes.filter(item => item.qty > 0);

    useEffect(() => {
        syncSelectionsWithLocalStorage(filteredLaborItemTypes);
    }, [laborItemTypes]);

    useEffect(() => {
        const calculatedPrice = calculateTotalPrice(filteredLaborItemTypes);
        setTotalPrice(calculatedPrice);
        setSelectedLabor(Date.now());
    }, [filteredLaborItemTypes, setSelectedLabor]);

    useEffect(() => {
        if (orderAllTrigger !== null) {
            handleOrderFragment(isTriggeredByParent);
        }
    }, [orderAllTrigger]);

    useEffect(() => {
        const hasItems = filteredLaborItemTypes.some(
            (itemType) => itemType.item_type.items && itemType.item_type.items.length > 0
        );
        setIsButtonDisabledOrderFragment(!hasItems);
    }, [filteredLaborItemTypes]);

    const syncSelectionsWithLocalStorage = (items: ILaborItemType[]) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const updatedLaborSelections: { [key: string]: boolean } = {};

        items.forEach(item => {
            const fragmentKey = `${item.fragment_id}-${item.fragment_index}-${item.template_index}`;
            const laborKey = generateLaborKey(item);
            if (storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[laborKey]) {
                updatedLaborSelections[laborKey] = true;
            }
        });
        setSelectedLaborItemsState(updatedLaborSelections);
    };

    const calculateTotalPrice = (items: ILaborItemType[]) => {
        let totalPrice = 0;

        const groupedItems: { [key: number]: ILaborItemType[] } = {};
        items.forEach(item => {
            if (!groupedItems[item.item_type.id]) {
                groupedItems[item.item_type.id] = [];
            }
            groupedItems[item.item_type.id].push(item);
        });

        Object.values(groupedItems).forEach(group => {
            let totalQty = 0;
            let totalOrderedQty = 0;
            group.forEach(item => {
                item.item_type.items?.forEach((laborItem: TItem) => {
                    totalQty += laborItem.qty || 0;
                    totalOrderedQty += laborItem.ordered_qty || 0;
                });
            });
            const price = parseFloat(String(group[0].item_type.items?.[0]?.price)) || 0;
            const difTotalQty = totalQty - totalOrderedQty;
            const priceTotal = difTotalQty > 0 ? price * difTotalQty : 0;
            totalPrice += priceTotal;
        });

        return totalPrice;
    };

    const mergeAndSaveLocalStorage = (itemType: ILaborItemType) => {
        if (!itemType.item_type.items || itemType.item_type.items.length === 0) {
            return;
        }
        const storedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
        const laborKey = generateLaborKey(itemType);

        if (!storedFragments[fragmentKey]) {
            storedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
        }

        const laborItems = storedFragments[fragmentKey].LaborItemTypes || {};

        let userBuyValue = Number(debouncedOrderedQty[laborKey]) || 0;
        if (userBuyValue <= 0) {
            return;
        }

        if (!laborItems[laborKey]) {
            laborItems[laborKey] = {
                ...itemType.item_type,
                items: itemType.item_type.items.map((laborItem: TItem) => ({
                    ...laborItem,
                    user_buy: userBuyValue,
                    payload: generatePayload(itemType),
                })),
                payload: generatePayload(itemType),
            };
        } else {
            itemType.item_type.items.forEach(laborItem => {
                const existing = laborItems[laborKey].items || [];
                if (!existing.some((i: any) => i.id === laborItem.id)) {
                    existing.push({
                        ...laborItem,
                        user_buy: userBuyValue,
                        payload: generatePayload(itemType),
                    });
                }
            });
        }

        storedFragments[fragmentKey].LaborItemTypes = laborItems;
        localStorage.setItem('selectedFragments', JSON.stringify(storedFragments));
    };

    const removeFromLocalStorage = (itemType: ILaborItemType) => {
        const storedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
        const laborKey = generateLaborKey(itemType);

        if (storedFragments[fragmentKey]?.LaborItemTypes) {
            delete storedFragments[fragmentKey].LaborItemTypes[laborKey];
            if (Object.keys(storedFragments[fragmentKey].LaborItemTypes).length === 0) {
                delete storedFragments[fragmentKey].LaborItemTypes;
            }
        }
        localStorage.setItem('selectedFragments', JSON.stringify(storedFragments));
    };

    const generatePayload = (itemType: ILaborItemType): any => ({
        template_index: itemType.template_index,
        fragment_index: itemType.fragment_index,
        item_type_index: itemType.item_type_index,
        template_id: itemType.template_id,
        fragment_id: itemType.fragment_id,
        item_type_id: itemType.item_type.id,
        item_ids: itemType.item_type.items?.map(i => i.id) || [],
    });

    const handleCheckboxChange = (itemType: ILaborItemType) => {
        const laborKey = generateLaborKey(itemType);
        const currentQty = debouncedOrderedQty[laborKey] || "";

        if (Number(currentQty) <= 0) {
            notifyWarningCustom("You need to enter a value in the “QTY to order” input field");
            return;
        }

        const isSelected = !selectedLaborItems[laborKey];
        const updatedSelections = { ...selectedLaborItems, [laborKey]: isSelected };
        setSelectedLaborItemsState(updatedSelections);

        if (isSelected) {
            filteredLaborItemTypes.forEach(originalItem => {
                if (originalItem.item_type.id === itemType.item_type.id) {
                    mergeAndSaveLocalStorage(originalItem);
                }
            });
        } else {
            filteredLaborItemTypes.forEach(originalItem => {
                if (originalItem.item_type.id === itemType.item_type.id) {
                    removeFromLocalStorage(originalItem);
                }
            });
        }

        const calculatedPrice = calculateTotalPrice(filteredLaborItemTypes);
        setTotalPrice(calculatedPrice);
        setSelectedLabor(Date.now());
    };

    const handleOrderFragment = (fromParent: boolean = false) => {
        setOrderAllTrigger(null);
        if (isButtonDisabledOrderFragment) return;

        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        let updatedSelections = { ...selectedLaborItems };

        const validLaborItems: ILaborItemType[] = [];
        const seen = new Set<number>();
        filteredLaborItemTypes.forEach(itemType => {
            const laborKey = generateLaborKey(itemType);
            if (Number(debouncedOrderedQty[laborKey] || "0") > 0) {
                if (!seen.has(itemType.item_type.id)) {
                    validLaborItems.push(itemType);
                    seen.add(itemType.item_type.id);
                }
            }
        });

        if (validLaborItems.length === 0) {
            if (orderAllTrigger === null) {
                notifyWarningCustom('You need to enter a value in the “QTY to order” input field');
            }
            return;
        }

        const missingLaborItems: ILaborItemType[] = [];
        validLaborItems.forEach(itemType => {
            const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
            const laborKey = generateLaborKey(itemType);

            const isPresent = !!storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[laborKey];
            if (!isPresent) {
                missingLaborItems.push(itemType);
            }
        });

        if (missingLaborItems.length === 0) {
            if (!fromParent) {
                validLaborItems.forEach(itemType => {
                    const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
                    const laborKey = generateLaborKey(itemType);

                    if (storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[laborKey]) {
                        delete storedSelectedFragments[fragmentKey].LaborItemTypes[laborKey];
                    }
                    updatedSelections[laborKey] = false;
                });
                if (orderAllTrigger === null) {
                    notifyWarningCustom('Labors removed from cart');
                }
            }
        } else {
            missingLaborItems.forEach(itemType => {
                const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
                const laborKey = generateLaborKey(itemType);
                const userBuyValue = Number(debouncedOrderedQty[laborKey] || "0");

                if (userBuyValue <= 0) {
                    return;
                }

                if (!storedSelectedFragments[fragmentKey]) {
                    storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
                }
                if (!storedSelectedFragments[fragmentKey].LaborItemTypes) {
                    storedSelectedFragments[fragmentKey].LaborItemTypes = {};
                }

                storedSelectedFragments[fragmentKey].LaborItemTypes[laborKey] = {
                    ...itemType.item_type,
                    items: itemType.item_type.items.map((laborItem: TItem) => ({
                        ...laborItem,
                        user_buy: userBuyValue,
                        payload: generatePayload(itemType),
                    })),
                    payload: generatePayload(itemType),
                };
                updatedSelections[laborKey] = true;
            });

            if (orderAllTrigger === null) {
                notifySuccessCustom('Labors added to cart');
            }
        }

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
        setSelectedLaborItemsState(updatedSelections);
        setSelectedLabor(Date.now());

        const calculatedPrice = calculateTotalPrice(filteredLaborItemTypes);
        setTotalPrice(calculatedPrice);
    };


    if (filteredLaborItemTypes.length === 0) {
        return <div className="step2-div-no-materials">No labor items</div>;
    }

    const displayedItemTypes = new Set<number>();
    return (
        <div>
            {filteredLaborItemTypes.map((itemType) => {
                const laborKey = generateLaborKey(itemType);

                if (displayedItemTypes.has(itemType.item_type.id)) {
                    return null;
                }
                displayedItemTypes.add(itemType.item_type.id);

                const hasNonEmptyItems = filteredLaborItemTypes.some(
                    (type) =>
                        type.item_type.id === itemType.item_type.id &&
                        type.item_type.items &&
                        type.item_type.items.length > 0
                );
                if (!hasNonEmptyItems) {
                    return (
                        <div key={laborKey}>
                            <div className="step2-item-type-list-no-items">
                                <div className='static-item-type-info'>
                                    <p className='static-item-type-info-title'>{itemType.title}</p>
                                    <p className='static-item-type-info-qty'>
                                        {itemType.qty} ({itemType.unit ? itemType.unit : 'each'})
                                    </p>
                                </div>
                                <p className="step2-other-width-list-total-no-item">R 0.00</p>
                                <div className="step2-tooltip-container-no-items">
                                    <input type="number" className="step2-other-width-input" disabled={true} />
                                    <span className='packing-value-steps'>psc</span>
                                </div>
                                <p className="step2-other-width-list">R 0.00</p>
                                <div className='step2-other-width-vendor-list'></div>
                            </div>
                        </div>
                    );
                }

                let totalQty = 0;
                let totalOrderedQty = 0;
                filteredLaborItemTypes
                    .filter(type => type.item_type.id === itemType.item_type.id)
                    .forEach(type => {
                        type.item_type.items?.forEach(laborItem => {
                            totalQty += laborItem.qty || 0;
                            totalOrderedQty += laborItem.ordered_qty || 0;
                        });
                    });

                const unit = itemType.item_type.items![0].unit || '';
                const price = parseFloat(String(itemType.item_type.items![0].price)) || 0;
                const safeTotalQty = totalQty;
                const safeTotalOrderedQty = totalOrderedQty;

                const orderedQtyPackaging = safeTotalOrderedQty * (
                    Array.isArray(itemType.item_type.items![0].packaging) &&
                    itemType.item_type.items![0].packaging.length === 0
                        ? 1
                        : itemType.item_type.items![0].packaging &&
                        typeof itemType.item_type.items![0].packaging === 'object'
                            ? itemType.item_type.items![0].packaging[Object.keys(itemType.item_type.items![0].packaging)[0]] || 1
                            : 1
                );

                const totalPriceRemaining = price * (safeTotalQty - safeTotalOrderedQty);
                const safeTotalPriceRemaining = Math.max(totalPriceRemaining, 0);
                const itemPackaging = (
                    itemType.item_type.items![0].packaging &&
                    typeof itemType.item_type.items![0].packaging === 'object' &&
                    Object.keys(itemType.item_type.items![0].packaging).length > 0
                )
                    ? Object.keys(itemType.item_type.items![0].packaging)[0]
                    : (unit ? unit : 'psc');

                const firstLaborItem = itemType.item_type.items![0];
                const itemKey = generateItemKey(
                    itemType.fragment_id,
                    itemType.fragment_index,
                    itemType.template_id,
                    itemType.template_index,
                    itemType.item_type.id,
                    firstLaborItem.id
                );
                const visited = isVisited(itemKey);
                const errorItem = parseItemKey(itemKey);

                return (
                    <React.Fragment key={laborKey}>
                        <div className='static-item-type-info'>
                            <p className='static-item-type-info-title'>{itemType.title}</p>
                            <p className='static-item-type-info-qty'>
                                {itemType.qty} ({itemType.item_type.unit ? itemType.item_type.unit : 'each'})
                            </p>
                        </div>
                        <div className='step2-item-type-list'>
                            <div className="step2-material-width-list">
                                <div className="step2-item-checkbox-block">
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${laborKey}`}
                                        checked={selectedLaborItems[laborKey] || false}
                                        onChange={() => handleCheckboxChange(itemType)}
                                    />
                                    <label className='checkbox-text-step2' htmlFor={`checkbox-${laborKey}`}>
                                        {firstLaborItem.title}
                                    </label>
                                </div>
                            </div>
                            <p className="step2-item-width-list-calc">{safeTotalQty} (psc)</p>
                            <p className="step2-item-width-list-qty-ordered">
                                {safeTotalOrderedQty}(psc) / {orderedQtyPackaging} ({itemPackaging})
                            </p>
                            <div className="step2-tooltip-container-item">
                                <input
                                    type="number"
                                    className="step2-other-width-input"
                                    value={debouncedOrderedQty[laborKey] || ""}
                                    onChange={(e) =>
                                        handleQtyChange(
                                            laborKey,
                                            e.target.value,
                                            errorItem,
                                            firstLaborItem.qty_from_store
                                        )
                                    }
                                    min="0"
                                    disabled={!!selectedLaborItems[laborKey]}
                                />
                                <span className='packing-value-steps'>psc</span>
                                {errorMessages[itemKey] && (
                                    <div className="error-message">{errorMessages[itemKey]}</div>
                                )}
                            </div>
                            <p className="step2-item-width-list-price">R {formatNumber(price)}</p>
                            <p className="step2-item-width-list-total">
                                R {formatNumber(safeTotalPriceRemaining)}
                            </p>
                            <div className='step2-other-width-vendor-list-item'>
                                {firstLaborItem.vendor !== null && vendorData[firstLaborItem.vendor as Vendor] ? (
                                    <>
                                        <p
                                            onClick={() =>
                                                handleLogoClick(
                                                    firstLaborItem.merchant_info?.link,
                                                    itemType.fragment_id,
                                                    itemType.fragment_index,
                                                    itemType.template_id,
                                                    itemType.template_index,
                                                    itemType.item_type.id,
                                                    firstLaborItem.id
                                                )
                                            }
                                            className='item-selection-modal-vendor'
                                        >
                                            {vendorData[firstLaborItem.vendor as Vendor].displayName}
                                        </p>
                                        {!visited && <span><IconEyesStep3 /></span>}
                                    </>
                                ) : (
                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
            <div className='step2-bottom-block'>
                <p>Total:</p>
                {totalPrice ? (
                    <span> R {totalPrice.toFixed(2)}</span>
                ) : (
                    <span> R 0</span>
                )}
                <button
                    className='step2-bottom-block-btn-cart-list'
                    onClick={()=>handleOrderFragment(false)}
                    style={{
                        backgroundColor: isButtonDisabledOrderFragment ? '#cccccc' : '',
                        cursor: isButtonDisabledOrderFragment ? 'not-allowed' : 'pointer'
                    }}
                >
                    Select for Ordering
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    setSelectedLabor,
    clearSelectedItems,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LaborItemTypeList);
