import { toast } from "react-toastify";
import {Project} from "../../types/ProjectTypes";
import {patch, post} from "../../api/APIWraper";
import {toastItemTrue} from "../../components/Toast/AssisCreateToastNotification";
import {loadMessages, messageReceived, saveMessages} from "../../Slice/WebSocket/WebSocketSlice";

interface IPayloadData {
    item_type_id: number;
    item_id: number;
    template_index: number;
    fragment_index: number;
    item_type_index: number;
    template_id: number;
    fragment_id: number;
    ordered_qty: number;
    user_buy: number;
    vendor: string;
    packaging: any;
}
const BASE_URL = process.env.REACT_APP_BASE_URL_CUSTOMER


const addCloneId = (data: any) => {
    const updatedData = { ...data };

    for (const fragmentKey in updatedData) {
        if (updatedData.hasOwnProperty(fragmentKey)) {
            const fragment = updatedData[fragmentKey];

            for (const itemKey in fragment.items) {
                if (fragment.items.hasOwnProperty(itemKey)) {
                    const item = fragment.items[itemKey];

                    if (!item.items || item.items.length === 0) {
                        delete fragment.items[itemKey];
                        continue;
                    }

                    item.clone_id = `${item.id}-${item.payload.item_type_index}-0`;
                    item.items = item.items.map((subItem: any, idx: number) => ({
                        ...subItem,
                        clone_id: `${subItem.id}-${item.payload.item_type_index}-${idx}`,
                    }));
                }
            }

            for (const laborKey in fragment.LaborItemTypes) {
                if (fragment.LaborItemTypes.hasOwnProperty(laborKey)) {
                    const laborItem = fragment.LaborItemTypes[laborKey];
                    if (!laborItem.items || laborItem.items.length === 0) {
                        delete fragment.LaborItemTypes[laborKey];
                        continue;
                    }
                    laborItem.clone_id = `${laborItem.id}-${laborItem.payload.item_type_index}-0`;
                    laborItem.items = laborItem.items.map((subItem: any, idx: number) => ({
                        ...subItem,
                        fragmentKey: fragmentKey,
                        clone_id: `${subItem.id}-${laborItem.payload.item_type_index}-${idx}`,
                    }));
                }
            }

            for (const toolKey in fragment.ToolsItemTypes) {
                if (fragment.ToolsItemTypes.hasOwnProperty(toolKey)) {
                    const toolItem = fragment.ToolsItemTypes[toolKey];
                    if (!toolItem.items || toolItem.items.length === 0) {
                        delete fragment.ToolsItemTypes[toolKey];
                        continue;
                    }
                    toolItem.clone_id = `${toolItem.id}-${toolItem.payload.item_type_index}-0`;
                    toolItem.items = toolItem.items.map((subItem: any, idx: number) => ({
                        ...subItem,
                        fragmentKey: fragmentKey,
                        clone_id: `${subItem.id}-${toolItem.payload.item_type_index}-${idx}`,
                    }));
                }
            }
        }
    }

    return updatedData;
};

export const startPurchase = async (project: Project, dispatch: any) => {
    await new Promise(resolve => setTimeout(resolve, 200));

    const data = localStorage.getItem("selectedFragments");
    if (!data) {
        toast.warning("You must select the fragments in Step2");
        return;
    }

    const parsedData = JSON.parse(data);
    const updatedData = addCloneId(parsedData);

    const itemsMap: { [key: string]: any[] } = {};
    const laborArray: any[] = [];
    const toolsArray: any[] = [];

    for (const fragmentKey in updatedData) {
        if (updatedData.hasOwnProperty(fragmentKey)) {
            const fragment = updatedData[fragmentKey];
            const nonEmptyItems = Object.values(fragment.items).flatMap(
                (item: any) => item.items || []
            );
            itemsMap[fragmentKey] = nonEmptyItems;

            for (const laborKey in fragment.LaborItemTypes) {
                if (
                    fragment.LaborItemTypes.hasOwnProperty(laborKey) &&
                    fragment.LaborItemTypes[laborKey].items.length > 0
                ) {
                    laborArray.push(...fragment.LaborItemTypes[laborKey].items);
                }
            }

            for (const toolKey in fragment.ToolsItemTypes) {
                if (
                    fragment.ToolsItemTypes.hasOwnProperty(toolKey) &&
                    fragment.ToolsItemTypes[toolKey].items.length > 0
                ) {
                    toolsArray.push(...fragment.ToolsItemTypes[toolKey].items);
                }
            }
        }
    }

    const payloadData: IPayloadData[] = [];

    Object.keys(itemsMap).forEach(fragmentKey => {
        itemsMap[fragmentKey].forEach((item: any) => {
            if (item.user_buy && item.user_buy > 0) {
                payloadData.push({
                    item_type_id: item.payload.item_type_id,
                    item_id: item.id,
                    template_index: item.payload.template_index,
                    fragment_index: item.payload.fragment_index,
                    item_type_index: item.payload.item_type_index,
                    template_id: item.payload.template_id,
                    fragment_id: item.payload.fragment_id,
                    ordered_qty: item.user_buy,
                    user_buy: item.user_buy,
                    vendor: item.vendor,
                    packaging: item.packaging,
                });
            }
        });
    });

    laborArray.forEach((item: any) => {
        if (item.user_buy && item.user_buy > 0) {
            payloadData.push({
                item_type_id: item.payload.item_type_id,
                item_id: item.id,
                template_index: item.payload.template_index,
                fragment_index: item.payload.fragment_index,
                item_type_index: item.payload.item_type_index,
                template_id: item.payload.template_id,
                fragment_id: item.payload.fragment_id,
                ordered_qty: item.user_buy,
                user_buy: item.user_buy,
                vendor: item.vendor,
                packaging: item.packaging,
            });
        }
    });

    toolsArray.forEach((item: any) => {
        if (item.user_buy && item.user_buy > 0) {
            payloadData.push({
                item_type_id: item.payload.item_type_id,
                item_id: item.id,
                template_index: item.payload.template_index,
                fragment_index: item.payload.fragment_index,
                item_type_index: item.payload.item_type_index,
                template_id: item.payload.template_id,
                fragment_id: item.payload.fragment_id,
                ordered_qty: item.user_buy,
                user_buy: item.user_buy,
                vendor: item.vendor,
                packaging: item.packaging,
            });
        }
    });

    const itemsForProject = payloadData.map(item => ({
        item_type_id: item.item_type_id,
        item_id: item.item_id,
        template_index: item.template_index,
        fragment_index: item.fragment_index,
        item_type_index: item.item_type_index,
        template_id: item.template_id,
        fragment_id: item.fragment_id,
        ordered_qty: item.ordered_qty,
    }));

    const itemsForCart = payloadData.map(item => ({
        id: item.item_id,
        qty: item.user_buy,
    }));

    const uniqueVendors = Array.from(new Set(payloadData.map(item => item.vendor)));

    localStorage.setItem("ItemQuantity", itemsForCart.length.toString());
    localStorage.setItem("ItemVendors", JSON.stringify(uniqueVendors));

    const bodyCart = {
        coordinates: {
            longitude: project?.address ? project.address.longitude : "",
            latitude: project?.address ? project.address.latitude : "",
        },
        items: itemsForCart,
    };

    try {
        const patchResponse = await patch(
            `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project?.id}/item_ordered_qty`,
            { data: itemsForProject }
        );

        if (patchResponse.status === 200) {
            toast.success("The project has been successfully updated!");
        } else {
            throw new Error("Error updating project elements.");
        }

        const responseCart = await post(`${BASE_URL}/cart`, bodyCart);
        if (responseCart.status === 200) {
            toastItemTrue("Loading has begun");

            const cartResponse = responseCart.data;
            if (
                cartResponse.cashBuildCart &&
                cartResponse.cashBuildCart.data.length > 0
            ) {
                const cashBuildItems = cartResponse.cashBuildCart.data;
                const cashBuildLink = cartResponse.cashBuildCart.url;
                localStorage.setItem("cashBuildLink", cashBuildLink);
                const hasCashbuildVendor = cashBuildItems.some(
                    (item: any) => item.item.vendor === "cashbuild"
                );

                if (hasCashbuildVendor) {
                    const newMessages = loadMessages().concat(cashBuildItems);
                    localStorage.setItem("SocketItemModal", JSON.stringify(newMessages));

                    saveMessages(newMessages);

                    dispatch(
                        messageReceived({
                            data: cashBuildItems,
                        })
                    );
                }
            }
            return patchResponse;
        } else {
            throw new Error("Error receiving products, try again later");
        }
    } catch (error) {
        console.error("Error: Failed to assemble the shopping cart:", error);
        toast.error("Error: Failed to assemble the shopping cart.");
        throw error;
    }
};

