import React from 'react';
import '../Functionality/Functionality.css'
import FAQSection from "../../../components/LandingPageComponent/FAQSection";

import SupportForm from "../../../components/LandingPageComponent/SupportForm";


function Support() {

    return (
        <main className='landing-page'>
            <div className='landing-middle-info-support'>
                <p className='support-page-title'>Support</p>
                <p className='support-page-info'>Please submit a support request by completing the form below.  </p>
                <p className='support-page-info'>Our team will endeavour to get back to you within 48hrs.</p>
            </div>
            <SupportForm/>
            <div className='landing-page-support'>
            <FAQSection/>
            </div>
        </main>
    );
}

export default Support;
