import React, {useState} from 'react';
import './styles/changePassword.css';
import {IconHidePassword, IconShowPassword} from "../../IconComponents/IconComponents";
import {updateUserPassword} from "../../api/APIWraper";
import {toast} from "react-toastify";
import {useSmartNavigate} from "../../Hooks/useNavigationHook";

const ChangePassword: React.FC = () => {
    const smartNavigate = useSmartNavigate();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [newPasswordTooShort, setNewPasswordTooShort] = useState(false);
    const [newPasswordContainsCyrillic, setNewPasswordContainsCyrillic] = useState(false);
    const [confirmPasswordMismatch, setConfirmPasswordMismatch] = useState(false);

    const [newPasswordTouched, setNewPasswordTouched] = useState(false);
    const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);

    const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(e.target.value);
    };

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewPassword(value);
        if (!newPasswordTouched) setNewPasswordTouched(true);

        if (value.length < 6) {
            setNewPasswordTooShort(true);
        } else {
            setNewPasswordTooShort(false);
        }

        if (/[а-яА-ЯЁё]/.test(value)) {
            setNewPasswordContainsCyrillic(true);
        } else {
            setNewPasswordContainsCyrillic(false);
        }

        if (confirmPassword && value !== confirmPassword) {
            setConfirmPasswordMismatch(true);
        } else {
            setConfirmPasswordMismatch(false);
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setConfirmPassword(value);
        if (!confirmPasswordTouched) setConfirmPasswordTouched(true);

        if (newPassword !== value) {
            setConfirmPasswordMismatch(true);
        } else {
            setConfirmPasswordMismatch(false);
        }
    };

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword((prev) => !prev);
    };
    const toggleNewPasswordVisibility = () => {
        setShowNewPassword((prev) => !prev);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prev) => !prev);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPassword.length < 6) {
            toast.error("New password must be at least 6 characters.");
            return;
        }
        if (/[а-яА-ЯЁё]/.test(newPassword)) {
            toast.error("New password must not contain Cyrillic characters.");
            return;
        }
        if (newPassword !== confirmPassword) {
            toast.error("Confirm password does not match new password.");
            return;
        }

        const body = {
            current_password: oldPassword,
            new_password: newPassword,
            new_password_confirmation: confirmPassword,
        }
        const response = await updateUserPassword(body)
        if (response.status === 200) {
            toast.success("Password successfully changed!");

        }
    };

    const handleForgotPassword = () => {
        localStorage.setItem('preferencesPage', String(true));
        smartNavigate("/auth/forgot-password");
    };


    return (
        <div className='change-password-block'>
            <div className='account-details-header'>
                <h3>Change password</h3>
            </div>
            <form className='account-details-content' onSubmit={handleSubmit}>
                <div className='account-details-name-container'>
                    <div className='input-with-icon'>
                        <p className='account-details-name-container-username'>Old password:</p>
                        <input
                            type={showOldPassword ? 'text' : 'password'}
                            className='change-password-details-input'
                            value={oldPassword}
                            onChange={handleOldPasswordChange}
                        />
                        <div className='icon-in-input-show' onClick={toggleOldPasswordVisibility}>
                            {showOldPassword ? <IconHidePassword/> : <IconShowPassword/>}
                        </div>
                        <div className='change-password-forgot-block'>
                            <p
                                className="change-password-button-forgot"
                                onClick={handleForgotPassword}
                            >
                                Forgot password?
                            </p>
                        </div>
                    </div>

                    <div className='input-with-icon'>
                        <p className='account-details-name-container-username'>New password:</p>
                        <input
                            type={showNewPassword ? 'text' : 'password'}
                            className='change-password-details-input'
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                        />
                        <div className='icon-in-input-show' onClick={toggleNewPasswordVisibility}>
                            {showNewPassword ? <IconHidePassword/> : < IconShowPassword/>}
                        </div>
                    </div>
                    {newPasswordTouched && (
                        <div className='login-card-form-input-message-container'>
                            {newPasswordTooShort && (
                                <p className='login-card-form-input-message'>Minimum password length is 6 characters</p>
                            )}
                            {newPasswordContainsCyrillic && (
                                <p className='login-card-form-input-message'>Password must not contain Cyrillic
                                    characters</p>
                            )}
                        </div>
                    )}

                    <div className='input-with-icon'>
                        <p className='account-details-name-container-username'>Confirm new password:</p>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            className='change-password-details-input'
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        <div className='icon-in-input-show' onClick={toggleConfirmPasswordVisibility}>
                            {showConfirmPassword ? <IconHidePassword/> : <IconShowPassword/>}
                        </div>
                    </div>
                    {confirmPasswordTouched && confirmPasswordMismatch && (
                        <div className='login-card-form-input-message-container'>
                            <p className='login-card-form-input-message'>Confirm password does not match new
                                password</p>
                        </div>
                    )}
                </div>
                <button type="submit" className='change-password-btn-change'>
                    Change Password
                </button>
            </form>
        </div>
    );
};

export default ChangePassword;
