import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import './AssisTemplaeModal.css';
import {Template} from "../../../types/TemplateType";
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from '../../../store';
import {
    updateLocalTemplate,
    removeTemplate,
    removeCalculatedTemplate,
    clearTemplates,
    addTemplate
} from '../../../Slice/LocalTemplate/LocalTemplateSlice';
import {transformTemplate} from '../../../utils/TransformTemplate';
import DeleteModal from "../ModalDelete/ModalDelete";
import {clearLocalStorageExceptToken} from "../../../utils/ClearLocalStorage";
import {IconRemoveTemplateModal} from "../../../IconComponents/IconComponents";
import {updatedProject} from "../../../Slice/projectById/projectByIdSlice";
import {useSmartNavigate} from "../../../Hooks/useNavigationHook";
import useIsSmallScreen from "../../../Hooks/SmallScreen/useIsSmallScreen";

interface AssisCreateModalProps {
    isOpen: boolean;
    onClose: () => void;
    templates: Template[];
    onSave: () => void;
    onRemoveTemplate: (templateId: number) => void;
    projectId: number;
    selectedTemplates: Template[];
}

interface TemplateWithCalculated extends Template {
    isCalculated: boolean;
}

const AssisTemplaeModal: React.FC<PropsFromRedux & AssisCreateModalProps> = ({
                                                                                 clearTemplates,
                                                                                 isOpen,
                                                                                 onClose,
                                                                                 templates,
                                                                                 onSave,
                                                                                 onRemoveTemplate,
                                                                                 projectId,
                                                                                 storedCalculatedTemplates,
                                                                                 updatedProject,
                                                                                 addTemplate,
                                                                                 selectedTemplates
                                                                             }) => {
    const [templatesWithCalculated, setTemplatesWithCalculated] = useState<TemplateWithCalculated[]>([]);
    const [templateToDelete, setTemplateToDelete] = useState<TemplateWithCalculated | null>(null);
    const smartNavigate = useSmartNavigate();
    const isSmallScreen = useIsSmallScreen();


    useEffect(() => {
        const updatedTemplates = templates.map(template => ({
            ...template,
            isCalculated: storedCalculatedTemplates.includes(template.id) || JSON.parse(localStorage.getItem(`selectedTemplate-${template.id}`) || 'false')
        }));
        setTemplatesWithCalculated(updatedTemplates);
    }, [templates, storedCalculatedTemplates]);

    useEffect(() => {
        localStorage.setItem('projectId', projectId.toString());
    }, [projectId]);

    const handleCalculateClick = (template: TemplateWithCalculated, index: number) => {
        localStorage.setItem(`selectedTemplate-${template.id}`, JSON.stringify(template));
        const transformedTemplates = templatesWithCalculated.map((template) => transformTemplate(template));
        const updatedProjectData = {
            data: {
                templates: transformedTemplates
            }
        };
        updatedProject({projectId, projectData: updatedProjectData});
        clearTemplates()
        clearLocalStorageExceptToken()
        onClose();
        setTimeout(() => {
            smartNavigate(`/assistance/step1/template/${template.id}/${index}/project/${projectId}`, {
                state: {
                    projectId,
                    template: template,
                    templIndex: index
                }
            });
        }, 500);
    };

    const handleRemoveTemplate = (template: TemplateWithCalculated) => {
        localStorage.removeItem(`selectedTemplate-${template.id}`);
        removeTemplate(template.id);
        removeCalculatedTemplate(template.id);
        onRemoveTemplate(template.id);
        setTemplatesWithCalculated(prevTemplates => {
            const updatedTemplates = prevTemplates.filter(t => t.id !== template.id);
            if (updatedTemplates.length === 0) {
                onClose();
            }
            return updatedTemplates;
        });
        setTemplateToDelete(null);
    };

    const handleSave = () => {
        const transformedTemplates = templatesWithCalculated.map(template => transformTemplate(template));
        const updatedProjectData = {
            data: {
                templates: transformedTemplates
            }
        };
        updatedProject({projectId, projectData: updatedProjectData});
        if (selectedTemplates.length === 1) {
            smartNavigate(`/assistance/step1/template/${selectedTemplates[0].id}/0/project/${projectId}`);
        } else {
            smartNavigate(`/costs/${projectId}`);
        }
        clearTemplates();
        templatesWithCalculated.forEach(template => addTemplate(template));
        onSave();
        onClose();
    };
    const isSaveDisabled = templatesWithCalculated.length === 0;
    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={onClose} className="selected-templates-modal"
                   overlayClassName="overlay">
                <div className="modal-header">
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-content">
                    <div className="templates-table">
                        {!isSmallScreen ? (
                            <div className='assis-modal-info'>
                                <p>Your template</p>
                                <p>Category</p>
                                <p>Options</p>
                            </div>
                        ) : (
                            <h3 className='modal-template-mobilte-title'>Your templates</h3>
                        )}

                        {!isSmallScreen ? (
                            <div className='modal-template-selected-templates-content'>
                                {templatesWithCalculated.map((template, index,) => (
                                    <div
                                        className={`assis-modal-content ${template.isCalculated ? 'template-calculated' : ''}`}
                                        key={index}>
                                        <p>{template.title}</p>
                                        <p>{template.categories && template.categories.length > 0 ? template.categories[0].category : 'Other'}</p>
                                        <>
                                            <button className="calculate-btn"
                                                    onClick={() => handleCalculateClick(template, index)}>
                                                Calculate
                                            </button>
                                            <div className='assis-action-block'>
                                                <div className='assis-modal-action-remove'
                                                     onClick={() => setTemplateToDelete(template)}>
                                                    <IconRemoveTemplateModal/>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                ))}
                            </div>
                        ) : (

                            <div className='modal-template-selected-templates-content'>
                                {templatesWithCalculated.map((template, index,) => (
                                    <div className="assis-modal-content" key={index}>
                                        <div className='modal-template-selected-templates-content-mobile-block'>
                                            <p>{template.title}</p>

                                            <div className='assis-modal-action-remove'
                                                 onClick={() => setTemplateToDelete(template)}>
                                                <IconRemoveTemplateModal/>
                                            </div>
                                        </div>

                                        <button className="calculate-btn"
                                                onClick={() => handleCalculateClick(template, index)}>
                                            Calculate
                                        </button>

                                    </div>
                                ))}
                            </div>

                        )}

                    </div>
                    <div className='assis-save-button'>
                        <button className={`save-button-template ${isSaveDisabled ? 'disabled' : ''}`}
                                onClick={handleSave} disabled={isSaveDisabled}>Save
                        </button>
                    </div>
                </div>
            </Modal>

            {templateToDelete && (
                <DeleteModal
                    isOpen={true}
                    onRequestClose={() => setTemplateToDelete(null)}
                    onConfirm={() => handleRemoveTemplate(templateToDelete)}
                    onCancel={() => setTemplateToDelete(null)}
                    message={`Are you sure you want to delete the template "${templateToDelete.title}"?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    storedCalculatedTemplates: state.localTemplate.calculatedTemplates,
});

const mapDispatchToProps = {
    updatedProject,
    clearTemplates,
    updateLocalTemplate,
    removeTemplate,
    removeCalculatedTemplate,
    addTemplate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssisTemplaeModal);