import React from 'react';
import {skipData} from "../../ArсhitectPage";
import {Loader} from "../../../../components/Loader/Loader";

interface DeletedObjectBlockProps {
    deletedObjects: skipData[];
    loadingDeleted: boolean;
    externalIdToDbId: Record<string, number>;
    setCurrentIndex: (index: number) => void;
    currentIndex: number;
    setSelectedExternalId: React.Dispatch<React.SetStateAction<string | null>>;
    selectedExternalId: string | null;

}

const DeletedObjectBlock: React.FC<DeletedObjectBlockProps> = ({
                                                                   deletedObjects,
                                                                   loadingDeleted,
                                                                   externalIdToDbId,
                                                                   setCurrentIndex,
                                                                   currentIndex,
                                                                   setSelectedExternalId,
                                                                   selectedExternalId
                                                               }) => {
    if (!deletedObjects || deletedObjects.length === 0) return null;
    const handleSelect = (externalId: string) => {
        const savedIndexStr = localStorage.getItem("SavedIndexRight");
        const savedIndex = savedIndexStr !== null ? Number(savedIndexStr) : null;
        const indexInFullList = Object.keys(externalIdToDbId).indexOf(externalId);

        if (selectedExternalId === externalId) {
            if (savedIndex !== null) {
                setCurrentIndex(savedIndex);
                localStorage.removeItem("SavedIndexRight");
            }
            setSelectedExternalId(null);
        } else {
            if (savedIndex === null) {
                localStorage.setItem("SavedIndexRight", String(currentIndex));
            }
            setSelectedExternalId(externalId);
            setCurrentIndex(indexInFullList);
        }
    };


    return (
        <>
            {loadingDeleted ? <Loader/> : (
                deletedObjects.map((deletedObject, index) => {
                    const isSelected = deletedObject.externalId === selectedExternalId;
                    return (
                        <div
                            className={`right-block-section-revit ${isSelected ? 'selected' : ''}`}
                            key={index}
                            onClick={() => handleSelect(deletedObject.externalId)}
                        >
                            <div className='right-block-section-revit-content'>
                                <p className='right-block-section-revit-content-text'>Name</p>
                                <p>{deletedObject.name}</p>
                            </div>
                            <div className='right-block-section-revit-content'>
                                <p className='right-block-section-revit-content-text'>Length</p>
                                <p>{deletedObject.measurements?.length ?? '-'}</p>
                            </div>
                            <div className='right-block-section-revit-content'>
                                <p className='right-block-section-revit-content-text'>Area</p>
                                <p>{deletedObject.measurements?.area ?? '-'}</p>
                            </div>
                            <div className='right-block-section-revit-content'>
                                <p className='right-block-section-revit-content-text'>Volume</p>
                                <p>{deletedObject.measurements?.volume ?? '-'}</p>
                            </div>
                            <div className='right-block-section-revit-content'>
                                <p className='right-block-section-revit-content-text'>Width</p>
                                <p>{deletedObject.measurements?.width ?? '-'}</p>
                            </div>
                        </div>
                    );
                })
            )}

        </>
    );
};

export default DeletedObjectBlock;