import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AuthStyle.css';
import {PuffLoader} from "react-spinners";
import {post} from "../../api/APIWraper";
import {IconHidePassword, IconShowPassword} from "../../IconComponents/IconComponents";
import PromoCode, {PromoCodeResult} from "../../components/Modals/PromoCode/PromoCode";

interface AuthFields {
    email: string;
    password: string;
    confirmPassword: string;
    username: string;
    promocode?:string
}


const Registration: React.FC = () => {
    const navigate = useNavigate();
    const [promoResult, setPromoResult] = useState<PromoCodeResult | null>(null);

    const code = process.env.REACT_APP_BASE_PROMOCODE;

    const [authFields, setAuthFields] = useState<AuthFields>({
        email: "",
        password: "",
        confirmPassword: "",
        username: "",
        promocode:promoResult?.code ? promoResult?.code : "",
    });

    const [isBadEmail, setIsBadEmail] = useState(false);
    const [isBadConfirmPassword, setIsBadConfirmPassword] = useState(false);
    const [isBadUsername, setIsBadUsername] = useState(false);
    const [isBadPassword, setIsBadPassword] = useState(false);
    const [isWaitingForSignIn, setIsWaitingForSignIn] = useState(false);

    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [passwordTooShort, setPasswordTooShort] = useState(false);
    const [passwordContainsCyrillic, setPasswordContainsCyrillic] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const [emailTooLong, setEmailTooLong] = useState(false);
    const [usernameTooLong, setUsernameTooLong] = useState(false);
    const [passwordTooLong, setPasswordTooLong] = useState(false);
    const [confirmPasswordTooLong, setConfirmPasswordTooLong] = useState(false);


    const [isOpenCode, setIsOpenCode] = useState<boolean>(false);

    const handleOpenCode = () => setIsOpenCode(true);
    const handleCloseCode = () => setIsOpenCode(false);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordConfirmVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    const handlePromoCodeValidated = (result: PromoCodeResult) => {
        if (result.isValid) {
            setPromoResult(result);
        } else {
            setPromoResult({activeUntil: "", code: "", message: "", isValid: false });
        }
    };

    useEffect(() => {
        if (promoResult?.code) {
            setAuthFields((prevFields) => ({
                ...prevFields,
                promocode: promoResult.code,
            }));
        }
    }, [promoResult]);


    useEffect(() => {
        if (authFields.email === "") {
            setIsBadEmail(false);
        } else {
            setIsBadEmail(!validEmailRegex.test(authFields.email));
        }
    }, [authFields.email]);

    useEffect(() => {
        if (passwordTouched) {
            const password = authFields.password;

            setPasswordTooShort(false);
            setPasswordContainsCyrillic(false);
            setIsBadPassword(false);

            if (password.length < 6) {
                setPasswordTooShort(true);
                setIsBadPassword(true);
                setTimeout(() => setPasswordTooShort(false), 3000);
            }

            if (/[а-яА-ЯЁё]/.test(password)) {
                setPasswordContainsCyrillic(true);
                setIsBadPassword(true);
                setTimeout(() => setPasswordContainsCyrillic(false), 3000);
            }

            if (password === '') {
                setIsBadPassword(true);
            }
        } else {
            setIsBadPassword(false);
            setPasswordTooShort(false);
            setPasswordContainsCyrillic(false);
        }
    }, [authFields.password, passwordTouched]);

    useEffect(() => {
        if (authFields.confirmPassword === "") {
            setIsBadConfirmPassword(false);
        } else {
            setIsBadConfirmPassword(authFields.password !== authFields.confirmPassword);
        }
    }, [authFields.confirmPassword, authFields.password]);

    useEffect(() => {
        if (authFields.username === "") {
            setIsBadUsername(false);
        } else {
            setIsBadUsername(authFields.username.length < 2);
        }
    }, [authFields.username]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        if (email.length >= 255) {
            setEmailTooLong(true);
            setAuthFields({...authFields, email: email.slice(0, 255)});
            setTimeout(() => setEmailTooLong(false), 3000);
        } else {
            setAuthFields({...authFields, email});
        }
    };

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const username = e.target.value;
        if (username.length >= 255) {
            setUsernameTooLong(true);
            setAuthFields({...authFields, username: username.slice(0, 255)});
            setTimeout(() => setUsernameTooLong(false), 3000);
        } else {
            setAuthFields({...authFields, username});
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!passwordTouched) {
            setPasswordTouched(true);
        }
        const newPassword = e.target.value;
        if (newPassword.length >= 255) {
            setPasswordTooLong(true);
            setAuthFields({
                ...authFields,
                password: newPassword.slice(0, 255)
            });
            setTimeout(() => setPasswordTooLong(false), 3000);
        } else {
            setAuthFields({
                ...authFields,
                password: newPassword
            });
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const confirmPassword = e.target.value;
        if (confirmPassword.length >= 255) {
            setConfirmPasswordTooLong(true);
            setAuthFields({
                ...authFields,
                confirmPassword: confirmPassword.slice(0, 255)
            });
            setTimeout(() => setConfirmPasswordTooLong(false), 3000);
        } else {
            setAuthFields({
                ...authFields,
                confirmPassword
            });
        }
    };

    const validateForm = () => {
        const {email, password, confirmPassword, username} = authFields;

        let isValid = true;

        if (!validEmailRegex.test(email)) {
            toast.error("Invalid email format");
            isValid = false;
        }

        if (username.length < 2) {
            toast.error("Username must be at least 2 characters long");
            isValid = false;
        }

        if (password.length < 6) {
            toast.error("Password must contain a minimum of 6 characters");
            setPasswordTooShort(true);
            setIsBadPassword(true);
            isValid = false;
        }

        if (/[а-яА-ЯЁё]/.test(password)) {
            toast.error("The password must not contain Cyrillic characters");
            setPasswordContainsCyrillic(true);
            setIsBadPassword(true);
            isValid = false;
        }

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            setIsBadConfirmPassword(true);
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsWaitingForSignIn(true);

        const { email, password, username, promocode } = authFields;

        const getRegistrationData = () => {
            return promoResult
                ? { email, password, username, promocode }
                : { email, password, username };
        };

        const handleErrorResponse = (errorMessage: string) => {
            if (errorMessage.includes("username")) {
                toast.error("The Username has already been taken");
            } else if (errorMessage.includes("email")) {
                toast.error("The Email has already been taken");
            } else {
                toast.error("Registration failed");
            }
        };

        try {
            const response = await post(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/register`, getRegistrationData());

            if (response.status === 400) {
                handleErrorResponse(response.data.error);
            } else if ([200, 201].includes(response.status)) {
                toast.success("Registration successful");
                setTimeout(() => navigate("/auth/login"), 3000);
            } else {
                toast.error("Registration failed");
            }
        } catch (error) {
            toast.error("An error occurred during registration");
        } finally {
            setIsWaitingForSignIn(false);
        }
    };


    const handleForgotPassword = () => {
        navigate("/auth/forgot-password");
    };

    const handleLogin = () => {
        navigate("/auth/login");
    };

    return (
        <div className="layout-login">
            <div className="register-card">
                <div className="login-card-top">
                    <h3>Create An Account</h3>
                </div>

                <form className="register-card-form" onSubmit={handleSubmit}>
                    <div>
                        <div className={`login-card-form-input-container ${isBadEmail ? 'red-font-error' : ''}`}>
                            <label className={`login-card-form-label ${isBadEmail ? 'red-font-error' : ''}`}>
                                Email
                                <span style={{color: 'green', marginLeft: '5px'}}>*</span>
                            </label>
                            <input
                                className={`login-card-form-input 
                                ${isBadEmail ? 'red-font-error ' : ''}
                                `}
                                type="email"
                                value={authFields.email}
                                onChange={handleEmailChange}
                                required
                                maxLength={255}
                            />
                        </div>
                        {isBadEmail && (
                            <p className="login-card-form-input-message">Incorrect e-mail format</p>
                        )}
                        {emailTooLong && (
                            <p className="login-card-form-input-message">Maximum length is 255 characters</p>
                        )}
                    </div>

                    <div>
                        <div className={`login-card-form-input-container ${isBadUsername ? 'red-font-error' : ''}`}>
                            <label className={`login-card-form-label ${isBadUsername ? 'red-font-error' : ''}`}>
                                Username
                                <span style={{color: 'green', marginLeft: '5px'}}>*</span>
                            </label>
                            <input
                                className={`login-card-form-input 
                                ${isBadUsername ? 'red-font-error' : ''}
                                `}
                                type="text"
                                value={authFields.username}
                                onChange={handleUsernameChange}
                                required
                                maxLength={255}
                            />
                        </div>
                        {isBadUsername && (
                            <p className="login-card-form-input-message">Username must be at least 2 characters long</p>
                        )}
                        {usernameTooLong && (
                            <p className="login-card-form-input-message">Maximum length is 255 characters</p>
                        )}
                    </div>

                    <div>
                        <div className={`login-card-form-input-container ${isBadPassword ? 'red-font-error' : ''}`}>
                            <label className={`login-card-form-label ${isBadPassword ? 'red-font-error' : ''}`}>
                                Password
                                <span style={{color: 'green', marginLeft: '5px'}}>*</span>
                            </label>
                            <input
                                className={`login-card-form-input 
                                ${isBadPassword ? 'red-font-error' : ''}
                                `}
                                type={showPassword ? "text" : "password"}
                                value={authFields.password}
                                onChange={handlePasswordChange}
                                required
                                maxLength={255}
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                className='login-card-show-password'
                            >
                                {showPassword ? <IconShowPassword/> : <IconHidePassword/>}
                            </span>
                        </div>
                        {isBadPassword && (
                            <div className="login-card-form-input-message-container">
                                {passwordTooShort && (
                                    <p className="login-card-form-input-message">
                                        Password must contain a minimum of 6 characters
                                    </p>
                                )}
                                {passwordContainsCyrillic && (
                                    <p className="login-card-form-input-message">
                                        The password must not contain Cyrillic characters
                                    </p>
                                )}
                            </div>
                        )}
                        {passwordTooLong && (
                            <p className="login-card-form-input-message">Maximum length is 255 characters</p>
                        )}
                    </div>

                    <div>
                        <div
                            className={`login-card-form-input-container ${isBadConfirmPassword ? 'red-font-error' : ''}`}>
                            <label className={`login-card-form-label ${isBadConfirmPassword ? 'red-font-error' : ''}`}>
                                Confirm Password
                                <span style={{color: 'green', marginLeft: '5px'}}>*</span>
                            </label>
                            <input
                                className={`login-card-form-input ${isBadConfirmPassword ? 'red-font-error' : ''}`}
                                type={showConfirmPassword ? "text" : "password"}
                                value={authFields.confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                required
                                maxLength={255}
                            />
                            <span
                                onClick={togglePasswordConfirmVisibility}
                                className='login-card-show-password'
                            >
                                {showConfirmPassword ? <IconShowPassword/> : <IconHidePassword/>}
                            </span>
                        </div>
                        {isBadConfirmPassword && (
                            <p className="login-card-form-input-message">Passwords do not match</p>
                        )}
                        {confirmPasswordTooLong && (
                            <p className="login-card-form-input-message">Maximum length is 255 characters</p>
                        )}
                    </div>

                    <div className='login-card-optionals-btn'>
                        <div className='login-card-remember-me'>
                            <label className='login-card-remember-me-label'>
                                <input
                                    type="checkbox"
                                    className='login-card-checkbox-remember'
                                    required
                                />
                                I agree with Terms and Conditions
                            </label>
                        </div>
                    </div>
                    {code && (
                        <div
                            onClick={handleOpenCode}
                            className='register-card-promocode-text'>
                            {promoResult?.isValid
                                ? "You have already used 1 promo code. Do you want to change it?"
                                : "Already have a promo code?"
                            }
                        </div>
                    )}


                    <button
                        className={`login-card-form-button ${isWaitingForSignIn ? 'gray-background' : ''}`}
                        type="submit"
                        disabled={isWaitingForSignIn}
                    >
                        {!isWaitingForSignIn && "Create an Account"}
                        {isWaitingForSignIn && (
                            <div className="login-loading-spin">
                                <PuffLoader size={25}/>
                            </div>
                        )}
                    </button>


                    <div className='register-bottom-password-block'>
                        <p className='register-bottom-question'>Already have an account?</p>
                        <p><span onClick={handleLogin} className='register-bottom-btn'>Log In</span> or <span
                            onClick={handleForgotPassword} className='register-bottom-btn'>Reset password</span></p>
                    </div>
                </form>
            </div>
            <PromoCode
            isOpen={isOpenCode}
            onRequestClose={handleCloseCode}
            onPromoCodeValidated={handlePromoCodeValidated}
            />
            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                    backgroundColor: "#333",
                    color: "#fff",
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
            />
        </div>
    );
};

export default Registration;
