import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {clearSelectedItems, setSelectedTools} from '../../../Slice/SelectedItems/SelectedItemsSlice';
import '../Step2Styles/projectUpdateItems.css';
import IToolsItemType from "../../../Interfaces/ProjectInterfaces/IToolsItemType";
import {Vendor, vendorData} from "../../../Enums/VendorEnum";
import {TItem} from "../../../types/TemplateType";
import {IconEyesStep3} from "../../../IconComponents/IconComponents";
import {
    notifySuccessCustom,
    notifyWarningCustom
} from "../../../components/Toast/AssisCreateToastNotification";
import {formatNumber} from "../../../utils/FormatNumber";
import {
    generateItemKey,
    parseItemKey,
    useErrorMessages,
    useQtyHandler,
    useVisitedItems
} from "../../../Hooks/Step2Customer/Step2Hooks";

const generateItemUniqueKey = (itemType: IToolsItemType, toolItem: TItem) => {
    const toolKey = `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;
    return `${toolKey}-${toolItem.id}`;
};

interface ToolItemTypeListProps {
    toolsItemTypes: IToolsItemType[];
    viewModeTools: 'Recommended' | 'Minimum';
    orderAllTrigger:number | null;
    setOrderAllTrigger:(value:null) => void;
    orderAllBooleanTrigger:boolean;
    isTriggeredByParent:boolean;

}

const ToolsItemTypeList: React.FC<ToolItemTypeListProps & PropsFromRedux> = ({
                                                                                 toolsItemTypes,
                                                                                 setSelectedTools,
                                                                                 viewModeTools,
                                                                                 orderAllTrigger,
                                                                                 setOrderAllTrigger,
                                                                                 orderAllBooleanTrigger,
                                                                                 isTriggeredByParent
                                                                             }) => {
    const { handleLogoClick, isVisited } = useVisitedItems();
    const { errorMessages, setError, clearError } = useErrorMessages();
    const { debouncedOrderedQty, handleQtyChange } = useQtyHandler(setError, clearError);

    const [selectedItems, setSelectedItemsState] = useState<{ [key: string]: boolean }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isButtonDisabledOrderFragment, setIsButtonDisabledOrderFragment] = useState(false);

    useEffect(() => {
        const hasItems = toolsItemTypes.some(
            (itemType) => itemType.item_type.items && itemType.item_type.items.length > 0
        );
        setIsButtonDisabledOrderFragment(!hasItems);
    }, [toolsItemTypes]);

    useEffect(() => {
        setSelectedItemsState({});
        setTotalPrice(0);
        const stored = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        Object.keys(stored).forEach(fragmentKey => {
            if (stored[fragmentKey].ToolsItemTypes) {
                delete stored[fragmentKey].ToolsItemTypes;
            }
        });
        localStorage.setItem('selectedFragments', JSON.stringify(stored));
    }, [viewModeTools]);

    const mergeItemTypes = (items: IToolsItemType[]) => {
        const merged: { [id: number]: IToolsItemType & { originalIndex: number } } = {};
        items.forEach((item, index) => {
            const id = item.item_type.id;
            if (merged[id]) {
                const exist = merged[id];
                item.item_type.items?.forEach(toolItem => {
                    const found = exist.item_type.items?.find(ti => ti.id === toolItem.id);
                    if (found) {
                        found.qty = viewModeTools === 'Minimum'
                            ? Math.max(found.qty, toolItem.qty)
                            : found.qty + toolItem.qty;
                        found.ordered_qty = viewModeTools === 'Minimum'
                            ? Math.max(found.ordered_qty ?? 0, toolItem.ordered_qty ?? 0)
                            : (found.ordered_qty ?? 0) + (toolItem.ordered_qty ?? 0);
                    } else {
                        exist.item_type.items?.push({ ...toolItem });
                    }
                });
            } else {
                merged[id] = { ...JSON.parse(JSON.stringify(item)), originalIndex: index };
            }
        });
        return Object.values(merged).sort((a, b) => a.originalIndex - b.originalIndex);
    };

    const mergedItemTypes = mergeItemTypes(toolsItemTypes);
    const filteredMergedItemTypes = mergedItemTypes.filter(item => item.qty > 0);

    const syncSelectionsWithLocalStorage = (items: IToolsItemType[]) => {
        const stored = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const upd: { [key: string]: boolean } = {};
        items.forEach(item => {
            const fragmentKey = `${item.fragment_id}-${item.fragment_index}-${item.template_index}`;
            const toolKey = `${item.item_type.id}-${item.clone_id || item.item_type_index}-${item.fragment_index}`;
            if (stored[fragmentKey]?.ToolsItemTypes?.[toolKey]) {
                item.item_type.items?.forEach(toolItem => {
                    const uniqKey = `${toolKey}-${toolItem.id}`;
                    if (
                        stored[fragmentKey].ToolsItemTypes[toolKey].items.some((i: any) => i.id === toolItem.id)
                    ) {
                        upd[uniqKey] = true;
                    }
                });
            }
        });
        setSelectedItemsState(upd);
    };

    useEffect(() => {
        syncSelectionsWithLocalStorage(toolsItemTypes);
    }, [toolsItemTypes, viewModeTools]);

    const handleCheckboxChange = (itemUniqueKey: string, item: IToolsItemType, toolItem: TItem) => {
        const currentQty = debouncedOrderedQty[itemUniqueKey] || "";
        if (Number(currentQty) <= 0) {
            notifyWarningCustom("You need to enter a value in the “QTY to order” input field");
            return;
        }
        const isSelected = !selectedItems[itemUniqueKey];
        const updated = { ...selectedItems, [itemUniqueKey]: isSelected };
        setSelectedItemsState(updated);
        if (isSelected) {
            saveToLocalStorage(item, toolItem, toolsItemTypes, itemUniqueKey);
        } else {
            removeFromLocalStorage(item, toolItem, toolsItemTypes, itemUniqueKey);
        }
        setSelectedTools(Date.now());
    };

    const handleOrderFragment = (fromParent: boolean = false) => {
        setOrderAllTrigger(null);
        if (isButtonDisabledOrderFragment) return;

        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        const validItemTypes = filteredMergedItemTypes.filter(itemType => {
            if (!itemType.item_type.items || itemType.item_type.items.length === 0) {
                return false;
            }
            return itemType.item_type.items.some(toolItem => {
                const uniqKey = generateItemUniqueKey(itemType, toolItem);
                return Number(debouncedOrderedQty[uniqKey] || 0) > 0;
            });
        });

        if (validItemTypes.length === 0) {
            if (orderAllTrigger === null) {
                notifyWarningCustom('You need to enter a value in the “QTY to order” input field');
            }
            return;
        }

        const missingItemTypes: IToolsItemType[] = [];

        validItemTypes.forEach(itemType => {
            const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
            const toolKey = `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;

            const isToolTypePresent = !!storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey];
            if (!isToolTypePresent) {
                missingItemTypes.push(itemType);
                return;
            }

            const storedItems = storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey].items || [];
            const storedIDs = storedItems.map((it: any) => it.id);
            const actualIDs = itemType.item_type.items!.map(i => i.id);

            const allPresent = actualIDs.every(id => storedIDs.includes(id));
            if (!allPresent) {
                missingItemTypes.push(itemType);
            }
        });

        if (missingItemTypes.length === 0) {
            if (!fromParent) {
                validItemTypes.forEach(itemType => {
                    const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
                    const toolKey = `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;

                    if (storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey]) {
                        delete storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey];
                    }

                    if (itemType.item_type.items && itemType.item_type.items.length > 0) {
                        itemType.item_type.items.forEach(toolItem => {
                            const uniqKey = generateItemUniqueKey(itemType, toolItem);
                            setSelectedItemsState(prev => ({ ...prev, [uniqKey]: false }));
                        });
                    }
                });

                if (orderAllTrigger === null) {
                    notifyWarningCustom('Tools removed from cart');
                }
            }
        } else {
            let hasAnyZero = false;

            missingItemTypes.forEach(itemType => {
                const anyZero = itemType.item_type.items?.some(toolItem => {
                    const uniqKey = generateItemUniqueKey(itemType, toolItem);
                    return Number(debouncedOrderedQty[uniqKey] || 0) <= 0;
                });
                if (anyZero) {
                    hasAnyZero = true;
                }
            });

            if (hasAnyZero && orderAllTrigger === null) {
                notifyWarningCustom('You need to enter a value in the “QTY to order” input field');
                return;
            }

            missingItemTypes.forEach(itemType => {
                const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
                if (!storedSelectedFragments[fragmentKey]) {
                    storedSelectedFragments[fragmentKey] = {
                        items: {},
                        LaborItemTypes: {},
                        ToolsItemTypes: {}
                    };
                }
                const toolKey = `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;

                if (!storedSelectedFragments[fragmentKey].ToolsItemTypes) {
                    storedSelectedFragments[fragmentKey].ToolsItemTypes = {};
                }
                if (!storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey]) {
                    storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey] = {
                        ...itemType.item_type,
                        items: [],
                        payload: generatePayload(itemType),
                    };
                }
                itemType.item_type.items?.forEach(toolItem => {
                    const uniqKey = generateItemUniqueKey(itemType, toolItem);
                    const localVal = Number(debouncedOrderedQty[uniqKey] || 0);
                    storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey].items.push({
                        ...toolItem,
                        user_buy: localVal,
                        payload: generatePayload(itemType, [toolItem.id])
                    });
                    setSelectedItemsState(prev => ({ ...prev, [uniqKey]: true }));
                });
            });

            if (orderAllTrigger === null) {
                notifySuccessCustom('Tools added to cart');
            }
        }

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
        setSelectedTools(Date.now());
        const calcPrice = calculateTotalPrice(filteredMergedItemTypes, viewModeTools);
        setTotalPrice(calcPrice);
    };



    useEffect(() => {
        if (orderAllTrigger !== null){
            handleOrderFragment(isTriggeredByParent)
        }
    }, [orderAllTrigger]);

    const calculateTotalPrice = (
        items: IToolsItemType[],
        viewModeTools: 'Recommended' | 'Minimum'
    ) => {
        let tot = 0;
        items.forEach(item => {
            item.item_type.items?.forEach(toolItem => {
                const p = parseFloat(String(toolItem.price)) || 0;
                let qty = toolItem.qty;
                if (viewModeTools === 'Recommended') {
                    qty = items
                        .filter(cur => cur.item_type.id === item.item_type.id)
                        .reduce((sum, cur) => {
                            const comb = cur.item_type.items?.find(i => i.id === toolItem.id);
                            return sum + (comb ? comb.qty : 0);
                        }, 0);
                }
                tot += p * qty;
            });
        });
        return tot;
    };

    useEffect(() => {
        const calcPrice = calculateTotalPrice(filteredMergedItemTypes, viewModeTools);
        setTotalPrice(calcPrice);
        setSelectedTools(Date.now());
    }, [filteredMergedItemTypes, setSelectedTools, viewModeTools]);

    const generatePayload = (itemType: IToolsItemType, itemIds?: number[]): any => ({
        template_index: itemType.template_index,
        fragment_index: itemType.fragment_index,
        item_type_index: itemType.item_type_index,
        template_id: itemType.template_id,
        fragment_id: itemType.fragment_id,
        item_type_id: itemType.item_type.id,
        item_ids: itemIds || (itemType.item_type.items?.map(i => i.id) || []),
    });

    const saveToLocalStorage = (
        item: IToolsItemType,
        toolItem: TItem,
        tools: IToolsItemType[],
        itemUniqueKey: string
    ) => {
        const stored = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        tools.forEach(origItem => {
            if (origItem.item_type.id === item.item_type.id) {
                origItem.item_type.items?.forEach(origToolItem => {
                    if (
                        origToolItem.id === toolItem.id &&
                        (viewModeTools === 'Recommended' ||
                            (viewModeTools === 'Minimum' && origToolItem.qty === toolItem.qty))
                    ) {
                        const fragmentKey = `${origItem.fragment_id}-${origItem.fragment_index}-${origItem.template_index}`;
                        if (!stored[fragmentKey]) {
                            stored[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
                        }
                        const toolKey = `${origItem.item_type.id}-${origItem.clone_id || origItem.item_type_index}-${origItem.fragment_index}`;
                        if (!stored[fragmentKey].ToolsItemTypes) {
                            stored[fragmentKey].ToolsItemTypes = {};
                        }
                        if (!stored[fragmentKey].ToolsItemTypes[toolKey]) {
                            let userBuyValue = Number(debouncedOrderedQty[itemUniqueKey]) || 0;
                            stored[fragmentKey].ToolsItemTypes[toolKey] = {
                                ...origItem.item_type,
                                items: origItem.item_type.items.map(toolItem => {
                                    const uniqKey = generateItemUniqueKey(origItem, toolItem);
                                    const localVal = Number(debouncedOrderedQty[uniqKey] || "0");
                                    return {
                                        ...toolItem,
                                        user_buy: localVal,
                                        payload: generatePayload(origItem),
                                    };
                                }),
                                payload: generatePayload(origItem),
                            };
                        }
                        const selToolType = stored[fragmentKey].ToolsItemTypes[toolKey];
                        let userBuyValue = Number(debouncedOrderedQty[itemUniqueKey]) || 0;
                        selToolType.items.push({
                            ...origToolItem,
                            user_buy: userBuyValue,
                            payload: generatePayload(origItem, [origToolItem.id])
                        });
                        selToolType.payload.item_ids.push(origToolItem.id);
                    }
                });
            }
        });
        localStorage.setItem('selectedFragments', JSON.stringify(stored));
    };

    const removeFromLocalStorage = (
        item: IToolsItemType,
        toolItem: TItem,
        tools: IToolsItemType[],
        itemUniqueKey: string
    ) => {
        const stored = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        tools.forEach(origItem => {
            if (origItem.item_type.id === item.item_type.id) {
                origItem.item_type.items?.forEach(origToolItem => {
                    if (origToolItem.id === toolItem.id) {
                        const fragmentKey = `${origItem.fragment_id}-${origItem.fragment_index}-${origItem.template_index}`;
                        const toolKey = `${origItem.item_type.id}-${origItem.clone_id || origItem.item_type_index}-${origItem.fragment_index}`;
                        const selToolType = stored[fragmentKey]?.ToolsItemTypes?.[toolKey];
                        if (selToolType) {
                            selToolType.items = selToolType.items.filter((i: any) => i.id !== origToolItem.id);
                            if (selToolType.items.length === 0) {
                                delete stored[fragmentKey].ToolsItemTypes[toolKey];
                            }
                        }
                    }
                });
            }
        });
        localStorage.setItem('selectedFragments', JSON.stringify(stored));
    };

    return (
        <div>
            {filteredMergedItemTypes.map((itemType, index) => {
                const toolKey = `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;
                const totalQty =
                    viewModeTools === 'Recommended'
                        ? toolsItemTypes
                            .filter(it => it.item_type.id === itemType.item_type.id)
                            .reduce((sum, it) => sum + it.qty, 0)
                        : Math.max(
                            ...toolsItemTypes
                                .filter(it => it.item_type.id === itemType.item_type.id)
                                .map(it => it.qty)
                        );
                if (totalQty === 0) return null;

                return (
                    <div key={toolKey}>
                        {itemType.item_type.items && itemType.item_type.items.length > 0 ? (
                            itemType.item_type.items.map((toolItem, toolIndex) => {
                                const checkTotalOrderedQty = !isNaN(toolItem.ordered_qty)
                                    ? Math.max(toolItem.ordered_qty || 0, 0)
                                    : 0;
                                const checkTotalQty = !isNaN(toolItem.qty)
                                    ? Math.max(toolItem.qty || 0, 0)
                                    : 0;
                                const price = parseFloat(String(toolItem.price)) || 0;
                                const orderedQtyPackaging = checkTotalOrderedQty * (
                                    Array.isArray(toolItem.packaging) && toolItem.packaging.length === 0
                                        ? 1
                                        : toolItem.packaging && typeof toolItem.packaging === 'object'
                                            ? toolItem.packaging[Object.keys(toolItem.packaging)[0]] || 1
                                            : 1
                                );
                                const totalPriceRemaining = price * (checkTotalQty - checkTotalOrderedQty);
                                const checkTotalPriceRemaining = Math.max(totalPriceRemaining, 0);
                                const itemUniqueKey = generateItemUniqueKey(itemType, toolItem);

                                const itemPackaging =
                                    toolItem.packaging &&
                                    typeof toolItem.packaging === 'object' &&
                                    Object.keys(toolItem.packaging).length > 0
                                        ? Object.keys(toolItem.packaging)[0]
                                        : toolItem.unit
                                            ? toolItem.unit
                                            : 'psc';

                                const itemKey = generateItemKey(
                                    itemType.fragment_id,
                                    itemType.fragment_index,
                                    itemType.template_id,
                                    itemType.template_index,
                                    itemType.item_type.id,
                                    toolItem.id
                                );
                                const visited = isVisited(itemKey);
                                const errorItem = parseItemKey(itemKey);

                                return (
                                        <React.Fragment key={`${toolKey}-${toolItem.id}`}>
                                            <div className='static-item-type-info'>
                                                <p className='static-item-type-info-title'>{itemType.title}</p>
                                                <p className='static-item-type-info-qty'>
                                                    {itemType.qty} ({itemType.item_type.unit ? itemType.item_type.unit : 'each'})
                                                </p>
                                            </div>
                                            <div className='step2-item-type-list'>
                                                <div className="step2-material-width-list">
                                                    <div className="step2-item-checkbox-block">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-${toolKey}-${toolItem.id}`}
                                                            checked={selectedItems[itemUniqueKey] || false}
                                                            onChange={() =>
                                                                handleCheckboxChange(itemUniqueKey, itemType, toolItem)
                                                            }
                                                        />
                                                        <label
                                                            className='checkbox-text-step2'
                                                            htmlFor={`checkbox-${toolKey}-${toolItem.id}`}
                                                        >
                                                            {toolItem.title}
                                                        </label>
                                                    </div>
                                                </div>
                                                <p className="step2-item-width-list-calc">{checkTotalQty} (psc)</p>
                                                <p className="step2-item-width-list-qty-ordered">
                                                    {checkTotalOrderedQty}(psc)/{orderedQtyPackaging.toFixed(2)} ({itemPackaging})
                                                </p>
                                                <div className="step2-tooltip-container-item">
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        value={debouncedOrderedQty[itemUniqueKey] || ""}
                                                        onChange={(e) =>
                                                            handleQtyChange(
                                                                itemUniqueKey,
                                                                e.target.value,
                                                                errorItem,
                                                                toolItem.qty_from_store
                                                            )
                                                        }
                                                        min="0"
                                                        disabled={!!selectedItems[itemUniqueKey]}
                                                    />
                                                    <span className='packing-value-steps'>psc</span>
                                                    {errorMessages[itemKey] && (
                                                        <div className="error-message">{errorMessages[itemKey]}</div>
                                                    )}
                                                </div>
                                                <p className="step2-item-width-list-price">
                                                    R {formatNumber(price)}
                                                </p>
                                                <p className="step2-item-width-list-total">
                                                    R {formatNumber(checkTotalPriceRemaining)}
                                                </p>
                                                <div className='step2-other-width-vendor-list-item'>
                                                    {toolItem.vendor !== null && vendorData[toolItem.vendor as Vendor] ? (
                                                        <>
                                                            <p
                                                                onClick={() =>
                                                                    handleLogoClick(
                                                                        toolItem.merchant_info?.link,
                                                                        itemType.fragment_id,
                                                                        itemType.fragment_index,
                                                                        itemType.template_id,
                                                                        itemType.template_index,
                                                                        itemType.item_type.id,
                                                                        toolItem.id
                                                                    )
                                                                }
                                                                className='item-selection-modal-vendor'
                                                            >
                                                                {vendorData[toolItem.vendor as Vendor].displayName}
                                                            </p>
                                                            {!visited && <span><IconEyesStep3/></span>}
                                                        </>
                                                    ) : (
                                                        <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                    )}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                );
                            })
                        ) : (
                                <div key={toolKey}>
                                    <div className="step2-item-type-list-no-items">
                                        <div className='static-item-type-info'>
                                            <p className='static-item-type-info-title'>{itemType.title}</p>
                                            <p className='static-item-type-info-qty'>
                                                {itemType.qty} ({itemType.unit ? itemType.unit : 'each'})
                                            </p>
                                        </div>
                                        <p className="step2-other-width-list-total-no-item">R 0.00</p>
                                        <div className="step2-tooltip-container-no-items">
                                            <input
                                                type="number"
                                                className="step2-other-width-input"
                                                disabled={true}
                                            />
                                            <span className='packing-value-steps'>psc</span>
                                        </div>
                                        <p className="step2-other-width-list">R 0.00</p>
                                        <div className='step2-other-width-vendor-list'></div>
                                    </div>
                                </div>
                        )}
                    </div>
                );
            })}
            <div className='step2-bottom-block'>
                    <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button
                            className='step2-bottom-block-btn-cart-list'
                            onClick={()=>handleOrderFragment(false)}
                            style={{
                                backgroundColor: isButtonDisabledOrderFragment ? '#cccccc' : '',
                                cursor: isButtonDisabledOrderFragment ? 'not-allowed' : 'pointer'
                            }}
                        >
                            Select for Ordering
                        </button>
                    </>
            </div>
        </div>
    );
};


const mapDispatchToProps = {
    setSelectedTools,
    clearSelectedItems,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ToolsItemTypeList);
