import React, {Dispatch, SetStateAction} from 'react';
import { connect, ConnectedProps } from "react-redux";
import '../Styles/StepCalcDesktop.css';

import 'react-toastify/dist/ReactToastify.css';
import {useStepCalcLogic} from "./StepCalcHook/useStepCalcLogic";
import parseTemplateToRequestObject from "../../../Parsers/TemplateParse/templateToTemplateRequestParser";
import {RootState} from "../../../store";
import {updateProject} from "../../../Slice/updateProject/updateProjectSlice";
import {
    clearTemplates,
    setCalculatedTemplate,
    updateLocalTemplate
} from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import {Template} from "../../../types/TemplateType";
import useIsSmallScreen from "../../../Hooks/SmallScreen/useIsSmallScreen";


export interface StepCalcProps {
    template: Template | null;
    selectedAnswers: Record<string, string>;
    setSelectedAnswers: Dispatch<SetStateAction<Record<string, string>>>;
    selectedTemplates: Template[];
    templIndex?: number;
}

const StepCalcDesktop: React.FC<StepCalcProps & PropsFromRedux> = ({
                                                                templIndex,
                                                                project,
                                                                updateLocalTemplate,
                                                                updateProject,
                                                                setCalculatedTemplate,
                                                                template,
                                                                selectedAnswers,
                                                                setSelectedAnswers,
                                                                clearTemplates,
                                                            }) => {
    const isSmallScreen = useIsSmallScreen();

    const {
        expandedFragments,
        isTemplateExpanded,
        fragmentsComplete,
        toggleTemplateVisibility,
        toggleFragment,
        handleAnswerChange,
        handleInputChange,
        handleInputBlur,
        handleSubmit,
    } = useStepCalcLogic(
        template,
        selectedAnswers,
        setSelectedAnswers,
        project,
        updateLocalTemplate,
        setCalculatedTemplate,
        updateProject,
        clearTemplates,
        templIndex
    );

    const allQuestions = Object.entries(template?.data || {})
        .filter(([key, item]: [string, any]) => !isNaN(parseInt(key)) && item?.question && item?.select);

    if (!template) {
        return <div>Loading...</div>;
    }

    const parserTemplate = parseTemplateToRequestObject(template);

    return (
        <div className="step-calc">
            <div>
                <div className="step-calc-template-fragment-info">
                    <p className="step-calc-template-title">Template: {template.title}</p>
                    {parserTemplate?.data?.fragments?.map((fragment: any, fragmentIndex: number) => (
                        <div key={`fragment-list-${fragmentIndex}`} className="fragment-list-item">
                            <label className="fragment-list-item-label">
                                {fragmentIndex + 1}. {fragment.title || `Fragment ${fragmentIndex + 1}`}
                                <input type="checkbox" checked={fragmentsComplete[fragmentIndex] || false} readOnly />
                            </label>
                        </div>
                    ))}
                </div>
                {allQuestions.length >= 1 && (
                    <div className={`step-calc-fragment ${isTemplateExpanded ? 'expanded' : 'collapsed'}`} key={template.id}>
                        <h3 className="step-calc-fragment-title" onClick={toggleTemplateVisibility}>
                            Required Information:
                            <button className={`toggle-button ${isTemplateExpanded ? 'expanded' : 'collapsed'}`}>
                                {isTemplateExpanded ? (
                                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
                                        <path d="M6 0L12 8H0L6 0Z" fill="#666" />
                                    </svg>
                                ) : (
                                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
                                        <path d="M6 8L0 0H12L6 8Z" fill="#666" />
                                    </svg>
                                )}
                            </button>
                        </h3>
                        <div className="step-calc-fragment-content">
                            {allQuestions.map(([key, templateQuestion]) => (
                                <div className="step-answer-block" key={`${template.id}-calc-${key}`}>
                                    <div className="step-question">{templateQuestion?.question || 'Default Question'}</div>
                                    <div className="step-calc-field-fragment">
                                        <input
                                            className={`step-calc-field-fragment ${selectedAnswers[`${template.id}-calc-${key}`] ? 'filled' : ''}`}
                                            type="text"
                                            value={selectedAnswers[`${template.id}-calc-${key}`] || ''}
                                            onChange={(e) => handleInputChange(`${template.id}-calc-${key}`, e.target.value)}
                                            onBlur={() => handleInputBlur(`${template.id}-calc-${key}`)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="step-calc-fragment-block">
                    {parserTemplate?.data?.fragments?.map((fragment: any, fragmentIndex: number) => {
                        const hiddenKeys = new Set<string>();
                        if (fragment?.data?.var_mapping) {
                            const mappings = fragment.data.var_mapping.split('&&').map((map: string) => map.trim());
                            mappings.forEach((mapping: string) => {
                                const [left, right] = mapping.split('=').map((s: string) => s.trim());
                                hiddenKeys.add(right);
                            });
                        }
                        const hasQuestions = Object.entries(fragment.data || {}).some(
                            ([key, fragmentData]: [string, any]) =>
                                !hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select
                        );
                        if (!hasQuestions) {
                            return null;
                        }
                        const isExpanded = expandedFragments.has(fragmentIndex);
                        const fragmentClass = fragmentsComplete[fragmentIndex] ? 'complete' : 'incomplete';

                        return (
                            <div
                                key={`fragment-${fragmentIndex}`}
                                className={`step-calc-fragment fragment-${fragmentIndex} ${isExpanded ? 'expanded' : 'collapsed'} ${fragmentClass}`}
                            >
                                <h3 className="step-calc-fragment-title" onClick={() => toggleFragment(fragmentIndex)}>
                                    {fragment.title || `Fragment ${fragmentIndex + 1}`}
                                    <button className={`toggle-button ${isExpanded ? '' : 'collapsed'}`}>
                                        {isExpanded ? (
                                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
                                                <path d="M6 0L12 8H0L6 0Z" fill="#666" />
                                            </svg>
                                        ) : (
                                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
                                                <path d="M6 8L0 0H12L6 8Z" fill="#666" />
                                            </svg>
                                        )}
                                    </button>
                                </h3>
                                <div className={`step-calc-fragment-content ${isExpanded ? 'expanded' : ''}`}>
                                    {Object.entries(fragment.data || {}).map(([key, fragmentData]: [string, any], index: number) => {
                                        const uniqueKey = `fragment-${fragmentIndex}-${key}-${fragmentData?.key}`;
                                        if (!hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select) {
                                            if (fragmentData.select === 'select' || fragmentData.select === 'style') {
                                                const validAnswers = (fragmentData.answers || []).filter((answer: any) => answer?.title);
                                                if (validAnswers.length === 0) return null;
                                                if (fragmentData.select === 'style' && !fragmentData.required_question) return null;
                                                return (
                                                    <div key={`${fragmentIndex}-${key}`} className="step-answer-block">
                                                        <div className="step-question">{fragmentData.question}</div>
                                                        {validAnswers.map((answer: any, idx: number) => (
                                                            <label key={idx} className="step-answer">
                                                                <input
                                                                    type="radio"
                                                                    name={`fragment-${fragmentIndex}-${key}`}
                                                                    value={answer.value}
                                                                    checked={selectedAnswers[uniqueKey] === answer.value}
                                                                    onChange={() => handleAnswerChange(uniqueKey, answer.value)}
                                                                />
                                                                <span className="radio-custom"></span>
                                                                {answer.title}
                                                            </label>
                                                        ))}
                                                    </div>
                                                );
                                            }
                                            if (fragmentData.select === 'input') {
                                                return (
                                                    <div key={`${fragmentIndex}-${key}`} className="step-answer-block">
                                                        <div className="step-question">{fragmentData.question}</div>
                                                        <div className="step-calc-field-fragment">
                                                            <input
                                                                type="text"
                                                                value={selectedAnswers[uniqueKey] || ''}
                                                                onChange={(e) => handleInputChange(uniqueKey, e.target.value)}
                                                                onBlur={() => handleInputBlur(uniqueKey)}
                                                                className={selectedAnswers[uniqueKey] ? 'filled' : ''}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <button className={isSmallScreen ? "assis-bottom-btn-mobile" : "assis-bottom-btn"} onClick={handleSubmit}>
                <span>Next step</span>
            </button>

        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
    isFetching: state.updateProject.isFetching,
});

const mapDispatchToProps = {
    updateProject,
    updateLocalTemplate,
    setCalculatedTemplate,
    clearTemplates,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StepCalcDesktop);
