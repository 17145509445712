import { useEffect } from 'react';

export function useAutoHideScrollbar(
    el: HTMLElement | null,
    isOpen: boolean,
    hideDelay = 1500
) {
    useEffect(() => {
        if (!isOpen || !el) return;

        let hideTimer: ReturnType<typeof setTimeout>;
        let attachTimer: ReturnType<typeof setTimeout>;

        const onScroll = () => {
            el.classList.add('scrollbar-visible');
            clearTimeout(hideTimer);
            hideTimer = setTimeout(() => {
                el.classList.remove('scrollbar-visible');
            }, hideDelay);
        };

        attachTimer = setTimeout(() => {
            el.addEventListener('scroll', onScroll);
        }, 300);

        return () => {
            clearTimeout(attachTimer);
            el.removeEventListener('scroll', onScroll);
            clearTimeout(hideTimer);
        };
    }, [el, isOpen, hideDelay]);
}
