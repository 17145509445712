import React, { useEffect, useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { UserRoleEnum } from "../Enums/UserRoleEnum";
import { useSelector } from "react-redux";
import { fetchUser, selectUser } from "../Slice/tokenSlice/userSlice";
import { RootState, useAppDispatch } from "../store";
import { io, Socket } from "socket.io-client";
import { notifySuccess } from "../components/Toast/AssisCreateToastNotification";
import { setTemplateMessage } from "../Slice/TemplateSlice/TemplateSlice";

interface UserRoleProps {
    redirectPath?: string;
    allowedRoles: UserRoleEnum[];
    children: React.ReactNode;
}

const token = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');

const SOCKET_SERVER_URL = process.env.REACT_APP_BASE_SOCKET_LINK;
export const socket_token_role: Socket = io(SOCKET_SERVER_URL, {
    transports: ['websocket', 'polling'],
    withCredentials: false,
    auth: {
        token: `Bearer ${token}`
    }
});

const UserRoleRouteMiddleware: React.FC<UserRoleProps> = ({ redirectPath = '/auth/login', allowedRoles, children }) => {
    const [isAuthorized, setIsAuthorized] = useState(true);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const user = useSelector(selectUser);
    const role = user?.role?.role as UserRoleEnum | null;
    const dispatch = useAppDispatch();

    const roleRef = useRef(role);
    useEffect(() => {
        roleRef.current = role;
    }, [role]);

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const handleBuyTemplate = (data: any) => {
            if (data && data.status === true) {
                dispatch(setTemplateMessage(data.template_title));
                notifySuccess(`You have successfully purchased a template: "${data.template_title}"`);
            }
        };

        const handleCreditTokens = (data: any) => {
            if (data && data.status === true) {
                dispatch(fetchUser());
            }
        };

        const handleSubscription = (data: any) => {
            if (data && data.status === true) {
                dispatch(fetchUser());
                notifySuccess(`Your subscription plan has been updated.`);
            }
        };

        socket_token_role.on("template", handleBuyTemplate);
        socket_token_role.on("credit_tokens", handleCreditTokens);
        socket_token_role.on("subscription", handleSubscription);

        return () => {
            socket_token_role.off("template", handleBuyTemplate);
            socket_token_role.off("credit_tokens", handleCreditTokens);
            socket_token_role.off("subscription", handleSubscription);
        };
    }, [dispatch,isAuthenticated]);

    useEffect(() => {
        if (!isAuthenticated) {
            setIsAuthorized(false);
            return;
        }
        dispatch(fetchUser()).then(() => {
            if (!allowedRoles.includes(roleRef.current as UserRoleEnum)) {
                timerRef.current = setTimeout(() => {
                    dispatch(fetchUser()).then(() => {
                        if (!allowedRoles.includes(roleRef.current as UserRoleEnum)) {
                            sessionStorage.removeItem('Bearer');
                            localStorage.removeItem('Bearer');
                            setIsAuthorized(false);
                        } else {
                            setIsAuthorized(true);
                        }
                    });
                }, 3000);
            } else {
                setIsAuthorized(true);
            }
        });

        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [isAuthenticated, allowedRoles, dispatch]);

    if (!isAuthorized) {
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</>;
};

export default UserRoleRouteMiddleware;
