import { useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';
import {Fragment, Template} from "../../../../types/TemplateType";
import {clearLocalStorageExceptToken} from "../../../../utils/ClearLocalStorage";
import {transformTemplate} from "../../../../utils/TransformTemplate";
import {useLocation} from "react-router-dom";
import {useSmartNavigate} from "../../../../Hooks/useNavigationHook";
import {checkDisabledQuestion} from "../../../../api/APIWraper";
import {notifyErrorCustom} from "../../../../components/Toast/AssisCreateToastNotification";

export const useStepCalcLogic = (
    template: Template | null,
    selectedAnswers: Record<string, string>,
    setSelectedAnswers: React.Dispatch<React.SetStateAction<Record<string, string>>>,
    project: any,
    updateLocalTemplate: (template: any) => void,
    setCalculatedTemplate: (id: number) => void,
    updateProject: any,
    clearTemplates: () => void,
    templIndex?: number
) => {


    useEffect(() => {
        if (template?.data) {
            const initialSelectedAnswers: Record<string, string> = {};
            Object.entries(template.data || {}).forEach(([key, item]: [string, any]) => {
                if (!isNaN(parseInt(key)) && item?.value) {
                    initialSelectedAnswers[`${template.id}-calc-${key}`] = item.value;
                }
            });
            template.data.fragments?.forEach((fragment: any, fragmentIndex: number) => {
                Object.entries(fragment.data || {}).forEach(([key, item]: [string, any]) => {
                    const uniqueKey = `fragment-${fragmentIndex}-${key}-${item?.key}`;
                    if (item?.value) {
                        initialSelectedAnswers[uniqueKey] = item.value;
                    }
                });
            });
            setSelectedAnswers(initialSelectedAnswers);
        }
    }, [template, setSelectedAnswers]);

    const location = useLocation();
    const smartNavigate = useSmartNavigate();

    const [expandedFragments, setExpandedFragments] = useState<Set<number>>(new Set([0]));
    const [isTemplateExpanded, setIsTemplateExpanded] = useState<boolean>(true);
    const [fragmentsComplete, setFragmentsComplete] = useState<Record<number, boolean>>({});

    const toggleTemplateVisibility = useCallback(() => {
        setIsTemplateExpanded(prev => !prev);
    }, []);

    const toggleFragment = useCallback((index: number) => {
        setExpandedFragments(prev => {
            const newSet = new Set(prev);
            newSet.has(index) ? newSet.delete(index) : newSet.add(index);
            return newSet;
        });
    }, []);

    const checkFragmentsCompletion = useCallback(() => {
        if (!template?.data?.fragments) return;
        const updatedStatus: Record<number, boolean> = {};

        template.data.fragments.forEach((fragment: Fragment, fragmentIndex: number) => {
            const hiddenKeys = new Set<string>();
            if (fragment?.data?.var_mapping) {
                const mappings = fragment.data.var_mapping.split('&&').map((m: string) => m.trim());
                mappings.forEach((mapping: string) => {
                    const [left, right] = mapping.split('=').map((s: string) => s.trim());
                    hiddenKeys.add(right);
                });
            }

            const visibleQuestions = Object.entries(fragment.data || {}).filter(([key, fragData]: [string, any]) => {
                if (!fragData) return false;
                if (hiddenKeys.has(fragData?.key)) return false;
                if (!fragData.question || !fragData.select) return false;
                if (fragData.select === 'select') {
                    const validAnswers = (fragData.answers || []).filter((ans: any) => ans?.title);
                    return validAnswers.length > 0;
                }
                return true;
            });

            if (visibleQuestions.length === 0) {
                updatedStatus[fragmentIndex] = true;
                return;
            }

            const isComplete = visibleQuestions.every(([key, fragData]: [string, any]) => {
                const uniqueKey = `fragment-${fragmentIndex}-${key}-${fragData?.key}`;
                const val = selectedAnswers[uniqueKey];
                const valStr = val == null ? '' : String(val);
                if (fragData.select === 'input') {
                    return valStr.trim().length > 0;
                }
                if (fragData.select === 'select') {
                    return valStr !== '';
                }
                return false;
            });
            updatedStatus[fragmentIndex] = isComplete;
        });
        setFragmentsComplete(updatedStatus);
    }, [template, selectedAnswers]);

    useEffect(() => {
        checkFragmentsCompletion();
    }, [template, selectedAnswers, checkFragmentsCompletion]);

    const handleAnswerChange =(async (key: string, value: string) => {
        let isDisabled = true;
        const fragmentPrefix = key.match(/^fragment-\d+-/)?.[0] ?? '';
        const filteredAnswers = Object.fromEntries(
            Object.entries(selectedAnswers)
                .filter(([k]) => k.startsWith(fragmentPrefix))
                .filter(([k]) => k !== key)
                .filter(([_, v]) => v !== "0")
        );
        try {
            const response = await checkDisabledQuestion(project.id, templIndex, `question[${key}]=${value}`, {
                data: filteredAnswers
            });
            if (response.status === 200) {
                isDisabled = response.data.disabled;
            } else {
                console.error('error');
            }
        } catch (error) {
            console.error(error);
        }
        if (isDisabled) {
            notifyErrorCustom('You cannot select this question.');
            return;
        }
        setSelectedAnswers(prev => ({ ...prev, [key]: value }));
    });

    const handleInputChange = useCallback((key: string, value: string) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setSelectedAnswers(prev => ({ ...prev, [key]: value }));
        }
    }, [setSelectedAnswers]);

    const handleInputBlur = useCallback((key: string) => {
        setSelectedAnswers(prev => ({ ...prev, [key]: prev[key] === '' ? '' : prev[key] }));
    }, [setSelectedAnswers]);

    const handleSubmit = useCallback(async () => {
        if (!template) {
            console.error("Template is undefined.");
            return;
        }
        const updatedTemplate = {
            ...template,
            isCalculated: true,
            data: {
                ...template.data,
                ...Object.entries(template.data || {})
                    .filter(([key]) => !isNaN(parseInt(key)))
                    .reduce((acc: Record<string, any>, [key, item]: [string, any]) => {
                        acc[key] = {
                            ...item,
                            value: selectedAnswers[`${template.id}-calc-${key}`] || item?.value,
                        };
                        return acc;
                    }, {}),
                fragments: template.data.fragments.map((fragment: any, fragmentIndex: number) => ({
                    ...fragment,
                    data: Object.entries(fragment.data || {}).reduce((acc: Record<string, any>, [key, item]: [string, any]) => {
                        const uniqueKey = `fragment-${fragmentIndex}-${key}-${item?.key}`;
                        acc[key] = {
                            ...item,
                            value: selectedAnswers[uniqueKey] || item?.value,
                            answers: item?.answers
                                ? item.answers.map((answer: any) => ({
                                    ...answer,
                                    value: item.select === 'input' ? selectedAnswers[uniqueKey] : answer?.value,
                                    selected: item.select === 'input'
                                        ? true
                                        : selectedAnswers[uniqueKey] === answer?.value?.toString(),
                                }))
                                : item?.answers,
                        };
                        return acc;
                    }, {}),
                })),
            },
        };

        updateLocalTemplate(updatedTemplate);
        setCalculatedTemplate(template.id);

        let currentProject = project;
        if (!currentProject) {
            const stored = localStorage.getItem('project');
            if (stored) {
                currentProject = JSON.parse(stored);
            }
        }
        if (currentProject && currentProject.data && currentProject.data.templates) {
            const updatedTemplates = currentProject.data.templates.map((t: any) =>
                t.id === updatedTemplate.id ? { ...updatedTemplate, counted: true } : t
            );
            const transformedTemplates = updatedTemplates.map(transformTemplate);
            const updatedProjectData = { data: { templates: transformedTemplates } };
            const toastId = toast.info("Waiting for server response...");
            try {
                const resultAction = await updateProject({
                    projectId: currentProject.id,
                    projectData: updatedProjectData,
                });
                if (resultAction.meta.requestStatus === 'fulfilled') {
                    toast.update(toastId, {
                        render: "Project updated successfully!",
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                    });
                    clearTemplates();
                    clearLocalStorageExceptToken();
                    setTimeout(() => {
                        smartNavigate(`/assistance/step2/template/${templIndex}/project/${project?.id}`, {
                            state: { from: location.pathname },
                        });
                    }, 2000);
                } else if (resultAction.meta.requestStatus === 'rejected') {
                    throw new Error("Update project failed");
                }
            } catch (error) {
                toast.update(toastId, {
                    render: "Failed to update project",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                });
            }
        } else {
            console.error("Unknown navigation source.");
        }
    }, [template, selectedAnswers, project, updateLocalTemplate, setCalculatedTemplate, updateProject, clearTemplates, location, templIndex]);

    return {
        expandedFragments,
        isTemplateExpanded,
        fragmentsComplete,
        toggleTemplateVisibility,
        toggleFragment,
        handleAnswerChange,
        handleInputChange,
        handleInputBlur,
        handleSubmit,
    };
};
