import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import './Step3Bom.css';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import 'react-toastify/dist/ReactToastify.css';
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {BOMItem, BOMItemType, BOMProject} from "../../types/ProjectBOMInterface";
import {IconEyesStep3} from "../../IconComponents/IconComponents";
import {getProjectBom, postBomEditItems} from "../../api/APIWraper";
import {toast} from "react-toastify";
import {Fragment, ItemType, TItem} from "../../types/TemplateType";
import StepTotalsBlockBom from "../../components/TotalsBlockBOM/StepTotalsBlockBom";

const Step3Bom = () => {
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const [projectBom, setProjectBom] = useState<BOMProject | null>(null);


    const [selectedItemTypes, setSelectedItemTypes] = useState<BOMItemType[]>([]);
    const {link} = useParams<{ link: string }>();

    const [visited, setVisited] = useState<{ [key: number]: boolean }>({});
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
    const [fragments, setFragments] = useState<Fragment[] | null>(null)
    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        if (link) {
            const fetchProject = async () => {
                try {
                    const response: BOMProject = await getProjectBom(link);
                    setProjectBom(response)
                } catch (error) {
                    console.error("Error fetching project BOM:", error);
                }
            };

            fetchProject();
        }
    }, [link]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const storedVisitedItems = JSON.parse(localStorage.getItem('visitedItemsBom') || '{}');
        setVisited(storedVisitedItems);
    }, []);

    useEffect(() => {
        const storedSelectedItems = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragmentBom') || '[]')
        setSelectedItemTypes(storedSelectedItems)
        setFragments(storedSelectedFragments)
    }, []);


    const calculateFragmentTotal = (fragment: Fragment): number => {
        const itemTypes = fragment.data.item_types as unknown as ItemType[];
        return itemTypes.reduce((total, itemType) => {
            const itemsTotal = itemType.items.reduce((subTotal, item) => {
                const qty = quantities[`${fragment.id}-${itemType.id}-${item.id}`] || 0;
                return subTotal + qty * parseFloat(String(item.price));
            }, 0);
            return total + itemsTotal;
        }, 0);
    };

    const calculateTotalItemTypePrice = () => {
        return selectedItemTypes.reduce((acc, itemType, index) => {
            const key = `${itemType.id}-${index}`;
            const qty = quantities[key] || 0;
            return acc + qty * parseFloat(itemType.item.price);
        }, 0);
    };


    const navigateToHome = () => {
        clearLocalStorageExceptToken();
        navigate('/home');
    };

    if (!selectedItemTypes) {
        return null;
    }

    const renderVendorBlock = (item: BOMItem | TItem, visited: boolean, index: number) => {
        if (!item.vendor) {
            return <p className='step3-bom-item-no-vendor'>No Vendor</p>;
        }
        if (!visited) {
            return (
                <div
                    onClick={() => handleLogoClick(item.merchant_info?.link, item.id, index)}
                    className='step3-bom-items-container-vendor'>
                    <p className='step3-bom-items-container-vendor-purchase'>
                        <span className='step3-vendor-eyes'>
                            <IconEyesStep3/>
                        </span>
                        <span className='step3-vendor-p'>
                            Purchase from
                        </span>
                    </p>
                    <p className='step3-vendor-img'>
                        {item.vendor}
                    </p>
                </div>
            );
        } else {
            return (
                <div className='step3-bom-items-container-vendor-visited'>
                    <p
                        className='step3-vendor-img'
                        onClick={() => handleLogoClick(item.merchant_info?.link, item.id, index)}
                    >
                        {item.vendor}
                    </p>
                    <span
                        onClick={() => handleLogoClick(item.merchant_info?.link, item.id, index)}
                        className='step3-vendor-eyes'
                    >
                        <IconEyesStep3/>
                    </span>
                    <span className='step3-vendor-p-visited'>Visited</span>
                </div>
            );
        }
    };

    const handleLogoClick = (link: string | undefined, itemId: number, index: number) => {
        const storedVisitedItems = JSON.parse(localStorage.getItem('visitedItemsBom') || '{}');
        if (!storedVisitedItems[itemId]) {
            storedVisitedItems[itemId] = true;
            localStorage.setItem('visitedItemsBom', JSON.stringify(storedVisitedItems));
            setVisited(prev => ({
                ...prev,
                [itemId]: true
            }));
        }
        if (link) {
            window.open(link, '_blank', 'noopener');
        }
    };

    const handleQtyChange = (key: string, value: number) => {
        setQuantities(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const handleSave = async () => {
        const logData = selectedItemTypes.map((itemType, index) => {
            const uniqueKey = `${itemType.id}-${index}`;
            const newQty = quantities[uniqueKey] || 0;
            const ordered_qty = (itemType.item.ordered_qty || 0) + newQty;
            return {
                id: itemType.id,
                item: itemType.item.id,
                index: index,
                ordered_qty: ordered_qty
            };
        });

        const fragmentData = fragments?.flatMap((fragment, fragmentIndex) => {
            const itemTypes = fragment.data.item_types as unknown as ItemType[];

            return itemTypes.flatMap((itemType, itemTypeIndex) => {
                return itemType.items
                    .filter(item => quantities[`${fragment.id}-${itemType.id}-${item.id}`] > 0)
                    .map(item => {
                        const newQty = quantities[`${fragment.id}-${itemType.id}-${item.id}`] || 0;
                        const ordered_qty = (item.ordered_qty || 0) + newQty;

                        return {
                            fragment_id: fragment.id,
                            fragment_index: fragmentIndex,
                            item_type_id: itemType.id,
                            item_type_index: itemTypeIndex,
                            item_id: item.id,
                            ordered_qty: ordered_qty
                        };
                    });
            });
        }) || [];

        const body = {
            item_types: logData,
            fragments: fragmentData
        };

        const {status} = await postBomEditItems(body, link);
        if (status === 200) {
            toast.success('The project has been successfully updated');
            setTimeout(() => {
                navigate(`/create/step2Bom/${link}`);
            }, 100);
            localStorage.removeItem('selectedItemTypes');
            localStorage.removeItem('selectedFragmentBom');
        } else {
            toast.error('Failed to update the project');
        }
    };
    if (!fragments) {
        return null
    }

    const productTypeTotal = Number(calculateTotalItemTypePrice());
    const fragmentTotal = fragments.reduce(
        (acc, fragment) => acc + calculateFragmentTotal(fragment),
        0
    );

    const renderHeaderList = () => (
        <div className='step3-bom-header-info'>
            <p className='step3-bom-item-title-info'>Material</p>
            <p className='step3-bom-qty-order-info'>QTY To order</p>
            <p className='step3-bom-qty-ordered-info'>QTY ordered</p>
            <p className='step3-bom-price-info'>Unit price</p>
            <p className='step3-bom-total-value-info'>Total Value</p>
            <p className='step3-bom-total-current-order-info'>Current Order</p>
            <p className='step3-bom-total-orderd-vendor-info'>Vendor</p>
        </div>
    );
    if (!fragments && !projectBom) {
        return null
    }

    const renderFragments = () => {
        return (
            <>
                <p className='step2-bom-name-container'>List of fragments</p>
                <div className='step2-content-block'>

                    {fragments.map((fragment, fragmentIndex) => (
                        <div key={fragmentIndex}>
                            <div className='step2-bom-bom-project-title'>
                                <p className='step2-bom-title'>{fragment.title}</p>

                            </div>
                            {renderHeaderList()}

                            {(fragment.data.item_types as unknown as ItemType[]).map((itemType: ItemType, itemTypeIndex: number) => (
                                <div key={`${fragmentIndex}-${itemTypeIndex}`}>

                                    {itemType.items.map((item, itemIndex) => {
                                        const isVisited = visited[item.id] || false;
                                        const uniqueKey = `${fragment.id}-${itemType.id}-${item.id}`

                                        return (
                                            <div key={`${fragmentIndex}-${itemTypeIndex}-${itemIndex}`}
                                                 className='step3-bom-items'>
                                                <p className='step3-bom-item-title'>{item.title}</p>
                                                <div className='step3-bom-item-input'>
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        value={quantities[uniqueKey]}
                                                        onChange={(e) => handleQtyChange(uniqueKey, Number(e.target.value))}
                                                        min={0}
                                                    />

                                                </div>
                                                <p className='step3-bom-qty-ordered'>{item.ordered_qty || 0}/{item.qty || 0} ({item.unit || 'each'})</p>
                                                <p className='step3-bom-price'>R {parseFloat(String(item.price)).toFixed(2)}</p>
                                                <p className='step3-bom-total'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                                <p className='step3-bom-total-ordered'>R {(parseFloat(String(item.price)) * (quantities[uniqueKey] || 0)).toFixed(2)}</p>
                                                {renderVendorBlock(item, isVisited, itemIndex)}
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                            <div className='step3-bottom-total-block-price'>
                                <p>Total:</p>
                                {isSmallScreen && (<span
                                    className="dashed-line"></span>)}
                                <span>R {calculateFragmentTotal(fragment).toFixed(2)}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }

    const renderItemTypes = () => {
        return (
            <>
                <p className='step2-bom-name-container'>List of products</p>
                <div className='step2-content-block'>
                    <div className='step2-bom-bom-project-title'>
                        <p className='step2-bom-title'>Bill of materials products list</p>
                    </div>

                    {renderHeaderList()}
                    <div>
                        {selectedItemTypes.map((itemType, index) => {
                            const uniqueKey = `${itemType.id}-${index}`;
                            const item = itemType.item;
                            const isVisited = visited[item.id] || false;
                            const qtyOrdered = quantities[uniqueKey] || 0;
                            return (
                                <div key={uniqueKey} className='step3-bom-items'>
                                    <p className='step3-bom-item-title'>{item.title}</p>
                                    <div className='step3-bom-item-input'>
                                        <input
                                            type="number"
                                            className="step2-other-width-input"
                                            value={qtyOrdered}
                                            onChange={(e) => handleQtyChange(uniqueKey, Number(e.target.value))}
                                            min={0}
                                        />
                                    </div>
                                    <p className='step3-bom-qty-ordered'>{item.ordered_qty || 0}/{itemType.qty}({item.unit || 'each'})</p>
                                    <p className='step3-bom-price'>R {parseFloat(item.price).toFixed(2)}</p>
                                    <p className='step3-bom-total'>R {(parseFloat(item.price) * itemType.qty).toFixed(2)}</p>
                                    <p className='step3-bom-total-ordered'>R {(parseFloat(item.price) * qtyOrdered).toFixed(2)}</p>
                                    {renderVendorBlock(item, isVisited, index)}
                                </div>
                            )
                        })}
                        <div className='step3-bottom-total-block-price'>
                            <p>Total:</p>
                            {isSmallScreen && (<span
                                className="dashed-line"></span>)}
                            <span>R {calculateTotalItemTypePrice().toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className='step2'>
            <div>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            {to: '/', label: 'Cashbuild'},
                            {to: '', label: '>'},
                            {to: '/', label: 'Home'},
                            {to: '', label: '>'},
                            {to: '/home', label: 'Project assistance'},
                            {to: '', label: '>'},
                            {to: '', label: 'Create new project space'}
                        ]}
                    />
                ) : (
                    <div className='template-name-content'>
                        <div className='template-blog-placeholder'>
                            <p>Home/</p>
                            <p onClick={navigateToHome} className='template-link-assistance'>Project group
                                assistance</p>
                            <p className='name-link'>/Create new project space</p>
                        </div>
                    </div>
                )}
                <div className='step-info'>
                    <div className='step-info-block'>
                        <h3>Step 3</h3>
                        <span>/</span>
                        <p className='step-info-block-name'>Order view</p>
                    </div>
                </div>
                <section className='step2-bom-container'>
                    <div className='step2-bom-cost-summary'>
                        <p className='cost-summary-bom'>Cost summary</p>
                    </div>
                    {fragments.length > 0 && (
                        renderFragments()
                    )}
                    {selectedItemTypes.length > 0 && (
                        renderItemTypes()
                    )}
                </section>

                <StepTotalsBlockBom
                    project={projectBom}
                    productTypeTotal={productTypeTotal}
                    fragmentTotal={fragmentTotal}
                    isSmallScreen={isSmallScreen}
                    isVisible={false}
                />
            </div>
            <div className='step2-page-bottom-btn'>
                <div className='step2-page-bottom-btn-block'>
                    <div className='step2-page-btn'>
                        <button className='step2-page-bottom-block-btn'>Save & Request finance</button>
                    </div>
                    <div className='step2-page-btn'>
                        <button
                            className='step2-page-bottom-block-btn'
                            onClick={handleSave}
                        >
                            Proceed to Checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step3Bom;
