import React, {useState} from 'react';
import Modal from 'react-modal';
import './Purchase3dProject.css';
import {buyTokens} from "../../../api/APIWraper";
import {useSelector} from "react-redux";
import {selectUser} from "../../../Slice/tokenSlice/userSlice";
import {IconTokenModal, UploadModalTokensBig} from "../../TopHeaderAfterAuth/DesctopHeader/Sidebar/IconsSidebar";

interface ModalProps {
    isOpen: boolean;
    onCancel: () => void;
}

const Purchase3dProject: React.FC<ModalProps> = ({isOpen, onCancel}) => {
    const user = useSelector(selectUser);
    const [tokenPacks, setTokenPacks] = useState(1);

    if (!user) return null;
     const handleBuyTokens = async () => {
        const body = {
            tokens: tokenPacks
        };
        try {
            const response = await buyTokens(body);

            if (response.status === 200 && response.data) {
                const approveLink = response.data.links.find((link: { rel: string; }) => link.rel === "approve");

                if (approveLink) {
                    window.location.href = approveLink.href;
                } else {
                    console.error("Approve link not found in response:", response);
                }
            } else {
                console.error("Unexpected response status:", response);
            }
        } catch (error) {
            console.error("Error while buying tokens:", error);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onCancel}
            className="purchase-modal"
            overlayClassName="overlay-purchase-modal"
        >
            <p className='purchase-amount-tokens'>Available amount of tokens: <span
                className='purchase-amount-tokens-qty'>{user.tokens ? user.tokens : 0} <IconTokenModal/></span></p>
            <div className="purchase-content">
                <button className="close-button-purchase" onClick={onCancel}>&times;</button>
                <p className="purchase-text">Tokens purchasing <UploadModalTokensBig/></p>
                <div className='purchase-text-info-block'>
                    <p className='purchase-text-info'>Tokens allow you to upload your 3D model plan to the
                        architect's project.</p>
                    <p className='purchase-text-info-token'>1 token equals $20</p>
                </div>
                <div className="purchase-amount-selection-container">
                    <p>Select the desired number of token packs to purchase</p>

                    <div className='item-selection-modal-quantity-input'>
                        <button
                            className="item-selection-modal-qty-button minus"
                            onClick={() => setTokenPacks(prev => Math.max(1, prev - 1))}
                        >-
                        </button>
                        <input
                            type="number"
                            className="item-selection-modal-qty-input-field"
                            value={tokenPacks}
                            onChange={(e) => {
                                const value = parseInt(e.target.value);
                                setTokenPacks(isNaN(value) ? 1 : Math.max(1, value));
                            }}
                        />
                        <button
                            className="item-selection-modal-qty-button plus"
                            onClick={() => setTokenPacks(prev => prev + 1)}
                        >+
                        </button>
                    </div>
                </div>
                <div className='purchase-amount-total-block'>
                    <p className="purchase-amount-total-price">Total: <span
                        className="purchase-amount-total-amount">{tokenPacks * 20}$</span></p>
                </div>
                <div className="button-container-purchase">
                    <button className="cancel-button-purchase" onClick={onCancel}>Cancel</button>
                    <button
                        onClick={handleBuyTokens}
                        className="confirm-button-purchase">Proceed to payment
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default Purchase3dProject;
