// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.qty_diff {
    color: #15e306;
    font-weight: bold;
    margin-left: 10px;
}
.step3-ordered-qty-input {
    text-align: center;
    max-height: 40px;
    height: 28px;
    outline: none;
    border-radius: 10px;
    border: 0.5px solid #626262;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    -moz-appearance: textfield;
}

.step3-ordered-qty-input::-webkit-outer-spin-button,
.step3-ordered-qty-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.step3-ordered-qty-input:focus {
    border-color: #0053A0;
    box-shadow: 0 0 5px rgba(0, 83, 160, 0.5);
}

.step3-ordered-qty-input:hover {
    border-color: #828282;
}

.step3-other-width {
    min-width: 70px !important;
    width: 14%;
}
.step3-other-width-input{
    width: 30%;
}
.step3-other-width-vendor{
    width: 210px;
    display: flex;
    justify-content: space-between;
}

.step3-other-width-current-order{
    width: 160px;
}



.step2-margin-right{
    margin-right: 45px ;
}
`, "",{"version":3,"sources":["webpack://./src/Page/Step2/Step2Styles/step2-width.css"],"names":[],"mappings":";;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;IAC1B,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;;;AAIA;IACI,mBAAmB;AACvB","sourcesContent":["\n\n.qty_diff {\n    color: #15e306;\n    font-weight: bold;\n    margin-left: 10px;\n}\n.step3-ordered-qty-input {\n    text-align: center;\n    max-height: 40px;\n    height: 28px;\n    outline: none;\n    border-radius: 10px;\n    border: 0.5px solid #626262;\n    font-size: 16px;\n    width: 100%;\n    box-sizing: border-box;\n    -moz-appearance: textfield;\n}\n\n.step3-ordered-qty-input::-webkit-outer-spin-button,\n.step3-ordered-qty-input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n.step3-ordered-qty-input:focus {\n    border-color: #0053A0;\n    box-shadow: 0 0 5px rgba(0, 83, 160, 0.5);\n}\n\n.step3-ordered-qty-input:hover {\n    border-color: #828282;\n}\n\n.step3-other-width {\n    min-width: 70px !important;\n    width: 14%;\n}\n.step3-other-width-input{\n    width: 30%;\n}\n.step3-other-width-vendor{\n    width: 210px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.step3-other-width-current-order{\n    width: 160px;\n}\n\n\n\n.step2-margin-right{\n    margin-right: 45px ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
