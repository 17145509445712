import React, { useState } from 'react';
import Modal from 'react-modal';
import './PromoCode.css';
import { checkPromoCodeAPI } from "../../../api/APIWraper";

interface PromoCodeProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onPromoCodeValidated: (result: PromoCodeResult) => void;
}

export interface PromoCodeResult {
    isValid: boolean;
    activeUntil?: string;
    message?: string;
    code:string
}

const PromoCode: React.FC<PromoCodeProps> = ({ isOpen, onRequestClose, onPromoCodeValidated }) => {
    const [promoCode, setPromoCode] = useState<string>('');
    const [message, setMessage] = useState<string | null>(null);
    const [activeUntil, setActiveUntil] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

    const formatDate = (isoDate: string): string => {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPromoCode(e.target.value);
        setMessage(null);
        setError(null);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        const timeout = setTimeout(() => {
            if (e.target.value.trim()) {
                checkPromoCode(e.target.value.trim());
            }
        }, 500);

        setDebounceTimeout(timeout);
    };

    const checkPromoCode = async (code: string) => {
        try {
            const body = { promocode: code };
            const response = await checkPromoCodeAPI(body);
            if (response.status === 200) {
                const roleExpiration = new Date(response.data.role_expiration);
                let successMessage = 'Congratulations! Your promo code has been applied. Enjoy 1 month of free Contractor access.';

                const untilDate = `Active until: ${formatDate(response.data.role_expiration)}`;
                setMessage(successMessage);
                setActiveUntil(untilDate);
                setError(null);

                if (roleExpiration.getFullYear() > 3000) {
                    successMessage = 'Congratulations! Your promo code has been applied. You have received infinite access to the Contractor plan.';
                    setMessage(successMessage);
                    setActiveUntil(null)
                }
                onPromoCodeValidated({
                    isValid: true,
                    activeUntil: formatDate(response.data.role_expiration),
                    message: successMessage,
                    code:code
                });
            } else if (response.status === 400) {
                setMessage(null);
                setError('Invalid or expired promo code.');

                onPromoCodeValidated({code: code ? code : "", isValid: false });
            }
        } catch (error) {
            setMessage(null);
            setError('Something went wrong. Please try again later.');

            onPromoCodeValidated({code:  code ? code : "", isValid: false });
        }
    };
    const handleClear = () =>{
        setPromoCode('');
        setMessage(null);
        setError(null);
        setActiveUntil(null);
        onPromoCodeValidated({
            code:'',
            isValid:false
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="promo-code-modal"
            overlayClassName="promo-code-overlay"
        >
            <div className="promo-code-container">
                <button className="close-button" onClick={onRequestClose}>&times;</button>
                <h2>Enter your promo code</h2>
                <input
                    type="text"
                    value={promoCode}
                    onChange={handleInputChange}
                    placeholder="Enter promo code"
                    className="promo-code-input"
                />
                {message && <div className="promo-code-success">{message}</div>}
                {activeUntil && <div className="promo-code-active-until">{activeUntil}</div>}
                {error && <div className="promo-code-error">{error}</div>}
                <div className='promo-code-btn-block'>
                    <button
                        onClick={handleClear}
                        className='promo-code-btn-block-clear'>Clear</button>
                    <button
                        onClick={onRequestClose}
                        disabled={error !== null}
                        className={`promo-code-btn-block-apply ${error !== null ? "disabled" : ''}`}>Apply</button>
                </div>
            </div>
        </Modal>
    );
};

export default PromoCode;