import React from "react";
import './style.css';
import {Fragment, Fragment as TemplateFragment, ParsedFragment} from "../../types/TemplateType";

interface ProgressBarProps {
    fragment: TemplateFragment | Fragment | ParsedFragment;
}

const ProgressBarFragment: React.FC<ProgressBarProps> = ({ fragment }) => {
const progress = fragment.progress || 0

    return (
        <div className="progress-bar step3-fragment">
            <div className='progress-bar-container step3-fragment'>
                <div className='progressBarFill' style={{width: `${progress}%`}}></div>
            </div>
            <p className='progress-bar-percentage step3-percentage '>{Math.min(100,progress).toFixed(0)}/100</p>
        </div>
    );
}

export default ProgressBarFragment;
