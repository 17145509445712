import './step2HeaderList.css';
import "../../Page/Step2/Step2Styles/step2-width.css";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

interface HeaderListProps {
    isLabor?: boolean;
    isTool?: boolean;
    showSettingsButton?: boolean;
    toggleActive?: boolean
}

const HeaderList: React.FC<HeaderListProps> = ({toggleActive}) => {
    const isPageActive = useSelector((state: RootState) => state.fragmentVisible.isPageActive);
    return (
        <>
            {toggleActive ? (
                <div className='step2-header-list-step2 '>
                    <div className='step2-header-list-step2-content '>
                        <p className='step2-material-width-step2'>Product</p>
                        <p className='step2-amend-width-calc'>Calculated QTY</p>
                        <p className='step2-amend-width-ordered'>QTY ordered</p>
                        <p className='step2-amend-width-price'>Unit price</p>
                        <p className='step2-amend-width-total'>Total</p>
                        <p className='step2-amend-vedor'>Vendor</p>
                    </div>
                </div>
                ) : (
                <div className='step2-header-list-step2 '>
                    <div className='step2-header-list-step2-content '>
                        <p className='step2-material-width-step2'>Product</p>
                        <p className='step2-other-width-calc'>Calculated QTY</p>
                        <p className='step2-other-width-ordered'>QTY ordered</p>
                        <p className='step2-other-width-total-ordered step2-item-total-ordered'>QTY to order</p>
                        <p className='step2-other-width-price'>Unit price</p>
                        <p className='step2-other-width-total'>Total</p>
                        <p className='step2-item-vedor'>Vendor</p>
                    </div>
                </div>
            )}

        </>
    );
};

export default HeaderList;
