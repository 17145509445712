import React from 'react';
import './OurPartners.css'
interface OurPartnersProps {
    isSmallScreen?: boolean;
}
const OurPartners:React.FC<OurPartnersProps> = ({isSmallScreen}) => {
    return (
        !isSmallScreen ? (
            <section className='about-us-section-partners'>
                <div className='about-us-partners-block'>
                    <div className='about-us-info-partners-text'>
                        <p className='about-us-info-block-text-bold'>Our Visionary Collaborations</p>
                        <p className='about-us-info-block-text-partners'>Transforming the construction and DIY landscape
                            requires powerful allies. That's why we've joined forces with industry titan Autodesk, the
                            masterminds behind revolutionary architectural software like Revit and AutoCAD. Together,
                            we're pushing the boundaries of what's possible in construction planning. </p>
                        <p className='about-us-info-block-text-partners'>This groundbreaking partnership has enabled us
                            to create a solution that seamlessly automates quantity take-offs from professional 3D
                            models and simplifies project planning for everyone - from seasoned architects to weekend
                            DIY warriors. By integrating Autodesk's cutting-edge technology with our extensive industry
                            expertise, we're not just streamlining processes; we're redefining the very essence of
                            construction and home improvement planning. </p>
                        <p><img src='/img_landing/autodesk.png' alt='autodesc'/></p>
                    </div>

                    <div className='about-us-info-block-second'></div>
                </div>
                <img className='about-us-image' src='/img_landing/partners.png' alt='partners'/>
            </section>
        ) : (
            <section className='about-us-section-partners-mobile'>
                <div className='about-us-partners-block'>
                    <div className='about-us-info-partners-text'>
                        <p className='about-us-info-block-text-bold'>Our Visionary Collaborations</p>
                        <img className='about-us-image-mobile' src='/img_landing/partners.png' alt='partners'/>

                        <p className='about-us-info-block-text-partners'>Transforming the construction and DIY landscape
                            requires powerful allies. That's why we've joined forces with industry titan Autodesk, the
                            masterminds behind revolutionary architectural software like Revit and AutoCAD. Together,
                            we're pushing the boundaries of what's possible in construction planning. </p>
                        <p className='about-us-info-block-text-partners'>This groundbreaking partnership has enabled us
                            to create a solution that seamlessly automates quantity take-offs from professional 3D
                            models and simplifies project planning for everyone - from seasoned architects to weekend
                            DIY warriors. By integrating Autodesk's cutting-edge technology with our extensive industry
                            expertise, we're not just streamlining processes; we're redefining the very essence of
                            construction and home improvement planning. </p>
                        <p><img src='/img_landing/autodesk.png' alt='autodesc'/></p>
                    </div>
                </div>
            </section>
        )

    );
}

export default OurPartners;