import React, {useEffect, useState, useMemo} from 'react';
import {useNavigate} from "react-router-dom";
import './style.css';
import PaginationAssistance from "../PaginationAssistance/pagination";
import ModalAddTemplate from "../ProjectAssistanceTemplatesAdd/ModalAddTemplate";
import {IconBuyTemplate, IconTemplatesAssistance} from "../../IconComponents/IconComponents";
import {getAllCategoriesTemplates} from "../../api/APIWraper";
import {CategoryTemplates, DataTemplateGroup, Template} from "../../types/TemplateType";
import {Loader} from "../Loader/Loader";
import PaidTemplate from "../Modals/PaidTemplate/PaidTemplate";
import {useSelector} from "react-redux";
import {selectUser} from "../../Slice/tokenSlice/userSlice";
import {RootState} from "../../store";
import useIsSmallScreen from "../../Hooks/SmallScreen/useIsSmallScreen";

interface AssisTemplateProps {
    categories: CategoryTemplates[];
    totalPages: number;
}

type categoryMobile = 'DIY' | 'Renovation' | 'Structures';

const AsisTemplate: React.FC<AssisTemplateProps> = ({categories, totalPages}) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTempId, setSelectedTempId] = useState<number>(0);
    const [selectedTempTitle, setSelectedTempTitle] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [paginatedCategories, setPaginatedCategories] = useState<CategoryTemplates[]>(categories);
    const [hasFetched, setHasFetched] = useState(false);
    const [isOpenPaidTemplate, setIsOpenPaidTemplate] = useState(false);
    const [templatePaidTemplate, setTemplatePaidTemplate] = useState({paid: false, bought: false});

    const templatesPerPage = 4;
    const user = useSelector(selectUser);
    const checkRole = ["Contractor", "SAU"].includes(user?.role.role ?? "");
    const templateMessage = useSelector((state: RootState) => state.templateSocket.templateMessage);
    const isSmallScreen = useIsSmallScreen();

    const [categoryMobile, setCategoryMobile] = useState<categoryMobile>('DIY');
    const [switchLoading, setSwitchLoading] = useState(false);

    const infoTemplateToModal = (template: Template | DataTemplateGroup) => {
        setSelectedTempId(template.id);
        setTemplatePaidTemplate({
            paid: template.paid,
            bought: template.bought
        });
        if (!checkRole && template.paid && !template.bought) {
            setIsOpenPaidTemplate(true);
        } else {
            setIsModalOpen(true);
            setSelectedTempTitle(template.title);
        }
    };

    const fetchCategories = async (page: number) => {
        setLoading(true);
        try {
            const {categories: fetchedCategories} = await getAllCategoriesTemplates(page, templatesPerPage);
            setPaginatedCategories(fetchedCategories);
            setHasFetched(true);
        } catch (error) {
            console.error("Error fetching categories:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentPage > 1 || hasFetched) {
            fetchCategories(currentPage);
        } else {
            setPaginatedCategories(categories);
        }
    }, [currentPage]);

    useEffect(() => {
        if (templateMessage && templateMessage.trim() !== "") {
            fetchCategories(currentPage);
        }
    }, [templateMessage, currentPage]);

    const availableCategories = useMemo(() => {
        return paginatedCategories.filter(
            cat => cat.templates.data.length > 0 || cat.templateGroups.data.length > 0
        );
    }, [paginatedCategories]);

    const displayedCategories = useMemo(() => {
        if (isSmallScreen) {
            return availableCategories.filter(cat => cat.category === categoryMobile);
        }
        return availableCategories;
    }, [availableCategories, categoryMobile, isSmallScreen]);

    const handleSwitchCategory = (cat: categoryMobile) => {
        if (cat === categoryMobile) return;
        setSwitchLoading(true);
            setCategoryMobile(cat);
            setSwitchLoading(false);
    };

    return (
        <div>
            <div className='template-name-content-assistance'>
                <p>Templates</p>
                <IconTemplatesAssistance/>
            </div>

            {isSmallScreen && availableCategories.length > 1 && (
                <div className='template-category-mobile-switch'>
                    {availableCategories.map((cat) => (
                        <div
                            key={cat.category}
                            className={`template-category-mobile-switch-content ${
                                categoryMobile === cat.category ? 'active-btn' : ''
                            }`}
                            onClick={() => handleSwitchCategory(cat.category as categoryMobile)}
                        >
                            <p className='template-category-mobile-switch-text'>{cat.category}</p>
                        </div>
                    ))}
                </div>
            )}

            {switchLoading ? (
                <div className='mobile-loading-template-block'>
                    <Loader/>
                </div>
            ) : (
                <>
                    {loading ? (
                        <Loader/>
                    ) : (
                        <div className='template-main'>
                            {displayedCategories.map(category => (
                                <div key={category.id}>
                                    {(category.templates.data.length > 0 || category.templateGroups.data.length > 0) && !isSmallScreen && (
                                        <h2 className='template-title-text'>{category.category}</h2>
                                    )}
                                    <div className='template-main-container'>
                                        {category.templates.data.map(template => {
                                            const templateImage =
                                                template.galleries?.[0]?.medias?.slice(-1)[0]?.url || '/img/house.jpg';
                                            return (
                                                <div className='template-project-list' key={template.id}>
                                                    <div
                                                        onClick={() => navigate(`/template/${template.id}`)}
                                                        className='template-project-get'
                                                    >
                                                        <div
                                                            className='template-img-shadow'
                                                            style={{backgroundImage: `url(${templateImage})`}}
                                                        />
                                                        {template.paid && !template.bought && (
                                                            <div className='paid-icon'>
                                                                <IconBuyTemplate/>
                                                            </div>
                                                        )}
                                                        <div className='template-title-block'>
                                                            <p className='template-title'>{template.title}</p>
                                                        </div>
                                                        <div className='view-details'>
                                                            View details <span className='view-details-arrow'/>
                                                        </div>
                                                    </div>
                                                    <div className='template-project-title'>
                                                        <button
                                                            onClick={() => infoTemplateToModal(template)}
                                                            className='assistance-template-project-btn'
                                                        >
                                                            Add to existing project
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {category.templateGroups.data.map(templateGroup => {
                                            const templateImage =
                                                templateGroup.galleries?.[0]?.medias?.slice(-1)[0]?.url || '/img/house.jpg';
                                            return (
                                                <div className='template-project-list' key={templateGroup.id}>
                                                    <div
                                                        onClick={() => navigate(`/template-group/${templateGroup.id}`)}
                                                        className='template-project-get'
                                                    >
                                                        <div
                                                            className='template-img-shadow'
                                                            style={{backgroundImage: `url(${templateImage})`}}
                                                        />
                                                        <div className='template-title-block'>
                                                            <p className='template-title'>{templateGroup.title}</p>
                                                        </div>
                                                        <div className='view-details'>
                                                            View details <span className='view-details-arrow'/>
                                                        </div>
                                                    </div>
                                                    <div className='template-project-title'>
                                                        <button
                                                            onClick={() => infoTemplateToModal(templateGroup)}
                                                            className='assistance-template-project-btn'
                                                        >
                                                            Add to existing project
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    <PaginationAssistance
                        projectsPerPage={templatesPerPage}
                        totalProjects={totalPages * templatesPerPage}
                        paginate={(page) => {
                            setCurrentPage(page);
                            if (page === 1) {
                                setHasFetched(false);
                            }
                        }}
                        currentPage={currentPage}
                    />
                </>
            )}

            <ModalAddTemplate
                templatePaidTemplate={templatePaidTemplate}
                isOpen={isModalOpen}
                tempId={selectedTempId}
                tempTitle={selectedTempTitle}
                onClose={() => setIsModalOpen(false)}
            />

            <PaidTemplate
                templateId={selectedTempId}
                isOpen={isOpenPaidTemplate}
                onRequestClose={() => setIsOpenPaidTemplate(false)}
                onCancel={() => setIsOpenPaidTemplate(false)}
            />
        </div>
    );
};

export default AsisTemplate;
