// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-bottom-menu{
    display: flex;
    justify-content: space-between;
    height: 100px;
    background-color: white;
    border: 1px solid var(--background-header-btn-color);
    border-radius: 15px 15px 0 0;
    padding: 20px 20px 0 20px;
    position: sticky;
    bottom: 0;
}
.mobile-bottom-menu-background-top{
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px 15px 0 0;
    width: 100%;
    height: 20px;
    background-color: var(--basic-table-shadow-color);
    z-index: 10;
}

.mobile-bottom-menu-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.mobile-bottom-menu-content>p{
    margin: 0;
    padding-top: 10px;
    color: var(--basic-text-description-color);
}
.mobile-bottom-menu-content.active>p{
    color: var(--basic-color);
}
.mobile-bottom-menu-content.active:after{
    content: "";
    position: absolute;
    top: -1.5px;
    width: 50px;
    height: 2.5px;
    background-color: var(--basic-color);
    color: var(--basic-color);
    z-index: 10;
}
.mobile-bottom-menu-content-create-project{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/MobileBottomMenu/MobileBottomMenu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,oDAAoD;IACpD,4BAA4B;IAC5B,yBAAyB;IACzB,gBAAgB;IAChB,SAAS;AACb;AACA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,4BAA4B;IAC5B,WAAW;IACX,YAAY;IACZ,iDAAiD;IACjD,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,SAAS;IACT,iBAAiB;IACjB,0CAA0C;AAC9C;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,aAAa;IACb,oCAAoC;IACpC,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".mobile-bottom-menu{\n    display: flex;\n    justify-content: space-between;\n    height: 100px;\n    background-color: white;\n    border: 1px solid var(--background-header-btn-color);\n    border-radius: 15px 15px 0 0;\n    padding: 20px 20px 0 20px;\n    position: sticky;\n    bottom: 0;\n}\n.mobile-bottom-menu-background-top{\n    position: absolute;\n    top: 0;\n    left: 0;\n    border-radius: 15px 15px 0 0;\n    width: 100%;\n    height: 20px;\n    background-color: var(--basic-table-shadow-color);\n    z-index: 10;\n}\n\n.mobile-bottom-menu-content{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    position: relative;\n}\n.mobile-bottom-menu-content>p{\n    margin: 0;\n    padding-top: 10px;\n    color: var(--basic-text-description-color);\n}\n.mobile-bottom-menu-content.active>p{\n    color: var(--basic-color);\n}\n.mobile-bottom-menu-content.active:after{\n    content: \"\";\n    position: absolute;\n    top: -1.5px;\n    width: 50px;\n    height: 2.5px;\n    background-color: var(--basic-color);\n    color: var(--basic-color);\n    z-index: 10;\n}\n.mobile-bottom-menu-content-create-project{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
