// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paid-template-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
.paid-template-block>p{
    font-family: var(--font-roboto),sans-serif;
    color: black;
    font-size: 24px;
    text-align: center;
    margin: 0 0 30px 0;
}

.paid-template-price{
    color: var(--basic-btn-dark-color);
}

@media (max-width: 940px) {
    .paid-template-block>p{
        font-size: 20px;
        margin-bottom: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/PaidTemplate/PaidTemplate.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,0CAA0C;IAC1C,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI;QACI,eAAe;QACf,mBAAmB;IACvB;AACJ","sourcesContent":[".paid-template-block{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 20px 0;\n}\n.paid-template-block>p{\n    font-family: var(--font-roboto),sans-serif;\n    color: black;\n    font-size: 24px;\n    text-align: center;\n    margin: 0 0 30px 0;\n}\n\n.paid-template-price{\n    color: var(--basic-btn-dark-color);\n}\n\n@media (max-width: 940px) {\n    .paid-template-block>p{\n        font-size: 20px;\n        margin-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
