import { useEffect, useState } from 'react';

const useIsSmallScreen = (breakpoint: number = 940): boolean => {
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
        typeof window !== 'undefined' ? window.innerWidth <= breakpoint : false
    );

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= breakpoint);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [breakpoint]);

    return isSmallScreen;
};

export default useIsSmallScreen;
