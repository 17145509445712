import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {ItemType, TItem, TMerchanInfo} from "../../../types/TemplateType";
import {Project} from "../../../types/ProjectTypes";
import {get, patch} from "../../../api/APIWraper";
import {updateLocalTemplate} from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import ItemSelectionModal from "../ItemSelectionModal";
import IToolsItemType from "../../../Interfaces/ProjectInterfaces/IToolsItemType";
import {Vendor, vendorData} from "../../../Enums/VendorEnum";
import {IconEyesStep3, IconStep2SelectArrow} from "../../../IconComponents/IconComponents";
import {SelectedItem} from "../../../Interfaces/ProjectInterfaces/SelectedItems";
import {formatNumber} from "../../../utils/FormatNumber";
import {generateItemKey, useVisitedItems} from "../../../Hooks/Step2Customer/Step2Hooks";

interface ItemTypeListCalculationProps {
    toolsItemTypes: IToolsItemType[];
    templateIndex: number;
    templateId: number;
    project: Project;
    onUpdateProject: (newProject: Project) => void;
    onToggle: () => void;
    relatedProjectItemTypes?: ItemType[];
    viewModeTools: "Recommended" | "Minimum";
}

interface SelectedItemInfo {
    packaging: any;
    vendor: Vendor | null;
    merchant_info: TMerchanInfo;
    user_item_qty?: number;
    item_packaging_qty?: number;
    item_packaging_value?: string;
    title: string;
    id: number;
    qty: number;
    ordered_qty: number;
    unit: string;
    price: number;
}


interface GroupedItem {
    item_type_id: number;
    item_ids: { id: number; qty: number }[];
    template_index: number;
    item_type_index: number;
    template_id: number;
    fragment_id: number;
    fragment_index: number;
    originalQty: number;
    fragments?: Array<any>;
}

const ToolsItemTypeListCalculation: React.FC<
    ItemTypeListCalculationProps & PropsFromRedux
> = ({
         toolsItemTypes,
         project,
         onUpdateProject,
         onToggle,
         templateId,
         viewModeTools,
     }) => {
    const { handleLogoClick, isVisited } = useVisitedItems();

    const [mergedItemTypes, setMergedItemTypes] = useState<
        (IToolsItemType & { originalIndex: number })[]
    >([]);
    const [selectedItemTypeIndex, setSelectedItemTypeIndex] = useState<number | null>(null);
    const [items, setItems] = useState<TItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedItemInfos, setSelectedItemInfos] = useState<{
        [id: string]: SelectedItemInfo;
    }>({});
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<{
        [id: string]: string;
    }>({});
    const [parsedItemTypes, setParsedItemTypes] = useState<{ [index: number]: any }>(
        {}
    );

    const [itemTypeUnit,setItemTypeUnit] = useState<string>('')

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>("");


    useEffect(() => {
        const mergeItemTypes = (): (IToolsItemType & { originalIndex: number })[] => {
            const mergedMap: { [key: number]: IToolsItemType & { originalIndex: number } } = {};

            toolsItemTypes.forEach((item, index) => {
                const itemTypeId = item.item_type.id;
                if (item.item_type.qty === 0) {
                    return;
                }

                if (mergedMap[itemTypeId]) {
                    const existing = mergedMap[itemTypeId];
                    item.item_type.items?.forEach((toolItem) => {
                        const existTool = existing.item_type.items?.find((ti) => ti.id === toolItem.id);
                        if (existTool) {
                            existTool.qty =
                                viewModeTools === "Minimum"
                                    ? Math.max(existTool.qty, toolItem.qty)
                                    : existTool.qty + toolItem.qty;
                            existTool.ordered_qty =
                                viewModeTools === "Minimum"
                                    ? Math.max(existTool.ordered_qty ?? 0, toolItem.ordered_qty ?? 0)
                                    : (existTool.ordered_qty ?? 0) + (toolItem.ordered_qty ?? 0);
                        } else {
                            existing.item_type.items?.push({ ...toolItem });
                        }
                    });
                } else {
                    const cloned = JSON.parse(JSON.stringify(item));
                    mergedMap[itemTypeId] = { ...cloned, originalIndex: index };
                }
            });

            const mergedArr = Object.values(mergedMap);
            mergedArr.sort((a, b) => a.originalIndex - b.originalIndex);
            return mergedArr;
        };


        const merged = mergeItemTypes();
        setMergedItemTypes(merged);
    }, [toolsItemTypes, viewModeTools]);


    useEffect(() => {
        if (mergedItemTypes.length === 0) return;
        const initialSelectedItems: SelectedItem[] = mergedItemTypes.flatMap(
            (mergedItem, mergedIdx) =>
                (mergedItem.item_type.items || []).map((itm, itemIdx) => {
                    const packagingKeys = itm.packaging ? Object.keys(itm.packaging) : [];
                    return {
                        itemTypeId: mergedItem.item_type.id,
                        itemId: itm.id,
                        originalIndex: mergedItem.originalIndex,
                        index: mergedIdx,
                        user_item_qty: itm.qty,
                        item_packaging_qty: packagingKeys.length > 0 ? itm.packaging[packagingKeys[0]] : 1,
                        item_packaging_value: packagingKeys.length > 0 ? packagingKeys[0] : "psc",
                        clone_id: `${itm.id}-${mergedIdx}-${itemIdx}`,
                        packaging:itm.packaging,
                        template_id:mergedItem.template_id,
                        template_index: mergedItem.template_index,
                        fragment_id:mergedItem.fragment_id,
                        fragment_index: mergedItem.fragment_index,
                    };
                })
        );
        setSelectedItems(initialSelectedItems);

        const initialSelectedItemInfos = initialSelectedItems.reduce((acc, sel) => {
                const mergedItem = mergedItemTypes.find(
                    (mi) => mi.item_type.id === sel.itemTypeId
                );
                const itm = mergedItem?.item_type.items?.find((i: any) => i.id === sel.itemId);

                if (mergedItem && itm) {
                    const packagingKeys = itm.packaging ? Object.keys(itm.packaging) : [];
                    acc[sel.clone_id] = {
                        merchant_info: itm.merchant_info ? itm.merchant_info : {} as TMerchanInfo,
                        vendor: itm.vendor,
                        user_item_qty: sel.user_item_qty,
                        item_packaging_qty: packagingKeys.length > 0 ? itm.packaging[packagingKeys[0]] : 1,
                        item_packaging_value: packagingKeys.length > 0 ? packagingKeys[0] : "psc",
                        title: itm.title,
                        id: itm.id,
                        qty: itm.qty || 0,
                        ordered_qty: itm.ordered_qty || 0,
                        unit: itm.unit,
                        price: itm.price || 0,
                        packaging:itm.packaging

                    };
                }
                return acc;
            }, {} as { [id: string]: SelectedItemInfo }
        );
        setSelectedItemInfos(initialSelectedItemInfos);

        const parsed = mergedItemTypes.reduce((acc, mergedItem, mergedIdx) => {
            const clone_id = `${mergedItem.item_type.id}-${mergedIdx}-${mergedIdx}`;
            const info = initialSelectedItemInfos[clone_id];
            const title = mergedItem.item_type.title;
            let qty = info ? info.qty : mergedItem.qty;
            if (!qty) qty = 0;
            let orderedQty = info ? info.ordered_qty : mergedItem.item_type.ordered_qty;
            if (!orderedQty) orderedQty = 0;
            const price =
                mergedItem.item_type.items && mergedItem.item_type.items.length > 0
                    ? mergedItem.item_type.items[0].price
                    : 0;
            const priceCurrentQty = info ? info.price * orderedQty : 0;
            const priceTotalQty = info ? info.price * qty : 0;
            const isItemSelected =
                mergedItem.item_type.items && mergedItem.item_type.items.length > 0;
            const unit =
                mergedItem.item_type.items && mergedItem.item_type.items.length > 0
                    ? mergedItem.item_type.items[0].unit
                    : "";
            const unitItemType = mergedItem.item_type.unit ? mergedItem.item_type.unit : "each";
            acc[mergedIdx] = {
                index: mergedIdx,
                originalIndex: mergedItem.originalIndex,
                title,
                qty,
                orderedQty,
                price,
                priceCurrentQty,
                priceTotalQty,
                isItemSelected,
                unit,
                unitItemType,
                itemId: mergedItem.item_type.id,
            };
            return acc;
        }, {} as { [index: number]: any });
        setParsedItemTypes(parsed);
        calculateTotalPrice();
    }, [mergedItemTypes]);

    useEffect(() => {
        calculateTotalPrice();
    }, [selectedItemInfos]);

    useEffect(() => {
        const handler = setTimeout(() => {
            Object.keys(debouncedOrderedQty).forEach((id) => {
                setSelectedItemInfos((prev) => {
                    const newQty = parseInt(debouncedOrderedQty[id]) || 0;
                    return {
                        ...prev,
                        [id]: {
                            ...prev[id],
                            ordered_qty: newQty,
                        },
                    };
                });
            });
            calculateTotalPrice();
        }, 500);
        return () => clearTimeout(handler);
    }, [debouncedOrderedQty, selectedItems]);

    const calculateTotalPrice = () => {
        let total = 0;
        selectedItems.forEach((sel) => {
            const info = selectedItemInfos[sel.clone_id];
            if (info) {
                const generalPrice = info.price * (info.user_item_qty || info.qty);
                const difTotalPrice = Math.max(
                    0,
                    info.price * Math.min(info.ordered_qty, info.user_item_qty || info.qty)
                );
                total += generalPrice - difTotalPrice;
            }
        });
        setTotalPrice(total);
    };

    const storeId = project?.store_id ? project.store_id : 0;

    const fetchItems = async (mergedIndex: number, page: number, search: string = "") => {
        setSelectedItemTypeIndex(mergedIndex);
        setLoading(true);
        try {
            if (!mergedItemTypes[mergedIndex]) return;
            const itemTypeId = mergedItemTypes[mergedIndex].item_type.id;
            const itemTypeUnit = mergedItemTypes[mergedIndex].unit;

            const shouldIncludeUnit = itemTypeUnit && itemTypeUnit !== "each" && itemTypeUnit !== "psc";

            let url = `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=${itemsPerPage}&storeIdFromProjects=${storeId}`;

            if (search) {
                url += `&title=${encodeURIComponent(search)}`;
            }
            if (shouldIncludeUnit) {
                url += `&packaging=${encodeURIComponent(itemTypeUnit)}`;
            }

            const response = await get(url);
            setTotalItems(response.data.items.pagination.lastPage);

            const itemsWithQty = (Array.isArray(response.data.items.data)
                    ? response.data.items.data
                    : [response.data.items.data]
            ).map((item: TItem) => {
                let qty_item_type = 0;
                if (viewModeTools === "Minimum") {
                    qty_item_type = Math.max(
                        ...toolsItemTypes
                            .filter((t) => t.item_type.id === itemTypeId)
                            .map((t) => t.qty)
                    );
                } else {
                    qty_item_type = toolsItemTypes
                        .filter((t) => t.item_type.id === itemTypeId)
                        .reduce((total, current) => total + current.qty, 0);
                }
                return {...item, qty_item_type};
            });

            setItems(itemsWithQty);
        } catch (error) {
            console.error("Failed to get elements:", error);
        } finally {
            setLoading(false);
        }
    };


    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, pageNumber, searchTerm);
        }
    };

    const handleButtonClick = (mergedIndex: number,itemTypeUnit:string) => {
        setIsModalOpen(true);
        setItemTypeUnit(itemTypeUnit)
        fetchItems(mergedIndex, currentPage);
    };

    const handleReload = () => {
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, currentPage);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setItemTypeUnit('')
        setCurrentPage(1)
        setSearchTerm('')
    };

    const handleItemClick = (modalItems: TItem[], mergedIndex: number | null) => {
        if (mergedIndex === null) return;
        const currentOriginalIndex = mergedItemTypes[mergedIndex].originalIndex;
        const updatedSelectedItems = [
            ...selectedItems.filter((si) => si.originalIndex !== currentOriginalIndex),
            ...modalItems.map((item, idx) => ({
                itemTypeId: mergedItemTypes[mergedIndex].item_type.id,
                itemId: item.id,
                originalIndex: currentOriginalIndex,
                index: mergedIndex,
                user_item_qty: item.user_item_qty,
                item_packaging_qty:
                    item.packaging[Object.keys(item.packaging)[0]] || 1,
                item_packaging_value: Object.keys(item.packaging)[0] || "psc",
                clone_id: `${item.id}-${mergedIndex}-${idx}`,
                packaging:item.packaging,
                template_id:item.template_id,
                template_index: item.template_index,
                fragment_id:item.fragment_id,
                fragment_index: item.fragment_index,
            })),
        ];

        const updatedItemInfos = modalItems.reduce((acc, item, idx) => {
            const clone_id = `${item.id}-${mergedIndex}-${idx}`;
            acc[clone_id] = {
                merchant_info: {} as TMerchanInfo,
                vendor: item.vendor,
                title: item.title,
                id: item.id,
                qty: item.qty,
                ordered_qty: item.ordered_qty || 0,
                user_item_qty: item.user_item_qty || 0,
                item_packaging_qty:
                    item.packaging[Object.keys(item.packaging)[0]] || 1,
                item_packaging_value: Object.keys(item.packaging)[0] || "psc",
                unit: item.unit,
                price: item.price || 0,
                packaging:item.packaging
            };
            return acc;
        }, {...selectedItemInfos});

        setSelectedItemInfos(updatedItemInfos);
        setSelectedItems(updatedSelectedItems);
        setParsedItemTypes((prev) => ({
            ...prev,
            [mergedIndex]: {
                ...prev[mergedIndex],
                isItemSelected: modalItems.length > 0,
                qty: modalItems.length > 0 ? modalItems[0].qty : 0,
                orderedQty: modalItems.length > 0 ? modalItems[0].ordered_qty || 0 : 0,
                user_item_qty: modalItems.length > 0 ? modalItems[0].user_item_qty || 0 : 0,
                unit: modalItems.length > 0 ? modalItems[0].unit : "",
                price: modalItems.length > 0 ? modalItems[0].price || 0 : 0,
                priceCurrentQty:
                    modalItems.length > 0
                        ? (modalItems[0].price || 0) * (modalItems[0].user_item_qty || 0)
                        : 0,
                priceTotalQty:
                    modalItems.length > 0 ? (modalItems[0].price || 0) * modalItems[0].qty : 0,
            },
        }));
        calculateTotalPrice();
        setIsModalOpen(false);
    };

    const handleQtyChange = (clone_id: string, newQty: string) => {
        const parsedQty = parseInt(newQty);
        setDebouncedOrderedQty((prev) => ({
            ...prev,
            [clone_id]: !isNaN(parsedQty) ? newQty : "",
        }));
        setSelectedItemInfos((prev) => ({
            ...prev,
            [clone_id]: {
                ...prev[clone_id],
                ordered_qty: !isNaN(parsedQty) ? parsedQty : 0,
            },
        }));
        calculateTotalPrice();
    };

    const handleSave = async (): Promise<void> => {
        function generateIndexes(itemTypeId: number) {
            return toolsItemTypes
                .filter((item) => item.item_type.id === itemTypeId)
                .map((item) => ({
                    fragment_id: item.fragment_id,
                    fragment_index: item.fragment_index,
                    item_type_index: item.item_type_index,
                    qty: item.item_type.qty,
                    fragments: item.fragments || [],
                }));
        }

        async function handleItemOrderedQty(): Promise<Project | undefined> {
            const payload = Object.entries(debouncedOrderedQty)
                .map(([clone_id, qtyStr]) => {
                    const selectedItem = selectedItems.find((item) => item.clone_id === clone_id);
                    if (!selectedItem) return null;
                    const userOrderedQty = parseInt(qtyStr, 10);
                    return {
                        ordered_qty: userOrderedQty,
                        template_index: toolsItemTypes[selectedItem.originalIndex]?.template_index || 0,
                        item_type_index: toolsItemTypes[selectedItem.originalIndex]?.item_type_index || 0,
                        template_id: templateId,
                        item_type_id: selectedItem.itemTypeId,
                        item_id: selectedItem.itemId,
                        fragment_id: toolsItemTypes[selectedItem.originalIndex]?.fragment_id || 0,
                        fragment_index: toolsItemTypes[selectedItem.originalIndex]?.fragment_index || 0,
                        fragments: toolsItemTypes[selectedItem.originalIndex]?.fragments || [],
                        indexes: generateIndexes(selectedItem.itemTypeId),
                    };
                })
                .filter((item) => item !== null);

            if (payload.length > 0) {
                const url = `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty_cascade`;

                try {
                    const response = await patch(url, { data: payload });
                    return response.data;
                } catch (error) {
                    console.error("Failed to update ordered_qty cascade:", error);
                }
            }
            return undefined;
        }

        const groupedItems: Record<string, GroupedItem> = selectedItems.reduce(
            (acc: Record<string, GroupedItem>, selection) => {
                const { itemTypeId, originalIndex, index } = selection;
                const key = `${itemTypeId}-${index}`;
                const info = selectedItemInfos[selection.clone_id];
                const toolItem = toolsItemTypes[originalIndex];
                const originalQty = info?.qty ?? toolItem?.qty ?? 0;

                if (!acc[key]) {
                    acc[key] = {
                        item_type_id: itemTypeId,
                        item_ids: [],
                        template_index: toolItem?.template_index || 0,
                        item_type_index: toolItem?.item_type_index || 0,
                        template_id: templateId,
                        fragment_id: toolItem?.fragment_id || 0,
                        fragment_index: toolItem?.fragment_index || 0,
                        fragments: toolItem?.fragments || [],
                        originalQty,
                    };
                }

                if (selection.itemId) {
                    acc[key].item_ids.push({
                        id: selection.itemId,
                        qty: selection.user_item_qty || 1,
                    });
                }
                return acc;
            },
            {}
        );

        const allItems = Object.values(groupedItems).filter((group) => group.item_ids.length > 0);

        let updatedProject: Project | undefined;

        try {
            if (allItems.length > 0) {
                const payloadForCascade = allItems
                    .filter(item => item.fragments && item.fragments.length > 0)
                    .map(item => ({
                        ...item,
                        indexes: generateIndexes(item.item_type_id),
                    }));

                const payloadForAddItem = allItems
                    .filter(item => !item.fragments || item.fragments.length === 0);

                if (payloadForAddItem.length > 0) {
                    const response = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item`, {
                        data: payloadForAddItem,
                    });

                    updatedProject = response.data || updatedProject;
                }

                if (payloadForCascade.length > 0) {
                    const responseCascade = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item_cascade`, {
                        data: payloadForCascade,
                    });

                    updatedProject = responseCascade.data || updatedProject;
                }
                handleItemOrderedQty();
            }
        } catch (error) {
            console.error("Failed to save selections:", error);
        }

        handleCloseModal();
        if (updatedProject) {
            onUpdateProject(updatedProject);
            onToggle();
        }
    };


    if (!toolsItemTypes || !Array.isArray(toolsItemTypes)) {
        return null;
    }

    const handleSearch = (query: string) => {
        setSearchTerm(query);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, 1, query);
        }
    };

    return (
        <div>
            {toolsItemTypes.length > 0 && (
                <>
                    {Object.values(parsedItemTypes).map((parsedItem, idx) => {
                        if (!parsedItem) return null;
                        const {title, price,qty, priceTotalQty,unit,unitItemType} = parsedItem;
                        const totalQty = toolsItemTypes.filter((tool) => tool.item_type.id === mergedItemTypes[parsedItem.index]?.item_type.id)
                            .reduce((sum, tool) => sum + tool.qty, 0);
                        const selectedItemsForType = selectedItems.filter(
                            (si) =>
                                si.itemTypeId ===
                                mergedItemTypes[parsedItem.index]?.item_type.id
                        );
                        return (
                            <div
                                key={`${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}`}
                            >
                                <label
                                    className='static-item-type-info'
                                    htmlFor={`button-${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}`}
                                >
                                    <p className='static-item-type-info-title'>{title}</p>
                                    <p className='static-item-type-info-qty'>{qty} ({unitItemType})</p>
                                </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                            <div className="step2-item-type-list">
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}`}
                                                    onClick={() => handleButtonClick(parsedItem.index,unit)}
                                                    disabled={loading}
                                                >
                                                    {loading &&
                                                    selectedItemTypeIndex === parsedItem.index
                                                        ? "Loading..."
                                                        : "Select Tools"}
                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>
                                                </button>
                                                <p className="step2-amend-no-items-list">
                                                    R 0.00
                                                </p>
                                                <div className="step2-tooltip-container-amend-no-items">
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-amend-no-items-total">
                                                    R {Math.max(0, priceTotalQty).toFixed(2)}
                                                </p>
                                            </div>

                                    ) : (
                                        <div className="step2-item-column">
                                            {selectedItemsForType.map((selectedItem, i) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const difPrice = item.user_item_qty
                                                    ? item.price * item.user_item_qty
                                                    : 0;
                                                const oldPrice = item.price * (item.user_item_qty || item.qty);
                                                const userQty = item.user_item_qty ? item.ordered_qty : 0;
                                                const totalItemQty = isNaN(userQty * item.price) ? 0 : Math.max(0, ((item.qty * item.price) - (userQty * item.price)));
                                                const price = parseFloat(String(item.price)) || 0;

                                                const itemKey = generateItemKey(selectedItem.fragment_id, selectedItem.fragment_index, selectedItem.template_id, selectedItem.template_index, selectedItem.itemTypeId, item.id);
                                                const visited = isVisited(itemKey);
                                                return (
                                                    <div
                                                        key={`${selectedItem.clone_id}`}
                                                        className="step2-item-type-list"
                                                    >
                                                        <button
                                                            className="step2-item-button-list"
                                                            id={`button-${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}-${i}`}
                                                            onClick={() => handleButtonClick(parsedItem.index,unit)}
                                                            disabled={loading}
                                                        >
                                                            {loading &&
                                                            selectedItemTypeIndex === parsedItem.index
                                                                ? "Loading..."
                                                                : item.title}
                                                            <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                        </button>
                                                        <p className="step2-calc-amend-item-list">
                                                            {item.user_item_qty || totalQty} (psc)
                                                        </p>
                                                        <div className="step2-tooltip-container-amend-items">
                                                            <input
                                                                type="number"
                                                                className="step2-other-width-input"
                                                                value={
                                                                    debouncedOrderedQty[selectedItem.clone_id] ??
                                                                    item.ordered_qty
                                                                }
                                                                onChange={(e) =>
                                                                    handleQtyChange(selectedItem.clone_id, e.target.value)
                                                                }
                                                                min="0"
                                                            />
                                                            <span className="packing-value-steps">psc</span>
                                                        </div>
                                                        <p className="step2-amend-items-list-price">
                                                            R {formatNumber(price)}
                                                        </p>
                                                        <p className="step2-amend-items-list-total">
                                                            R {formatNumber(totalItemQty)}
                                                        </p>

                                                        <div className='step2-amend-item-vendor-list'>
                                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                <>
                                                                    <p
                                                                        onClick={()=>handleLogoClick(item.merchant_info?.link,selectedItem.fragment_id, selectedItem.fragment_index, selectedItem.template_id, selectedItem.template_index, selectedItem.itemTypeId, item.id)}
                                                                        className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                    {!visited && <span><IconEyesStep3/></span>}
                                                                </>
                                                            ) : (
                                                                <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                            )}

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            <ItemSelectionModal
                itemTypeUnit={itemTypeUnit ? itemTypeUnit : ''}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                items={items}
                loading={loading}
                handleItemClick={(items) => handleItemClick(items, selectedItemTypeIndex)}
                selectedItems={selectedItems.filter(
                    (si) => si.index === selectedItemTypeIndex
                )}
                setSelectedItems={setSelectedItems}
                onReload={handleReload}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                onSearch={handleSearch}
                totalSearchResults={totalItems}
                storeId={storeId}
            />
            <div className="step2-bottom-block">                 <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button className="step2-bottom-block-btn-cart-list" onClick={handleSave}>Save</button>
                    </>

            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateLocalTemplate,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ToolsItemTypeListCalculation);
