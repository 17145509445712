import React, {useEffect, useState} from 'react';
import '../AboutUs/AboutUs.css';
import CountInfoSection from "../../../components/LandingPageComponent/CountInfoSection";

import OurPartners from "../OurPartnersComponent/OurPartners";
import WhatWeOfferComponent from "../WhatWeOfferComponent/WhatWeOfferComponent";
import InfoBlockBlogAboutUs from "../../../components/LandingPageComponent/InfoBlockBlogAboutUs";
import useIsSmallScreen from "../../../Hooks/SmallScreen/useIsSmallScreen";

function AboutUs() {
    const [isHovered, setIsHovered] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isImageVisible, setIsImageVisible] = useState(true);
    const isSmallScreen = useIsSmallScreen();

    const handleImageClick = () => {
        setIsImageVisible(false);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (!isPlaying) {
            setIsHovered(false);
        }
    };

    const handleClick = () => {
        setIsHovered(true);
        setIsPlaying(true);
    };


    return (
        <main className="landing-page">
            <div className="landing-middle-info-nav">
                <p>About Us</p>
            </div>

            <section className="landing-video-section-about">
                <div className="upper-block-video-about"
                     onClick={handleClick}
                >
                    <iframe
                        width="1560"
                        height="500"
                        src="https://drive.google.com/file/d/1uIKGRljIErLZCn_iYAWV-Y9EMB7IPSmO/preview"
                        title="Google Drive video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen

                    ></iframe>
                    {isImageVisible && (
                        <div
                            className="video-overlay"
                            onClick={handleImageClick}
                        >
                            <img
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                title='Play video' src="/img_landing/functionaluty.png" alt="Play Video"
                                className="overlay-image"/>
                        </div>
                    )}
                </div>
            </section>

            {isSmallScreen ? (
                <>
                    {/*<ReviewSlider variant="compact"/>*/}
                    <CountInfoSection iconVisible={false}/>
                    <InfoBlockBlogAboutUs isSmallScreen={isSmallScreen} />
                    <OurPartners isSmallScreen={isSmallScreen}/>
                    <WhatWeOfferComponent/>
                </>
            ) : (
                <div className={`review-slider-container ${isHovered || isPlaying ? 'shifted' : ''}`}>
                    {/*<ReviewSlider variant="compact"/>*/}
                    <CountInfoSection iconVisible={false}/>
                    <div className="landing-middle-info-nav">
                        <p>Benefits</p>
                    </div>
                    <InfoBlockBlogAboutUs isSmallScreen={isSmallScreen} />
                    <OurPartners/>
                    <WhatWeOfferComponent/>
                </div>
            )}

        </main>
    );
}

export default AboutUs;
