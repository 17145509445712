import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './TemplateGroupSelect.css';
import { Template } from '../../../types/TemplateType';
import { addTemplate } from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateProjectModal from "../ModalCreateProject/ModalCreateProject";
import { useNavigate } from "react-router-dom";
import { Project } from "../../../types/ProjectTypes";
import PaginationAssistance from "../../PaginationAssistance/pagination";
import { getTemplateGroupById } from '../../../api/APIWraper';
import { IconBuyTemplate } from "../../../IconComponents/IconComponents";
import PaidTemplate from "../PaidTemplate/PaidTemplate";
import { selectUser } from "../../../Slice/tokenSlice/userSlice";
import { RootState } from "../../../store";
import useIsSmallScreen from "../../../Hooks/SmallScreen/useIsSmallScreen";

interface ModalDeleteProps {
    isOpen: boolean;
    onRequestClose: () => void;
    templateGroupId: string;
    createProjectPage?: boolean;
}

Modal.setAppElement('#root');

const TemplateGroupSelect: React.FC<ModalDeleteProps> = ({
                                                             isOpen,
                                                             onRequestClose,
                                                             templateGroupId,
                                                             createProjectPage
                                                         }) => {
    const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
    const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
    const [templates, setTemplates] = useState<Template[]>([]);
    const [totalTemplates, setTotalTemplates] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);
    const isSmallScreen = useIsSmallScreen();

    const templatesPerPage = isSmallScreen ? 4 : 6;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isOpenPaidTemplate, setIsOpenPaidTemplate] = useState(false);

    const user = useSelector(selectUser);
    const checkRole = ["Contractor", "SAU"].includes(user?.role.role ?? "");
    const templateMessage = useSelector((state: RootState) => state.templateSocket.templateMessage);

    const handleCanselPaidTemplate = () => {
        setIsOpenPaidTemplate(false);
        setSelectedTemplateId(null);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            fetchTemplates(currentPage);
        }
    }, [currentPage, isOpen]);

    useEffect(() => {
        if (templateMessage && templateMessage.trim() !== "" && isOpen) {
            fetchTemplates(currentPage);
        }
    }, [templateMessage, currentPage, isOpen]);

    const fetchTemplates = async (page: number) => {
        try {
            const response = await getTemplateGroupById(templateGroupId, page, templatesPerPage);
            if (response.status === 200) {
                setTemplates(response.data.templates.data);
                setTotalTemplates(response.data.templates.pagination.lastPage);
            } else {
                console.error('No content found');
            }
        } catch (error) {
            console.error("Error fetching templates:", error);
        }
    };

    const handleTemplateSelect = (template: Template) => {
        setSelectedTemplateId(template.id);
        if (!checkRole && template.paid && !template.bought) {
            setIsOpenPaidTemplate(true);
            return;
        }
        const templateSelected = templates.find((templateSelected) => templateSelected.id === template.id);
        if (templateSelected) {
            setSelectedTemplate(templateSelected);
        }
    };

    const handleClickCreateProject = () => {
        if (createProjectPage === undefined) {
            setCreateProjectModalOpen(true);
        } else {
            if (selectedTemplate) {
                dispatch(addTemplate(selectedTemplate));
            }
            onRequestClose();
        }
    };

    const handleProjectCreation = (project: Project) => {
        navigate(`/assistance/create/${project.id}/from/projectCosts`, { state: { projectId: project.id } });
        if (selectedTemplate) dispatch(addTemplate(selectedTemplate));
    };

    const handleCloseModal = () => {
        setCreateProjectModalOpen(false);
    };

    const handleClickTemplate = () => {
        if (createProjectPage === undefined) {
            onRequestClose();
        } else {
            navigate(`/template-group/${templateGroupId}`);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="template-group-select-modal"
            overlayClassName="overlay-template-group-select"
        >
            <button className="close-button" onClick={onRequestClose}>
                &times;
            </button>
            <h3 className="modal-group-template-item-info">
                Please, select one of the suggested boundary wall options to add it to your project
            </h3>
            <div className="modal-template-group-select">
                <div className="modal-group-template-block-templates">
                    {templates.map((template) => {
                        const templateImage =
                            template.galleries && template.galleries.length > 0 && template.galleries[0].medias.length > 0
                                ? template.galleries[0].medias[template.galleries[0].medias.length - 1].url
                                : '/img/house.jpg';
                        return (
                            <div key={template.id} className="modal-group-template-item-block">
                                <div
                                    className="modal-group-template-item"
                                    style={{ backgroundImage: `url(${templateImage})` }}
                                    onClick={() => handleClickTemplate()}
                                >
                                    {template.paid && !template.bought && (
                                        <div className='paid-icon'>
                                            <IconBuyTemplate />
                                        </div>
                                    )}
                                    <p className="modal-group-template-item-title">{template.title}</p>
                                    <div className="modal-view-details">
                                        View details <span className="modal-view-details-arrow"></span>
                                    </div>
                                </div>
                                <input
                                    type="radio"
                                    name="template"
                                    checked={selectedTemplateId === template.id}
                                    onChange={() => handleTemplateSelect(template)}
                                    className="template-group-radio-input"
                                />
                            </div>
                        );
                    })}
                </div>
                {totalTemplates > 1 && (
                    <PaginationAssistance
                        projectsPerPage={templatesPerPage}
                        totalProjects={totalTemplates}
                        paginate={(page) => setCurrentPage(page)}
                        currentPage={currentPage}
                    />
                )}
                <div className="modal-group-template-item-btn-block">
                    <button
                        className="group-template-item-btn"
                        onClick={() => handleClickCreateProject()}
                        disabled={selectedTemplateId === null}
                    >
                        Confirm selection
                    </button>
                </div>
            </div>
            <CreateProjectModal
                isOpen={createProjectModalOpen}
                onRequestClose={handleCloseModal}
                template={selectedTemplate}
                onProjectCreated={handleProjectCreation}
            />
            <PaidTemplate
                templateId={selectedTemplateId ? selectedTemplateId : 0}
                isOpen={isOpenPaidTemplate}
                onRequestClose={() => handleCanselPaidTemplate()}
                onCancel={() => handleCanselPaidTemplate()}
            />
        </Modal>
    );
};

export default TemplateGroupSelect;
