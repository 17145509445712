import React, { useCallback, useEffect, useState } from 'react';
import {IconSettingsStep2Products, IconStep2BomArchitectArrow} from "../../../IconComponents/IconComponents";
import { BOMItemType, BOMProject } from "../../../types/ProjectBOMInterface";
import { Fragment, ItemType, TItem } from "../../../types/TemplateType";
import { addItemsToFragmentsArchitect, addItemsToFragmentsByLink, get, postBomEditItems } from "../../../api/APIWraper";
import FragmentComponentModal from "./FragmentComponentModal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ArchitectProject, CustomFragment } from "../../../types/ArhitectProject";

interface FragmentsComponentProps {
    onToggle: (fragment: string) => void;
    replace: boolean;
    selectedItemsStep3: { [key: string]: BOMItemType };
    selectedItems: { [key: string]: any };
    setProjectBom: React.Dispatch<React.SetStateAction<ArchitectProject | null>>;
    onSelectedFragmentsChange: (selectedFragments: Fragment[]) => void;
    projectArchitect: ArchitectProject;
}

const FragmentsComponent: React.FC<FragmentsComponentProps> = ({
                                                                   onToggle,
                                                                   replace,
                                                                   setProjectBom,
                                                                   onSelectedFragmentsChange,
                                                                   projectArchitect
                                                               }) => {
    const [fragments, setFragments] = useState<Fragment[]>([]);
    const [customFragments, setCustomFragments] = useState<CustomFragment[]>([]);
    const [itemsFragment, setItemsFragment] = useState<TItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [itemTypeId, setItemTypeId] = useState<number | null>(null);
    const [itemTypeIndex, setItemTypeIndex] = useState<number | null>(null);
    const [fragmentId, setFragmentId] = useState<number | null>(null);
    const [fragmentIndex, setFragmentIndex] = useState<number | null>(null);
    const [currentFragmentType, setCurrentFragmentType] = useState<'normal' | 'custom' | null>(null);
    const [initialSelectedItems, setInitialSelectedItems] = useState<{ [key: string]: TItem[] }>({});

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortDir, setSortDir] = useState<string>('asc');
    const [page, setPage] = useState<number>(1);
    const [isModalOpenFragment, setIsModalOpenFragment] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalSearchResults, setTotalSearchResults] = useState<number>(0);
    const [selectedItemsByItemType, setSelectedItemsByItemType] = useState<{ [key: string]: TItem[] }>({});
    const [itemTypeQtyByFragment, setItemTypeQtyByFragment] = useState<number>(0)
    const [itemTypeUnitByFragment, setItemTypeUnitByFragment] = useState<string>('')
    const [selectedFragments, setSelectedFragments] = useState<Fragment[]>([]);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [totalPrices, setTotalPrices] = useState<{ [key: string]: number }>({});
    const [fragmentReplaceState, setFragmentReplaceState] = useState<{ [key: string]: boolean }>({});

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        if (Array.isArray(projectArchitect.data.customFragments) && projectArchitect.data.customFragments.length > 0) {
            setCustomFragments(projectArchitect.data.customFragments);
        }
        if (Array.isArray(projectArchitect.data.fragments) && projectArchitect.data.fragments.length > 0) {
            setFragments(projectArchitect.data.fragments);
        }
    }, [projectArchitect]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (itemTypeId !== null && isModalOpenFragment) {
            fetchItems();
        }
    }, [page, searchTerm, sortDir, itemTypeId, isModalOpenFragment]);

    useEffect(() => {
        const storageKey = 'selectedFragmentArchitect';
        const data = JSON.parse(localStorage.getItem(storageKey) || '[]');
        setSelectedFragments(data);
    }, [onSelectedFragmentsChange]);

    useEffect(() => {
        const initialSelectedItems: { [key: string]: TItem[] } = {};

        fragments.forEach((fragment, index) => {
            if (fragment.data && fragment.data.item_types) {
                fragment.data.item_types.forEach((itemType: ItemType, itemTypeIndex: number) => {
                    if (itemType.items && itemType.items.length > 0) {
                        const key = `normal-${fragment.id}-${index}-${itemType.id}-${itemTypeIndex}`;
                        initialSelectedItems[key] = itemType.items;
                    }
                });
            }
        });

        customFragments.forEach((fragment, index) => {
            if (fragment.data && fragment.data.item_types) {
                fragment.data.item_types.forEach((itemType: ItemType, itemTypeIndex: number) => {
                    if (itemType.items && itemType.items.length > 0) {
                        const key = `custom-${fragment.id}-${index}-${itemType.id}-${itemTypeIndex}`;
                        initialSelectedItems[key] = itemType.items;
                    }
                });
            }
        });

        setSelectedItemsByItemType(initialSelectedItems);
    }, [fragments, customFragments]);

    const handleOpenModal = async (
        id: number,
        index: number,
        qty: number,
        fragmentId: number,
        fragmentIndex: number,
        type: 'normal' | 'custom',
        unit:string
    ) => {
        setCurrentFragmentType(type);
        setItemTypeId(id);
        setItemTypeIndex(index);
        setFragmentId(fragmentId);
        setFragmentIndex(fragmentIndex);
        setItemTypeQtyByFragment(qty);
        setItemTypeUnitByFragment(unit)
        setIsModalOpenFragment(true);
        setPage(1)

        let fragment;
        if (type === 'normal') {
            fragment = fragments.find(f => f.id === fragmentId);
        } else {
            fragment = customFragments.find(f => f.id === fragmentId);
        }

        if (fragment) {
            const itemType = (fragment.data.item_types).find(it => it.id === id);
            if (itemType) {
                setItemsFragment(itemType.items || []);
            }
        }
    };

    const handleCloseModal = () => {
        setIsModalOpenFragment(false);
        setFragmentId(null);
        setFragmentIndex(null);
        setCurrentFragmentType(null);
        setPage(1)
        setSearchTerm('')
    };

    const fetchItems = useCallback(async () => {
        if (itemTypeId === null) {
            return;
        }
        setIsLoading(true);

        let url = '';
        url = searchTerm
            ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5&title=${encodeURIComponent(searchTerm)}&sortOrder=${sortDir}`
            : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5&sortOrder=${sortDir}`;

        setItemsFragment([]);

        try {
            const response = await get(url);
            setItemsFragment(response.data.items.data);
            setTotalItems(response.data.items.pagination.lastPage);
            setTotalSearchResults(response.data.items.pagination.total);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false)
            console.error("Error when including elements\n:", error);
        }
    }, [page, searchTerm, sortDir, itemTypeId]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setPage(1);
    };

    const handleSortChange = () => {
        setSortDir(prevSortDir => (prevSortDir === 'asc' ? 'desc' : 'asc'));
        setPage(1);
    };

    const handleSelectItems = (selectedItems: TItem[]) => {
        if (
            itemTypeId !== null &&
            itemTypeIndex !== null &&
            fragmentId !== null &&
            fragmentIndex !== null &&
            currentFragmentType
        ) {
            const typePrefix = currentFragmentType;
            const key = `${typePrefix}-${fragmentId}-${fragmentIndex}-${itemTypeId}-${itemTypeIndex}`;
            setSelectedItemsByItemType((prev) => ({
                ...prev,
                [key]: selectedItems,
            }));
        }

        handleCloseModal();
    };

    const handleSave = async () => {
        const fragmentsToSave: {
            fragment_id: number;
            fragment_index: number;
            item_type_id: number;
            item_type_index: number;
            items: { id: number; qty: number }[];
        }[] = [];

        const customFragmentsToSave: {
            fragment_id: number;
            fragment_index: number;
            item_type_id: number;
            item_type_index: number;
            items: { id: number; qty: number }[];
        }[] = [];

        fragments.forEach((fragmentData, fragIndex) => {
            if (fragmentData.data && fragmentData.data.item_types) {
                fragmentData.data.item_types.forEach((itemType, itemTypeIndex) => {
                    const key = `normal-${fragmentData.id}-${fragIndex}-${itemType.id}-${itemTypeIndex}`;
                    const selectedItems = selectedItemsByItemType[key] || [];

                    const itemsToFragment = selectedItems.map((item) => ({
                        id: item.id,
                        qty: item.qty || 0
                    }));

                    if (itemsToFragment.length > 0) {
                        fragmentsToSave.push({
                            fragment_id: fragmentData.id,
                            fragment_index: fragIndex,
                            item_type_id: itemType.id,
                            item_type_index: itemTypeIndex,
                            items: itemsToFragment
                        });
                    }
                });
            }
        });

        customFragments.forEach((fragmentData, fragIndex) => {
            if (fragmentData.data && fragmentData.data.item_types) {
                fragmentData.data.item_types.forEach((itemType, itemTypeIndex) => {
                    const key = `custom-${fragmentData.id}-${fragIndex}-${itemType.id}-${itemTypeIndex}`;
                    const selectedItems = selectedItemsByItemType[key] || [];

                    const itemsToFragment = selectedItems.map((item) => ({
                        id: item.id,
                        qty: item.qty || 0
                    }));

                    if (itemsToFragment.length > 0) {
                        customFragmentsToSave.push({
                            fragment_id: fragmentData.id,
                            fragment_index: fragIndex,
                            item_type_id: itemType.id,
                            item_type_index: itemTypeIndex,
                            items: itemsToFragment
                        });
                    }
                });
            }
        });

        const body: any = {};
        if (fragmentsToSave.length > 0) {
            body.fragments = fragmentsToSave;
        }
        if (customFragmentsToSave.length > 0) {
            body.customFragments = customFragmentsToSave;
        }

        if (Object.keys(body).length === 0) {
            toast.info('There are no changes to save.');
            return;
        }

        try {
            const response = await addItemsToFragmentsArchitect(projectArchitect.id, body);
            if (response.status === 200) {
                if (fragmentsToSave.length > 0) {
                    setFragments(prevFragments => {
                        return prevFragments.map((fragment, fragmentIndex) => {
                            return {
                                ...fragment,
                                data: {
                                    ...fragment.data,
                                    item_types: fragment.data.item_types.map((itemType, itemTypeIndex) => {
                                        const key = `normal-${fragment.id}-${fragmentIndex}-${itemType.id}-${itemTypeIndex}`;
                                        return {
                                            ...itemType,
                                            items: selectedItemsByItemType[key] || itemType.items,
                                        };
                                    }),
                                },
                            } as Fragment;
                        });
                    });
                }

                if (customFragmentsToSave.length > 0) {
                    setCustomFragments(prevCustomFragments => {
                        return prevCustomFragments.map((fragment, fragmentIndex) => {
                            return {
                                ...fragment,
                                data: {
                                    ...fragment.data,
                                    item_types: fragment.data.item_types.map((itemType, itemTypeIndex) => {
                                        const key = `custom-${fragment.id}-${fragmentIndex}-${itemType.id}-${itemTypeIndex}`;
                                        return {
                                            ...itemType,
                                            items: selectedItemsByItemType[key] || itemType.items,
                                        };
                                    }),
                                },
                            } as CustomFragment;
                        });
                    });
                }

                setProjectBom(response.data);
                toast.success('You have successfully replaced products in fragments\n.');

                setFragmentReplaceState({});
            }
        } catch (error) {
            console.error("An error occurred while saving items:", error);
            toast.error('An error occurred while saving items.');
        }
    };


    const handleToggleFragment = (fragmentIndex: number, type: 'normal' | 'custom') => {
        setFragmentReplaceState((prevState) => {
            const key = `${type}-${fragmentIndex}`;
            const isCurrentlyOpen = prevState[key];

            if (!isCurrentlyOpen) {
                let fragmentData: any;
                if (type === 'normal') {
                    fragmentData = fragments[fragmentIndex];
                } else {
                    fragmentData = customFragments[fragmentIndex];
                }

                if (fragmentData?.data?.item_types) {
                    const initialItems: { [key: string]: TItem[] } = {};

                    fragmentData.data.item_types.forEach((itemType: ItemType, itemTypeIndex: number) => {
                        if (itemType.items && itemType.items.length > 0) {
                            const key = `${type}-${fragmentData.id}-${fragmentIndex}-${itemType.id}-${itemTypeIndex}`;
                            initialItems[key] = [...itemType.items];
                        }
                    });

                    setInitialSelectedItems(initialItems);
                }
            } else {
                setSelectedItemsByItemType((prev) => {
                    const updatedItems: { [key: string]: TItem[] } = {};
                    Object.keys(prev).forEach((key) => {
                        if (initialSelectedItems[key]) {
                            updatedItems[key] = [...initialSelectedItems[key]];
                        }
                    });
                    return updatedItems;
                });
            }
            return {
                ...prevState,
                [key]: !isCurrentlyOpen,
            };
        });

        onToggle('Fragment');
    };


    const recalculateTotalPrice = () => {
        const storageKey = 'selectedFragmentArchitect';
        const storedFragments: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');
        const prices: { [key: string]: number } = {};

        storedFragments.forEach((fragment) => {
            let fragmentTotal = 0;

            (fragment.data?.item_types || []).forEach((itemType) => {
                (itemType.items || []).forEach((item) => {
                    const qty = item.qty || 0;
                    const price = parseFloat(String(item.price || 0));
                    fragmentTotal += qty * price;
                });
            });

            prices[fragment.id] = fragmentTotal;
        });

        setTotalPrices(prices);
    };

    const isItemChecked = (
        fragmentId: number,
        itemTypeId: number,
        itemTypeIndex: number,
        itemId: number,
    ): boolean => {
        const storageKey = 'selectedFragmentArchitect';
        const existingData: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

        const fragment = existingData.find((frag) => frag.id === fragmentId);
        if (!fragment) return false;

        const itemType = (fragment.data.item_types).find((typeItem, idx) => {
            return typeItem.id === itemTypeId && idx === itemTypeIndex;
        });

        return !!itemType?.items.some((item) => item.id === itemId);
    };

    const handleToggleItemSelection = (
        fragment: any,
        itemTypeId: number,
        itemTypeIndex: number,
        selectedItem: TItem,
    ) => {
        const storageKey = 'selectedFragmentArchitect';
        const existingData: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

        const fragmentIndex = existingData.findIndex((f) => f.id === fragment.id);

        let updatedFragment: Fragment;

        if (fragmentIndex !== -1) {
            updatedFragment = { ...existingData[fragmentIndex] };
        } else {
            updatedFragment = {
                ...fragment,
                data: {
                    ...fragment.data,
                    item_types: (fragment.data.item_types).map((itemType: any) => ({
                        ...itemType,
                        items: [],
                    })),
                },
            };
        }

        updatedFragment.data.item_types = (updatedFragment.data.item_types).map(
            (itemType, idx) => {
                if (itemType.id === itemTypeId && idx === itemTypeIndex) {
                    const existingItems = itemType.items || [];
                    const itemExists = existingItems.some((item) => item.id === selectedItem.id);

                    const updatedItems = itemExists
                        ? existingItems.filter((item) => item.id !== selectedItem.id)
                        : [...existingItems, selectedItem];

                    return {
                        ...itemType,
                        items: updatedItems,
                    };
                }
                return itemType;
            }
        );

        const hasItems = updatedFragment.data.item_types.some(
            (itemType: ItemType) => itemType.items && itemType.items.length > 0
        );

        const updatedFragments = [...existingData];

        if (hasItems) {
            if (fragmentIndex !== -1) {
                updatedFragments[fragmentIndex] = updatedFragment;
            } else {
                updatedFragments.push(updatedFragment);
            }
        } else {
            if (fragmentIndex !== -1) {
                updatedFragments.splice(fragmentIndex, 1);
            }
        }

        localStorage.setItem(storageKey, JSON.stringify(updatedFragments));
        setSelectedFragments(updatedFragments);
        onSelectedFragmentsChange(updatedFragments);

        if (hasItems) {
            toast.success(`Item ${selectedItem.title} toggled in fragment ${fragment.title}`);
        } else {
            toast.info(`Item ${selectedItem.title} removed from fragment ${fragment.title}`);
        }

    };


    const handleAddProductsToCart = useCallback(
        (fragment: any) => {
            const storageKey = 'selectedFragmentArchitect';
            const existingData: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

            const filteredItemTypes = fragment.data.item_types.filter(
                (itemType: { items: string | any[]; }) => Array.isArray(itemType.items) && itemType.items.length > 0
            );
            if (filteredItemTypes.length === 0) {
                toast.info(`Fragment ${fragment.title} has no valid item types and won't be added.`);
                return;
            }
            const updatedFragment = {
                ...fragment,
                data: {
                    ...fragment.data,
                    item_types: filteredItemTypes
                }
            };

            let updatedFragments = [...existingData];
            const fragmentIndex = existingData.findIndex((f: Fragment) => f.id === fragment.id);

            if (fragmentIndex === -1) {
                updatedFragments.push(updatedFragment);
                toast.success(`Products from fragment ${fragment.title} added to cart.`);
            } else {
                updatedFragments.splice(fragmentIndex, 1);
                toast.info(`Products from fragment ${fragment.title} removed from cart.`);
            }
            localStorage.setItem(storageKey, JSON.stringify(updatedFragments));
            setSelectedFragments(updatedFragments);
            onSelectedFragmentsChange(updatedFragments);
        },
        [setSelectedFragments, onSelectedFragmentsChange]
    );


    useEffect(() => {
        recalculateTotalPrice();
    }, [selectedFragments]);

    const renderHeaderList = () => (
        <div className='step2-bom-header-info'>
            <p className='step2-bom-item-title-checkbox-info'>Product</p>
            <p className='step2-bom-calculated-qty-info'>Calculated QTY</p>
            <p className='step2-bom-qty-ordered-info'>QTY ordered</p>
            <p className='step2-bom-price-info'>Unit price</p>
            <p className='step2-bom-total-info'>Total</p>
            <p className='step2-bom-total-orderd-info'>Total ordered</p>
        </div>
    );

    return (
        <>
            {fragments.length > 0 && (
                <p>Exist Fragments</p>
            )}
            {fragments.map((fragment, fragmentIndex) => {
                const totalPriceKey = fragment.id;
                const isReplace = fragmentReplaceState[`normal-${fragmentIndex}`] || false;

                return (
                    <div className='step2-content-block' key={`normal-${fragment.id}-${fragmentIndex}`}>
                        <div className='step2-bom-bom-project-title'>
                            <p className='step2-bom-title'>{fragment.title}</p>
                            <div onClick={() => handleToggleFragment(fragmentIndex, 'normal')} className='material-setting-block'>
                                <div><IconSettingsStep2Products /></div>
                                <p>Select Products</p>
                            </div>
                        </div>

                        {renderHeaderList()}
                        {(fragment.data.item_types).map((itemType: ItemType, itemTypeIndex: number) => {
                            const key = `normal-${fragment.id}-${fragmentIndex}-${itemType.id}-${itemTypeIndex}`;
                            return (
                                <div key={key}>
                                    <div className='step2-bom-items'>
                                        <div
                                            onClick={() => handleOpenModal(itemType.id, itemTypeIndex, itemType.qty, fragment.id, fragmentIndex, 'normal',itemType.unit)}
                                            className={!isReplace ? "step2-bom-item-title-checkbox" : "step2-bom-item-title-checkbox-item"}>

                                            {isReplace ? (
                                                <div>
                                                    <p className='step2-bom-item-title'>{itemType.title}</p>
                                                </div>
                                            ) : (
                                                <p className='step2-bom-item-title'>{itemType.title}</p>
                                            )}
                                            {isReplace && (
                                                <div className='step2-bom-architect-arrow'><IconStep2BomArchitectArrow/></div>
                                            )}

                                        </div>
                                        <p className='step2-bom-calculated-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'psc'})</p>
                                        <p className='step2-bom-qty-ordered'>{itemType.ordered_qty || 0}
                                            <span>({itemType.unit ? itemType.unit : 'psc'})</span>
                                        </p>
                                        <p className='step2-bom-price'>-</p>
                                        <p className='step2-bom-total'>-</p>
                                        <p className='step2-bom-total-orderd'>-</p>
                                    </div>

                                    {(selectedItemsByItemType[key] || []).map((item, itemIndex) => {
                                        const orderedQty = item.ordered_qty || 0;
                                        const orderedQtyPackaging = orderedQty * (item.packaging &&
                                            Array.isArray(item.packaging) && item.packaging.length === 0
                                                ? 1
                                                : item.packaging && typeof item.packaging === 'object'
                                                    ? item.packaging[Object.keys(item.packaging)[0]] || 1
                                                    : 1
                                        );
                                        const packagingValue = item.packaging && typeof item.packaging === 'object' && Object.values(item.packaging).length > 0
                                            ? Object.values(item.packaging)[0]
                                            : 1;
                                        const itemPackagingTotal = Math.ceil(orderedQty * Number(packagingValue));
                                        const itemPackagingItem = item.packaging && Object.keys(item.packaging).length > 0
                                            ? Object.keys(item.packaging)[0]
                                            : item.unit || 'psc'
                                        return (
                                            <div key={`${key}-${itemIndex}`} className='step2-bom-items'>
                                                <div className='step2-bom-item-title-item'>
                                                    {isReplace ? (
                                                        <p className='step2-bom-item-title'>{item.title}</p>
                                                    ) : (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                className="step2-bom-input-checkbox"
                                                                checked={isItemChecked(fragment.id, itemType.id, itemTypeIndex, item.id)}
                                                                onChange={() =>
                                                                    handleToggleItemSelection(fragment, itemType.id, itemTypeIndex, item)
                                                                }
                                                            />
                                                            <p className='step2-bom-item-title'>{item.title}</p>
                                                        </>
                                                    )}
                                                </div>
                                                <p className='step2-bom-calculated-qty'>{item.qty || 0}(psc)/{itemPackagingTotal}({itemPackagingItem})
                                                </p>
                                                <p className='step2-bom-qty-ordered'>
                                                    {item.ordered_qty || 0}(psc)/{orderedQtyPackaging}({itemPackagingItem})
                                                </p>
                                                <p className='step2-bom-price'>R {parseFloat(String(item.price)).toFixed(2)}</p>
                                                <p className='step2-bom-total'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                                <p className='step2-bom-total-orderd'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                        {isReplace ? (
                            <div className='save-button-step2bom-block'>
                                <button onClick={() => handleSave()} className='save-button-step2bom'>
                                    Save
                                </button>
                            </div>
                        ) : (
                            <div className='step3-bom-bottom-total-block-price'>
                                <p>Total:</p>
                                {isSmallScreen && (<span className="dashed-line"></span>)}
                                <span>R {(totalPrices[totalPriceKey] || 0).toFixed(2)}</span>
                                <button
                                    onClick={() => handleAddProductsToCart(fragment)}
                                    className='step2-bottom-block-btn-cart-list'>
                                    Add products to cart
                                </button>
                            </div>
                        )}

                    </div>
                );
            })}
            {customFragments.length > 0 && (
                <p>Custom Fragments</p>
            )}
            {customFragments.map((fragment, fragmentIndex) => {
                const totalPriceKey = fragment.id;
                const isReplace = fragmentReplaceState[`custom-${fragmentIndex}`] || false;

                return (
                    <div className='step2-content-block' key={`custom-${fragment.id}-${fragmentIndex}`}>
                        <div className='step2-bom-bom-project-title'>
                            <p className='step2-bom-title'>{fragment.title}</p>
                            <div onClick={() => handleToggleFragment(fragmentIndex, 'custom')} className='material-setting-block'>
                                <div><IconSettingsStep2Products /></div>
                                <p>Select Products</p>
                            </div>
                        </div>

                        {renderHeaderList()}
                        {(fragment.data.item_types).map((itemType: ItemType, itemTypeIndex: number) => {
                            const key = `custom-${fragment.id}-${fragmentIndex}-${itemType.id}-${itemTypeIndex}`;
                            return (
                                <div key={key}>
                                    <div className='step2-bom-items'>
                                        <div
                                            onClick={() => handleOpenModal(itemType.id, itemTypeIndex, itemType.qty, fragment.id, fragmentIndex, 'custom',itemType.unit)}
                                            className={!isReplace ? "step2-bom-item-title-checkbox" : "step2-bom-item-title-checkbox-item"}>

                                            {isReplace ? (
                                                <div>
                                                    <p className='step2-bom-item-title'>{itemType.title}</p>
                                                </div>
                                            ) : (
                                                <p className='step2-bom-item-title'>{itemType.title}</p>
                                            )}
                                            {isReplace && (
                                                <div className='step2-bom-architect-arrow'><IconStep2BomArchitectArrow/></div>
                                            )}

                                        </div>
                                        <p className='step2-bom-calculated-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'psc'})</p>
                                        <p className='step2-bom-qty-ordered'>{itemType.ordered_qty || 0}
                                            <span>({itemType.unit ? itemType.unit : 'psc'})</span>
                                        </p>
                                        <p className='step2-bom-price'>-</p>
                                        <p className='step2-bom-total'>-</p>
                                        <p className='step2-bom-total-orderd'>-</p>
                                    </div>

                                    {(selectedItemsByItemType[key] || []).map((item, itemIndex) => {
                                        const orderedQty = item.ordered_qty || 0;
                                        const orderedQtyPackaging = orderedQty * (item.packaging &&
                                            Array.isArray(item.packaging) && item.packaging.length === 0
                                                ? 1
                                                : item.packaging && typeof item.packaging === 'object'
                                                    ? item.packaging[Object.keys(item.packaging)[0]] || 1
                                                    : 1
                                        );
                                        const packagingValue = item.packaging && typeof item.packaging === 'object' && Object.values(item.packaging).length > 0
                                            ? Object.values(item.packaging)[0]
                                            : 1;
                                        const itemPackagingTotal = Math.ceil(orderedQty * Number(packagingValue));
                                        const itemPackagingItem = item.packaging && Object.keys(item.packaging).length > 0
                                            ? Object.keys(item.packaging)[0]
                                            : item.unit || 'psc'
                                        return (
                                            <div key={`${key}-${itemIndex}`} className='step2-bom-items'>
                                                <div className='step2-bom-item-title-item'>
                                                    {isReplace ? (
                                                        <p className='step2-bom-item-title'>{item.title}</p>
                                                    ) : (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                className="step2-bom-input-checkbox"
                                                                checked={isItemChecked(fragment.id, itemType.id, itemTypeIndex, item.id)}
                                                                onChange={() =>
                                                                    handleToggleItemSelection(fragment, itemType.id, itemTypeIndex, item)
                                                                }
                                                            />
                                                            <p className='step2-bom-item-title'>{item.title}</p>
                                                        </>
                                                    )}
                                                </div>
                                                <p className='step2-bom-calculated-qty'>{item.qty || 0}(psc)/{itemPackagingTotal}({itemPackagingItem})
                                                </p>
                                                <p className='step2-bom-qty-ordered'>
                                                    {item.ordered_qty || 0}(psc)/{orderedQtyPackaging}({itemPackagingItem})
                                                </p>

                                                <p className='step2-bom-price'>R {parseFloat(String(item.price)).toFixed(2)}</p>
                                                <p className='step2-bom-total'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                                <p className='step2-bom-total-orderd'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                        {isReplace ? (
                            <div className='save-button-step2bom-block'>
                                <button onClick={() => handleSave()} className='save-button-step2bom'>
                                    Save
                                </button>
                            </div>
                        ) : (
                            <div className='step3-bom-bottom-total-block-price'>
                                <p>Total:</p>
                                {isSmallScreen && (<span className="dashed-line"></span>)}
                                <span>R {(totalPrices[totalPriceKey] || 0).toFixed(2)}</span>
                                <button
                                    onClick={() => handleAddProductsToCart(fragment)}
                                    className='step2-bottom-block-btn-cart-list'>
                                    Add products to cart
                                </button>
                            </div>
                        )}

                    </div>
                );
            })}

            <FragmentComponentModal
                isLoading={isLoading}
                isOpen={isModalOpenFragment}
                onClose={handleCloseModal}
                items={itemsFragment}
                currentPage={page}
                totalItems={totalItems}
                itemsPerPage={5}
                onPageChange={setPage}
                onSortChange={handleSortChange}
                onSearch={handleSearchChange}
                itemTypeQtyByFragment={itemTypeQtyByFragment}
                itemTypeUnitByFragment={itemTypeUnitByFragment}
                totalSearchResults={totalSearchResults}
                initialSelectedItems={
                    currentFragmentType && fragmentId !== null && fragmentIndex !== null && itemTypeId !== null && itemTypeIndex !== null
                        ? selectedItemsByItemType[`${currentFragmentType}-${fragmentId}-${fragmentIndex}-${itemTypeId}-${itemTypeIndex}`] || []
                        : []
                }
                onSelectItems={handleSelectItems}
            />
        </>
    )
};

export default FragmentsComponent;
