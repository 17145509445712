import React, { useEffect, useState} from 'react';
import '../TotalsBlockCustomer/TotalsBlockCustomer.css';
import {IconStep2VisibleArrowBottom, IconStep2VisibleArrowTop} from "../../IconComponents/IconComponents";
import {BOMProject} from "../../types/ProjectBOMInterface";

interface ProjectTotalCostsProps {
    project: BOMProject | null;
    isSmallScreen: boolean;
    productTypeTotal: number;
    fragmentTotal: number;
    isVisible: boolean;

}

const StepTotalsBlockBom: React.FC<ProjectTotalCostsProps> = ({
                                                                  project,
                                                                  isSmallScreen,
                                                                  productTypeTotal,
                                                                  fragmentTotal,
                                                                  isVisible
                                                              }) => {
    const [isExpanded, setIsExpanded] = useState(isVisible);
    const [projectItemTypeTotal, setProjectItemTypeTotal] = useState<number>(0);
    const [projectFragmentTotal, setProjectFragmentTotal] = useState<number>(0);
    const [projectTotal, setProjectTotal] = useState<number>(0);

    const [projectItemTypeTotalComplete, setProjectItemTypeTotalComplete] = useState<number>(0);
    const [projectFragmentTotalComplete, setProjectFragmentTotalComplete] = useState<number>(0);
    const [projectTotalComplete, setProjectTotalComplete] = useState<number>(0);

    useEffect(() => {
        if (project !== null) {
            const itemTypeTotal = Array.isArray(project.data.item_types)
                ? project.data.item_types.reduce((acc: number, itemType: any) => {
                    const qty = itemType.qty || 0;
                    const price = parseFloat(itemType.item?.price || 0);
                    return acc + qty * price;
                }, 0)
                : 0;
            const itemTypeTotalComplete = Array.isArray(project.data.item_types)
                ? project.data.item_types.reduce((acc: number, itemType: any) => {
                    const qty = itemType.item.ordered_qty || 0;
                    const price = parseFloat(itemType.item?.price || 0);
                    return acc + qty * price;
                }, 0)
                : 0;
            setProjectItemTypeTotalComplete(itemTypeTotalComplete)
            setProjectItemTypeTotal(itemTypeTotal);

            const fragmentTotal = Array.isArray(project.data.fragments)
                ? project.data.fragments.reduce((acc: number, fragment: any) => {
                    const fragmentItemTypeTotal = Array.isArray(fragment.data.item_types)
                        ? fragment.data.item_types.reduce((fragmentAcc: number, itemType: any) => {
                            const itemTotal = Array.isArray(itemType.items)
                                ? itemType.items.reduce((itemAcc: number, item: any) => {
                                    const qty = item.qty || 0;
                                    const price = parseFloat(item.price || 0);
                                    return itemAcc + qty * price;
                                }, 0)
                                : 0;
                            return fragmentAcc + itemTotal;
                        }, 0)
                        : 0;
                    return acc + fragmentItemTypeTotal;
                }, 0)
                : 0;

            const fragmentTotalComplete = Array.isArray(project.data.fragments)
                ? project.data.fragments.reduce((acc: number, fragment: any) => {
                    const fragmentItemTypeTotal = Array.isArray(fragment.data.item_types)
                        ? fragment.data.item_types.reduce((fragmentAcc: number, itemType: any) => {
                            const itemTotal = Array.isArray(itemType.items)
                                ? itemType.items.reduce((itemAcc: number, item: any) => {
                                    const qty = item.ordered_qty || 0;
                                    const price = parseFloat(item.price || 0);
                                    return itemAcc + qty * price;
                                }, 0)
                                : 0;
                            return fragmentAcc + itemTotal;
                        }, 0)
                        : 0;
                    return acc + fragmentItemTypeTotal;
                }, 0)
                : 0;
            setProjectFragmentTotalComplete(fragmentTotalComplete)
            setProjectFragmentTotal(fragmentTotal);
            setProjectTotalComplete(fragmentTotalComplete+itemTypeTotalComplete)

            setProjectTotal(itemTypeTotal + fragmentTotal);
        }
    }, [project]);


    useEffect(() => {
        setIsExpanded(!isVisible);
    }, [isVisible]);

    const toggleVisibility = () => setIsExpanded((prev) => !prev);

    if (!isExpanded && isVisible) {
        return (
            <div className='total-block-btn'>
                <div
                    className={`toggle-visibility-button ${isExpanded ? 'expanded' : 'collapsed'}`}
                    onClick={toggleVisibility}
                >
                    <p>{isExpanded ? 'Hide' : 'Show'}</p>
                    <div>{isExpanded ? <IconStep2VisibleArrowBottom/> : <IconStep2VisibleArrowTop/>}</div>
                </div>
            </div>
        );
    }

    return (

        <div className={isVisible ? "total-block" : 'total-block-none-visible'}>
            {isVisible && (
                <div
                    className={`toggle-visibility-button ${isExpanded ? 'expanded' : 'collapsed'}`}
                    onClick={toggleVisibility}
                >
                    <p>{isExpanded ? 'Hide' : 'Show'}</p>
                    <div>{isExpanded ? <IconStep2VisibleArrowBottom/> : <IconStep2VisibleArrowTop/>}</div>
                </div>
            )}

            <div className='total-block-price'>
                <div className='step3-page-bottom-total-container'>
                    <div className='step3-page-bottom-block-price'>
                        <h3 className='step3-page-bottom-block-total'>Total project costs:</h3>
                        <div className='step3-page-bottom-total-price-block'>
                            <p>Product types:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {projectItemTypeTotal.toFixed(2)}</span>
                        </div>


                        <div className='step3-page-bottom-total-price-block'>
                            <p>Fragments:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {projectFragmentTotal.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='step3-page-bottom-total-price-block-total'>
                        <p>Grand total:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span className='step3-page-bottom-price-total'>
              R {(projectTotal).toFixed(2)}
            </span>
                    </div>
                </div>

                <div className='step3-page-bottom-total-container step3-page-bottom-total-container-border'>
                    <div className='step3-page-bottom-block-price'>
                        <h3 className='step3-page-bottom-block-total'>Total costs to complete:</h3>
                        <div className='step3-page-bottom-total-price-block'>
                            <p>Product types:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {Math.max(0, (projectItemTypeTotal - projectItemTypeTotalComplete)).toFixed(2)}</span>
                        </div>

                        <div className='step3-page-bottom-total-price-block'>
                            <p>Fragments:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {Math.max(0, (projectFragmentTotal - projectFragmentTotalComplete)).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='step3-page-bottom-total-price-block-total'>
                        <p>Grand total:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span className='step3-page-bottom-price-total'>
              R {Math.max(0,(projectTotal - projectTotalComplete)).toFixed(2)}
            </span>
                    </div>
                </div>
            </div>
            <div className='step3-page-bottom-total-container'>
                <div className='step3-page-bottom-block-price'>
                    <h3 className='step3-page-bottom-block-total'>Total selected for purchase:</h3>
                    <div className='step3-page-bottom-total-price-block'>
                        <p>Product types:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span
                            className='step2-page-bottom-price'>R {Math.max(0, productTypeTotal).toFixed(2)}</span>
                    </div>
                    <div className='step3-page-bottom-total-price-block'>
                        <p>Fragments:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span
                            className='step2-page-bottom-price'>R {Math.max(0, fragmentTotal).toFixed(2)}</span>
                    </div>

                </div>
                <div className='step3-page-bottom-total-price-block-total'>
                    <p>Grand total:</p>
                    {isSmallScreen && <span className='dashed-line'></span>}
                    <span
                        className='step3-page-bottom-price-total'>R {Math.max(0, (fragmentTotal + productTypeTotal)).toFixed(2)}</span>
                </div>
            </div>
        </div>

    );
};

export default StepTotalsBlockBom;
