import React from 'react';
import Modal from 'react-modal';
import './TokenRevitAutocad.css';
import {IconPayMentInfoModalBig} from "../../../IconComponents/IconComponents";
import {IconTokenModal} from "../../TopHeaderAfterAuth/DesctopHeader/Sidebar/IconsSidebar";
import {User} from "../../../types/ProjectTypes";

interface ModalDeleteProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
    user: User;
}

const TokenRevitAutocad: React.FC<ModalDeleteProps> = ({isOpen, onRequestClose, onConfirm, onCancel,user}) => {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="payment-info-modal"
            overlayClassName="payment-info-delete"
        >
            <p className='purchase-amount-tokens'>Available amount of tokens: <span
                className='purchase-amount-tokens-qty'>{user.tokens ? user.tokens : 0} <IconTokenModal/></span></p>
                <button className="close-button" onClick={onCancel}>&times;</button>
            <div className="payment-info-block">

                <div>
                    <IconPayMentInfoModalBig width={'50'} height={'50'}/>
                </div>
                <p className='payment-info-block-text'>1 token will be debited from your account.</p>
                <p className='payment-info-block-text-2'>Payment is requested for each download of a new 3D plan.</p>
                <div className="payment-info-confirmation-buttons">
                    <button className="payment-info-cancel-button" onClick={onCancel}>Cansel</button>
                    <button className="payment-info-confirm-button" onClick={onConfirm}>Confirm Deletion</button>
                </div>
            </div>
        </Modal>
    );
};

export default TokenRevitAutocad;
