import {
    useMatch, useNavigate,
} from 'react-router-dom';
import './style.css'
import {useSelector} from "react-redux";
import {selectUser} from "../../Slice/tokenSlice/userSlice";
import {startTransition} from "react";


interface ILink{
    to:string,
    children:string
}

function CustomNavLink({ to, children }: ILink) {
    const match = useMatch(to);
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        startTransition(() => {
            navigate(to);
        });
    };

    return (
        <li className='list-wrapper-list'>
            <a
                className={match ? 'list-rout active' : 'list-rout'}
                href={to}
                onClick={handleClick}
            >
                {children}
            </a>
        </li>
    );
}




function HeaderList():any{
    const user = useSelector(selectUser);

    const role = user?.role?.role
    const checkRole = role === 'Customer'
    return(
            <div className='list-wrapper'>
                <CustomNavLink to='/projects'>Projects list</CustomNavLink>
                {!checkRole && (<CustomNavLink to='/team'>Team management</CustomNavLink>)}
                <CustomNavLink to='/order_history'>Order history</CustomNavLink>
            </div>
        )
}
export default HeaderList