import React, { useState} from 'react';
import './MobileBottomMenu.css';
import {
    IconBottomMobile,
    IconBottomMobileHomeActive,
    IconBottomMobileHomeNon,
    IconBottomMobileProjectsActive,
    IconBottomMobileProjectsNon,
    IconBottomMobileSettingsActive,
    IconBottomMobileSettingsNon,
    IconBottomMobileTeamActive,
    IconBottomMobileTeamNon,
} from '../../IconComponents/IconComponents';
import {useLocation} from 'react-router-dom';
import CreateProjectModal from "../Modals/ModalCreateProject/ModalCreateProject";
import {useSmartNavigate} from "../../Hooks/useNavigationHook";

function MobileBottomMenu() {
    const location = useLocation();
    const pathname = location.pathname;
    const smartNavigate = useSmartNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleNavigate = (path:string) => {
            smartNavigate(path);
    }
    return (
        <div className="mobile-bottom-menu">
            <div className='mobile-bottom-menu-background-top'></div>
            <div
                onClick={()=>handleNavigate('/assistance')}
                className={`mobile-bottom-menu-content ${pathname === '/assistance' ? 'active' : ''}`}>
                {pathname === '/assistance' ? < IconBottomMobileHomeActive/> : <IconBottomMobileHomeNon/>}
                <p>Home</p>
            </div>

            <div
                onClick={()=>handleNavigate('/projects')}
                className={`mobile-bottom-menu-content ${pathname === '/projects' ? 'active' : ''}`}>
                {pathname === '/projects' ? < IconBottomMobileProjectsActive/> : <IconBottomMobileProjectsNon/>}
                <p>Projects</p>
            </div>

            <div
                onClick={()=>setIsModalOpen(true)}
                className='mobile-bottom-menu-content-create-project'>
                <IconBottomMobile/>
            </div>

            <div
                onClick={()=>handleNavigate('/team')}
                className={`mobile-bottom-menu-content ${pathname === '/team' ? 'active' : ''}`}>
                {pathname === '/team' ? < IconBottomMobileTeamActive/> : <IconBottomMobileTeamNon/>}
                <p>Team</p>
            </div>

            <div
                onClick={()=>handleNavigate('/preferences')}
                className={`mobile-bottom-menu-content ${pathname === '/preferences' ? 'active' : ''}`}>
                {pathname === '/preferences' ? < IconBottomMobileSettingsActive/> : <IconBottomMobileSettingsNon/>}
                <p>Settings</p>
            </div>
            <CreateProjectModal
                isOpen={isModalOpen}
                onRequestClose={()=>setIsModalOpen(false)}
            />
        </div>
    );
}

export default MobileBottomMenu;
