import React, {useEffect, useRef, useState} from "react";
import { connect, ConnectedProps } from "react-redux";
import {Fragment, ParsedFragment} from "../../types/TemplateType";
import {Gallery, Project} from "../../types/ProjectTypes";
import './step2HeaderList.css';
import "../../Page/Step2/Step2Styles/step2.css";
import HeaderList from "./HeaderList";
import ProgressBarFragment from "../ProgressBar/ProgressBarFragment";
import SwitcherTools from "../Switcher/SwitcherTools";
import {
    IconArrowDownStep2,
    IconSettingsStep2,
    IconSettingsStep2Products
} from "../../IconComponents/IconComponents";
import { RootState } from "../../store";
import {
    toggleFragmentVisibility,
    toggleLaborVisibility,
    toggleToolVisibility
} from "../../Slice/ClickSlice/FragmentVisibityState";
import {getGallery} from "../../api/APIWraper";
import SelectFragmentStyleButton from "../../Page/Step2/Components/SelectFragmentStyleButton/SelectFragmentStyleButton";


interface MaterialNameOwnProps {
    fragmentIndex?: number;
    fragment?: Fragment | ParsedFragment;
    project: Project;
    onToggle?: () => void;
    showSettingsButton?: boolean;
    visibleStep3?:boolean;
    isLabor?: boolean;
    isTool?: boolean;
    isToolStep2?: boolean;
    handleSwitchChangeTools?: (value: 'Recommended' | 'Minimum') => void;
    allFragmentsIds?:number[]
    toggleActive?:boolean
    onUpdateProject: (newProject: Project) => void
}

type MaterialNameProps = MaterialNameOwnProps & PropsFromRedux;

const MaterialName: React.FC<MaterialNameProps> = ({
                                                       fragment,
                                                       onToggle,
                                                       fragmentIndex,
                                                       showSettingsButton,
                                                       isLabor,
                                                       isTool,
                                                       isToolStep2,
                                                       handleSwitchChangeTools,
                                                       isVisible,
                                                       toggleActive,
                                                       toggleFragmentVisibility,
                                                       toggleLaborVisibility,
                                                       toggleToolVisibility,
                                                       isLaborVisible,
                                                       isToolVisible,
                                                       allFragmentsIds,
                                                       onUpdateProject,
                                                   }) => {
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [fragmentGallery, setFragmentGallery] = useState<string>("/img_vendor/item.png");
    const lastFetchedIds = useRef<number[]>([]);
    const isFirstRender = useRef<boolean>(true);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    const handleToggleVisibility = () => {
        if (fragment && fragmentIndex !== undefined) {
            toggleFragmentVisibility(fragment.id, fragmentIndex);
        }
    };

    const handleToggleLaborVisibility = () => {
        toggleLaborVisibility();
    };

    const handleToggleToolVisibility = () => {
        toggleToolVisibility();
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        const isSameIds =
            allFragmentsIds &&
            lastFetchedIds.current.length === allFragmentsIds.length &&
            lastFetchedIds.current.every((id, index) => id === allFragmentsIds[index]);

        if (!isSameIds && allFragmentsIds && allFragmentsIds?.length > 0) {
            fetchFragmentGallery();
            lastFetchedIds.current = [...allFragmentsIds];
        }
    }, [allFragmentsIds]);


    const fetchFragmentGallery = async () => {
        try {
            const body = {
                type: "fragment",
                ids: allFragmentsIds
            };
            const response = await getGallery(body);

            if (response?.data?.data?.length > 0 && fragment) {
                const matchingGallery = response.data.data.find(
                    (gallery: Gallery) => gallery.galleryable_id === fragment.id
                );

                if (matchingGallery && matchingGallery.medias.length > 0) {
                    const lastMediaUrl = matchingGallery.medias[matchingGallery.medias.length - 1].url;
                    setFragmentGallery(lastMediaUrl);
                }
            }
        } catch (error) {
            console.error("Error loading fragments gallery:", error);
        }
    };


    const visibleStyle = isVisible ? 'material-setting-block-arrow-up' : 'material-setting-block-arrow-down';
    const laborVisibleStyle = isLaborVisible ? 'material-setting-block-arrow-up' : 'material-setting-block-arrow-down';
    const toolVisibleStyle = isToolVisible ? 'material-setting-block-arrow-up' : 'material-setting-block-arrow-down';
    return (
        <>
            <div
                style={{
                    position: !showSettingsButton ? 'static' : 'sticky',
                }}
                className='new-step2-material-list'
            >
                <div className='new-step2-material-list-name'>
                    {isLabor && (
                        <div>
                        <p className='project-item-update-material-list-name-name'>
                            Labor required for task (optional)
                        </p>
                        <small className='labor-small-text'>Indicative labor costs for each section, not purchasable.</small>
                        </div>
                    )}
                    {isTool && (
                        <div>
                            <p className='project-item-update-material-list-name-name'>
                                Tools required for task (optional)
                            </p>
                            <small className='labor-small-text'>You can select the tools you require, these are purchasable from
                                the supplier you select.</small>
                        </div>

                    )}
                    {isTool && (
                        <SwitcherTools
                            options={['Recommended', 'Minimum']}
                            onChange={handleSwitchChangeTools || (() => {})}
                        />
                    )}
                    <>
                        {fragment && <img className='fragment-img-step2' src={fragmentGallery} alt={'fragment-img'}/>}
                        {fragment && <p className='new-step2-material-list-name-name'>{fragment.title}</p>}
                        {fragment && !fragment.is_style && (
                            <div className='new-step2-progress'>
                                <ProgressBarFragment fragment={fragment} />
                            </div>
                        )}
                    </>
                </div>
                <div className='new-step2-material-action-buttons'>
                    {(fragment && ((fragment?.is_style || false) || Number(fragment?.fragment_style_id))) ? <SelectFragmentStyleButton
                        id={fragment.fragment_style_id || fragment.id}
                        index={'index' in fragment ? fragment.index : fragment.current_index}
                        questions={'questions' in fragment ? fragment?.questions || [] : []}
                        onUpdateProject={onUpdateProject}
                    />
                        :<></>
                    }

                    {!(fragment?.is_style || false) && <>
                        {isSmallScreen ? (
                            <div className='material-setting-block-mobile'>
                                <div
                                    className={isLabor ? laborVisibleStyle : isTool ? toolVisibleStyle : visibleStyle}
                                    onClick={isLabor ? handleToggleLaborVisibility : isTool ? handleToggleToolVisibility : handleToggleVisibility}
                                >
                                    <IconArrowDownStep2/>
                                </div>
                                {(isLabor ? isLaborVisible : isToolStep2 ? isToolVisible : isVisible) && (
                                    <div onClick={onToggle}>
                                        {showSettingsButton && (<IconSettingsStep2/>)}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                {showSettingsButton && (
                                    <div className='material-setting-block' onClick={onToggle}>
                                        <div><IconSettingsStep2Products/></div>
                                        <p>{isTool ? 'Amend Tools' : isLabor ? 'Amend Labor' : 'Amend Products'}</p>
                                    </div>
                                )}
                            </>
                        )
                        }
                        </> }
                </div>

            </div>
            { !(fragment?.is_style || false) && !isSmallScreen && (
                <HeaderList toggleActive={toggleActive} showSettingsButton={showSettingsButton} isTool={isTool} isLabor={isLabor}/>
            )}
        </>
    );
};

const mapStateToProps = (state: RootState, ownProps: MaterialNameOwnProps) => {
    const { fragment, fragmentIndex } = ownProps;
    return {
        isVisible: fragment ? state.fragmentVisible.visibleFragments[`${fragment.id}-${fragmentIndex}`] : false,
        isLaborVisible: state.fragmentVisible.isLaborVisible,
        isToolVisible: state.fragmentVisible.isToolVisible,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    toggleFragmentVisibility: (fragment_id: number, fragmentIndex?: number) => {
        if (fragmentIndex !== undefined) {
            dispatch(toggleFragmentVisibility({ fragment_id, fragmentIndex }));
        }
    },
    toggleLaborVisibility: () => dispatch(toggleLaborVisibility()),
    toggleToolVisibility: () => dispatch(toggleToolVisibility()),
});


const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MaterialName);
