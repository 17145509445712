import React from 'react';
import {
    IconComprehensivePlanning, IconCostOptimization,
    IconEfficientDecisionMaking,
     IconStreamlinedWorkflow,
} from "../../IconComponents/IconComponents";

interface InfoBlockBlogProps {
    isSmallScreen?: boolean;
}

const InfoBlockBlogAboutUs:React.FC<InfoBlockBlogProps> = ({isSmallScreen}) => {
    return (
        <main>
            <section className='middle-content2'>
                <div>
                    <div className='middle-block1-info1'>
                        <div className='middle-block1-info1-content'>
                            <IconComprehensivePlanning/>
                            <p className='middle-block1-info1-name'>Streamlined Workflow</p>
                            <p className='middle-block1-info1-text width-text-middle'>Integrate seamlessly with your existing construction planning process</p>
                        </div>
                    </div>
                    <div className='middle-block1-section'>
                        <div className='middle-block1-info2'>
                            <div className='middle-block1-info2-content'>
                                <IconCostOptimization/>
                                <p className='middle-block1-info1-name'>Cost Optimization</p>
                                <p className='middle-block1-info1-text'>Accurate cost estimates help you budget effectively and avoid overruns</p>
                            </div>
                        </div>
                        <div className='middle-block1-info2'>
                            <div className='middle-block1-info1-content'>
                                <IconStreamlinedWorkflow/>
                                <p className='middle-block1-info1-name'>Efficient Decision-Making</p>
                                <p className='middle-block1-info1-text width-text-short'>Compare different materials and design options quickly to make informed choices</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='middle-block2-info1-last'>
                    <div className='middle-block2-info1'>
                        <div className='middle-block2-info1-content'>
                            < IconEfficientDecisionMaking/>
                            <p className='middle-block1-info1-name'>Comprehensive Planning</p>
                            <p className='middle-block1-info1-text '>Get a detailed breakdown of all necessary materials and their quantities</p>
                        </div>
                        {!isSmallScreen && (
                            <div className='middle-section-image-block'>
                                <img className='about-us-section-image-screen1' src='/img_landing/Projectlist1.png'
                                     alt="Image pop up"/>
                            </div>
                        )}
                    </div>
                </div>
            </section>


        </main>
    );
};

export default InfoBlockBlogAboutUs;