import { useState, useEffect } from "react";

export const generateItemKey = (
    fragmentId: number,
    fragmentIndex: number,
    templateId: number,
    templateIndex: number,
    itemTypeId: number,
    itemId: number
): string => {
    return `${fragmentId}-${fragmentIndex}-${templateId}-${templateIndex}-${itemTypeId}-${itemId}`;
};

export const useVisitedItems = () => {
    const [visitedItems, setVisitedItems] = useState<string[]>([]);

    useEffect(() => {
        const storedVisited = JSON.parse(localStorage.getItem("visitedItems") || "[]");
        setVisitedItems(storedVisited);
    }, []);

    const markAsVisited = (key: string) => {
        if (!visitedItems.includes(key)) {
            const updatedVisited = [...visitedItems, key];
            setVisitedItems(updatedVisited);
            localStorage.setItem("visitedItems", JSON.stringify(updatedVisited));
        }
    };

    const isVisited = (key: string) => visitedItems.includes(key);

    const handleLogoClick = (
        link: string | undefined,
        fragmentId: number,
        fragmentIndex: number,
        templateId: number,
        templateIndex: number,
        itemTypeId: number,
        itemId: number
    ) => {
        if (!link) return;

        const key = generateItemKey(fragmentId, fragmentIndex, templateId, templateIndex, itemTypeId, itemId);
        markAsVisited(key);

        window.open(link, "_blank", "noopener");
    };

    return { visitedItems, markAsVisited, isVisited, handleLogoClick };
};

export const parseItemKey = (key: string) => {
    const [fragment_id, fragment_index, template_id, template_index, item_type_id, item_id] = key.split("-").map(Number);

    return {
        fragment_id,
        fragment_index,
        template_id,
        template_index,
        item_type_id,
        item_id
    };
};


export const useErrorMessages = () => {
    const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({});

    const setError = (key: string, message: string, timeout = 5000) => {
        setErrorMessages((prev) => ({ ...prev, [key]: message }));

        setTimeout(() => {
            setErrorMessages((prev) => {
                const updated = { ...prev };
                delete updated[key];
                return updated;
            });
        }, timeout);
    };

    const clearError = (key: string) => {
        setErrorMessages((prev) => {
            const updated = { ...prev };
            delete updated[key];
            return updated;
        });
    };

    return { errorMessages, setError, clearError };
};

export const useQtyHandler = (
    setError: (key: string, message: string) => void,
    clearError: (key: string) => void
) => {
    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<any>({});

    const handleQtyChange = (itemTypeIndex: string, newQty: string, errorItem?: any, qtyStore?: number | { quantity: number } | 'other vendor' | undefined
    ) => {
        setDebouncedOrderedQty((prev: any) => ({
            ...prev,
            [itemTypeIndex]: newQty,
        }));

        if (errorItem === undefined || qtyStore === undefined) {
            return;
        }

        let availableQty = 0;
        if (qtyStore === "other vendor") {
            availableQty = Infinity;
        } else if (typeof qtyStore === "number") {
            availableQty = qtyStore;
        } else if (typeof qtyStore === "object" && qtyStore !== null && "quantity" in qtyStore) {
            availableQty = qtyStore.quantity;
        }


        let parsedValue = parseInt(newQty, 10);
        if (isNaN(parsedValue) || parsedValue < 0) {
            parsedValue = 0;
        }
        const newValue = Math.min(parsedValue, availableQty);

        setDebouncedOrderedQty((prev: any) => ({
            ...prev,
            [itemTypeIndex]: newValue.toString(),
        }));

        const key = generateItemKey(
            errorItem.fragment_id,
            errorItem.fragment_index,
            errorItem.template_id,
            errorItem.template_index,
            errorItem.item_type_id,
            errorItem.item_id
        );

        if (parsedValue > availableQty) {
            setError(
                key,
                availableQty !== 0
                    ? `You can buy a maximum of ${availableQty} pieces`
                    : "Out of stock"
            );

            setTimeout(() => {
                clearError(key);
            }, 5000);
        } else {
            clearError(key);
        }
    };

    return { debouncedOrderedQty, handleQtyChange };
};
