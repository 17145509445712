import React, { useState, useEffect, ChangeEvent } from 'react';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import { Fragment } from "../../../../types/TemplateType";
import { toastItemCheckboxFalse, toastItemTrue } from "../../../../components/Toast/AssisCreateToastNotification";
import { IconArrowBottom, IconArrowTop, IconReload, IconSearch } from "../../../../IconComponents/IconComponents";
import { Loader } from "../../../../components/Loader/Loader";
import PaginationInModal from "../../../../components/PaginationAssistance/paginationInModal";
import { get } from "../../../../api/APIWraper";

interface ItemTypeBomModalProps {
    setFragments: (fragments: Fragment[]) => void;
    fragments: Fragment[];
    isOpen: boolean;
    onClose: () => void;
}

const API_URL = process.env.REACT_APP_BASE_URL_CUSTOMER;

const SelectAllFragments: React.FC<ItemTypeBomModalProps> = ({
                                                                 isOpen,
                                                                 onClose,
                                                                 setFragments,
                                                                 fragments,
                                                             }) => {
    const [availableFragments, setAvailableFragments] = useState<Fragment[]>([]);
    const [selectedFragments, setSelectedFragments] = useState<Fragment[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            fetchFragments();
            setSelectedFragments(fragments);
        } else {
            document.body.style.overflow = '';
            resetState();
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    const fetchFragments = async () => {
        setLoading(true);
        try {
            const url = searchTerm
                ? `${API_URL}/fragments?page=${currentPage}&perPage=5&title=${searchTerm}&sortOrder=${sortDirection}`
                : `${API_URL}/fragments?page=${currentPage}&perPage=5&sortOrder=${sortDirection}`;

            const response = await get(url);
            setAvailableFragments(response.data.data);
            setTotalItems(response.data.pagination.total);
        } catch (error) {
            console.error("Error fetching fragments:", error);
        } finally {
            setLoading(false);
        }
    };

    const resetState = () => {
        setSearchTerm('');
    };

    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.trim();
        setSearchTerm(query);
        if (searchTimer) {
            clearTimeout(searchTimer);
        }
        if (query === "") {
            setAvailableFragments([]);
            setLoading(true);
            get(`${API_URL}/fragments?page=${currentPage}&perPage=5&sortOrder=${sortDirection}`)
                .then(response => {
                    setAvailableFragments(response.data.data);
                    setTotalItems(response.data.pagination.total);
                })
                .catch(error => console.error("Error fetching fragments:", error))
                .finally(() => setLoading(false));
        } else {
            const timer = setTimeout(() => {
                fetchFragments();
            }, 500);
            setSearchTimer(timer);
        }
    };

    const handleSortChange = (direction: 'asc' | 'desc') => {
        setSortDirection(direction);
    };

    const handleConfirmSelection = () => {
        const updatedFragments = [...fragments, ...selectedFragments];
        const uniqueFragments = Array.from(new Map(updatedFragments.map(frag => [frag.id, frag])).values());
        setFragments(uniqueFragments);
        onClose();
    };

    const handleAddFragmentClick = (fragment: Fragment) => {
        if (!selectedFragments.some(f => f.id === fragment.id)) {
            setSelectedFragments(prev => [...prev, fragment]);
            toastItemTrue(`${fragment.title} added`);
        }
    };

    const handleRemoveFragmentClick = (fragment: Fragment) => {
        setSelectedFragments(prev => prev.filter(f => f.id !== fragment.id));
        setFragments(fragments.filter(f => f.id !== fragment.id));
        toastItemCheckboxFalse(`Removed ${fragment.title}`);
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="item-selection-modal-content"
            overlayClassName="item-selection-modal-overlay"
            ariaHideApp={false}
        >
            <section className="item-selection-modal-section">
                <div className='item-selection-modal-header'>
                    <h2 className="item-selection-modal-title">Please, select your fragment</h2>
                    <button className="item-selection-modal-close-button" onClick={onClose}>
                        &#x2715;
                    </button>
                </div>

                <div className='item-selection-modal-search'>
                    <div className='item-selection-modal-search-icon'>
                        <IconSearch />
                    </div>
                    <input
                        className='item-selection-modal-search-input'
                        placeholder='Search'
                        type='search'
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                </div>

                <div className='item-selection-modal-sort-icons'>
                    <div className='item-selection-modal-sort-buttons'>
                        <button className='item-selection-modal-sort-button' onClick={fetchFragments}>
                            <IconReload />
                        </button>
                        <button className='item-selection-modal-sort-button' onClick={() => handleSortChange('desc')}>
                            <IconArrowTop />
                        </button>
                        <button className='item-selection-modal-sort-button' onClick={() => handleSortChange('asc')}>
                            <IconArrowBottom />
                        </button>
                    </div>
                </div>

                {loading ? (
                    <Loader />
                ) : (
                    <ul className="item-selection-modal-list">
                        {availableFragments.map((fragment: Fragment) => {
                            const isSelected = selectedFragments.some(f => f.id === fragment.id);

                            return (
                                <li key={fragment.id} className="item-selection-modal-list-item">
                                    <div className="item-selection-modal-list-item-image">
                                        <img
                                            className="item-selection-modal-image"
                                            src={fragment.galleries?.[0]?.medias?.slice(-1)[0]?.url || "/img_vendor/item.png"}
                                            alt="Item"
                                        />
                                        <div className="item-selection-modal-list-item-info">
                                            <p className="item-selection-modal-item-title">{fragment.title}</p>
                                        </div>
                                    </div>

                                    <div className='item-type-bom-modal-action-block'>
                                        <button
                                            onClick={() => handleAddFragmentClick(fragment)}
                                            className={`item-selection-modal-button ${isSelected ? `remove-item` : 'add-item'}`}
                                            disabled={isSelected}
                                            style={{
                                                backgroundColor: isSelected ? '#ccc' : '',
                                                cursor: isSelected ? 'not-allowed' : 'pointer',
                                            }}
                                        >
                                            {isSelected ? 'Added' : 'Add'}
                                        </button>
                                        <button
                                            onClick={() => handleRemoveFragmentClick(fragment)}
                                            className="item-selection-modal-button remove-item"
                                            disabled={!isSelected}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                )}

                <div className='item-selection-modal-save-button-block'>
                    <PaginationInModal
                        perPage={5}
                        total={totalItems}
                        paginate={setCurrentPage}
                        currentPage={currentPage}
                    />
                    <button onClick={handleConfirmSelection} className="item-selection-modal-save-button">
                        Confirm selection
                    </button>
                </div>
            </section>
        </Modal>
    );
};

export default SelectAllFragments;