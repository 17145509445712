import {ParsedFragment} from "../types/TemplateType";
import ILaborItemType from "../Interfaces/ProjectInterfaces/IlaborItemType";
import IToolsItemType from "../Interfaces/ProjectInterfaces/IToolsItemType";

export const filterLaborAndToolsItemTypes = (
    parsedFragments: ParsedFragment[],
    templateIndex: number,
    templateId: number,
    filterType?: 'labor' | 'tool'
): { laborItemTypes?: ILaborItemType[], toolsItemTypes?: IToolsItemType[] } => {
    const newLaborItemTypes: ILaborItemType[] = [];
    const newToolsItemTypes: IToolsItemType[] = [];

    parsedFragments.forEach(
        (fragment: ParsedFragment) => {
            const fragmentIndex = fragment.index
            fragment.item_types.forEach((itemType) => {
                const itemTypeIndex = itemType.index;
                if (itemType.labor && (!filterType || filterType === 'labor')) {
                    newLaborItemTypes.push({
                        item_type: itemType,
                        item: itemType.item ? itemType.item : null,
                        item_type_id: itemType.id,
                        item_id: itemType.item ? itemType.item.id : null,
                        template_index: templateIndex,
                        fragment_index: fragmentIndex,
                        item_type_index: itemTypeIndex,
                        template_id: templateId,
                        fragment_id: fragment.id,
                        qty: itemType.qty,
                        unit: "",
                        title:itemType.title,
                        fragments: itemType.fragments
                    });
                } else if (itemType.tool && (!filterType || filterType === 'tool')) {
                    newToolsItemTypes.push({
                        unit: "",
                        clone_id: 0,
                        item_type: itemType,
                        item: itemType.item ? itemType.item : null,
                        item_type_id: itemType.id,
                        item_id: itemType.item ? itemType.item.id : null,
                        template_index: templateIndex,
                        fragment_index: fragmentIndex,
                        item_type_index: itemTypeIndex,
                        template_id: templateId,
                        fragment_id: fragment.id,
                        qty: itemType.qty,
                        title:itemType.title,
                        fragments: itemType.fragments
                    });
                }
            });
        }
    );

    if (filterType === 'labor') {
        return { laborItemTypes: newLaborItemTypes };
    } else if (filterType === 'tool') {
        return { toolsItemTypes: newToolsItemTypes };
    }
    return { laborItemTypes: newLaborItemTypes, toolsItemTypes: newToolsItemTypes };
};
