import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import './Step1.css';
import 'react-toastify/dist/ReactToastify.css';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import StepCalc from "../../components/AssisStepCalc/StepCalc";
import {clearTemplates, updateLocalTemplate} from '../../Slice/LocalTemplate/LocalTemplateSlice';
import {Template} from '../../types/TemplateType';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {Loader} from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {fetchProjectById} from "../../Slice/projectById/projectByIdSlice";

const Step1: React.FC<PropsFromRedux> = ({selectedTemplates, project, fetchProjectById,clearTemplates, isAuthenticated}) => {
    const navigate = useNavigate();
    const [template, setTemplate] = useState<Template | null>(null);
    const [isReady, setIsReady] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState<Record<string, string>>({});
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const {projectId, indexTemplate, templateId} = useParams<{
        projectId: string;
        templateId: string;
        indexTemplate: string
    }>();

    const projectIdNumber = projectId ? parseInt(projectId, 10) : NaN;
    const templateIdNumber = templateId ? parseInt(templateId, 10) : NaN;
    const templateIndex = indexTemplate ? parseInt(indexTemplate, 10) : NaN;

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            if (projectIdNumber) {
                fetchProjectById({projectId: String(projectId)});
            }
        } else {
            navigate('/home')
        }
    }, [projectId, isAuthenticated]);

    useEffect(() => {
        if (project?.data?.templates) {
            const storedTemplate = project.data.templates.find(template => template.id === templateIdNumber);
            if (storedTemplate) {
                setTemplate(storedTemplate);
                setIsReady(true);
            }
        }
    }, [templateIdNumber, navigate, project]);


    useEffect(() => {
        if (template) {
            const { description, ...templateWithoutDescription } = template;
            localStorage.setItem(`selectedTemplate-${template.id}`, JSON.stringify(templateWithoutDescription));
        }
    }, [template]);


    useEffect(() => {
        if (isReady) {
            document.body.style.overflow = 'unset';
        }
    }, [isReady]);

    const navigateToAssistance = () => {
        clearLocalStorageExceptToken()
        navigate('/assistance');
    };

    if (!isReady) {
        return <Loader/>
    }
    if (!template) return null;

    const descriptionText = template.short_description || 'No description';

    const handleCalculateClick = () => {
        if (template) {
            navigate(`/assistance/create/${projectId}/from/projectCosts`, {state: {from: 'projectCosts'}});
        }
    };

    const navigateTo = (path:string) =>{
        switch (path) {
            case 'home':{
                clearLocalStorageExceptToken();
               clearTemplates();
                navigate('/assistance');
                break
            }
            case 'createProject':{
                handleCalculateClick()
                break
            }
        }
    }

    return (
        <>
            <div className='template-name-content'>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            {to: '/', label: 'Cashbuild'},
                            {to: '', label: '>'},
                            {to: '/', label: 'Home'},
                            {to: '', label: '>'},
                            {to: '/assistance', label: 'Project assistance'},
                            {to: '', label: '>'},
                            {to: '', label: 'Create new project space'}
                        ]}
                    />
                ) : (
                    <div className='template-blog-placeholder'>
                        <p onClick={() => navigateTo('home')} className='template-link-assistance'>Home/</p>
                        <p onClick={() => navigateTo('createProject')} className='template-link-assistance'>Create new
                            project</p>
                        <p className='name-link'>/Step1</p>
                    </div>
                )}
            </div>
            <div className='step-info'>
                <div className='step-info-block'>
                    <h3>Step 1</h3>
                    <span>/</span>
                    <p className='step-info-block-name'>Specify parameters of building</p>
                </div>
            </div>
            <div className='step-template-container'>
                <div className='step-template-block left'>
                    <h2>Type:</h2>
                    <p className='step-template-block-title'>{template.title}</p>
                </div>
                <div className='step-template-block right'>
                    <h2>Description:</h2>
                    <p className='step-template-block-desc'>{descriptionText}</p>
                </div>
                {!isSmallScreen && (
                    <div className='step-template-block-percent'>
                        <div className='progress-bar-wrapper'>
                            <CircularProgressbar
                                value={project?.progress ? project.progress : 0}
                                styles={buildStyles({
                                    pathColor: 'var(--basic-btn-dark-color)',
                                    textColor: '#000000',
                                    trailColor: '#d6d6d6',
                                    textSize: '24px',
                                    pathTransitionDuration: 0.5,
                                })}
                            />
                            <div className='progress-bar-text'>
                                {project?.progress ? project.progress : 0}%
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <p className='step1-info-text'>This building project requires you to insert dimensions for the following
                sections below:</p>
            <StepCalc
                templIndex={templateIndex}
                selectedTemplates={selectedTemplates}
                template={template}
                selectedAnswers={selectedAnswers}
                setSelectedAnswers={setSelectedAnswers}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedTemplates: state.localTemplate.templates,
    project: state.projectById.project,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    updateLocalTemplate,
    fetchProjectById,
    clearTemplates
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step1);
