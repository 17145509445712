import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import '../ProjectList/Styles/ProjectListDesktop.css';
import '../ProjectList/Styles/ProjectListMobile.css'
import './style.css';
import ElementList from "../../components/ElementList/ElementList";
import HeaderList from "../../components/HeaderRoutList/HeaderListRout";
import {RootState} from "../../store";
import {fetchUsersFromOwnProjects} from "../../Slice/userFromOwnProject/UserFromOwnProjectSlice";
import AddMemberModal from "../../components/Modals/ModalAddNewMember/AddNewMember";
import {User} from "../../types/ProjectTypes";
import {
    IconArrowDownOrderHistory,
    IconSettings,
    IconSettingsDarkVersion,
    IconTeamManagement,
    IconUserDefaultProfile
} from "../../IconComponents/IconComponents";
import {Loader} from "../../components/Loader/Loader";
import PaginationAssistance from "../../components/PaginationAssistance/pagination";
import ModalUserInformation from "../../components/Modals/ModalUserInformation/ModalUserInforamtion";
import {unifiedColumnFieldMapping} from "../../utils/ColumnFieldSorting";
import useIsSmallScreen from "../../Hooks/SmallScreen/useIsSmallScreen";
import {useSmartNavigate} from "../../Hooks/useNavigationHook";

const TeamManagerPage: React.FC<PropsFromRedux> = ({
                                                       users,
                                                       totalUsers,
                                                       isFetching,
                                                       error,
                                                       fetchUsersFromOwnProjects,
                                                       isAuthenticated
                                                   }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [openUserInformation, setOpenUserInformation] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);
    const isSmallScreen = useIsSmallScreen();

    const [expandedTeamBlock, setExpandedTeamBlock] = useState<{ [id: number]: boolean }>({});
    const [animatingTeamBlock, setAnimatingTeamBlock] = useState<{ [id: number]: boolean }>({});
    const [delayedExpandedTeamBlock, setDelayedExpandedTeamBlock] = useState<{ [id: number]: boolean }>({});
    const [arrowRotatedTeamBlock, setArrowRotatedTeamBlock] = useState<{ [id: number]: boolean }>({});
    const smartNavigate = useSmartNavigate();

    const handleSortChange = (column: string) => {
        const field = unifiedColumnFieldMapping[column];
        if (sortBy === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortOrder('asc');
        }
    };

    const [perPage] = useState(10);

    useEffect(() => {
        if (isAuthenticated) {
            fetchUsersFromOwnProjects({
                page: currentPage,
                perPage: perPage,
                sortBy: sortBy ?? undefined,
                sortOrder: sortOrder ?? undefined
            });
        } else {
            smartNavigate('/home');
        }
    }, [currentPage, perPage, sortBy, sortOrder, isAuthenticated]);

    if (isFetching) return <Loader/>;
    if (error) return <p>Error: {error}</p>;

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleOpenUserInformationModal = (user: User) => {
        setSelectedUser(user);
        setOpenUserInformation(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const columnsWidthClasses = [
        'width-user-username-team-mobile',
        'width-user-projects-team-mobile',
        'width-user-email-team-mobile',
        'width-user-access-rights-team-mobile',
        'table-content-data-section-field-actions-1btn-team'
    ];

    const toggleProject = (id: number) => {
        if (expandedTeamBlock[id]) {
            setArrowRotatedTeamBlock(prev => ({...prev, [id]: false}));
            setAnimatingTeamBlock(prev => ({...prev, [id]: true}));
            setDelayedExpandedTeamBlock(prev => ({...prev, [id]: false}));

            setTimeout(() => {
                setExpandedTeamBlock(prev => ({...prev, [id]: false}));
                setAnimatingTeamBlock(prev => ({...prev, [id]: false}));
            }, 300);
        } else {
            setExpandedTeamBlock(prev => ({...prev, [id]: true}));
            setArrowRotatedTeamBlock(prev => ({...prev, [id]: true}));

            requestAnimationFrame(() => {
                setDelayedExpandedTeamBlock(prev => ({...prev, [id]: true}));
            });
        }
    };

    const renderUsers = () => {
        if (!Array.isArray(users)) {
            return <p>No users available</p>;
        }

        if (isSmallScreen) {
            return users.map((user) => {
                const userImgCheck =
                    user &&
                    user.galleries &&
                    user.galleries.length > 0 &&
                    user.galleries[user.galleries.length - 1] &&
                    user.galleries[user.galleries.length - 1].medias &&
                    user.galleries[user.galleries.length - 1].medias[
                    user.galleries[user.galleries.length - 1].medias.length - 1
                        ] &&
                    user.galleries[user.galleries.length - 1].medias[
                    user.galleries[user.galleries.length - 1].medias.length - 1
                        ].url;
                const userImg =
                    user?.galleries?.[user.galleries.length - 1]?.medias?.[
                    user.galleries[user.galleries.length - 1].medias.length - 1
                        ]?.url;
                return (
                    <li key={user.id}>
                        <section className="project-list-mobile-section">
                            <div
                                className="project-list-mobile-header"
                                onClick={() => toggleProject(user.id)}
                            >
                                <div className="team-mobile-user-img-block">
                                    {userImgCheck ? (
                                        <img
                                            alt={user.username}
                                            src={userImg}
                                            className='team-user-mobile-image'
                                        />
                                    ) : (
                                        <div className="width-table-img-team icon-circle">
                                            <IconUserDefaultProfile className="custom-avatar-team" width="50"
                                                                    height="50"/>
                                        </div>
                                    )}

                                    <p className="project-list-mobile-text-title">{user.username}</p>
                                </div>
                                <div className="project-list-mobile-header-action">
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenUserInformationModal(user);
                                        }}
                                        title="User Permissions"
                                    >
                                        <IconSettingsDarkVersion/>
                                    </div>
                                    <div
                                        className={`project-list-mobile-arrow ${arrowRotatedTeamBlock[user.id] ? "rotated" : ""}`}
                                    >
                                        <IconArrowDownOrderHistory width={'35'} height={'35'}/>
                                    </div>
                                </div>
                            </div>
                            {(expandedTeamBlock[user.id] || animatingTeamBlock[user.id]) && (
                                <div
                                    className={`project-list-mobile-section-content 
                                ${delayedExpandedTeamBlock[user.id] ? "expanded" : ""} 
                                ${animatingTeamBlock[user.id] ? "animating" : ""}`}
                                >
                                    <div className="user-info-details">
                                        <div className='team-user-info-block'>
                                            <p className='team-user-info-block-text-first'>E-mail</p>
                                            <p className='team-user-info-block-text-second'>
                                                {user.username}
                                            </p>
                                        </div>

                                        <div className='team-user-info-block'>
                                            <p className='team-user-info-block-text-first'>Address</p>
                                            <p className='team-user-info-block-text-second'>
                                                {user?.address}
                                            </p>
                                        </div>
                                        <div className='team-user-info-block'>
                                            <p className='team-user-info-block-text-first'>Project group</p>
                                            <p className='team-user-info-block-text-second'>
                                                {user.projects_count}
                                            </p>
                                        </div>
                                        <div className='team-user-info-block'>
                                            <p className='team-user-info-block-text-first'>Access rights</p>
                                            <p className='team-user-info-block-text-second'>
                                                {user.role.role}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </section>
                    </li>
                );
            });
        } else {
            return users.map((user) => {
                const userImgCheck =
                    user &&
                    user.galleries &&
                    user.galleries.length > 0 &&
                    user.galleries[user.galleries.length - 1] &&
                    user.galleries[user.galleries.length - 1].medias &&
                    user.galleries[user.galleries.length - 1].medias[
                    user.galleries[user.galleries.length - 1].medias.length - 1
                        ] &&
                    user.galleries[user.galleries.length - 1].medias[
                    user.galleries[user.galleries.length - 1].medias.length - 1
                        ].url;
                const userImg =
                    user?.galleries?.[user.galleries.length - 1]?.medias?.[
                    user.galleries[user.galleries.length - 1].medias.length - 1
                        ]?.url;
                return (
                    <li key={user.id}>
                        <section className="table-content-data-section-team">
                            <div className="table-content-data-section-field-image-team width-user-username-team">
                                {userImgCheck ? (
                                    <img
                                        className="width-table-img-team icon-circle"
                                        src={userImg}
                                        alt="User"
                                    />
                                ) : (
                                    <div className="width-table-img-team icon-circle">
                                        <IconUserDefaultProfile className="custom-avatar-team" width="48px"
                                                                height="48px"/>
                                    </div>
                                )}
                                <p className="table-content-data-section-field-image-title-team">
                                    {user.username}
                                </p>
                            </div>
                            <p className="table-content-data-section-field-team width-user-projects-team">
                                {user.projects_count}
                            </p>
                            <p className="table-content-data-section-field-team width-user-email-team">
                                {user.email}
                            </p>
                            <p className="table-content-data-section-field-team width-user-access-rights-team">
                                {user.role.role}
                            </p>
                            <div className="table-content-data-section-field-actions-1btn-team">
                                <button
                                    onClick={() => handleOpenUserInformationModal(user)}
                                    className="team-action-btn-manag"
                                >
                                    <IconSettings/>
                                </button>
                            </div>
                        </section>
                    </li>
                );
            });
        }
    };

    return (
        <>
            <HeaderList/>
            <div className="name-content-team">
                {!isSmallScreen && (
                    <div className="team">
                        <p>Team management</p>
                        <IconTeamManagement/>
                    </div>
                )}

                <button className="mh-btn-member-users" onClick={handleOpenModal}>
                    <span className="add-member-plus">+</span> Add new member
                </button>
            </div>
            {!isSmallScreen && (
                <ElementList
                    columns={['User', 'Projects', 'E-mail', 'Role', 'Action']}
                    columnsWidthClasses={columnsWidthClasses}
                    sortBy={sortBy ?? undefined}
                    sortOrder={sortOrder ?? undefined}
                    onSortChange={handleSortChange}
                    isShow={true}
                />
            )}
            <div className="wrapper">
                <ul className="table-content-data-section-container-team">
                    {renderUsers()}
                </ul>
            </div>
            {totalUsers.total > 1 && (
                <PaginationAssistance
                    projectsPerPage={perPage}
                    totalProjects={totalUsers.total}
                    paginate={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
            <AddMemberModal
                pageTeamManagement={true}
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                onPermissionsUpdated={() => {
                }}
                showProjects={true}
            />
            <ModalUserInformation
                isOpen={openUserInformation}
                onClose={() => setOpenUserInformation(false)}
                user={selectedUser}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    users: state.usersFromOwnProjects.users,
    isFetching: state.usersFromOwnProjects.isFetching,
    error: state.usersFromOwnProjects.error,
    totalUsers: state.usersFromOwnProjects.totalUsers,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    fetchUsersFromOwnProjects,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TeamManagerPage);
