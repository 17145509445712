import { useEffect } from "react";

export const useGoogleAnalytics = () => {
    const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    const IS_PROD = process.env.REACT_APP_IS_PROD === "true";

    useEffect(() => {
        if (IS_PROD && GOOGLE_ANALYTICS_ID) {
            const script = document.createElement("script");
            script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`;
            script.async = true;
            document.head.appendChild(script);

            script.onload = () => {
                window.dataLayer = window.dataLayer || [];

                function gtag(...args: any[]) {
                    window.dataLayer.push(args);
                }

                window.gtag = gtag;

                gtag("js", new Date());
                gtag("config", GOOGLE_ANALYTICS_ID);
            };
        }
    }, []);
};
