import Modal from "react-modal";
import React, {FC, useEffect, useState} from "react";
import './SelectFragmentModal.css';
import {Loader} from "../../../../components/Loader/Loader";
import PaginationInModal from "../../../../components/PaginationAssistance/paginationInModal";
import {Fragment} from "../../../../types/TemplateType";
import {getFragmentStyleByIdWidthPagination} from "../../../../api/APIWraper";
import {Gallery} from "../../../../types/ProjectTypes";
import {
	IconArrowBottomSketched,
	IconArrowTopSketched,
	OpenIcon,
} from "../../../../IconComponents/IconComponents";
import {TQuestion} from "../../../../types/QuestionTypes/TQuestion";

interface SelectFragmentModalProps {
	isOpen: boolean;
	onClose: () => void;
	id: number;
	questions: TQuestion[];
	defaultQueries: string;
	chooseFragmentStyle: (fragmentId: number) => void;
}

interface SelectedQuestion {
	id: number;
	key: string;
	question: string;
	answer: string;
	answers: string[];
	locked: boolean;
}

const SelectFragmentModal: FC<SelectFragmentModalProps> = ({
	isOpen,
    onClose,
	id,
    questions,
    defaultQueries,
    chooseFragmentStyle,
}) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [fragments, setFragments] = useState<Fragment[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [page, setPage] = useState(1);
	const [selectedId, setSelectedId] = useState<number | null>(null);
	const [selectedQuestions, setSelectedQuestions] = useState<SelectedQuestion[]>([]);
	const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(null);
	const [queries, setQueries] = useState<string>(defaultQueries);

	const getImage = (gallery: Gallery | null): string => {
		if (gallery && gallery.medias.length > 0) {
			return gallery.medias[gallery.medias.length - 1].url;
		}
		return '/img_vendor/item.png';
	}

	const clearStates = () => {
		setLoading(true);
		setFragments([]);
		setSelectedQuestionId(null);
		setSelectedQuestions([]);
		setSelectedId(null);
		setPage(1);
		setTotal(0);
	}

	const getSelectedQuestionById = (id: number): SelectedQuestion | undefined => {
		return selectedQuestions.filter((selectedQuestion) => selectedQuestion.id === id).at(0)
	}

	const getSelectedQuestionByKey = (key: string): SelectedQuestion | undefined => {
		return selectedQuestions.filter((selectedQuestion) => selectedQuestion.key === key).at(0)
	}

	const handleSelectedQuestion = (id: number) => {
		if (getSelectedQuestionById(id)?.locked) return;
		if (selectedQuestionId) setSelectedQuestionId(null);
		else setSelectedQuestionId(id);
	}

	const getQuestionsFromData = (data: any) => {
		return Object.keys(data)
			.filter(key => /^\d+$/.test(key))
			.map(key => data[key])
			.filter(item => item.select === "style");
	};

	const confirmSelection = () => {
		if (selectedId) {
			chooseFragmentStyle(selectedId);
		}
		onClose()
	}

	useEffect(() => {
		if (!isOpen) {
			clearStates();
			return;
		}
		const getFragments = async () => {
			try {
				setLoading(true);
				const response = await getFragmentStyleByIdWidthPagination(id, page, 5, queries);
				if(response.status === 200) {
					setFragments(response.data.fragments.data);
					setTotal(response.data.fragments.pagination.lastPage);
				} else {
					console.error('Error when getting minimum price');
				}
			} catch (error) {
				console.error('Error when getting minimum price:', error);
			} finally {
				setLoading(false);
			}
		}
		getFragments();
	}, [isOpen, page, queries]);

	useEffect(() => {
		const newSelectedQuestions: SelectedQuestion[] = [];
		questions.filter((question) => question.select === 'style')
			.filter((question) => (question.answers && question.answers?.length > 0) || false)
			.forEach((question, index) => {
			newSelectedQuestions.push({
				id: index,
				key: question.key,
				question: question.question,
				answer:
					question.required_question === undefined
						? ''
						: (question.required_question
							? ((question.answers && question.answers?.length > 0)
								? question.answers[0].title
								: '')
							: ''),
				answers: question.answers?.map((answer) => answer.title) || [],
				locked: question.required_question || false,
			});
		});
		setSelectedQuestions(newSelectedQuestions);
	}, [isOpen, questions]);

	useEffect(() => {
		let newQueries = defaultQueries;
		selectedQuestions.filter((question) => !question.locked)
		.filter((question) => question.answer)
		.forEach((question) => {
			newQueries += `&queries[${question.key}]=${question?.answer || 0}`;
		});
		setQueries(newQueries);
	}, [selectedQuestions]);

	const getOpenSelectComponent = (answers: string[]) => {
		if (!selectedQuestionId) return null;
		const selectAnswer = (answer: string) => {
			setSelectedQuestions((prevState) =>
				prevState.map((question) =>
					question.id === selectedQuestionId
						? { ...question, answer }
						: question
				)
			);
		}
		return <ul className='open-select-container'>
			{answers.map((answer, i) => (
				<li key={i} className='open-select-content' onClick={() => selectAnswer(answer)}>
					{answer}
				</li>
			))}
		</ul>
	}

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="item-selection-modal-content"
			overlayClassName="item-selection-modal-overlay"
			ariaHideApp={false}
		>
			<section className="item-selection-modal-section">
				<div className='item-selection-modal-header fragment-selection-modal-header'>
					<div className='item-selection-modal-header-block-text'>
						<h2 className="item-selection-modal-title">Select fragment style</h2>
					</div>
					<button className="item-selection-modal-close-button" onClick={onClose}>
						&#x2715;
					</button>
				</div>
				<div className='fragment-selection-modal-search-container'>
					{
						selectedQuestions.map((question) => (
							<div key={question.id}
								 className='fragment-selection-modal-search-block'
								 onClick={() => handleSelectedQuestion(question.id)}
							>
								<p className='fragment-selection-modal-search-text'>
									{question.question}
								</p>
								<div className={`fragment-selection-modal-search ${question.locked && 'fragment-selection-modal-search-locked'}`}>
									{question.answer || 'Select'}
									{selectedQuestionId === question.id ? <IconArrowBottomSketched /> : <IconArrowTopSketched/>}
								</div>
								{selectedQuestionId === question.id
									&& getOpenSelectComponent(question.answers)
								}
							</div>
						))
					}
				</div>
				{loading ? (
					<Loader />
				) : (
					<ul className="item-selection-modal-list">
						{fragments.map((fragment: Fragment) => {
							return (
								<li key={fragment.id}
									className="item-selection-modal-list-item fragment-selection-modal-list-fragment"
									onClick={() => setSelectedId(fragment.id)}
								>
									<section className="item-type-bom-modal-list-item-content">
										<div className="fragment-selection-modal-list-fragment-image">
											<img
												className="fragment-selection-modal-image"
												src={getImage(fragment.galleries[0] || null)}
												alt="Item"
											/>
											<div className="fragment-selection-modal-svg">
												<OpenIcon />
											</div>
										</div>
										<div className='fragment-selection-modal-list-fragment-center'>
											<section className="fragment-selection-modal-list-fragment-value fragment-selection-modal-list-fragment-name">
												<p className="fragment-selection-modal-list-fragment-value-label">
													Name
												</p>
												<p className="fragment-selection-modal-list-fragment-value-value">
													{fragment.title}
												</p>
											</section>
											{
												getQuestionsFromData(fragment.data).map((question) => {
													const selectedQuestion = getSelectedQuestionByKey(question.key)
													if (!selectedQuestion) return null;
														return <section className = "fragment-selection-modal-list-fragment-value" >
															<p className = "fragment-selection-modal-list-fragment-value-label" >
																{selectedQuestion.question}
															</p>
															<p className="fragment-selection-modal-list-fragment-value-value">
																{question.style || question.value}
															</p>
														</section>
												})
											}
										</div>
										<div className="fragment-selection-modal-list-fragment-select-container">
											<div className='fragment-selection-modal-list-fragment-select'>
												{selectedId === fragment.id && <div className="fragment-selection-modal-list-fragment-select-true"/>}
											</div>
										</div>
									</section>
								</li>
							);
						})}
					</ul>

				)}
				<div className='item-selection-modal-save-button-block'>
					<div>
						<PaginationInModal
							perPage={5}
							total={total}
							paginate={(page) => setPage(page)}
							currentPage={page}
						/>
					</div>
					<button onClick={confirmSelection} className="item-selection-modal-save-button">
						Confirm selection
					</button>
				</div>
			</section>
		</Modal>
	)
}
export default SelectFragmentModal;