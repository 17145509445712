// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.revit-container{
    width: 970px;
    height: 515px;
    background-color: gray;
    transition: all 0.5s ease;
}

.revit-container.wizard{
    width: 1560px;
    height: 515px;
    background-color: gray;
    margin-left: 25px;
    transition: all 0.5s ease;
}
#forgeViewer-wizard{
    max-width: 1560px !important;
    max-height: 500px !important;
    transition: all 0.5s ease;
}

#forgeViewer {
    max-width: 1000px !important;
    max-height: 500px !important;
    transition: all 0.5s ease;
    border-radius: 10px;
}
.adsk-viewing-viewer{
    max-width: 970px !important;
    max-height: 500px !important;
    border-radius: 8px;
    border: #D9D9D9 2px solid;
    transition: all 0.5s ease;
}

.revit-container.wizard .adsk-viewing-viewer {
    max-width: 1560px !important;
    max-height: 500px !important;
    transition: all 0.5s ease;
}

.adsk-viewing-viewer .adsk-button{
    border: none;
    margin: 0;
}

.adsk-viewing-viewer .adsk-toolbar .adsk-button>.toolbar-vertical-group{
    margin: 0 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Page/ArchitectPage/ArhitectStyles/RevitComponent.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB;AAC7B;AACA;IACI,4BAA4B;IAC5B,4BAA4B;IAC5B,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,4BAA4B;IAC5B,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,2BAA2B;IAC3B,4BAA4B;IAC5B,kBAAkB;IAClB,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,4BAA4B;IAC5B,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".revit-container{\n    width: 970px;\n    height: 515px;\n    background-color: gray;\n    transition: all 0.5s ease;\n}\n\n.revit-container.wizard{\n    width: 1560px;\n    height: 515px;\n    background-color: gray;\n    margin-left: 25px;\n    transition: all 0.5s ease;\n}\n#forgeViewer-wizard{\n    max-width: 1560px !important;\n    max-height: 500px !important;\n    transition: all 0.5s ease;\n}\n\n#forgeViewer {\n    max-width: 1000px !important;\n    max-height: 500px !important;\n    transition: all 0.5s ease;\n    border-radius: 10px;\n}\n.adsk-viewing-viewer{\n    max-width: 970px !important;\n    max-height: 500px !important;\n    border-radius: 8px;\n    border: #D9D9D9 2px solid;\n    transition: all 0.5s ease;\n}\n\n.revit-container.wizard .adsk-viewing-viewer {\n    max-width: 1560px !important;\n    max-height: 500px !important;\n    transition: all 0.5s ease;\n}\n\n.adsk-viewing-viewer .adsk-button{\n    border: none;\n    margin: 0;\n}\n\n.adsk-viewing-viewer .adsk-toolbar .adsk-button>.toolbar-vertical-group{\n    margin: 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
