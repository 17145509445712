import React, { useRef, useEffect, ReactNode } from 'react';

interface CollapsibleBlockProps {
    title: string;
    children: ReactNode;
    onToggle: (relate: string, isOpen: boolean) => void;
    relate: string;
    isOpen: boolean;
}

const CollapsibleBlock: React.FC<CollapsibleBlockProps> = ({ title, children, onToggle, relate, isOpen }) => {
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = isOpen ? "600px" : "0px";
        }
    }, [isOpen]);

    const handleClick = () => {
        onToggle(relate, !isOpen);
    };

    return (
        <div className="right-block-revit-objects">
            <div
                className={`header-right-revit-objects ${isOpen ? 'active' : ''}`}
                onClick={handleClick}
            >
                <span>{title}</span>
                <button className={`toggle-btn-revit-objects ${isOpen ? 'open active' : ''}`} />
            </div>

            <div ref={contentRef} className="collapsible-revit-objects">
                <div className="content-revit-objects">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CollapsibleBlock;
