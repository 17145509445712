import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store';
import { setSelectedLabor, clearSelectedItems } from '../../../Slice/SelectedItems/SelectedItemsSlice';
import '../Step2Styles/projectUpdateItems.css';
import {Vendor, vendorData} from "../../../Enums/VendorEnum";
import { IconArrowLightStpe2 } from "../../../IconComponents/IconComponents";
import {
    notifyInfoOrderTemplate,
    notifySuccessOrderTemplate
} from "../../../components/Toast/AssisCreateToastNotification";
import ILaborItemType from "../../../Interfaces/ProjectInterfaces/IlaborItemType";
import {TItem} from "../../../types/TemplateType";

interface LaborItemTypeListProps {
    laborItemTypes: ILaborItemType[];
}

const LaborItemTypeList: React.FC<LaborItemTypeListProps & PropsFromRedux> = ({
                                                                                  laborItemTypes,
                                                                                  setSelectedLabor
                                                                              }) => {
    const [selectedLaborItems, setSelectedLaborItemsState] = useState<{ [key: string]: boolean }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});
    const [isButtonDisabledOrderFragment, setIsButtonDisabledOrderFragment] = useState(false);

    const toggleVisibility = (itemTypeKey: string) => {
        setVisibilityMap((prevMap) => ({
            ...prevMap,
            [itemTypeKey]: !prevMap[itemTypeKey],
        }));
    };

    const isVisible = (itemTypeKey: string) => {
        return visibilityMap[itemTypeKey] || false;
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const generateLaborKey = (itemType: ILaborItemType) => {
        return `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;
    };

    const generateLaborKeyVisible = (itemType: ILaborItemType, idx: number) => {
        return `${itemType.item_type.id}-${idx}-${itemType.clone_id}-${itemType.item_type.title}`;
    };

    useEffect(() => {
        syncSelectionsWithLocalStorage(laborItemTypes);
    }, [laborItemTypes]);

    useEffect(() => {
        const calculatedPrice = calculateTotalPrice(laborItemTypes, selectedLaborItems);
        setTotalPrice(calculatedPrice);
        setSelectedLabor(Date.now());
    }, [selectedLaborItems, laborItemTypes, setSelectedLabor]);

    const syncSelectionsWithLocalStorage = (items: ILaborItemType[]) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const updatedLaborSelections: { [key: string]: boolean } = {};

        items.forEach(item => {
            const fragmentKey = `${item.fragment_id}-${item.fragment_index}-${item.template_index}`;
            const laborKey = generateLaborKey(item);

            if (storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[laborKey]) {
                updatedLaborSelections[laborKey] = true;
            }
        });

        setSelectedLaborItemsState(updatedLaborSelections);
    };

    const calculateTotalPrice = (items: ILaborItemType[], selected: { [key: string]: boolean }) => {
        let totalPrice = 0;

        const groupedItems: { [key: number]: ILaborItemType[] } = {};
        items.forEach(item => {
            if (!groupedItems[item.item_type.id]) {
                groupedItems[item.item_type.id] = [];
            }
            groupedItems[item.item_type.id].push(item);
        });

        Object.values(groupedItems).forEach(group => {
            const laborKey = generateLaborKey(group[0]);

            if (selected[laborKey]) {
                let totalQty = 0;
                let totalOrderedQty = 0;

                group.forEach(item => {
                    item.item_type.items?.forEach((laborItem:TItem) => {
                        totalQty += laborItem.qty || 0;
                        totalOrderedQty += laborItem.ordered_qty || 0;
                    });
                });

                const price = parseFloat(String(group[0].item_type.items?.[0]?.price)) || 0;
                const difTotalQty = totalQty - totalOrderedQty;
                const priceTotal = difTotalQty > 0 ? price * difTotalQty : 0;
                totalPrice += priceTotal;
            }
        });

        return totalPrice;
    };

    const handleCheckboxChange = (itemType: ILaborItemType) => {
        const laborKey = generateLaborKey(itemType);
        const isSelected = !selectedLaborItems[laborKey];
        const updatedSelections = { ...selectedLaborItems, [laborKey]: isSelected };

        setSelectedLaborItemsState(updatedSelections);

        if (isSelected) {
            laborItemTypes.forEach(originalItem => {
                if (originalItem.item_type.id === itemType.item_type.id &&
                    originalItem.fragment_id === itemType.fragment_id &&
                    originalItem.fragment_index === itemType.fragment_index) {
                    mergeAndSaveLocalStorage(originalItem);
                }
            });
        } else {
            laborItemTypes.forEach(originalItem => {
                if (originalItem.item_type.id === itemType.item_type.id &&
                    originalItem.fragment_id === itemType.fragment_id &&
                    originalItem.fragment_index === itemType.fragment_index) {
                    removeFromLocalStorage(originalItem);
                }
            });
        }

        const calculatedPrice = calculateTotalPrice(laborItemTypes, updatedSelections);
        setTotalPrice(calculatedPrice);
        setSelectedLabor(Date.now());
    };

    const mergeAndSaveLocalStorage = (itemType: ILaborItemType) => {
        const storedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
        const laborKey = generateLaborKey(itemType);

        if (!storedFragments[fragmentKey]) {
            storedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
        }

        const laborItems = storedFragments[fragmentKey].LaborItemTypes || {};
        if (!laborItems[laborKey]) {
            laborItems[laborKey] = {
                ...itemType.item_type,
                items: itemType.item_type.items?.map((item: any) => ({
                    ...item,
                    payload: generatePayload(itemType),
                })),
                payload: generatePayload(itemType),
            };
        } else {
            itemType.item_type.items?.forEach(item => {
                if (!laborItems[laborKey].items.some((i: any) => i.id === item.id)) {
                    laborItems[laborKey].items.push({
                        ...item,
                        payload: generatePayload(itemType),
                    });
                }
            });
        }

        storedFragments[fragmentKey].LaborItemTypes = laborItems;

        localStorage.setItem('selectedFragments', JSON.stringify(storedFragments));
    };

    const removeFromLocalStorage = (itemType: ILaborItemType) => {
        const storedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
        const laborKey = generateLaborKey(itemType);

        if (storedFragments[fragmentKey]?.LaborItemTypes) {
            delete storedFragments[fragmentKey].LaborItemTypes[laborKey];

            if (Object.keys(storedFragments[fragmentKey].LaborItemTypes).length === 0) {
                delete storedFragments[fragmentKey].LaborItemTypes;
            }
        }

        localStorage.setItem('selectedFragments', JSON.stringify(storedFragments));
    };

    const generatePayload = (itemType: ILaborItemType): any => ({
        template_index: itemType.template_index,
        fragment_index: itemType.fragment_index,
        item_type_index: itemType.item_type_index,
        template_id: itemType.template_id,
        fragment_id: itemType.fragment_id,
        item_type_id: itemType.item_type.id,
        item_ids: itemType.item_type.items?.map(i => i.id) || [],
    });

    const calculateTotalQty = (id: number) => {
        return laborItemTypes
            .filter(type => type.item_type.id === id)
            .reduce((total, current) => total + (current.qty || 0), 0);
    };

    const handleOrderFragment = () => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        let updatedSelections = { ...selectedLaborItems };

        const isAllSelected = laborItemTypes.every(itemType => {
            const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
            const laborKey = generateLaborKey(itemType);
            return storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[laborKey];
        });

        if (isAllSelected) {
            laborItemTypes.forEach(itemType => {
                const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
                const laborKey = generateLaborKey(itemType);

                if (storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[laborKey]) {
                    delete storedSelectedFragments[fragmentKey].LaborItemTypes[laborKey];
                }

                updatedSelections[laborKey] = false;
            });

            notifyInfoOrderTemplate('Labors removed from cart');
        } else {
            laborItemTypes.forEach(itemType => {
                const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
                const laborKey = generateLaborKey(itemType);

                if (!storedSelectedFragments[fragmentKey]) {
                    storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
                }

                if (!storedSelectedFragments[fragmentKey].LaborItemTypes[laborKey]) {
                    storedSelectedFragments[fragmentKey].LaborItemTypes[laborKey] = {
                        ...itemType.item_type,
                        items: itemType.item_type.items?.map((item: TItem) => ({
                            ...item,
                            payload: generatePayload(itemType),
                        })),
                        payload: generatePayload(itemType),
                    };
                    updatedSelections[laborKey] = true;
                }
            });

            notifySuccessOrderTemplate('Labors added to cart');
        }

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));

        setSelectedLaborItemsState(updatedSelections);
        setSelectedLabor(Date.now());

        const calculatedPrice = calculateTotalPrice(laborItemTypes, updatedSelections);
        setTotalPrice(calculatedPrice);
    };


    useEffect(() => {
        const checkIfButtonShouldBeDisabled = () => {
            const hasItemsInFragments =   laborItemTypes.some((itemType) => itemType.item_type.items && itemType.item_type.items.length > 0)
            setIsButtonDisabledOrderFragment(!hasItemsInFragments);
        };
        checkIfButtonShouldBeDisabled();
    }, [laborItemTypes]);

    if (laborItemTypes.length === 0) {
        return <div className="step2-div-no-materials">No labor items</div>;
    }

    const displayedItemTypes = new Set<number>();

    return (
        <div>
            {laborItemTypes.map((itemType, itemIndex) => {
                const laborKey = generateLaborKey(itemType);
                const laborKeyVisible = generateLaborKeyVisible(itemType, itemIndex);
                const title = itemType.item_type.title;

                if (displayedItemTypes.has(itemType.item_type.id)) {
                    return null;
                }

                displayedItemTypes.add(itemType.item_type.id);

                const hasNonEmptyItems = laborItemTypes.some(
                    (type) => type.item_type.id === itemType.item_type.id && type.item_type.items && type.item_type.items.length > 0
                );

                if (!hasNonEmptyItems) {
                    const totalQty = calculateTotalQty(itemType.item_type.id);

                    return (
                        !isSmallScreen ? (
                            <div key={laborKey}>
                                <p className="item-type-list-label">{title}</p>
                                <div className="step2-item-type-list ">
                                    <div className='step2-material-width-list'>
                                        <label style={{color: "#828282"}}>{title}</label>
                                    </div>
                                    <p className="step2-other-width-list">0</p>
                                    <p className="step2-other-width-list">{totalQty}<span
                                        className='labor-hours'>(each)</span></p>
                                    <p className="step2-other-width-list">R 0.00</p>
                                    <p className="step2-other-width-list">R 0.00</p>
                                    <p className="step2-other-width-list-total">R 0.00</p>
                                    <div className='step2-other-width-vendor-list'></div>
                                </div>
                            </div>
                        ) : (
                            <div key={laborKey} className={`step2-item-type-list`}>
                                <div
                                    className={`step2-item-checkbox-block ${isVisible(laborKeyVisible) ? 'visible' : 'hidden'}`}>
                                    <label style={{ color: "#828282" }}>{title}</label>
                                    <div onClick={() => toggleVisibility(laborKeyVisible)}
                                         className={isVisible(laborKeyVisible) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                        <IconArrowLightStpe2 />
                                    </div>
                                </div>

                                <div
                                    className={`step2-content-list ${isVisible(laborKeyVisible) ? 'visible' : 'hidden'}`}>
                                    <p className="step2-other-width-list">
                                        <span>Calculated QTY:</span>
                                        <span className="dashed-line"></span>0
                                    </p>
                                    <p className="step2-other-width-list">
                                        <span>QTY ordered:</span>
                                        <span className="dashed-line"></span>{itemType.qty}<span
                                        className='labor-hours'>(each)</span>
                                    </p>
                                    <p className="step2-other-width-list">
                                        <span>Unit price:</span>
                                        <span className="dashed-line"></span>R 0.00
                                    </p>
                                    <p className="step2-other-width-list">
                                        <span>Total:</span>
                                        <span className="dashed-line"></span>R 0.00
                                    </p>
                                    <p className="step2-other-width-list-total total-ordred-bold">
                                        <span>Total remaining:</span>
                                        <span className="dashed-line"></span>R 0.00
                                    </p>
                                </div>
                            </div>
                        )
                    );
                }

                if (itemType.item_type.items && itemType.item_type.items.length > 0) {
                    let totalQty = 0;
                    let totalOrderedQty = 0;
                    laborItemTypes
                        .filter(type => type.item_type.id === itemType.item_type.id)
                        .forEach(type => {
                            type.item_type.items?.forEach(laborItem => {
                                totalQty += laborItem.qty || 0;
                                totalOrderedQty += laborItem.ordered_qty || 0;
                            });
                        });
                    const unit = itemType.item_type.items[0].unit || '';
                    const price = parseFloat(String(itemType.item_type.items[0].price)) || 0;
                    const checkTotalQty = !isNaN(totalQty) ? Math.max(totalQty, 0) : 0;
                    const checkTotalOrderedQty = !isNaN(totalOrderedQty) ? Math.max(totalOrderedQty, 0) : 0;
                    const totalPriceOrdered = price * checkTotalOrderedQty;
                    const totalPriceRemaining = price * (checkTotalQty - checkTotalOrderedQty);
                    const safeTotalPriceRemaining = Math.max(totalPriceRemaining, 0);

                    return (
                        !isSmallScreen ? (
                            <div key={laborKey} className='step2-item-type-list'>
                                <div className="step2-material-width-list">
                                    <div className="step2-item-checkbox-block">
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${laborKey}`}
                                            checked={selectedLaborItems[laborKey]}
                                            onChange={() => handleCheckboxChange(itemType)}
                                        />
                                        <label
                                            htmlFor={`checkbox-${laborKey}`}>{itemType.item_type.items[0].title}</label>
                                    </div>
                                </div>
                                <p className="step2-other-width-list">{checkTotalOrderedQty} ({unit})</p>
                                <p className="step2-other-width-list">{checkTotalQty} <span
                                    className='labor-hours'>({unit})</span></p>
                                <p className="step2-other-width-list">R {price.toFixed(2)}</p>
                                <p className="step2-other-width-list">R {totalPriceOrdered.toFixed(2)}</p>
                                <p className="step2-other-width-list-total">R {safeTotalPriceRemaining.toFixed(2)}</p>
                                <div className='step2-other-width-vendor-list'>
                                    {itemType.item_type.items[0].vendor !== null && vendorData[itemType.item_type.items[0].vendor as Vendor] ? (
                                        <p className='item-selection-modal-vendor'>{vendorData[itemType.item_type.items[0].vendor as Vendor].displayName}</p>
                                    ) : (
                                        <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div key={laborKey} className='step2-item-type-list'>
                                <div className="step2-material-width-list">
                                    <div
                                        className={`step2-item-checkbox-block ${isVisible(laborKeyVisible) ? 'visible' : 'hidden'}`}>
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${laborKey}`}
                                            checked={selectedLaborItems[laborKey]}
                                            onChange={() => handleCheckboxChange(itemType)}
                                        />
                                        <label htmlFor={`checkbox-${laborKey}`}>
                                            {itemType.item_type.items[0].title}
                                        </label>
                                        <div onClick={() => toggleVisibility(laborKeyVisible)}
                                             className={isVisible(laborKeyVisible) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                            <IconArrowLightStpe2 />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`step2-content-list ${isVisible(laborKeyVisible) ? 'visible' : 'hidden'}`}>

                                    <p className="step2-other-width-list">
                                        <span>Calculated QTY:</span>
                                        {isSmallScreen && <span className='dashed-line'></span>}
                                        {checkTotalOrderedQty} ({unit})
                                    </p>

                                    <p className="step2-other-width-list">
                                        <span>QTY ordered:</span>
                                        {isSmallScreen && <span className='dashed-line'></span>}
                                        {checkTotalQty} <span className='labor-hours'>({unit})</span>
                                    </p>

                                    <p className="step2-other-width-list">
                                        <span>Unit price:</span>
                                        {isSmallScreen && <span className='dashed-line'></span>}
                                        R {price.toFixed(2)}
                                    </p>

                                    <p className="step2-other-width-list">
                                        <span>Total:</span>
                                        {isSmallScreen && <span className='dashed-line'></span>}
                                        R {totalPriceOrdered.toFixed(2)}
                                    </p>

                                    <p className="step2-other-width-list-total">
                                        <span>Total remaining:</span>
                                        {isSmallScreen && <span className='dashed-line'></span>}
                                        R {safeTotalPriceRemaining.toFixed(2)}
                                    </p>

                                    <div className='step2-other-width-vendor-list'>
                                        {itemType.item_type.items[0].vendor !== null && vendorData[itemType.item_type.items[0].vendor as Vendor] ? (
                                            <p className='item-selection-modal-vendor'>{vendorData[itemType.item_type.items[0].vendor as Vendor].displayName}</p>
                                        ) : (
                                            <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    );
                }
                return null;
            })}

            <div className='step2-bottom-block'>
                {!isSmallScreen ? (
                    <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button
                            onClick={handleOrderFragment}
                            className='step2-bottom-block-btn-cart-list'
                            style={{
                                backgroundColor: isButtonDisabledOrderFragment ? '#cccccc' : '',
                                cursor: isButtonDisabledOrderFragment ? 'not-allowed' : 'pointer'
                            }}
                        >
                            Add products to cart
                        </button>
                    </>
                ) : (
                    <>
                        <div className='step2-bottom-block-mobile'>
                            <p>Total: </p>
                            <span className="dashed-line"></span>
                            {totalPrice ? (
                                <span className='mobile-total-price-red'> R {totalPrice.toFixed(2)}</span>
                            ) : (
                                <span className='mobile-total-price-red'> R 0</span>
                            )}
                        </div>
                        <button
                            onClick={handleOrderFragment}
                            className='step2-bottom-block-btn-cart-list'
                            style={{
                                backgroundColor: isButtonDisabledOrderFragment ? '#cccccc' : '',
                                cursor: isButtonDisabledOrderFragment ? 'not-allowed' : 'pointer'
                            }}>
                            Add products to cart
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    oldProject: state.projectById.oldProject,
});

const mapDispatchToProps = {
    setSelectedLabor,
    clearSelectedItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LaborItemTypeList);
