import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from "react-redux";
import {ParsedFragment, ParsedItemType, TItem} from "../../../types/TemplateType";
import {RootState} from "../../../store";
import "../Step2Styles/projectUpdateItems.css";
import "../Step2Styles/ProductLists.css";

import {
    setSelectedItemsTotal,
    setSelectedLaborTotal,
    setSelectedToolsTotal
} from "../../../Slice/SelectedItems/SelectedItemsSlice";
import {Vendor, vendorData} from "../../../Enums/VendorEnum";
import { IconEyesStep3} from "../../../IconComponents/IconComponents";
import {
    notifySuccessCustom, notifyWarningCustom
} from "../../../components/Toast/AssisCreateToastNotification";
import {formatNumber} from "../../../utils/FormatNumber";
import {generateItemKey, parseItemKey, useErrorMessages, useVisitedItems} from "../../../Hooks/Step2Customer/Step2Hooks";

interface ItemTypeWithIndex extends ParsedItemType {
    originalIndex: number;
    clone_id?: number;
}

interface ItemTypeListProps {
    fragment: ParsedFragment;
    fragmentIndex: number;
    templateIndex: number;
    templateId: number;
    viewMode: 'Work Stage' | 'Materials|Labor|Tools';
    toggleActive: boolean;
    orderAllTrigger: number | null;
    setOrderAllTrigger: (value: number | null) => void;
    setSelectedItemsState: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    setSelectedLaborItemsState: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    setSelectedToolItemsState: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    selectedItems: { [key: string]: boolean };
    selectedLaborItems: { [key: string]: boolean };
    selectedToolItems: { [key: string]: boolean };
    orderAllBooleanTrigger:boolean;
    isTriggeredByParent:boolean
}


const ItemTypeList: React.FC<ItemTypeListProps & PropsFromRedux> = ({
                                                                        fragment,
                                                                        fragmentIndex,
                                                                        templateIndex,
                                                                        templateId,
                                                                        viewMode,
                                                                        setSelectedItemsTotal,
                                                                        setSelectedToolsTotal,
                                                                        setSelectedLaborTotal,
                                                                        orderAllTrigger,
                                                                        setOrderAllTrigger,
                                                                        setSelectedItemsState,
                                                                        setSelectedLaborItemsState,
                                                                        setSelectedToolItemsState,
                                                                        selectedItems,
                                                                        selectedLaborItems,
                                                                        selectedToolItems,
                                                                        orderAllBooleanTrigger,
                                                                        isTriggeredByParent
                                                                    }) => {

    const { handleLogoClick, isVisited } = useVisitedItems();
    const { errorMessages, setError, clearError } = useErrorMessages();


    const fragmentKey = `${fragment.id}-${fragmentIndex}-${templateIndex}`;
    const generateItemTypeKey = (itemType: ItemTypeWithIndex) => {
        return `${itemType.id}-${itemType.clone_id || itemType.originalIndex}-${fragmentIndex}`;
    };
    const generateItemTypeKeyVisible = (itemType: ItemTypeWithIndex, idx: number) => {
        if (itemType.items && itemType.items.length > 0) {
            return `${itemType.id}-${idx}-${itemType.items.map((item, itemIdx) => `${item.id}-${item.title}-${itemIdx}`).join('-')}`;
        }
        return `${itemType.id}-${itemType.originalIndex}-${fragmentIndex}-${idx}`;
    };

    const generateItemKeyVisible = (item: TItem, idx: number) => {
        return `${item.id}-${item.clone_id}-${fragmentIndex}-${idx}`;
    };


    const generateUniqueKey = (itemType: ItemTypeWithIndex, itemIndex: number, item: any) => {
        return `${itemType.id}-${itemType.clone_id || itemType.originalIndex}-${itemIndex}`;
    };


    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isButtonDisabledOrderFragment, setIsButtonDisabledOrderFragment] = useState(false);

    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<{ [id: string]: string }>({});

    const handleOrderFragment = (fragmentIndex: number, fromParent: boolean = false) => {
        setOrderAllTrigger(null);
        if (isButtonDisabledOrderFragment) return;

        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const fragmentKey = `${fragment.id}-${fragmentIndex}-${templateIndex}`;

        const validItemTypes = fragment.item_types
            .map((itemType, i) => ({ itemType, originalIndex: i }))
            .filter(({ itemType, originalIndex }) =>
                itemType.qty > 0 &&
                itemType.items && itemType.items.length > 0 &&
                debouncedOrderedQty[originalIndex] &&
                Number(debouncedOrderedQty[originalIndex]) > 0
            );

        if (validItemTypes.length === 0) {
            if (orderAllTrigger === null) {
                notifyWarningCustom('You need to enter a value in the “QTY to order” input field');
            }
            return;
        }

        const missingItemTypes = validItemTypes.filter(({ itemType, originalIndex }) => {
            const itemKey = `${itemType.id}-${originalIndex}-${fragmentIndex}`;
            return !(
                storedSelectedFragments[fragmentKey]?.items?.[itemKey] ||
                storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[itemKey] ||
                storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[itemKey]
            );
        });

        if (missingItemTypes.length === 0) {
            if (!fromParent) {
                validItemTypes.forEach(({ itemType, originalIndex }) => {
                    const itemKey = `${itemType.id}-${originalIndex}-${fragmentIndex}`;
                    if (storedSelectedFragments[fragmentKey]?.items) {
                        delete storedSelectedFragments[fragmentKey].items[itemKey];
                    }
                    if (storedSelectedFragments[fragmentKey]?.LaborItemTypes) {
                        delete storedSelectedFragments[fragmentKey].LaborItemTypes[itemKey];
                    }
                    if (storedSelectedFragments[fragmentKey]?.ToolsItemTypes) {
                        delete storedSelectedFragments[fragmentKey].ToolsItemTypes[itemKey];
                    }
                });

                setSelectedItemsTotal(Date.now());
                setSelectedLaborTotal(Date.now());
                setSelectedToolsTotal(Date.now());

                notifyWarningCustom('Products removed from cart');
            }
        } else {
            missingItemTypes.forEach(({ itemType, originalIndex }) => {
                const userInput = debouncedOrderedQty[originalIndex];
                if ((!userInput || Number(userInput) <= 0) && orderAllTrigger === null) {
                    notifyWarningCustom('You need to enter a value in the “QTY to order” input field');
                    return;
                }
                const itemKey = `${itemType.id}-${originalIndex}-${fragmentIndex}`;

                if (!storedSelectedFragments[fragmentKey]) {
                    storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
                }

                const addItemType = (category: string) => {
                    if (!storedSelectedFragments[fragmentKey][category]) {
                        storedSelectedFragments[fragmentKey][category] = {};
                    }
                    storedSelectedFragments[fragmentKey][category][itemKey] = {
                        id: itemType.id,
                        qty: itemType.qty,
                        tool: category === 'ToolsItemTypes',
                        labor: category === 'LaborItemTypes',
                        item_type_title: itemType.title,
                        payload: {
                            template_index: templateIndex,
                            fragment_index: fragmentIndex,
                            item_type_index: originalIndex,
                            template_id: templateId,
                            fragment_id: fragment.id,
                            item_type_id: itemType.id,
                            item_ids: itemType.items ? itemType.items.map(i => i.id) : [],
                        },
                        items: itemType.items.map(i => ({
                            id: i.id,
                            qty: i.qty,
                            unit: i.unit,
                            price: i.price,
                            title: i.title,
                            packaging: i.packaging,
                            ordered_qty: i.ordered_qty,
                            vendor: i.vendor,
                            payload: {
                                template_index: templateIndex,
                                fragment_index: fragmentIndex,
                                item_type_index: originalIndex,
                                template_id: templateId,
                                fragment_id: fragment.id,
                                item_type_id: itemType.id,
                                item_ids: i.id ? [i.id] : [],
                            },
                            user_buy: Number(userInput)
                        }))
                    };
                };

                if (itemType.labor) {
                    addItemType('LaborItemTypes');
                } else if (itemType.tool) {
                    addItemType('ToolsItemTypes');
                } else {
                    addItemType('items');
                }
            });

            setSelectedItemsTotal(Date.now());
            setSelectedLaborTotal(Date.now());
            setSelectedToolsTotal(Date.now());

            notifySuccessCustom('Products added to cart');
        }

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
        syncSelectionsWithLocalStorage();
    };



    useEffect(() => {
        if (orderAllTrigger !== null){
            handleOrderFragment(fragmentIndex, isTriggeredByParent);
        }
    }, [orderAllTrigger]);


    useEffect(() => {
        setDebouncedOrderedQty((prev) => {
            const newState = { ...prev };
            fragment.item_types.forEach((itemType, index) => {
                if (itemType.labor || itemType.tool) {
                    newState[index] = "";
                }
            });
            return newState;
        });
    }, [viewMode, fragment.item_types]);

    useEffect(() => {
        const checkIfButtonShouldBeDisabled = () => {
            const hasItemsInFragments = fragment.item_types.some((itemType) => itemType.items && itemType.items.length > 0)
            setIsButtonDisabledOrderFragment(!hasItemsInFragments);
        };
        checkIfButtonShouldBeDisabled();
    }, [fragment, viewMode]);

    useEffect(() => {
        syncSelectionsWithLocalStorage();
    }, [fragment, fragmentIndex, templateIndex, viewMode]);

    useEffect(() => {
        getTotalPrice();
    }, [fragment, viewMode]);



    const syncSelectionsWithLocalStorage = () => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const initialSelectedItems = storedSelectedFragments[fragmentKey]?.items || {};
        const laborSelectedItems = storedSelectedFragments[fragmentKey]?.LaborItemTypes || {};
        const toolsSelectedItems = storedSelectedFragments[fragmentKey]?.ToolsItemTypes || {};

        const updatedItemsSelections: { [key: string]: boolean } = {};
        const updatedLaborSelections: { [key: string]: boolean } = {};
        const updatedToolSelections: { [key: string]: boolean } = {};

        fragment.item_types.forEach((itemType, i) => {
            const itemTypeKey = generateItemTypeKey({...itemType, originalIndex: i});

            if (initialSelectedItems[itemTypeKey]) {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedItemsSelections[key] = initialSelectedItems[itemTypeKey].items?.some((it: {
                        id: number;
                    }) => it.id === itemType.id) || false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedItemsSelections[key] = initialSelectedItems[itemTypeKey].items?.some((it: {
                            id: number;
                        }) => it.id === item.id) || false;
                    });
                }
            } else {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedItemsSelections[key] = false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedItemsSelections[key] = false;
                    });
                }
            }

            if (laborSelectedItems[itemTypeKey]) {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedLaborSelections[key] = laborSelectedItems[itemTypeKey].items?.some((it: {
                        id: number;
                    }) => it.id === itemType.id) || false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedLaborSelections[key] = laborSelectedItems[itemTypeKey].items?.some((it: {
                            id: number;
                        }) => it.id === item.id) || false;
                    });
                }
            } else {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedLaborSelections[key] = false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedLaborSelections[key] = false;
                    });
                }
            }

            if (toolsSelectedItems[itemTypeKey]) {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedToolSelections[key] = toolsSelectedItems[itemTypeKey].items?.some((it: {
                        id: number;
                    }) => it.id === itemType.id) || false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedToolSelections[key] = toolsSelectedItems[itemTypeKey].items?.some((it: {
                            id: number;
                        }) => it.id === item.id) || false;
                    });
                }
            } else {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({...itemType, originalIndex: i}, i, itemType);
                    updatedToolSelections[key] = false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({...itemType, originalIndex: i}, index, item);
                        updatedToolSelections[key] = false;
                    });
                }
            }
        });

        setSelectedItemsState(updatedItemsSelections);
        setSelectedLaborItemsState(updatedLaborSelections);
        setSelectedToolItemsState(updatedToolSelections);
        getTotalPrice();

    };

    const getTotalPrice = () => {
        let totalPrice = 0;

        fragment.item_types.forEach((itemType, index) => {
            if (itemType.qty === 0) return;
            if (itemType.labor || itemType.tool) {
                if (viewMode === 'Materials|Labor|Tools') return;
            }

            if (itemType.items && itemType.items.length > 0) {
                itemType.items.forEach((item) => {
                    const price = parseFloat(String(item.price)) || 0;
                    const priceTotalQty = price * item.qty;
                    const difTotalQty = item.ordered_qty
                        ? Math.max(0, priceTotalQty - (item.ordered_qty * price))
                        : priceTotalQty;
                    totalPrice += difTotalQty;
                });
            }
        });

        setTotalPrice(totalPrice);
    };


    const handleCheckboxChange = (index: number, itemIndex: number, itemType: ItemTypeWithIndex) => {
        const userInput = debouncedOrderedQty[itemType.originalIndex];

        if (!userInput || Number(userInput) <= 0) {
            notifyWarningCustom("You need to enter a value in the “QTY to order” input field");
            return;
        }

        const item = itemType.items ? itemType.items[itemIndex] : itemType;
        const key = generateUniqueKey(itemType, itemIndex, item);

        const isSelected = !selectedItems[key];
        const isLaborSelected = !selectedLaborItems[key];
        const isToolSelected = !selectedToolItems[key];

        const updatedSelections = { ...selectedItems, [key]: isSelected };
        const updatedLaborSelections = { ...selectedLaborItems, [key]: isLaborSelected };
        const updatedToolSelections = { ...selectedToolItems, [key]: isToolSelected };

        if (itemType.labor) {
            setSelectedLaborItemsState(updatedLaborSelections);
            setSelectedLaborTotal(Date.now());
        } else if (itemType.tool) {
            setSelectedToolItemsState(updatedToolSelections);
            setSelectedToolsTotal(Date.now());
        } else {
            setSelectedItemsState(updatedSelections);
            setSelectedItemsTotal(Date.now());
        }

        updateLocalStorage(itemType, item, isSelected, isLaborSelected, isToolSelected);
        getTotalPrice();
    };

    const updateLocalStorage = (itemType: ItemTypeWithIndex, item: any, isSelected: boolean, isLaborSelected: boolean, isToolSelected: boolean) => {
        const storedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        if (!storedFragments[fragmentKey]) {
            storedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
        }

        const itemTypeKey = generateItemTypeKey(itemType);

        const fragmentItems = storedFragments[fragmentKey].items || {};
        const laborItemTypes = storedFragments[fragmentKey].LaborItemTypes || {};
        const toolsItemTypes = storedFragments[fragmentKey].ToolsItemTypes || {};

        const userBuyValue = Number(debouncedOrderedQty[itemType.originalIndex]);

        const addItemToLocalStorage = (storage: any, item: any) => {
            if (!storage[itemTypeKey]) {
                storage[itemTypeKey] = {
                    ...itemType,
                    items: [{
                        ...item,
                        payload: generatePayload(itemType, [item.id]),
                        user_buy: userBuyValue
                    }],
                    payload: generatePayload(itemType, itemType.items ? [item.id] : []),
                    user_buy: userBuyValue
                };
            } else if (itemType.items) {
                if (!storage[itemTypeKey].items) {
                    storage[itemTypeKey].items = [];
                }
                storage[itemTypeKey].items.push({
                    ...item,
                    payload: generatePayload(itemType, [item.id]),
                    user_buy: userBuyValue
                });
                if (!storage[itemTypeKey].payload) {
                    storage[itemTypeKey].payload = { item_ids: [] };
                }
                if (!storage[itemTypeKey].payload.item_ids) {
                    storage[itemTypeKey].payload.item_ids = [];
                }
                storage[itemTypeKey].payload.item_ids.push(item.id);
            }
        };

        const removeItemFromLocalStorage = (storage: any, item: any) => {
            if (storage[itemTypeKey] && itemType.items) {
                if (storage[itemTypeKey].items) {
                    storage[itemTypeKey].items = storage[itemTypeKey].items.filter((i: any) => i.id !== item.id) || [];
                }
                if (storage[itemTypeKey].payload && storage[itemTypeKey].payload.item_ids) {
                    storage[itemTypeKey].payload.item_ids = storage[itemTypeKey].payload.item_ids.filter((id: number) => id !== item.id);
                }

                if (storage[itemTypeKey].items.length === 0) {
                    delete storage[itemTypeKey];
                }
            }
        };

        switch (true) {
            case itemType.labor:
                if (isLaborSelected) {
                    addItemToLocalStorage(laborItemTypes, item);
                } else {
                    removeItemFromLocalStorage(laborItemTypes, item);
                }
                break;
            case itemType.tool:
                if (isToolSelected) {
                    addItemToLocalStorage(toolsItemTypes, item);
                } else {
                    removeItemFromLocalStorage(toolsItemTypes, item);
                }
                break;
            default:
                if (isSelected) {
                    addItemToLocalStorage(fragmentItems, item);
                } else {
                    removeItemFromLocalStorage(fragmentItems, item);
                }
                break;
        }

        storedFragments[fragmentKey] = {
            items: fragmentItems,
            LaborItemTypes: laborItemTypes,
            ToolsItemTypes: toolsItemTypes
        };

        localStorage.setItem('selectedFragments', JSON.stringify(storedFragments));
        syncSelectionsWithLocalStorage();
    };


    const generatePayload = (itemType: ItemTypeWithIndex, itemIds: number[]): any => ({
        template_index: templateIndex,
        fragment_index: fragmentIndex,
        item_type_index: itemType.originalIndex,
        template_id: templateId,
        fragment_id: fragment.id,
        item_type_id: itemType.id,
        item_ids: itemIds,
    });

    const filteredItemTypes = fragment.item_types
        .map((itemType, index) => ({
            ...itemType,
            originalIndex: index,
            clone_id: itemType.index || index,
        } as ItemTypeWithIndex))
        .filter(itemType => !itemType.labor && !itemType.tool && itemType.qty > 0);

    const laborItems = fragment.item_types
        .map((itemType, index) => ({
            ...itemType,
            originalIndex: index,
            clone_id: itemType.index || index,
        } as ItemTypeWithIndex))
        .filter(itemType => itemType.labor && itemType.qty > 0);

    const toolItems = fragment.item_types
        .map((itemType, index) => ({
            ...itemType,
            originalIndex: index,
            clone_id: itemType.index || index,
        } as ItemTypeWithIndex))
        .filter(itemType => itemType.tool && itemType.qty > 0);


    if (filteredItemTypes.length === 0 && laborItems.length === 0 && toolItems.length === 0) {
        return <div className="step2-div-no-materials">No product</div>;
    }


    const handleQtyChange = (itemTypeIndex: number, newQty: string, errorItem: any, qtyStore: number | { quantity: number } | "other vendor" | undefined) => {
        let availableQty = 0;
        if (qtyStore === "other vendor") {
            availableQty = Infinity;
        } else if (typeof qtyStore === "number") {
            availableQty = qtyStore;
        } else if (typeof qtyStore === "object" && qtyStore !== null && "quantity" in qtyStore) {
            availableQty = qtyStore.quantity;
        }

        let parsedQty = parseInt(newQty, 10);
        if (isNaN(parsedQty) || parsedQty < 0) {
            parsedQty = 0;
        }

        const newValue = Math.min(parsedQty, availableQty);

        const itemKey = generateItemKey(
            errorItem.fragment_id,
            errorItem.fragment_index,
            errorItem.template_id,
            errorItem.template_index,
            errorItem.item_type_id,
            errorItem.item_id
        );

        setDebouncedOrderedQty((prev) => ({
            ...prev,
            [itemTypeIndex]: newValue.toString(),
        }));



        if (parsedQty > availableQty) {
            setError(
                itemKey,
                availableQty !== 0
                    ? `You can buy a maximum of ${availableQty} pieces`
                    : "Out of stock"
            );

            setTimeout(() => {
                clearError(itemKey);
            }, 5000);
        } else {
            clearError(itemKey);
        }
    };


    return (
        <div>
            {filteredItemTypes.length > 0 && <h3>Products name</h3>}
            {filteredItemTypes.map((itemType: ItemTypeWithIndex, index: number) => {
                const itemTypeKey = generateItemTypeKeyVisible(itemType, index);
                return (
                    <React.Fragment key={`${itemType.originalIndex}`}>
                        {itemType.items && itemType.items.length > 0 && (
                                <div className='static-item-type-info'>
                                    <p className='static-item-type-info-title'>{itemType.title}</p>
                                    <p className='static-item-type-info-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'each'})</p>
                                </div>
                        )}

                        {(!itemType.items || itemType.items.length === 0) && (
                                <div key={itemType.originalIndex} className='step2-item-type-list-no-items'>
                                    <div className='static-item-type-info'>
                                        <p className='static-item-type-info-title'>{itemType.title}</p>
                                        <p className='static-item-type-info-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'each'})</p>
                                    </div>
                                    <p className="step2-other-width-list-total-no-item">R 0.00</p>
                                    <div className="step2-tooltip-container-no-items">
                                        <input
                                            type="number"
                                            className="step2-other-width-input"
                                            disabled={true}
                                        />
                                        <span className='packing-value-steps'>psc</span>
                                    </div>
                                    <p className="step2-other-width-list">R 0.00</p>
                                    <div className='step2-other-width-vendor-list'></div>
                                </div>

                        )}

                        {itemType.items && itemType.items.map((item, itemIndex) => {
                            const key = generateUniqueKey(itemType, itemIndex, item);
                            const price = parseFloat(String(item.price)) || 0;
                            const orderedQty = item.ordered_qty || 0;
                            const orderedQtyPackaging = orderedQty * (
                                Array.isArray(item.packaging) && item.packaging.length === 0
                                    ? 1
                                    : item.packaging && typeof item.packaging === 'object'
                                        ? item.packaging[Object.keys(item.packaging)[0]] || 1
                                        : 1
                            );
                            const totalDifPrice = Math.max(0, (item.qty * price) - (orderedQty * price));
                            const itemPackaging = item.packaging && typeof item.packaging === 'object' && Object.keys(item.packaging).length > 0
                                ? Object.keys(item.packaging)[0]
                                : item.unit ? item.unit : 'psc';

                            const packagingValue = item.packaging && typeof item.packaging === 'object' && Object.values(item.packaging).length > 0
                                ? Object.values(item.packaging)[0]
                                : 1;

                            const itemQty = item.user_item_qty || item.qty;
                            const itemPackagingTotal = Math.ceil(itemQty * Number(packagingValue));

                            const itemKey = generateItemKey(fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id);
                            const visited = isVisited(itemKey);
                            const errorItem = parseItemKey(itemKey);

                            return (
                                    <div key={`${itemType.originalIndex}-${itemIndex}`}
                                         className='step2-item-type-list'>
                                        <div className='step2-material-width-list'>
                                            <div className="step2-item-checkbox-block">
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                    checked={!!selectedItems[key] || !!selectedLaborItems[key] || !!selectedToolItems[key]}
                                                    onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                />
                                                <label
                                                    className='checkbox-text-step2'
                                                    htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                    {item.title}
                                                </label>
                                            </div>
                                        </div>
                                        <p className="step2-item-width-list-calc">
                                            {itemQty} (psc)/{itemPackagingTotal} ({itemPackaging})
                                        </p>
                                        <p className="step2-item-width-list-qty-ordered">
                                            {orderedQty}(psc)/
                                            {orderedQtyPackaging.toFixed(2)}({itemPackaging})
                                        </p>
                                        <div className="step2-tooltip-container-item">
                                            <input
                                                type="number"
                                                className="step2-other-width-input"
                                                value={debouncedOrderedQty[itemType.originalIndex]}
                                                onChange={(e) => handleQtyChange(itemType.originalIndex, e.target.value,errorItem,item.qty_from_store)}
                                                min="0"
                                                disabled={!!selectedItems[key] || !!selectedLaborItems[key] || !!selectedToolItems[key]}
                                            />
                                            <span className='packing-value-steps'>psc</span>
                                            {errorMessages[itemKey] &&
                                                <div className="error-message">{errorMessages[itemKey]}</div>}
                                        </div>
                                        <p className="step2-item-width-list-price">R {formatNumber(price)}</p>
                                        <p className="step2-item-width-list-total">R {isNaN(totalDifPrice) ? 0 : formatNumber(totalDifPrice)}</p>
                                        <div className='step2-other-width-vendor-list-item'>
                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                <>
                                                <p
                                                    onClick={()=>handleLogoClick(item.merchant_info?.link,fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id)}
                                                    className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                {!visited && <span><IconEyesStep3/></span>}
                                                </>
                                            ) : (
                                                <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                            )}

                                        </div>
                                    </div>

                            );
                        })}
                    </React.Fragment>
                )
            })}

            {viewMode === 'Work Stage' && (
                <>
                    {laborItems.length > 0 && (
                        <>
                            <h3 className='step2-labor-items'>Labor Items</h3>
                            {laborItems.map((itemType: ItemTypeWithIndex, index: number) => {
                                return (
                                    <React.Fragment key={`${itemType.originalIndex}`}>
                                        {itemType.items && itemType.items.length > 0 && (
                                                <div className='static-item-type-info'>
                                                    <p className='static-item-type-info-title'>{itemType.title}</p>
                                                    <p className='static-item-type-info-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'each'})</p>
                                                </div>

                                        )}

                                        {(!itemType.items || itemType.items.length === 0) && (
                                                <div key={itemType.originalIndex} className='step2-item-type-list-no-items'>
                                                    <div className='static-item-type-info'>
                                                        <p className='static-item-type-info-title'>{itemType.title}</p>
                                                        <p className='static-item-type-info-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'each'})</p>
                                                    </div>
                                                    <p className="step2-other-width-list-total-no-item">R 0.00</p>
                                                    <div className="step2-tooltip-container-no-items">
                                                        <input
                                                            type="number"
                                                            className="step2-other-width-input"
                                                            disabled={true}
                                                        />
                                                        <span className='packing-value-steps'>psc</span>
                                                    </div>
                                                    <p className="step2-other-width-list">R 0.00</p>
                                                    <div className='step2-other-width-vendor-list'></div>
                                                </div>

                                        )}

                                        {itemType.items && itemType.items.map((item, itemIndex) => {
                                            const key = generateUniqueKey(itemType, itemIndex, item);
                                            const price = parseFloat(String(item.price)) || 0;
                                            const orderedQty = item.ordered_qty || 0;
                                            const orderedQtyPackaging = orderedQty * (
                                                Array.isArray(item.packaging) && item.packaging.length === 0
                                                    ? 1
                                                    : item.packaging && typeof item.packaging === 'object'
                                                        ? item.packaging[Object.keys(item.packaging)[0]] || 1
                                                        : 1
                                            );
                                            const difPrice = orderedQty * price;
                                            const totalDifPrice = Math.max(0, (item.qty * price) - (orderedQty * price));
                                            const itemKeyVisible = generateItemKeyVisible(item, itemIndex);
                                            const itemPackaging = item.packaging && typeof item.packaging === 'object' && Object.keys(item.packaging).length > 0
                                                ? Object.keys(item.packaging)[0]
                                                : item.unit ? item.unit : 'psc';

                                            const packagingValue = item.packaging && typeof item.packaging === 'object' && Object.values(item.packaging).length > 0
                                                ? Object.values(item.packaging)[0]
                                                : 1;

                                            const itemQty = item.user_item_qty || item.qty;
                                            const itemPackagingTotal = Math.ceil(itemQty * Number(packagingValue));

                                            const itemKey = generateItemKey(fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id);
                                            const visited = isVisited(itemKey);
                                            const errorItem = parseItemKey(itemKey);

                                            return (
                                                    <div key={`${itemType.originalIndex}-${itemIndex}`}
                                                         className='step2-item-type-list'>
                                                        <div className='step2-material-width-list'>
                                                            <div className="step2-item-checkbox-block">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                                    checked={selectedLaborItems[key]}
                                                                    onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                                />
                                                                <label
                                                                    className='checkbox-text-step2'
                                                                    htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                                    {item.title}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <p className="step2-item-width-list-calc">
                                                            {itemQty} (psc)/{itemPackagingTotal} ({itemPackaging})
                                                        </p>
                                                        <p className="step2-item-width-list-qty-ordered">
                                                            {orderedQty}(psc)/
                                                            {orderedQtyPackaging.toFixed(2)}({itemPackaging})
                                                        </p>
                                                        <div className="step2-tooltip-container-item">
                                                            <input
                                                                type="number"
                                                                className="step2-other-width-input"
                                                                value={debouncedOrderedQty[itemType.originalIndex]}
                                                                onChange={(e) => handleQtyChange(itemType.originalIndex, e.target.value,errorItem,item.qty_from_store)}
                                                                min="0"
                                                                disabled={!!selectedLaborItems[key]}
                                                            />
                                                            <span className='packing-value-steps'>psc</span>
                                                            {errorMessages[itemKey] &&
                                                                <div className="error-message">{errorMessages[itemKey]}</div>}
                                                        </div>
                                                        <p className="step2-item-width-list-price">R {formatNumber(price)}</p>
                                                        <p className="step2-item-width-list-total">R {isNaN(totalDifPrice) ? 0 : formatNumber(totalDifPrice)}</p>
                                                        <div className='step2-other-width-vendor-list-item'>
                                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                <>
                                                                    <p
                                                                        onClick={()=>handleLogoClick(item.merchant_info?.link,fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id)}
                                                                        className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                    {!visited && <span><IconEyesStep3/></span>}
                                                                </>
                                                            ) : (
                                                                <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                            )}

                                                        </div>
                                                    </div>
                                            );
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )}

                    {toolItems.length > 0 && (
                        <>
                            <h3 className='step2-labor-items'>Tool Items</h3>
                            {toolItems.map((itemType: ItemTypeWithIndex, index: number) => {
                                return (
                                    <React.Fragment key={`${itemType.originalIndex}`}>
                                        {itemType.items && itemType.items.length > 0 && (
                                                <div className='static-item-type-info'>
                                                    <p className='static-item-type-info-title'>{itemType.title}</p>
                                                    <p className='static-item-type-info-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'each'})</p>
                                                </div>

                                        )}

                                        {(!itemType.items || itemType.items.length === 0) && (
                                                <div key={itemType.originalIndex} className='step2-item-type-list-no-items'>
                                                    <div className='static-item-type-info'>
                                                        <p className='static-item-type-info-title'>{itemType.title}</p>
                                                        <p className='static-item-type-info-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'each'})</p>
                                                    </div>
                                                    <p className="step2-other-width-list-total-no-item">R 0.00</p>
                                                    <div className="step2-tooltip-container-no-items">
                                                        <input
                                                            type="number"
                                                            className="step2-other-width-input"
                                                            disabled={true}
                                                        />
                                                        <span className='packing-value-steps'>psc</span>
                                                    </div>
                                                    <p className="step2-other-width-list">R 0.00</p>
                                                    <div className='step2-other-width-vendor-list'></div>
                                                </div>
                                        )}

                                        {itemType.items && itemType.items.map((item, itemIndex) => {
                                            const key = generateUniqueKey(itemType, itemIndex, item);
                                            const price = parseFloat(String(item.price)) || 0;
                                            const orderedQty = item.ordered_qty || 0;
                                            const orderedQtyPackaging = orderedQty * (
                                                Array.isArray(item.packaging) && item.packaging.length === 0
                                                    ? 1
                                                    : item.packaging && typeof item.packaging === 'object'
                                                        ? item.packaging[Object.keys(item.packaging)[0]] || 1
                                                        : 1
                                            );
                                            const difPrice = orderedQty * price;
                                            const totalDifPrice = Math.max(0, (item.qty * price) - (orderedQty * price));
                                            const itemKeyVisible = generateItemKeyVisible(item, itemIndex);
                                            const itemPackaging = item.packaging && typeof item.packaging === 'object' && Object.keys(item.packaging).length > 0
                                                ? Object.keys(item.packaging)[0]
                                                : item.unit ? item.unit : 'psc';

                                            const packagingValue = item.packaging && typeof item.packaging === 'object' && Object.values(item.packaging).length > 0
                                                ? Object.values(item.packaging)[0]
                                                : 1;

                                            const itemQty = item.user_item_qty || item.qty;
                                            const itemPackagingTotal = Math.ceil(itemQty * Number(packagingValue));
                                            const itemKey = generateItemKey(fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id);
                                            const visited = isVisited(itemKey);
                                            const errorItem = parseItemKey(itemKey);
                                            return (
                                                    <div key={`${itemType.originalIndex}-${itemIndex}`}
                                                         className='step2-item-type-list'>
                                                        <div className='step2-material-width-list'>
                                                            <div className="step2-item-checkbox-block">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                                    checked={!!selectedToolItems[key]}
                                                                    onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                                />
                                                                <label
                                                                    className='checkbox-text-step2'
                                                                    htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                                    {item.title}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <p className="step2-item-width-list-calc">
                                                            {itemQty} (psc)/{itemPackagingTotal} ({itemPackaging})
                                                        </p>
                                                        <p className="step2-item-width-list-qty-ordered">
                                                            {orderedQty}(psc)/
                                                            {orderedQtyPackaging.toFixed(2)}({itemPackaging})
                                                        </p>
                                                        <div className="step2-tooltip-container-item">
                                                            <input
                                                                type="number"
                                                                className="step2-other-width-input"
                                                                value={debouncedOrderedQty[itemType.originalIndex]}
                                                                onChange={(e) => handleQtyChange(itemType.originalIndex, e.target.value,errorItem,item.qty_from_store)}
                                                                min="0"
                                                                disabled={!!selectedToolItems[key]}
                                                            />
                                                            <span className='packing-value-steps'>psc</span>
                                                            {errorMessages[itemKey] &&
                                                                <div className="error-message">{errorMessages[itemKey]}</div>}
                                                        </div>
                                                        <p className="step2-item-width-list-price">R {formatNumber(price)}</p>
                                                        <p className="step2-item-width-list-total">R {isNaN(difPrice) ? 0 : formatNumber(difPrice)}</p>
                                                        <div className='step2-other-width-vendor-list-item'>
                                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                <>
                                                                    <p
                                                                        onClick={()=>handleLogoClick(item.merchant_info?.link,fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id)}
                                                                        className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                    {!visited && <span><IconEyesStep3/></span>}
                                                                </>
                                                            ) : (
                                                                <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                            )}

                                                        </div>
                                                    </div>
                                            );
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )}
                </>
            )}

            <div className='step2-bottom-block'>                 <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button
                            onClick={() => handleOrderFragment(fragmentIndex,false)}
                            className='step2-bottom-block-btn-cart-list'
                            style={{
                                backgroundColor: isButtonDisabledOrderFragment ? '#cccccc' : '',
                                cursor: isButtonDisabledOrderFragment ? 'not-allowed' : 'pointer'
                            }}>Select for Ordering
                        </button>
                    </>

            </div>

        </div>
    );

};

const mapStateToProps = (state: RootState) => ({
    selectedLabor: state.selectedItems.selectedLabor,
    selectedTools: state.selectedItems.selectedTools,
});

const mapDispatchToProps = {
    setSelectedItemsTotal,
    setSelectedToolsTotal,
    setSelectedLaborTotal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypeList);
