import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from "react-redux";
import {ItemType, TItem, TMerchanInfo} from "../../../types/TemplateType";
import { Project } from "../../../types/ProjectTypes";
import { get, patch } from "../../../api/APIWraper";
import { updateLocalTemplate } from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import ItemSelectionModal from "../ItemSelectionModal";
import "../Step2Styles/step2.css";
import "../Step2Styles/step2-width.css";
import "../Step2Styles/itemModal.css";
import { Vendor, vendorData} from "../../../Enums/VendorEnum";
import { setPageActiveCalculation} from "../../../Slice/ClickSlice/FragmentVisibityState";
import {IconArrowLightStpe2, IconStep2SelectArrow} from "../../../IconComponents/IconComponents";

interface ItemTypeListCalculationProps {
    fragment: {
        id: number;
        data: {
            item_types: ItemType[];
        };
    };
    fragmentIndex: number;
    templateIndex: number;
    templateId: number;
    project: Project;
    viewMode: 'Work Stage'| 'Materials|Labor|Tools';
    onUpdateProject: (newProject: Project) => void;
    onToggle: () => void;
}

interface SelectedItemInfo {
    merchant_info?: TMerchanInfo;
    vendor: Vendor | null;
    user_item_qty?: number;
    title: string;
    id: number;
    qty: number;
    ordered_qty: number;
    unit: string;
    price: number;
}

interface SelectedItem {
    itemTypeId: number;
    itemId: number;
    index: number;
    user_item_qty: number;
    clone_id: string;
}

interface ItemTypeWithIndex extends ItemType {
    originalIndex: number;
}

const ItemTypeListCalculation: React.FC<ItemTypeListCalculationProps & PropsFromRedux> = ({
                                                                                              fragment,
                                                                                              fragmentIndex,
                                                                                              templateId,
                                                                                              templateIndex,
                                                                                              project,
                                                                                              viewMode,
                                                                                              onUpdateProject,
                                                                                              onToggle,
                                                                                              setPageActiveCalculation
                                                                                          }) => {
    const [selectedItemTypeIndex, setSelectedItemTypeIndex] = useState<number | null>(null);
    const [items, setItems] = useState<TItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedItemInfos, setSelectedItemInfos] = useState<{ [id: string]: SelectedItemInfo }>({});
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<{ [id: string]: string }>({});
    const [parsedItemTypes, setParsedItemTypes] = useState<{ [index: number]: any }>({});


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});

    const generateItemTypeKey = (itemType: ItemTypeWithIndex,idx:number) => {
        return `${itemType.id}-${idx}-${itemType.originalIndex}-${fragmentIndex}`;
    };

    const toggleVisibility = (itemTypeKey: string) => {
        setVisibilityMap((prevMap) => ({
            ...prevMap,
            [itemTypeKey]: !prevMap[itemTypeKey],
        }));
    };

    const isVisible = (itemTypeKey: string) => {
        return visibilityMap[itemTypeKey] || false;
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setPageActiveCalculation(true)
        return () => {
            setPageActiveCalculation(false);
        };
    }, [setPageActiveCalculation]);


    useEffect(() => {
        if (Array.isArray(fragment.data.item_types)) {
            const fragmentItems = fragment.data.item_types.flatMap((itemType, idx) =>
                (itemType.items || []).map((item, itemIdx) => ({
                    itemTypeId: itemType.id,
                    itemId: item.id,
                    index: idx,
                    user_item_qty: item.qty,
                    clone_id: `${item.id}-${idx}-${itemIdx}`
                }))
            );

            const initialSelectedItems = [
                ...fragmentItems,
                ...fragment.data.item_types
                    .map((itemType, index) => ({ ...itemType, originalIndex: index }))
                    .filter(itemType => itemType.item && (viewMode === 'Work Stage' || (!itemType.labor && !itemType.tool)))
                    .map((itemType, idx) => ({
                        itemTypeId: itemType.id,
                        itemId: itemType.item.id,
                        index: itemType.originalIndex,
                        user_item_qty: itemType.item.user_item_qty,
                        clone_id: `${itemType.id}-${itemType.originalIndex}-${idx}`
                    }))
            ];

            setSelectedItems(initialSelectedItems);

            const initialSelectedItemInfos = initialSelectedItems.reduce((acc, selectedItem) => {
                const itemType = fragment.data.item_types[selectedItem.index];
                const item = itemType.items ? itemType.items.find(it => it.id === selectedItem.itemId) : null;
                if (itemType && item) {
                    acc[selectedItem.clone_id] = {
                        merchant_info: {} as TMerchanInfo,
                        vendor: item.vendor,
                        user_item_qty: selectedItem.user_item_qty,
                        title: item.title,
                        id: item.id,
                        qty: item.qty || 0,
                        ordered_qty: item.ordered_qty || 0,
                        unit: item.unit,
                        price: item.price || 0
                    };
                }
                return acc;
            }, {} as { [id: string]: SelectedItemInfo });

            setSelectedItemInfos(initialSelectedItemInfos);

            const parsedItemTypes = fragment.data.item_types.reduce((acc, itemType, index) => {
                if (viewMode === 'Materials|Labor|Tools' && (itemType.labor || itemType.tool)) {
                    return acc;
                }
                const clone_id = `${itemType.id}-${index}-${index}`;
                const item = initialSelectedItemInfos[clone_id];
                const title = itemType.title;
                let qty = item ? item.qty : itemType.qty;
                if (!qty) {
                    qty = 0;
                }
                let orderedQty = item ? item.ordered_qty : itemType.ordered_qty;
                if (!orderedQty) {
                    orderedQty = 0;
                }
                const price = itemType.item ? itemType.item.price : 0;
                const priceCurrentQty = item ? item.price * orderedQty : 0;
                const priceTotalQty = item ? item.price * qty : 0;
                const isItemSelected = !!itemType.item;
                const unit = itemType.item ? itemType.item.unit : '';

                acc[index] = {
                    index,
                    title,
                    qty,
                    orderedQty,
                    price,
                    priceCurrentQty,
                    priceTotalQty,
                    isItemSelected,
                    unit
                };

                return acc;
            }, {} as { [index: number]: any });

            setParsedItemTypes(parsedItemTypes);
            calculateTotalPrice();
        }
    }, [fragment.data.item_types, viewMode]);


    useEffect(() => {
        calculateTotalPrice();
    }, [selectedItemInfos]);

    useEffect(() => {
        const handler = setTimeout(() => {
            Object.keys(debouncedOrderedQty).forEach(id => {
                setSelectedItemInfos((prev) => {
                    const newOrderedQty = parseInt(debouncedOrderedQty[id]) || 0;
                    return {
                        ...prev,
                        [id]: {
                            ...prev[id],
                            ordered_qty: newOrderedQty,
                        },
                    };
                });
            });
            calculateTotalPrice();
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedOrderedQty, selectedItems]);

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        selectedItems.forEach((selectedItem) => {
            const item = selectedItemInfos[selectedItem.clone_id];
            if (item) {
                const generalPrice = item.price * (item.user_item_qty || item.qty);
                const difTotalPrice = Math.max(0, item.price * Math.min(item.ordered_qty, (item.user_item_qty || item.qty)));
                totalPrice += (generalPrice - difTotalPrice);
            }
        });
        setTotalPrice(totalPrice);
    };

    const storeId = project?.store_id ? project.store_id : 0;

    const fetchItems = async (index: number, page: number = 1,searchTerm: string = '') => {
        setSelectedItemTypeIndex(index);
        setLoading(true);
        try {
            const url = searchTerm
                ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${fragment.data.item_types[index].id}?page=${page}&perPage=${itemsPerPage}&title=${searchTerm}&storeIdFromProjects=${storeId}`
                : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${fragment.data.item_types[index].id}?page=${page}&perPage=${itemsPerPage}&storeIdFromProjects=${storeId}`

            const response = await get(url);
            setTotalItems(response.data.items.pagination.lastPage);
            const itemsWithQty = (Array.isArray(response.data.items.data) ? response.data.items.data : [response.data.items.data]).map(
                (item: ItemType) => ({
                    ...item,
                    qty_item_type: fragment.data.item_types[index].qty,
                })
            );
            setItems(itemsWithQty);
        } catch (error) {
            console.error("Failed to get elements:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, pageNumber, searchTerm);
        }
    };


    const handleButtonClick = (index: number) => {
        setIsModalOpen(true);
        fetchItems(index, currentPage);
    };

    const handleReload = () => {
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, currentPage);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleItemClick = (items: TItem[], itemTypeIndex: number | null) => {
        if (itemTypeIndex !== null) {
            const updatedSelectedItems = [
                ...selectedItems.filter(selectedItem => selectedItem.index !== itemTypeIndex),
                ...items.map((item, idx) => ({
                    itemTypeId: fragment.data.item_types[itemTypeIndex].id,
                    itemId: item.id,
                    index: itemTypeIndex,
                    user_item_qty: item.user_item_qty,
                    clone_id: `${item.id}-${itemTypeIndex}-${idx}`
                }))
            ];

            const updatedItemInfos = items.reduce((acc, item, idx) => {
                const clone_id = `${item.id}-${itemTypeIndex}-${idx}`;
                acc[clone_id] = {
                    merchant_info: {} as TMerchanInfo,
                    vendor: item.vendor,
                    title: item.title,
                    id: item.id,
                    qty: item.qty,
                    ordered_qty: item.ordered_qty || 0,
                    user_item_qty: item.user_item_qty || 0,
                    unit: item.unit,
                    price: item.price || 0
                };
                return acc;
            }, { ...selectedItemInfos });

            setSelectedItemInfos(updatedItemInfos);
            setSelectedItems(updatedSelectedItems);
            setParsedItemTypes((prev) => ({
                ...prev,
                [itemTypeIndex]: {
                    ...prev[itemTypeIndex],
                    isItemSelected: items.length > 0,
                    qty: items.length > 0 ? items[0].qty : 0,
                    ordered_qty: items.length > 0 ? items[0].ordered_qty || 0 : 0,
                    user_item_qty: items.length > 0 ? items[0].user_item_qty || 0 : 0,
                    unit: items.length > 0 ? items[0].unit : '',
                    price: items.length > 0 ? items[0].price || 0 : 0,
                    priceCurrentQty: items.length > 0 ? (items[0].price || 0) * (items[0].user_item_qty || 0) : 0,
                    priceTotalQty: items.length > 0 ? (items[0].price || 0) * items[0].qty : 0,
                },
            }));

            calculateTotalPrice();
        }
        setIsModalOpen(false);
    };

    const handleQtyChange = (clone_id: string, newQty: string) => {
        const parsedQty = parseInt(newQty);
        if (!isNaN(parsedQty)) {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: newQty,
            }));
        } else {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: '',
            }));
        }
        setSelectedItemInfos((prev) => ({
            ...prev,
            [clone_id]: {
                ...prev[clone_id],
                ordered_qty: parsedQty,
            },
        }));
        calculateTotalPrice();
    };

    const handleSave = async () => {
        const groupedItems: Record<string, {
            item_type_id: number,
            item_ids: { id: number, qty: number }[],
            template_index: number,
            fragment_index: number,
            item_type_index: number,
            template_id: number,
            fragment_id: number
        }> = selectedItems.reduce((acc, selection) => {
            const key = `${selection.itemTypeId}-${selection.index}`;
            if (!acc[key]) {
                acc[key] = {
                    item_type_id: selection.itemTypeId,
                    item_ids: [],
                    template_index: templateIndex,
                    fragment_index: fragmentIndex,
                    item_type_index: selection.index,
                    template_id: templateId,
                    fragment_id: fragment.id
                };
            }
            acc[key].item_ids.push({ id: selection.itemId, qty: selection.user_item_qty });
            return acc;
        }, {} as Record<string, {
            item_type_id: number,
            item_ids: { id: number, qty: number }[],
            template_index: number,
            fragment_index: number,
            item_type_index: number,
            template_id: number,
            fragment_id: number
        }>);

        fragment.data.item_types.forEach((itemType, idx) => {
            const key = `${itemType.id}-${idx}`;
            if (!groupedItems[key]) {
                groupedItems[key] = {
                    item_type_id: itemType.id,
                    item_ids: [],
                    template_index: templateIndex,
                    fragment_index: fragmentIndex,
                    item_type_index: idx,
                    template_id: templateId,
                    fragment_id: fragment.id
                };
            }
        });

        const body = Object.keys(groupedItems).map(key => groupedItems[key]);

        let updatedProject;
        try {
            const response = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item`, { data: body });

            if (response.status === 200 || response.status === 201) {
                updatedProject = response.data;
                const itemOrderedQtyBody = selectedItems.map(selection => ({
                    ordered_qty: selectedItemInfos[selection.clone_id]?.ordered_qty || 0,
                    template_index: templateIndex,
                    fragment_index: fragmentIndex,
                    item_type_index: selection.index,
                    template_id: templateId,
                    fragment_id: fragment.id,
                    item_type_id: selection.itemTypeId,
                    item_id: selection.itemId,
                }));

                const responseOrderedQTY = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty`, { data: itemOrderedQtyBody });

                if (responseOrderedQTY.status === 200 || responseOrderedQTY.status === 201) {
                    updatedProject = responseOrderedQTY.data;
                }
            } else {
                throw new Error("Failed to update project.");
            }
            handleCloseModal();
        } catch (error) {
            console.error("Failed to save selections:", error);
        } finally {
            if (updatedProject != null) {
                onUpdateProject(updatedProject);
                onToggle();
            }
        }
    };


    if (!fragment.data.item_types || !Array.isArray(fragment.data.item_types)) {
        return null;
    }

    const filteredItemTypes = fragment.data.item_types
        .map((itemType, index) => ({ ...itemType, originalIndex: index }))
        .filter(itemType => !itemType.labor && !itemType.tool);

    const laborItems = viewMode === 'Work Stage' ? fragment.data.item_types
        .map((itemType, index) => ({ ...itemType, originalIndex: index }))
        .filter(itemType => itemType.labor) : [];

    const toolItems = viewMode === 'Work Stage' ? fragment.data.item_types
        .map((itemType, index) => ({ ...itemType, originalIndex: index }))
        .filter(itemType => itemType.tool) : [];

    const handleSearch = (query: string) => {
        setSearchTerm(query);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, 1, query);
        }
    };


    return (
        <div>
            {filteredItemTypes.length > 0 && (
                <>
                    {viewMode === 'Work Stage' && <h3>Products name</h3>}
                    {filteredItemTypes.map((itemType: ItemTypeWithIndex, index: number) => {
                        const parsedItem = parsedItemTypes[itemType.originalIndex];
                        if (!parsedItem) return null;

                        const {
                            title,
                            qty,
                            orderedQty,
                            price,
                            priceTotalQty,
                        } = parsedItem;
                        const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === itemType.id && item.index === itemType.originalIndex);
                        const itemTypeKey = generateItemTypeKey(itemType,index);

                        return (
                            <div key={`${itemType.originalIndex}-${index}`}>

                                    <label
                                        className="step2-item-type-label"
                                        htmlFor={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                        style={{color: "#828282"}}
                                    >
                                        {title}
                                    </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                        !isSmallScreen ? (
                                            <div className='step2-item-type-list'>
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                    onClick={() => handleButtonClick(itemType.originalIndex)}
                                                    disabled={loading}
                                                >
                                                    {loading && selectedItemTypeIndex === itemType.originalIndex
                                                        ? "Loading..."
                                                        : "Select Products"}
                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                </button>
                                                <div className="step2-tooltip-container">
                                                <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-width-list">{qty} </p>
                                                <p className="step2-other-width-list">R {parseInt(price).toFixed(2)}</p>
                                                <p className="step2-other-width-list">
                                                    {(isNaN(orderedQty * price) || orderedQty > qty ? 0 : (orderedQty * price)).toFixed(2)}
                                                </p>
                                                <p className="step2-other-width-list-total">R {Math.max(0, priceTotalQty).toFixed(2)}</p>
                                                <div className='step2-other-width-vendor-list'></div>
                                            </div>
                                        ) : (
                                            <div className='step2-item-type-list'>
                                                <div className='step2-item-type-list-block-arrow'>
                                                    <button
                                                        className="step2-item-button-list"
                                                        id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                        onClick={() => handleButtonClick(itemType.originalIndex)}
                                                        disabled={loading}
                                                    >
                                                        {loading && selectedItemTypeIndex === itemType.originalIndex
                                                            ? "Loading..."
                                                            : "Select Products"}
                                                        <div className='step2-select-arrow'><IconStep2SelectArrow/>
                                                        </div>

                                                    </button>
                                                    <div onClick={() => toggleVisibility(itemTypeKey)}
                                                         className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                        <IconArrowLightStpe2/>
                                                    </div>
                                                </div>
                                                <div className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                <div className="step2-tooltip-container">
                                                    <span>QTY to order:</span><span className="dashed-line"></span>
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p title='TEST' className="step2-other-width-list">
                                                    <span>QTY ordered:</span><span className="dashed-line"></span>{qty}
                                                </p>
                                                <p className="step2-other-width-list">
                                                    <span>Unit price:</span><span
                                                    className="dashed-line"></span>R {parseInt(price).toFixed(2)}</p>
                                                <p className="step2-other-width-list">
                                                    <span>Total:</span><span className="dashed-line"></span>
                                                    {(isNaN(orderedQty * price) || orderedQty > qty ? 0 : (orderedQty * price)).toFixed(2)}
                                                </p>
                                                <p className="step2-other-width-list-total total-ordred-bold">
                                                    <span>Total ordered:</span><span
                                                    className="dashed-line"></span>R {Math.max(0, priceTotalQty).toFixed(2)}
                                                </p>
                                            </div>
                                            </div>
                                        )
                                    ) : (
                                        <div>
                                        {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const difPrice = item.ordered_qty ? item.price * item.ordered_qty : 0;
                                                const oldPrice = item?.price * (item.user_item_qty || item.qty);
                                                const difTotalPrice = isNaN(oldPrice - difPrice) ? 0 : oldPrice - difPrice;
                                                return (
                                                    !isSmallScreen ? (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${fragment.id}-${itemType.originalIndex}-${idx}`}
                                                                onClick={() => handleButtonClick(itemType.originalIndex)}
                                                                disabled={loading}
                                                            >
                                                                {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                    ? "Loading..."
                                                                    : item.title}
                                                                <div className='step2-select-arrow'>
                                                                    <IconStep2SelectArrow/></div>

                                                            </button>
                                                            <div className="step2-tooltip-container">
                                                            <input
                                                                    type="number"
                                                                    className="step2-other-width-input"
                                                                    value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                    onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                    min="0"
                                                                />
                                                            </div>
                                                            <p className="step2-other-width-list">{item.user_item_qty || item.qty} ({item.unit})</p>
                                                            <p className="step2-other-width-list">R {parseInt(String(item.price)).toFixed(2)}</p>
                                                            <p className="step2-other-width-list">
                                                                {(isNaN(item.ordered_qty * item.price) || item.ordered_qty > item.qty ? 0 : (item.ordered_qty * item.price)).toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list-total">R {Math.max(0, difTotalPrice).toFixed(2)}</p>

                                                            <div className='step2-other-width-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}
                                                            </div>
                                                        </div>

                                                    ) : (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <div className='step2-item-type-list-block-arrow'>
                                                            <button
                                                                    className="step2-item-button-list"
                                                                    id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                                    onClick={() => handleButtonClick(itemType.originalIndex)}
                                                                    disabled={loading}
                                                                >
                                                                    {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                        ? "Loading..."
                                                                        : item.title}
                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                                </button>
                                                                <div onClick={() => toggleVisibility(itemTypeKey)}
                                                                     className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                                    <IconArrowLightStpe2/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                                <div className="step2-tooltip-container">
                                                                    <span>QTY to order:</span><span
                                                                    className="dashed-line"></span>
                                                                    <input
                                                                        type="number"
                                                                        className="step2-other-width-input"
                                                                        value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                        onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                        min="0"
                                                                    />
                                                                </div>
                                                                <p className="step2-other-width-list">
                                                                    <span>QTY ordered:</span><span
                                                                    className="dashed-line"></span>{item.user_item_qty || item.qty} ({item.unit})
                                                                </p>
                                                                <p className="step2-other-width-list">
                                                                    <span>Unit price:</span><span
                                                                    className="dashed-line"></span>R {parseInt(String(item.price)).toFixed(2)}
                                                                </p>
                                                                <p className="step2-other-width-list">
                                                                    <span>Total:</span><span
                                                                    className="dashed-line"></span>
                                                                    {(isNaN(item.ordered_qty * item.price) || item.ordered_qty > item.qty ? 0 : (item.ordered_qty * item.price)).toFixed(2)}
                                                                </p>
                                                                <p className="step2-other-width-list-total total-ordred-bold">
                                                                    <span>Total ordered:</span><span
                                                                    className="dashed-line"></span>R {Math.max(0, difTotalPrice).toFixed(2)}
                                                                </p>
                                                                <div className='step2-other-width-vendor-list'>
                                                                    {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                        <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                    ) : (
                                                                        <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                );
                                        })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            {viewMode === 'Work Stage' && laborItems.length > 0 && (
                <>
                    <h3>Labor Items</h3>
                    {laborItems.map((itemType: ItemTypeWithIndex, index: number) => {
                        const parsedItem = parsedItemTypes[itemType.originalIndex];
                        if (!parsedItem) return null;

                        const {
                            title,
                            qty,
                            orderedQty,
                            price,
                            priceTotalQty,
                        } = parsedItem;
                        const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === itemType.id && item.index === itemType.originalIndex);
                        const itemTypeKey = generateItemTypeKey(itemType,index);

                        return (
                            <div key={`${itemType.originalIndex}-${index}`}>
                                    <label
                                        className="step2-item-type-label"
                                        htmlFor={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                        style={{color: "#828282"}}
                                    >
                                        {title}
                                    </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                        !isSmallScreen ? (
                                            <div className='step2-item-type-list'>
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                    onClick={() => handleButtonClick(itemType.originalIndex)}
                                                    disabled={loading}
                                                >
                                                    {loading && selectedItemTypeIndex === itemType.originalIndex
                                                        ? "Loading..."
                                                        : "Select Labors"}
                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                </button>
                                                <div className="step2-tooltip-container">
                                                <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-width-list">{qty}<span
                                                    className='labor-hours'>(each)</span></p>
                                                <p className="step2-other-width-list">R {parseInt(price).toFixed(2)}</p>
                                                <p className="step2-other-width-list">
                                                    {(isNaN(orderedQty * price) || orderedQty > qty ? 0 : (orderedQty * price)).toFixed(2)}
                                                </p>
                                                <p className="step2-other-width-list-total">R {Math.max(0, priceTotalQty).toFixed(2)}</p>
                                                <div className='step2-other-width-vendor-list'></div>
                                            </div>
                                        ): (
                                            <div className='step2-item-type-list'>
                                                <div className='step2-item-type-list-block-arrow'>
                                                    <button
                                                        className="step2-item-button-list"
                                                        id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                        onClick={() => handleButtonClick(itemType.originalIndex)}
                                                        disabled={loading}
                                                    >
                                                        {loading && selectedItemTypeIndex === itemType.originalIndex
                                                            ? "Loading..."
                                                            : 'Select Labors'}
                                                        <div className='step2-select-arrow'><IconStep2SelectArrow/>
                                                        </div>

                                                    </button>
                                                    <div onClick={() => toggleVisibility(itemTypeKey)}
                                                         className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                        <IconArrowLightStpe2/>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                    <div className="step2-tooltip-container">
                                                        <span>QTY to order:</span><span className="dashed-line"></span>
                                                        <input
                                                            type="number"
                                                            className="step2-other-width-input"
                                                            min="0"
                                                            disabled={!loading}
                                                        />
                                                    </div>
                                                    <p className="step2-other-width-list">
                                                        <span>QTY ordered:</span><span
                                                        className="dashed-line"></span>{qty}<span
                                                        className='labor-hours'>(each)</span>
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Unit price:</span><span
                                                        className="dashed-line"></span>R {parseInt(price).toFixed(2)}
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Total:</span><span className="dashed-line"></span>
                                                        {(isNaN(orderedQty * price) || orderedQty > qty ? 0 : (orderedQty * price)).toFixed(2)}
                                                    </p>
                                                    <p className="step2-other-width-list-total total-ordred-bold">
                                                        <span>Total ordered:</span><span
                                                        className="dashed-line"></span>R {Math.max(0, priceTotalQty).toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div>
                                            {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const difPrice = item.ordered_qty ? item.price * item.ordered_qty : 0;
                                                const oldPrice = item?.price * (item.user_item_qty || item.qty);
                                                const difTotalPrice = isNaN(oldPrice - difPrice) ? 0 : oldPrice - difPrice;
                                                const itemTypeKey = generateItemTypeKey(itemType,index);

                                                return (
                                                    !isSmallScreen ? (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${fragment.id}-${itemType.originalIndex}-${idx}`}
                                                                onClick={() => handleButtonClick(itemType.originalIndex)}
                                                                disabled={loading}
                                                            >
                                                                {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                    ? "Loading..."
                                                                    : item.title}
                                                                <div className='step2-select-arrow'>
                                                                    <IconStep2SelectArrow/></div>

                                                            </button>
                                                            <div className="step2-tooltip-container">
                                                            <input
                                                                    type="number"
                                                                    className="step2-other-width-input"
                                                                    value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                    onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                    min="0"
                                                                />
                                                            </div>
                                                            <p className="step2-other-width-list">{item.user_item_qty || item.qty}
                                                                <span className='labor-hours'>({item.unit ? item.unit : 'each'})</span></p>
                                                            <p className="step2-other-width-list">R {parseInt(String(item.price)).toFixed(2)}</p>
                                                            <p className="step2-other-width-list">
                                                                {(isNaN(item.ordered_qty * item.price) || item.ordered_qty > item.qty ? 0 : (item.ordered_qty * item.price)).toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list-total">R {Math.max(0, difTotalPrice).toFixed(2)}</p>
                                                            <div className='step2-other-width-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ): (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <div className='step2-item-type-list-block-arrow'>
                                                                <button
                                                                    className="step2-item-button-list"
                                                                    id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                                    onClick={() => handleButtonClick(itemType.originalIndex)}
                                                                    disabled={loading}
                                                                >
                                                                    {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                        ? "Loading..."
                                                                        : item.title}
                                                                    <div className='step2-select-arrow'>
                                                                        <IconStep2SelectArrow/></div>

                                                                </button>
                                                                <div onClick={() => toggleVisibility(itemTypeKey)}
                                                                     className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                                    <IconArrowLightStpe2/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                                <div className="step2-tooltip-container">
                                                                    <span>QTY to order:</span><span
                                                                    className="dashed-line"></span>
                                                                    <input
                                                                        type="number"
                                                                        className="step2-other-width-input"
                                                                        value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                        onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                        min="0"
                                                                    />
                                                                </div>
                                                                <p className="step2-other-width-list">
                                                                    <span>QTY ordered:</span><span
                                                                    className="dashed-line"></span>{item.user_item_qty || item.qty}
                                                                    <span className='labor-hours'>({item.unit ? item.unit : 'each'})</span>
                                                                </p>
                                                                <p className="step2-other-width-list">
                                                                    <span>Unit price:</span><span
                                                                    className="dashed-line"></span>R {parseInt(String(item.price)).toFixed(2)}
                                                                </p>
                                                                <p className="step2-other-width-list">
                                                                    <span>Total:</span><span
                                                                    className="dashed-line"></span>
                                                                    {(isNaN(item.ordered_qty * item.price) || item.ordered_qty > item.qty ? 0 : (item.ordered_qty * item.price)).toFixed(2)}
                                                                </p>
                                                                <p className="step2-other-width-list-total total-ordred-bold">
                                                                    <span>Total ordered:</span><span
                                                                    className="dashed-line"></span>R {Math.max(0, difTotalPrice).toFixed(2)}
                                                                </p>
                                                                <div className='step2-other-width-vendor-list'>
                                                                    {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                        <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                    ) : (
                                                                        <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            {viewMode === 'Work Stage' && toolItems.length > 0 && (
                <>
                    <h3>Tool Items</h3>
                    {toolItems.map((itemType: ItemTypeWithIndex, index: number) => {
                        const parsedItem = parsedItemTypes[itemType.originalIndex];
                        if (!parsedItem) return null;

                        const {
                            title,
                            qty,
                            orderedQty,
                            price,
                            priceTotalQty,
                        } = parsedItem;
                        const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === itemType.id && item.index === itemType.originalIndex);
                        const itemTypeKey = generateItemTypeKey(itemType,index);

                        return (
                            <div key={`${itemType.originalIndex}-${index}`}>
                                    <label
                                        className="step2-item-type-label"
                                        htmlFor={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                        style={{color: "#828282"}}
                                    >
                                        {title}
                                    </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                        !isSmallScreen ? (
                                            <div className='step2-item-type-list'>
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                    onClick={() => handleButtonClick(itemType.originalIndex)}
                                                    disabled={loading}
                                                >
                                                    {loading && selectedItemTypeIndex === itemType.originalIndex
                                                        ? "Loading..."
                                                        : "Select Tools"}
                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                </button>
                                                <div className="step2-tooltip-container">
                                                <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-width-list ">{qty}</p>
                                                <p className="step2-other-width-list">R {parseInt(price).toFixed(2)}</p>
                                                <p className="step2-other-width-list">
                                                    {(isNaN(orderedQty * price) || orderedQty > qty ? 0 : (orderedQty * price)).toFixed(2)}
                                                </p>
                                                <p className="step2-other-width-list-total">R {Math.max(0, priceTotalQty).toFixed(2)}</p>
                                                <div className='step2-other-width-vendor-list'></div>
                                            </div>

                                        ) : (
                                            <div className='step2-item-type-list'>

                                                <div className='step2-item-type-list-block-arrow'>
                                                    <button
                                                        className="step2-item-button-list"
                                                        id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                        onClick={() => handleButtonClick(itemType.originalIndex)}
                                                        disabled={loading}
                                                    >
                                                        {loading && selectedItemTypeIndex === itemType.originalIndex
                                                            ? "Loading..."
                                                            : "Select Tools"}
                                                        <div className='step2-select-arrow'><IconStep2SelectArrow/>
                                                        </div>

                                                    </button>
                                                    <div onClick={() => toggleVisibility(itemTypeKey)}
                                                         className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                        <IconArrowLightStpe2/>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                    <div className="step2-tooltip-container">
                                                        <span>QTY to order:</span><span className="dashed-line"></span>
                                                        <input
                                                            type="number"
                                                            className="step2-other-width-input"
                                                            min="0"
                                                            disabled={!loading}
                                                        />
                                                    </div>
                                                    <p className="step2-other-width-list">
                                                        <span>QTY ordered:</span><span
                                                        className="dashed-line"></span>{qty}
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Unit price:</span><span
                                                        className="dashed-line"></span>R {parseInt(price).toFixed(2)}
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Total:</span><span className="dashed-line"></span>
                                                        {(isNaN(orderedQty * price) || orderedQty > qty ? 0 : (orderedQty * price)).toFixed(2)}
                                                    </p>
                                                    <p className="step2-other-width-list-total total-ordred-bold">
                                                        <span>Total ordered:</span><span
                                                        className="dashed-line"></span>R {Math.max(0, priceTotalQty).toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                        )

                                    ) : (
                                        <div>
                                            {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const difPrice = item.ordered_qty ? item.price * item.ordered_qty : 0;
                                                const oldPrice = item?.price * (item.user_item_qty || item.qty);
                                                const difTotalPrice = isNaN(oldPrice - difPrice) ? 0 : oldPrice - difPrice;
                                                const itemTypeKey = generateItemTypeKey(itemType,idx);
                                                return (
                                                    !isSmallScreen ? (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${fragment.id}-${itemType.originalIndex}-${idx}`}
                                                                onClick={() => handleButtonClick(itemType.originalIndex)}
                                                                disabled={loading}
                                                            >
                                                                {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                    ? "Loading..."
                                                                    : item.title}
                                                                <div className='step2-select-arrow'>
                                                                    <IconStep2SelectArrow/></div>

                                                            </button>
                                                            <div className="step2-tooltip-container">
                                                            <input
                                                                    type="number"
                                                                    className="step2-other-width-input"
                                                                    value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                    onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                    min="0"
                                                                />
                                                            </div>
                                                            <p className="step2-other-width-list">{item.user_item_qty || item.qty} ({item.unit})</p>
                                                            <p className="step2-other-width-list">R {parseInt(String(item.price)).toFixed(2)}</p>
                                                            <p className="step2-other-width-list">
                                                                {(isNaN(item.ordered_qty * item.price) || item.ordered_qty > item.qty ? 0 : (item.ordered_qty * item.price)).toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list-total">R {Math.max(0, difTotalPrice).toFixed(2)}</p>
                                                            <div className='step2-other-width-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <div className='step2-item-type-list-block-arrow'>
                                                                <button
                                                                    className="step2-item-button-list"
                                                                    id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                                    onClick={() => handleButtonClick(itemType.originalIndex)}
                                                                    disabled={loading}
                                                                >
                                                                    {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                        ? "Loading..."
                                                                        : item.title}
                                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>
                                                                </button>
                                                                <div onClick={() => toggleVisibility(itemTypeKey)}
                                                                     className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                                    <IconArrowLightStpe2/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                                <div className="step2-tooltip-container">
                                                                    <span>QTY to order:</span><span
                                                                    className="dashed-line"></span>
                                                                    <input
                                                                        type="number"
                                                                        className="step2-other-width-input"
                                                                        value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                        onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                        min="0"
                                                                    />
                                                                </div>
                                                                <p className="step2-other-width-list">
                                                                    <span>QTY ordered:</span><span
                                                                    className="dashed-line"></span>{item.user_item_qty || item.qty} ({item.unit})
                                                                </p>
                                                                <p className="step2-other-width-list">
                                                                    <span>Unit price:</span><span
                                                                    className="dashed-line"></span>R {parseInt(String(item.price)).toFixed(2)}
                                                                </p>
                                                                <p className="step2-other-width-list">
                                                                    <span>Total:</span><span
                                                                    className="dashed-line"></span>
                                                                    {(isNaN(item.ordered_qty * item.price) || item.ordered_qty > item.qty ? 0 : (item.ordered_qty * item.price)).toFixed(2)}
                                                                </p>
                                                                <p className="step2-other-width-list-total total-ordred-bold">
                                                                    <span>Total ordered:</span><span
                                                                    className="dashed-line"></span>R {Math.max(0, difTotalPrice).toFixed(2)}
                                                                </p>
                                                                <div className='step2-other-width-vendor-list'>
                                                                    {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                        <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                    ) : (
                                                                        <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            <ItemSelectionModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                items={items}
                loading={loading}
                handleItemClick={(items) => handleItemClick(items, selectedItemTypeIndex)}
                selectedItems={selectedItems.filter(item => item.index === selectedItemTypeIndex)}
                onReload={handleReload}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                onSearch={handleSearch}
                totalSearchResults={totalItems}
                storeId={storeId}
            />


            <div className='step2-bottom-block'>
                {!isSmallScreen ? (
                    <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button className='step2-bottom-block-btn-cart-list' onClick={handleSave}>Save</button>
                    </>
                ) : (
                    <>
                        <div className='step2-bottom-block-mobile'>
                            <p>Total: </p>
                            <span className="dashed-line"></span>
                            {totalPrice ? (
                                <span className='mobile-total-price-red'> R {totalPrice.toFixed(2)}</span>
                            ) : (
                                <span className='mobile-total-price-red'> R 0</span>
                            )}
                        </div>
                        <button className='step2-bottom-block-btn-cart-list' onClick={handleSave}>Save</button>
                    </>
                )}
            </div>


        </div>
    );
};

const mapDispatchToProps = {
    updateLocalTemplate,
    setPageActiveCalculation
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypeListCalculation);