import React, { useEffect, useRef, useState } from 'react';
import { AllFragments, skipData } from "../../ArсhitectPage";
import { ArchitectProject } from "../../../../types/ArhitectProject";
import '../../ArhitectStyles/RightBlockWithObjectsStyle.css';
import CollapsibleBlock from "./CollapsibleBlock";
import { toast } from "react-toastify";
import { fetchAllRevitObjectRightBlock } from "../../../../api/APIWraper";
import DeletedObjectBlock from "./DeletedObjectBlock";
import SkippedObjectBlock from "./SkippedObjectBlock";
import ExtractObjectBlock from "./ExtractObjectBlock";
import SelectedFragmentsComponent from "../SelectedFragmentsComponent";

interface RightBlockWithObjectsProps {
    revitProjectId: number;
    allFragments: AllFragments[];
    setArhitectProject: (project: ArchitectProject) => void;
    setAllFragments: (allFragments: (prevFragments: any) => any) => void;
    setSelecting: (boolean: boolean) => void;
    setCurrentIndex: (index: number) => void;
    arhitectProject: ArchitectProject | null;
    isUpdatedFragment: { [key: number]: boolean };
    setIsUpdatedFragment: React.Dispatch<React.SetStateAction<{ [key: number]: boolean }>>;
    currentIndex: number;
    externalIdToDbId: Record<string, number>;
    skipsObjectsRevitProject: { index: number; status: string }[];
    disableSavedIndex: boolean;
    externalIds:string[]
}

const RightBlockWithObjects: React.FC<RightBlockWithObjectsProps> = ({
                                                                         revitProjectId,
                                                                         allFragments,
                                                                         setArhitectProject,
                                                                         arhitectProject,
                                                                         setAllFragments,
                                                                         setSelecting,
                                                                         setCurrentIndex,
                                                                         setIsUpdatedFragment,
                                                                         isUpdatedFragment,
                                                                         currentIndex,
                                                                         externalIdToDbId,
                                                                         skipsObjectsRevitProject,
                                                                         disableSavedIndex,
                                                                         externalIds
                                                                     }) => {
    const [deletedObjects, setDeletedObjects] = useState<skipData[]>([]);
    const [loadingDeleted, setLoadingDeleted] = useState(false);

    const [skippedObjects, setSkippedObjects] = useState<skipData[]>([]);
    const [loadingSkipped, setLoadingSkipped] = useState(false);

    const [extractObjects, setExtractObjects] = useState<skipData[]>([]);
    const [loadingExtract, setLoadingExtract] = useState(false);


    const [openBlocks, setOpenBlocks] = useState<{ [key: string]: boolean }>({
        skip: false,
        delete: false,
        extract: false
    });

    const [selectedExternalId, setSelectedExternalId] = useState<string | null>(null);
    const extractBlockRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        Object.entries(openBlocks).forEach(([key, isOpen]) => {
            if (isOpen) {
                handleToggle(key as "skip" | "delete" | "extract", true);
            }
        });
    }, [skipsObjectsRevitProject, allFragments]);

    useEffect(() => {
        const fetchExtractObjects = async () => {
            setLoadingExtract(true);
            try {
                const response = await fetchAllRevitObjectRightBlock(revitProjectId);
                setExtractObjects(response.data.data);
            } catch (error) {
                toast.error("Failed to fetch extracted objects");
            } finally {
                setLoadingExtract(false);
            }
        };

        if (disableSavedIndex && extractObjects.length === 0) {
            setOpenBlocks(prev => ({ ...prev, extract: true }));
            fetchExtractObjects();
        }
    }, [disableSavedIndex, extractObjects.length, revitProjectId]);

    useEffect(() => {
        if (disableSavedIndex && extractObjects.length > 0) {
            scrollToSelected();
        }
    }, [currentIndex, extractObjects]);

    const scrollToSelected = () => {
        if (!extractBlockRef.current) return;

        const selectedElement = extractBlockRef.current.querySelector(".selected");
        if (selectedElement) {
            selectedElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    const handleToggle = async (relate: string, isOpen: boolean) => {
        setOpenBlocks(prev => ({ ...prev, [relate]: isOpen }));

        if (isOpen) {
            try {
                switch (relate) {
                    case "extract":
                        if (extractObjects.length > 0) return;
                        setLoadingExtract(true);
                        const responseExtract = await fetchAllRevitObjectRightBlock(revitProjectId);
                        setExtractObjects(responseExtract.data.data);
                        setLoadingExtract(false);
                        break;
                    case "skip":
                        setLoadingSkipped(true);
                        const responseSkipped = await fetchAllRevitObjectRightBlock(revitProjectId, "skip");
                        setSkippedObjects(responseSkipped.data.data);
                        setLoadingSkipped(false);
                        break;
                    case "delete":
                        setLoadingDeleted(true);
                        const responseDeleted = await fetchAllRevitObjectRightBlock(revitProjectId, "deleted");
                        setDeletedObjects(responseDeleted.data.data);
                        setLoadingDeleted(false);
                        break;
                }
            } catch (error) {
                toast.error("Failed to fetch revit objects");
            }
        }
    };

    return (
        <div className="revit-right-blocks" ref={extractBlockRef}>
            <CollapsibleBlock title="Extracted objects" relate="extract" onToggle={handleToggle} isOpen={openBlocks.extract}>
                <ExtractObjectBlock
                    skipsObjectsRevitProject={skipsObjectsRevitProject}
                    allFragments={allFragments}
                    disableSavedIndex={disableSavedIndex}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    extractObjects={extractObjects}
                    loadingExtract={loadingExtract}
                    selectedExternalId={selectedExternalId}
                    setSelectedExternalId={setSelectedExternalId}
                    externalIds={externalIds}
                />
            </CollapsibleBlock>

            <CollapsibleBlock title="Skipped objects" relate="skip" onToggle={handleToggle} isOpen={openBlocks.skip}>
                <SkippedObjectBlock
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    externalIdToDbId={externalIdToDbId}
                    skippedObjects={skippedObjects}
                    loadingSkipped={loadingSkipped}
                    setSelectedExternalId={setSelectedExternalId}
                    selectedExternalId={selectedExternalId}
                />
            </CollapsibleBlock>

            <CollapsibleBlock title="Deleted objects" relate="delete" onToggle={handleToggle} isOpen={openBlocks.delete}>
                <DeletedObjectBlock
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    externalIdToDbId={externalIdToDbId}
                    loadingDeleted={loadingDeleted}
                    deletedObjects={deletedObjects}
                    setSelectedExternalId={setSelectedExternalId}
                    selectedExternalId={selectedExternalId}
                />
            </CollapsibleBlock>

            <CollapsibleBlock title="Selected fragments" relate="selected" onToggle={handleToggle} isOpen={openBlocks.selected}>
                <SelectedFragmentsComponent
                    arhitectProject={arhitectProject}
                    allFragments={allFragments}
                    currentIndex={currentIndex}
                    setArhitectProject={setArhitectProject}
                    setAllFragments={setAllFragments}
                    setSelecting={setSelecting}
                    setCurrentIndex={setCurrentIndex}
                    setIsUpdatedFragment={setIsUpdatedFragment}
                    isUpdatedFragment={isUpdatedFragment}
                />
            </CollapsibleBlock>
        </div>
    );
};

export default RightBlockWithObjects;
