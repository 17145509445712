import React, { useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from "../store";
import { checkAuthentication } from "../Slice/AuthSlice/AuthSlice";
import {selectUser} from "../Slice/tokenSlice/userSlice";
import {useSmartNavigate} from "../Hooks/useNavigationHook";

interface SessionPolicyProps {
    children: React.ReactNode;
    isAuthenticated: boolean;
    hasBeenAuthenticated: boolean;
    checkAuthentication: () => void;
}

const publicRoutes = [
    '/', '/home', '/aboutUs', '/functionality', '/support', '/pricing',
    '/termsAndConditions', '/dataProtection', '/auth/login',
    '/create/step2Bom', '/create/step3Bom'
];

const SessionPolicy: React.FC<SessionPolicyProps> = ({ children, isAuthenticated, checkAuthentication }) => {
    const location = useLocation();
    const bearerToken = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');
    const user = useSelector(selectUser);
    const smartNavigate = useSmartNavigate();

    useEffect(() => {
        checkAuthentication();

        const isPublicRoute = publicRoutes.some(route =>
            route === '/'
                ? location.pathname === '/'
                : location.pathname.startsWith(route)
        );

        if (isPublicRoute) {
            if (location.pathname === '/auth/login' && isAuthenticated) {
                smartNavigate('/home');
            }
            return;
        }

        if (isAuthenticated) {
            const currentRole = user?.role?.role ?? "";
            const oldRole = localStorage.getItem('oldRole');

            if (currentRole) {
                if (!oldRole) {
                    localStorage.setItem('oldRole', currentRole);
                } else if (currentRole !== oldRole) {
                    localStorage.setItem('oldRole', currentRole);
                }

                if (currentRole === 'Contractor' && oldRole !== 'Contractor') {
                    const hasSeenContractorGuide = localStorage.getItem('hasSeenContractorGuide');
                    // if (!hasSeenContractorGuide) {
                    //     navigate('/guide/step1');
                    // }
                }
            }

            const hasSeenGuide = localStorage.getItem('hasSeenGuide');
            // if (!hasSeenGuide && currentRole === 'Customer') {
            //     localStorage.setItem('hasSeenGuide', 'true');
            //     navigate('/guide-new-user');
            // }

            // if (location.pathname === '/auth/login') {
            //     navigate('/home');
            // }
        } else {
            localStorage.setItem('redirectAfterLogin', location.pathname);
            smartNavigate('/auth/login');
            toast.error('Authentication required!', {
                position: "bottom-center",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                    backgroundColor: "#ffcccc",
                    color: "#660000",
                    fontFamily: "Segoe UI, Roboto, Arial, sans-serif",
                    fontSize: "16px",
                    fontWeight: "500",
                    width: "30vw",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                },
                autoClose: 3000
            });
        }

        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'Bearer' && (!event.newValue || event.newValue !== bearerToken)) {
                toast.error('Authentication required!', { autoClose: 3000 });
            }
        };

        window.addEventListener('storage', handleStorageChange);

        const intervalId = setInterval(() => {
            const currentBearerToken = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');
            if (!currentBearerToken) {
                toast.error('Authentication required!', { autoClose: 3000 });
            }
        }, 1000);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            clearInterval(intervalId);
        };
    }, [bearerToken, location.pathname, smartNavigate, checkAuthentication, isAuthenticated]);

    return <>{children}</>;
};

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuthenticated,
    hasBeenAuthenticated: state.auth.hasBeenAuthenticated,
});

const mapDispatchToProps = {
    checkAuthentication,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionPolicy);
