import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    deleteProjectById,
    getAllProjectsAssistance,
    getAllProjectsAssistanceTemplateModal,
    getLastProjectsAssistance,
} from "../../api/APIWraper";
import {Project} from "../../types/ProjectTypes";
import {updateProjectPermissions} from "../../Slice/updateProject/updateProjectSlice";

export interface ProjectsAssistanceState {
    projects: Project[];
    allProjects: Project[];
    allProjectsModalTemplate: Project[];
    total: number;
    isFetching: boolean;
    isFetchingLazy: boolean;
    error: string | null;
    currentPage: number;
    lazyPage: number;
    lastPage: number;
    hasMoreLazy: boolean;
    pages: Record<number, Project[]>;
    sortBy: string | null;
    sortOrder: 'asc' | 'desc' | null;
}

export const initialState: ProjectsAssistanceState = {
    projects: [],
    allProjects: [],
    allProjectsModalTemplate: [],
    total: 0,
    isFetching: false,
    isFetchingLazy: false,
    error: null,
    currentPage: 1,
    lazyPage: 1,
    lastPage: 1,
    hasMoreLazy: true,
    pages: {},
    sortBy: null,
    sortOrder: null
};

export const fetchAllProjects = createAsyncThunk<
    { allProjects: Project[]; total: number },
    { page: number; perPage: number; sortBy?: string; sortOrder?: 'asc' | 'desc';queryParams?:string },
    { rejectValue: string }
>(
    'projectAssistance/fetchAllProjects',
    async ({ page, perPage, sortBy, sortOrder,queryParams}, { rejectWithValue }) => {
        try {
            return await getAllProjectsAssistance(page, perPage, sortBy, sortOrder,queryParams);
        } catch (error: any) {
            return rejectWithValue(error.message || 'Error loading projects');
        }
    }
);


export const fetchLazyProjects = createAsyncThunk<
    { projects: Project[]; pagination: any; page: number},
    {queryParams?:string; page?: number; perPage?: number },
    { rejectValue: string }
>(
    'projectAssistance/fetchLazyProjects',
    async ({ page = 1, perPage = 10, queryParams }, { rejectWithValue }) => {
        try {
            const response = await getAllProjectsAssistanceTemplateModal(page, perPage, queryParams);
            return {
                projects: response.projects,
                pagination: response.pagination,
                page,
            };
        } catch (error: any) {
            return rejectWithValue(error.message || 'Error when lazy loading projects');
        }
    }
);

export const deleteProject = createAsyncThunk<
    number,
    number,
    { rejectValue: string }
>(
    'projectAssistance/deleteProject',
    async (projectId, { rejectWithValue }) => {
        try {
            const response = await deleteProjectById(projectId);

            if (response.status === 200 || response.status === 204) {
                return projectId;
            } else if (
                response.status === 401 ||
                response.status === 403 ||
                response.status === 404
            ) {
                return rejectWithValue("You don't have enough rights");
            } else {
                return rejectWithValue('Failed to delete project. Please try again later.');
            }
        } catch (error: any) {
            return rejectWithValue(error.message || 'Unexpected error occurred while deleting the project');
        }
    }
);


const projectAssistanceSlice = createSlice({
    name: 'projectAssistance',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProjects.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(fetchAllProjects.fulfilled, (state, action: PayloadAction<{ allProjects: Project[]; total: number }>) => {
                state.isFetching = false;
                state.allProjects = action.payload.allProjects;
                state.total = action.payload.total;
            })
            .addCase(fetchAllProjects.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.payload || action.error.message || 'Error loading projects';
            })


            .addCase(fetchLazyProjects.pending, (state) => {
                state.isFetchingLazy = true;
                state.error = null;
            })
            .addCase(fetchLazyProjects.fulfilled, (
                state,
                action: PayloadAction<{ projects: Project[]; pagination: any; page: number }>
            ) => {
                state.isFetchingLazy = false;
                const { projects = [], pagination, page } = action.payload;

                if (!Array.isArray(projects) || !pagination) {
                    state.error = 'Incorrect data from the server';
                    return;
                }

                state.lazyPage = page;
                state.total = pagination.total;
                state.hasMoreLazy = page < pagination.lastPage;

                const existingProjectIds = new Set(state.allProjectsModalTemplate.map((proj) => proj.id));
                const newProjects = projects.filter((proj) => !existingProjectIds.has(proj.id));
                state.allProjectsModalTemplate = [...state.allProjectsModalTemplate, ...newProjects];
            })
            .addCase(fetchLazyProjects.rejected, (state, action) => {
                state.isFetchingLazy = false;
                state.error = action.payload || action.error.message || 'Error when lazy loading projects';
            })

            .addCase(deleteProject.fulfilled, (state, action: PayloadAction<number>) => {
                const projectId = action.payload;
                state.allProjects = state.allProjects.filter(project => project.id !== projectId);
                Object.keys(state.pages).forEach((page) => {
                    state.pages[parseInt(page)] = state.pages[parseInt(page)].filter(
                        (project) => project.id !== projectId
                    );
                });
                state.total = state.allProjects.length;

                state.projects = state.pages[state.currentPage] || [];
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.error = action.payload || action.error.message || 'Error when deleting a project';
            })

            .addCase(updateProjectPermissions.fulfilled, (state, action: PayloadAction<Project>) => {
                const updatedProject = action.payload;
                state.allProjects = state.allProjects.map((project) =>
                    project.id === updatedProject.id ? updatedProject : project
                );
                Object.keys(state.pages).forEach((page) => {
                    state.pages[parseInt(page)] = state.pages[parseInt(page)].map((project) =>
                        project.id === updatedProject.id ? updatedProject : project
                    );
                });
                state.projects = state.pages[state.currentPage] || [];
            });
    },
});

export default projectAssistanceSlice.reducer;
