import React from 'react';
import Modal from 'react-modal';
import './ModalDelete.css';
import {IconWarning} from "../../../IconComponents/IconComponents";

interface ModalDeleteProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
    message: string;
    confirmText: string;
    cancelText: string;
    paidTemplate?: boolean;
}

const ModalDelete: React.FC<ModalDeleteProps> = ({
                                                     isOpen,
                                                     onRequestClose,
                                                     onConfirm,
                                                     onCancel,
                                                     message,
                                                     confirmText,
                                                     cancelText,
                                                     paidTemplate
                                                 }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="warning-modal"
            overlayClassName="overlay-delete"
        >
            <div className="warning">
                <button className="close-button" onClick={onCancel}>&times;</button>
            </div>
            <div className="warning">
                {paidTemplate && <IconWarning/>}
                <p>{message}</p>
                {paidTemplate &&
                    <p className='modal-delete-text-template'>
                        Deleting this project will result in losing access to all paid templates associated with it.
                        <span className='modal-delete-text-template-color'>Paid templates will need to be re-purchased</span> if you want to use them in the future.
                    </p>
                }
                <div className="confirmation-buttons">
                    <button className="cancel-button" onClick={onCancel}>{paidTemplate ? 'Cansel' : cancelText}</button>
                    <button className="confirm-button" onClick={onConfirm}>{paidTemplate ? 'Confirm Deletion' : confirmText}</button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalDelete;
