import React, {useMemo, useEffect, useState} from 'react';
import {Project} from "../../types/ProjectTypes";
import {ItemType} from "../../types/TemplateType";
import './TotalsBlockCustomer.css';
import {IconStep2VisibleArrowBottom, IconStep2VisibleArrowTop} from "../../IconComponents/IconComponents";

interface ProjectTotalCostsProps {
    project: Project;
    indexTemplate?: string;
    isSmallScreen: boolean;
    totalLabor: number;
    totalTools: number;
    totalMaterialPriceItem: number;
    isVisible: boolean;

}

const StepTotalsBlock: React.FC<ProjectTotalCostsProps> = ({
                                                               project,
                                                               indexTemplate,
                                                               isSmallScreen,
                                                               totalTools,
                                                               totalLabor,
                                                               totalMaterialPriceItem,
                                                               isVisible
                                                           }) => {
    const [totalLaborProjectPrice, setTotalLaborProjectPrice] = useState(0);
    const [totalToolProjectPrice, setTotalToolProjectPrice] = useState(0);
    const [totalMaterialProjectPrice, setTotalMaterialProjectPrice] = useState(0);

    const [totalLaborProjectOrderd, setTotalLaborProjectOrdered] = useState(0);
    const [totalToolProjectOrderd, setTotalToolProjectOrdered] = useState(0);
    const [totalMaterialProjectOrderd, setTotalMaterialProjectOrdered] = useState(0);


    const [isExpanded, setIsExpanded] = useState(isVisible);
    useEffect(() => {
        setIsExpanded(!isVisible);
    }, [isVisible]);

    const toggleVisibility = () => setIsExpanded((prev) => !prev);

    const calculateTotalPrice = (type: string, isOrdered: boolean) => {
        const templateIndex = Number(indexTemplate);
        if (isNaN(templateIndex) || !project?.data?.templates?.[templateIndex]?.data?.fragments) {
            return 0;
        }

        return project.data.templates[templateIndex].data.fragments.reduce((acc: number, fragment: any) => {
            if (!fragment.data?.item_types) return acc;

            return (
                acc +
                fragment.data.item_types.reduce((innerAcc: number, itemType: ItemType) => {
                    if (!itemType.items) return innerAcc;

                    return (
                        innerAcc +
                        itemType.items
                            .filter(item => {
                                if (type === 'labor') return itemType.labor === true;
                                if (type === 'tool') return itemType.tool === true;
                                return !itemType.labor && !itemType.tool;
                            })
                            .reduce((sum, item) => {
                                const quantity = isOrdered ? (item.ordered_qty || 0) : item.qty;
                                const price = parseInt(String(item.price));
                                return sum + (quantity * price);
                            }, 0)
                    );
                }, 0)
            );
        }, 0);
    };


    const totalLaborProject = useMemo(() => calculateTotalPrice('labor', false), [project, indexTemplate]);
    const totalLaborProjectOrdered = useMemo(() => calculateTotalPrice('labor', true), [project, indexTemplate]);
    const totalToolsProject = useMemo(() => calculateTotalPrice('tool', false), [project, indexTemplate]);
    const totalToolsProjectOrdered = useMemo(() => calculateTotalPrice('tool', true), [project, indexTemplate]);
    const totalMaterialProject = useMemo(() => calculateTotalPrice('material', false), [project, indexTemplate]);
    const totalMaterialProjectOrdered = useMemo(() => calculateTotalPrice('material', true), [project, indexTemplate]);


    useEffect(() => {
        setTotalLaborProjectPrice(totalLaborProject);
        setTotalToolProjectPrice(totalToolsProject);
        setTotalMaterialProjectPrice(totalMaterialProject);

        setTotalLaborProjectOrdered(totalLaborProjectOrdered);
        setTotalToolProjectOrdered(totalToolsProjectOrdered);
        setTotalMaterialProjectOrdered(totalMaterialProjectOrdered);
    }, [totalLaborProject, totalToolsProject, totalMaterialProject, totalLaborProjectOrdered, totalToolsProjectOrdered, totalMaterialProjectOrdered]);

    if (!isExpanded && isVisible) {
        return (
            <div className='total-block-btn'>
                <div
                    className={`toggle-visibility-button ${isExpanded ? 'expanded' : 'collapsed'}`}
                    onClick={toggleVisibility}
                >
                    <p>{isExpanded ? 'Hide' : 'Show'}</p>
                    <div>{isExpanded ? <IconStep2VisibleArrowBottom/> : <IconStep2VisibleArrowTop/>}</div>
                </div>
            </div>
        );
    }

    return (

        <div className={isVisible ? "total-block" : 'total-block-none-visible'}>
            {isVisible && (
                <div
                    className={`toggle-visibility-button ${isExpanded ? 'expanded' : 'collapsed'}`}
                    onClick={toggleVisibility}
                >
                    <p>{isExpanded ? 'Hide' : 'Show'}</p>
                    <div>{isExpanded ? <IconStep2VisibleArrowBottom/> : <IconStep2VisibleArrowTop/>}</div>
                </div>
            )}

            <div className='total-block-price'>
                <div className='step3-page-bottom-total-container'>
                    <div className='step3-page-bottom-block-price'>
                        <h3 className='step3-page-bottom-block-total'>Total project costs:</h3>
                        <div className='step3-page-bottom-total-price-block'>
                            <p>Labour:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {totalLaborProjectPrice.toFixed(2)}</span>
                        </div>

                        <div className='step3-page-bottom-total-price-block'>
                            <p>Tools:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {totalToolProjectPrice.toFixed(2)}</span>
                        </div>

                        <div className='step3-page-bottom-total-price-block'>
                            <p>Materials:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {totalMaterialProjectPrice.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='step3-page-bottom-total-price-block-total'>
                        <p>Grand total:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span className='step3-page-bottom-price-total'>
              R {(totalLaborProjectPrice + totalToolProjectPrice + totalMaterialProjectPrice).toFixed(2)}
            </span>
                    </div>
                </div>

                <div className='step3-page-bottom-total-container step3-page-bottom-total-container-border'>
                    <div className='step3-page-bottom-block-price'>
                        <h3 className='step3-page-bottom-block-total'>Total costs to complete:</h3>
                        <div className='step3-page-bottom-total-price-block'>
                            <p>Labours:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {totalLaborProjectOrderd.toFixed(2)}</span>
                        </div>

                        <div className='step3-page-bottom-total-price-block'>
                            <p>Tools:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {totalToolProjectOrderd.toFixed(2)}</span>
                        </div>

                        <div className='step3-page-bottom-total-price-block'>
                            <p>Materials:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span
                                className='step3-page-bottom-price'>R {totalMaterialProjectOrderd.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='step3-page-bottom-total-price-block-total'>
                        <p>Grand total:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span className='step3-page-bottom-price-total'>
              R {(totalLaborProjectOrderd + totalToolProjectOrderd + totalMaterialProjectOrderd).toFixed(2)}
            </span>
                    </div>
                </div>
            </div>
            <div className='step3-page-bottom-total-container'>
                <div className='step3-page-bottom-block-price'>
                    <h3 className='step3-page-bottom-block-total'>Total selected for purchase:</h3>
                    <div className='step3-page-bottom-total-price-block'>
                        <p>Labours:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span
                            className='step2-page-bottom-price'>R {Math.max(0, totalLabor).toFixed(2)}</span>
                    </div>
                    <div className='step3-page-bottom-total-price-block'>
                        <p>Tools:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span
                            className='step2-page-bottom-price'>R {Math.max(0, totalTools).toFixed(2)}</span>
                    </div>

                    <div className='step3-page-bottom-total-price-block'>
                        <p>Materials:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span
                            className='step2-page-bottom-price'>R {Math.max(0, totalMaterialPriceItem).toFixed(2)}</span>
                    </div>

                </div>
                <div className='step3-page-bottom-total-price-block-total'>
                    <p>Grand total:</p>
                    {isSmallScreen && <span className='dashed-line'></span>}
                    <span
                        className='step3-page-bottom-price-total'>R {(totalLabor + totalMaterialPriceItem + totalTools).toFixed(2)}</span>
                </div>
            </div>
        </div>

    );
};

export default StepTotalsBlock;
