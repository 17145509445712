import React, { useEffect, useState } from 'react';
import '../TotalsBlockCustomer/TotalsBlockCustomer.css';
import { IconStep2VisibleArrowBottom, IconStep2VisibleArrowTop } from "../../IconComponents/IconComponents";
import { ArchitectProject } from "../../types/ArhitectProject";

interface ProjectTotalCostsProps {
    project: ArchitectProject | null;
    isSmallScreen: boolean;
    fragmentTotal: number;
    isVisible: boolean;
}

const StepTotalsBlockArchitector: React.FC<ProjectTotalCostsProps> = ({
                                                                          project,
                                                                          isSmallScreen,
                                                                          fragmentTotal,
                                                                          isVisible
                                                                      }) => {
    const [isExpanded, setIsExpanded] = useState(isVisible);
    const [projectFragmentTotal, setProjectFragmentTotal] = useState<number>(0);
    const [projectCustomFragmentTotal, setProjectCustomFragmentTotal] = useState<number>(0);
    const [projectTotal, setProjectTotal] = useState<number>(0);

    const [projectFragmentTotalComplete, setProjectFragmentTotalComplete] = useState<number>(0);
    const [projectCustomFragmentTotalComplete, setProjectCustomFragmentTotalComplete] = useState<number>(0);
    const [projectTotalComplete, setProjectTotalComplete] = useState<number>(0);

    useEffect(() => {
        if (project !== null) {
            const fragmentTotal = Array.isArray(project.data.fragments)
                ? project.data.fragments.reduce((acc: number, fragment: any) => {
                    const fragmentItemTypeTotal = Array.isArray(fragment.data.item_types)
                        ? fragment.data.item_types.reduce((fragAcc: number, itemType: any) => {
                            const itemTotal = Array.isArray(itemType.items)
                                ? itemType.items.reduce((itemAcc: number, item: any) => {
                                    const qty = item.qty || 0;
                                    const price = parseFloat(item.price || '0');
                                    return itemAcc + qty * price;
                                }, 0)
                                : 0;
                            return fragAcc + itemTotal;
                        }, 0)
                        : 0;
                    return acc + fragmentItemTypeTotal;
                }, 0)
                : 0;

            const fragmentTotalComplete = Array.isArray(project.data.fragments)
                ? project.data.fragments.reduce((acc: number, fragment: any) => {
                    const fragmentItemTypeTotal = Array.isArray(fragment.data.item_types)
                        ? fragment.data.item_types.reduce((fragAcc: number, itemType: any) => {
                            const itemTotal = Array.isArray(itemType.items)
                                ? itemType.items.reduce((itemAcc: number, item: any) => {
                                    const qty = item.ordered_qty || 0;
                                    const price = parseFloat(item.price || '0');
                                    return itemAcc + qty * price;
                                }, 0)
                                : 0;
                            return fragAcc + itemTotal;
                        }, 0)
                        : 0;
                    return acc + fragmentItemTypeTotal;
                }, 0)
                : 0;

            setProjectFragmentTotal(fragmentTotal);
            setProjectFragmentTotalComplete(fragmentTotalComplete);

            const customFragmentTotal = Array.isArray(project.data.customFragments)
                ? project.data.customFragments.reduce((acc: number, fragment: any) => {
                    const fragmentItemTypeTotal = Array.isArray(fragment.data.item_types)
                        ? fragment.data.item_types.reduce((fragAcc: number, itemType: any) => {
                            const itemTotal = Array.isArray(itemType.items)
                                ? itemType.items.reduce((itemAcc: number, item: any) => {
                                    const qty = item.qty || 0;
                                    const price = parseFloat(item.price || '0');
                                    return itemAcc + qty * price;
                                }, 0)
                                : 0;
                            return fragAcc + itemTotal;
                        }, 0)
                        : 0;
                    return acc + fragmentItemTypeTotal;
                }, 0)
                : 0;

            const customFragmentTotalComplete = Array.isArray(project.data.customFragments)
                ? project.data.customFragments.reduce((acc: number, fragment: any) => {
                    const fragmentItemTypeTotal = Array.isArray(fragment.data.item_types)
                        ? fragment.data.item_types.reduce((fragAcc: number, itemType: any) => {
                            const itemTotal = Array.isArray(itemType.items)
                                ? itemType.items.reduce((itemAcc: number, item: any) => {
                                    const qty = item.ordered_qty || 0;
                                    const price = parseFloat(item.price || '0');
                                    return itemAcc + qty * price;
                                }, 0)
                                : 0;
                            return fragAcc + itemTotal;
                        }, 0)
                        : 0;
                    return acc + fragmentItemTypeTotal;
                }, 0)
                : 0;

            setProjectCustomFragmentTotal(customFragmentTotal);
            setProjectCustomFragmentTotalComplete(customFragmentTotalComplete);

            const total = fragmentTotal + customFragmentTotal;
            setProjectTotal(total);

            const totalComplete = fragmentTotalComplete + customFragmentTotalComplete;
            setProjectTotalComplete(totalComplete);
        }
    }, [project]);

    useEffect(() => {
        setIsExpanded(!isVisible);
    }, [isVisible]);

    const toggleVisibility = () => setIsExpanded((prev) => !prev);

    if (!isExpanded && isVisible) {
        return (
            <div className='total-block-btn'>
                <div
                    className={`toggle-visibility-button ${isExpanded ? 'expanded' : 'collapsed'}`}
                    onClick={toggleVisibility}
                >
                    <p>{isExpanded ? 'Hide' : 'Show'}</p>
                    <div>{isExpanded ? <IconStep2VisibleArrowBottom /> : <IconStep2VisibleArrowTop />}</div>
                </div>
            </div>
        );
    }

    return (
        <div className={isVisible ? "total-block" : 'total-block-none-visible'}>
            {isVisible && (
                <div
                    className={`toggle-visibility-button ${isExpanded ? 'expanded' : 'collapsed'}`}
                    onClick={toggleVisibility}
                >
                    <p>{isExpanded ? 'Hide' : 'Show'}</p>
                    <div>{isExpanded ? <IconStep2VisibleArrowBottom /> : <IconStep2VisibleArrowTop />}</div>
                </div>
            )}

            <div className='total-block-price'>
                <div className='step3-page-bottom-total-container'>
                    <div className='step3-page-bottom-block-price'>
                        <h3 className='step3-page-bottom-block-total'>Total project costs:</h3>
                        <div className='step3-page-bottom-total-price-block'>
                            <p>Fragments:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span className='step3-page-bottom-price'>R {projectFragmentTotal.toFixed(2)}</span>
                        </div>

                        <div className='step3-page-bottom-total-price-block'>
                            <p>Custom Fragments:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span className='step3-page-bottom-price'>R {projectCustomFragmentTotal.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='step3-page-bottom-total-price-block-total'>
                        <p>Grand total:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span className='step3-page-bottom-price-total'>
                            R {projectTotal.toFixed(2)}
                        </span>
                    </div>
                </div>

                <div className='step3-page-bottom-total-container step3-page-bottom-total-container-border'>
                    <div className='step3-page-bottom-block-price'>
                        <h3 className='step3-page-bottom-block-total'>Total costs to complete:</h3>
                        <div className='step3-page-bottom-total-price-block'>
                            <p>Fragments:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span className='step3-page-bottom-price'>R {Math.max(0, (projectFragmentTotal - projectFragmentTotalComplete)).toFixed(2)}</span>
                        </div>

                        <div className='step3-page-bottom-total-price-block'>
                            <p>Custom Fragments:</p>
                            {isSmallScreen && <span className='dashed-line'></span>}
                            <span className='step3-page-bottom-price'>R {Math.max(0, (projectCustomFragmentTotal - projectCustomFragmentTotalComplete)).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='step3-page-bottom-total-price-block-total'>
                        <p>Grand total:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span className='step3-page-bottom-price-total'>
                            R {Math.max(0, (projectTotal - projectTotalComplete)).toFixed(2)}
                        </span>
                    </div>
                </div>
            </div>

            <div className='step3-page-bottom-total-container'>
                <div className='step3-page-bottom-block-price'>
                    <h3 className='step3-page-bottom-block-total'>Total selected for purchase:</h3>
                    <div className='step3-page-bottom-total-price-block'>
                        <p>Fragments:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span className='step2-page-bottom-price'>R {Math.max(0, fragmentTotal).toFixed(2)}</span>
                    </div>

                    <div className='step3-page-bottom-total-price-block'>
                        <p>Custom Fragments:</p>
                        {isSmallScreen && <span className='dashed-line'></span>}
                        <span className='step2-page-bottom-price'>R {Math.max(0, fragmentTotal).toFixed(2)}</span>
                    </div>
                </div>
                <div className='step3-page-bottom-total-price-block-total'>
                    <p>Grand total:</p>
                    {isSmallScreen && <span className='dashed-line'></span>}
                    <span className='step3-page-bottom-price-total'>
                        R {Math.max(0, (fragmentTotal + projectCustomFragmentTotal)).toFixed(2)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default StepTotalsBlockArchitector;
