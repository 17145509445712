import React, {useState, useEffect} from 'react';
import {connect, ConnectedProps} from "react-redux";
import {ParsedFragment, ParsedItemType, TItem, TMerchanInfo} from "../../../types/TemplateType";
import {Project} from "../../../types/ProjectTypes";
import {get, patch} from "../../../api/APIWraper";
import {updateLocalTemplate} from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import ItemSelectionModal from "../ItemSelectionModal";
import "../Step2Styles/step2.css";
import "../Step2Styles/step2-width.css";
import "../Step2Styles/itemModal.css";
import {Vendor, vendorData} from "../../../Enums/VendorEnum";
import {setPageActiveCalculation} from "../../../Slice/ClickSlice/FragmentVisibityState";
import {IconArrowLightStpe2, IconEyesStep3, IconStep2SelectArrow} from "../../../IconComponents/IconComponents";
import {SelectedItem} from "../../../Interfaces/ProjectInterfaces/SelectedItems";
import {formatNumber} from "../../../utils/FormatNumber";
import {generateItemKey, useVisitedItems} from "../../../Hooks/Step2Customer/Step2Hooks";

interface ItemTypeListCalculationProps {
    fragment: ParsedFragment;
    fragmentIndex: number;
    templateIndex: number;
    templateId: number;
    project: Project;
    viewMode: 'Work Stage' | 'Materials|Labor|Tools';
    onUpdateProject: (newProject: Project) => void;
    onToggle: () => void;
    toggleActive:boolean
}

export interface SelectedItemInfo {
    merchant_info?: TMerchanInfo;
    packaging: any;
    vendor: Vendor | null;
    user_item_qty?: number;
    item_packaging_qty?: number;
    item_packaging_value?: string;
    title: string;
    id: number;
    qty: number;
    ordered_qty: number;
    unit: string;
    price: number;
    fragments?: Array<any>;
}


interface ItemTypeWithIndex extends ParsedItemType {
    originalIndex: number;
}

type HandleSave = Array<{ id: number; qty: number }>;
type HandleSaveRec = Record<string, HandleSave | any>;

const ItemTypeListCalculation: React.FC<ItemTypeListCalculationProps & PropsFromRedux> = ({
                                                                                              fragment,
                                                                                              fragmentIndex,
                                                                                              templateId,
                                                                                              templateIndex,
                                                                                              project,
                                                                                              viewMode,
                                                                                              onUpdateProject,
                                                                                              onToggle,
                                                                                              setPageActiveCalculation
                                                                                          }) => {
    const { handleLogoClick, isVisited } = useVisitedItems();

    const [selectedItemTypeIndex, setSelectedItemTypeIndex] = useState<number | null>(null);
    const [items, setItems] = useState<TItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedItemInfos, setSelectedItemInfos] = useState<{ [id: string]: SelectedItemInfo }>({});
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<{ [id: string]: string }>({});
    const [parsedItemTypes, setParsedItemTypes] = useState<{ [index: number]: any }>({});
    const [itemTypeUnit, setItemTypeUnit] = useState<string>('');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});

    const generateItemTypeKey = (itemType: ItemTypeWithIndex) => {
        return `${itemType.id}-${itemType.index}-${itemType.originalIndex}-${fragmentIndex}`;
    };

    const toggleVisibility = (itemTypeKey: string) => {
        setVisibilityMap((prevMap) => ({
            ...prevMap,
            [itemTypeKey]: !prevMap[itemTypeKey],
        }));
    };

    const isVisible = (itemTypeKey: string) => {
        return visibilityMap[itemTypeKey] || false;
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setPageActiveCalculation(true);
        return () => {
            setPageActiveCalculation(false);
        };
    }, [setPageActiveCalculation]);

    useEffect(() => {
        if (Array.isArray(fragment.item_types)) {
            const fragmentItems = fragment.item_types.flatMap((itemType) =>
                (itemType.items || []).map((item, itemIdx) => {
                    const packagingKeys = item.packaging ? Object.keys(item.packaging) : [];
                    return {
                        itemTypeId: itemType.id,
                        itemId: item.id,
                        item_packaging_qty: packagingKeys.length > 0 ? item.packaging[packagingKeys[0]] : 1,
                        item_packaging_value: packagingKeys.length > 0 ? packagingKeys[0] : 'each',
                        index: itemType.index,
                        originalIndex: 0,
                        user_item_qty: item.qty,
                        clone_id: `${item.id}-${itemType.index}-${itemIdx}-${JSON.stringify(itemType.fragments)}`,
                        fragments: itemType.fragments,
                        template_id:0,
                        template_index: 0,
                        fragment_id:0,
                        fragment_index:0,
                    };
                })
            );

            const initialSelectedItems = [
                ...fragmentItems,
                ...fragment.item_types
                    .map((itemType) => ({...itemType, originalIndex: itemType.index}))
                    .filter(itemType => itemType.item && (viewMode === 'Work Stage' || (!itemType.labor && !itemType.tool)))
                    .map((itemType,index) => {
                        const packagingKeys = itemType.item.packaging ? Object.keys(itemType.item.packaging) : [];
                        return {
                            itemTypeId: itemType.id,
                            itemId: itemType.item.id,
                            index: itemType.originalIndex,
                            user_item_qty: itemType.item.user_item_qty,
                            item_packaging_qty: packagingKeys.length > 0 ? itemType.item.packaging[packagingKeys[0]] : 1,
                            item_packaging_value: packagingKeys.length > 0 ? packagingKeys[0] : 'each',
                            clone_id: `${itemType.id}-${itemType.originalIndex}-${itemType.index}-${JSON.stringify(itemType.fragments)}`,
                            originalIndex: itemType.index,
                            fragments: itemType.fragments,
                            template_id:0,
                            template_index: 0,
                            fragment_id:0,
                            fragment_index:0,
                        };
                    })
            ];

            setSelectedItems(initialSelectedItems);

            const initialSelectedItemInfos = initialSelectedItems.reduce((acc, selectedItem) => {
                const itemType = fragment.item_types[selectedItem.index];
                const item = itemType.items ? itemType.items.find(it => it.id === selectedItem.itemId) : null;
                if (itemType && item) {
                    const packagingKeys = item.packaging ? Object.keys(item.packaging) : [];
                    acc[selectedItem.clone_id] = {
                        merchant_info: {} as TMerchanInfo,
                        vendor: item.vendor,
                        user_item_qty: selectedItem.user_item_qty,
                        item_packaging_qty: item.item_packaging_qty || 1,
                        item_packaging_value: packagingKeys.length > 0 ? packagingKeys[0] : 'each',
                        title: item.title,
                        id: item.id,
                        qty: item.qty || 0,
                        ordered_qty: item.ordered_qty || 0,
                        unit: item.unit,
                        price: item.price || 0,
                        packaging: item.packaging,
                        fragments: itemType.fragments,
                    };
                }
                return acc;
            }, {} as { [id: string]: SelectedItemInfo });

            setSelectedItemInfos(initialSelectedItemInfos);

            const parsedItemTypes = fragment.item_types.reduce((acc, itemType, index) => {
                if (viewMode === 'Materials|Labor|Tools' && (itemType.labor || itemType.tool)) {
                    return acc;
                }
                const clone_id = `${itemType.id}-${index}-${index}`;
                const item = initialSelectedItemInfos[clone_id];
                const title = itemType.title;
                let qty = item ? item.qty : itemType.qty;
                if (!qty) {
                    qty = 0;
                }
                let orderedQty = item ? item.ordered_qty : (itemType?.ordered_qty || 0);
                if (!orderedQty) {
                    orderedQty = 0;
                }
                const price = itemType.item ? itemType.item.price : 0;
                const priceCurrentQty = item ? item.price * orderedQty : 0;
                const priceTotalQty = item ? item.price * qty : 0;
                const isItemSelected = !!itemType.item;
                const unit = itemType.item ? itemType.item.unit : '';

                acc[index] = {
                    index,
                    title,
                    qty,
                    orderedQty,
                    price,
                    priceCurrentQty,
                    priceTotalQty,
                    isItemSelected,
                    unit
                };

                return acc;
            }, {} as { [index: number]: any });

            setParsedItemTypes(parsedItemTypes);
            calculateTotalPrice();
        }
    }, [fragment.item_types, viewMode]);

    useEffect(() => {
        calculateTotalPrice();
    }, [selectedItemInfos]);

    useEffect(() => {
        const handler = setTimeout(() => {
            Object.keys(debouncedOrderedQty).forEach(id => {
                setSelectedItemInfos((prev) => {
                    const newOrderedQty = parseInt(debouncedOrderedQty[id]) || 0;
                    return {
                        ...prev,
                        [id]: {
                            ...prev[id],
                            ordered_qty: newOrderedQty,
                        },
                    };
                });
            });
            calculateTotalPrice();
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedOrderedQty, selectedItems]);

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        selectedItems.forEach((selectedItem) => {
            const item = selectedItemInfos[selectedItem.clone_id];
            if (item) {
                const generalPrice = item.price * (item.user_item_qty || item.qty);
                const difTotalPrice = Math.max(0, item.price * Math.min(item.ordered_qty, (item.user_item_qty || item.qty)));
                totalPrice += (generalPrice - difTotalPrice);
            }
        });
        setTotalPrice(totalPrice);
    };

    const storeId = project?.store_id ? project.store_id : 0;

    const fetchItems = async (index: number, page: number = 1, searchTerm: string = '') => {
        setSelectedItemTypeIndex(index);
        setLoading(true);
        try {
            const itemType = fragment.item_types[index];
            const itemTypeUnit = itemType.unit;

            const shouldIncludeUnit = itemTypeUnit && itemTypeUnit !== "each" && itemTypeUnit !== "psc";
            const packagingParam = shouldIncludeUnit ? `&packaging=${encodeURIComponent(itemTypeUnit)}` : "";
            let url = `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemType.id}?page=${page}&perPage=${itemsPerPage}&storeIdFromProjects=${storeId}`;

            if (searchTerm) {
                url += `&title=${encodeURIComponent(searchTerm)}`;
            }
            if (shouldIncludeUnit) {
                url += packagingParam;
            }

            const response = await get(url);
            setTotalItems(response.data.items.pagination.lastPage);
            const itemsWithQty = (Array.isArray(response.data.items.data) ? response.data.items.data : [response.data.items.data]).map(
                (item: ParsedItemType) => ({
                    ...item,
                    qty_item_type: fragment.item_types[index].qty,
                })
            );
            setItems(itemsWithQty);
        } catch (error) {
            console.error("Failed to get elements:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, pageNumber, searchTerm);
        }
    };


    const handleButtonClick = (index: number, itemTypeUnit:string) => {
        setIsModalOpen(true);
        setItemTypeUnit(itemTypeUnit)
        fetchItems(index, currentPage);
    };

    const handleReload = () => {
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, currentPage);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setItemTypeUnit('')
        setCurrentPage(1)
        setSearchTerm('')
    };

    const handleItemClick = (items: TItem[], itemTypeIndex: number | null) => {
        if (itemTypeIndex !== null) {
            const updatedSelectedItems = [
                ...selectedItems.filter(selectedItem => selectedItem.index !== itemTypeIndex),
                ...items.map((item, idx) => ({
                    itemTypeId: fragment.item_types[itemTypeIndex].id,
                    itemId: item.id,
                    index: itemTypeIndex,
                    user_item_qty: item.user_item_qty,
                    item_packaging_qty: item.packaging[Object.keys(item.packaging)[0]] || 1,
                    item_packaging_value: Object.keys(item.packaging)[0] || 'each',
                    clone_id: `${item.id}-${itemTypeIndex}-${idx}-${JSON.stringify(fragment.item_types[itemTypeIndex].fragments)}`,
                    originalIndex:idx,
                    fragments: fragment.item_types[itemTypeIndex].fragments,
                    template_id:0,
                    template_index: 0,
                    fragment_id:0,
                    fragment_index:0,
                }))
            ];
            const updatedItemInfos = items.reduce((acc, item, idx) => {
                const clone_id = `${item.id}-${itemTypeIndex}-${idx}-${JSON.stringify(fragment.item_types[itemTypeIndex].fragments)}`;
                acc[clone_id] = {
                    merchant_info: {} as TMerchanInfo,
                    vendor: item.vendor,
                    title: item.title,
                    id: item.id,
                    qty: item.qty,
                    ordered_qty: item.ordered_qty || 0,
                    user_item_qty: item.user_item_qty || 0,
                    item_packaging_qty: item.packaging[Object.keys(item.packaging)[0]] || 1,
                    item_packaging_value: Object.keys(item.packaging)[0] || 'each',
                    unit: item.unit,
                    price: item.price || 0,
                    packaging: item.packaging,
                    fragments: fragment.item_types[itemTypeIndex].fragments
                };
                return acc;
            }, {...selectedItemInfos});
            setSelectedItemInfos(updatedItemInfos);
            setSelectedItems(updatedSelectedItems);
            setParsedItemTypes((prev) => ({
                ...prev,
                [itemTypeIndex]: {
                    ...prev[itemTypeIndex],
                    isItemSelected: items.length > 0,
                    qty: items.length > 0 ? items[0].qty : 0,
                    ordered_qty: items.length > 0 ? items[0].ordered_qty || 0 : 0,
                    user_item_qty: items.length > 0 ? items[0].user_item_qty || 0 : 0,
                    item_packaging_qty: items.length > 0 ? items[0].packaging[Object.keys(items[0].packaging)[0]] : 1,
                    item_packaging_value: items.length > 9 ? Object.keys(items[0].packaging)[0] : 'each',
                    unit: items.length > 0 ? items[0].unit : '',
                    price: items.length > 0 ? items[0].price || 0 : 0,
                    priceCurrentQty: items.length > 0 ? (items[0].price || 0) * (items[0].user_item_qty || 0) : 0,
                    priceTotalQty: items.length > 0 ? (items[0].price || 0) * items[0].qty : 0,
                },
            }));

            calculateTotalPrice();
        }
        setIsModalOpen(false);
    };

    const handleQtyChange = (clone_id: string, newQty: string) => {
        const parsedQty = parseInt(newQty);
        if (!isNaN(parsedQty)) {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: newQty,
            }));
        } else {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: '',
            }));
        }
        setSelectedItemInfos((prev) => ({
            ...prev,
            [clone_id]: {
                ...prev[clone_id],
                ordered_qty: parsedQty,
            },
        }));
        calculateTotalPrice();
    };

    const handleSave = async () => {
        const currentGrouped = selectedItems.reduce((acc, selection) => {
            let object = acc;
            if (selection.fragments && selection.fragments.length > 0) {
                const fragmentsKey = JSON.stringify(selection.fragments);
                acc[fragmentsKey] = acc[fragmentsKey] || {};
                object = acc[fragmentsKey];
            }
            const key = `${selection.itemTypeId}-${selection.originalIndex}`;
            if (!object[key]) {
                object[key] = [];
            }
            object[key].push({ id: selection.itemId, qty: selection.user_item_qty });

            if (selection.fragments && selection.fragments.length > 0) {
                const fragmentsKey = JSON.stringify(selection.fragments);
                acc[fragmentsKey] = object;
            }
            return acc;
        }, {} as HandleSaveRec);

        const defaultGrouped: HandleSaveRec = {};
        fragment.item_types.forEach((itemType) => {
            let object = defaultGrouped;
            if (itemType.fragments.length > 0) {
                const fragmentsKey = JSON.stringify(itemType.fragments);
                defaultGrouped[fragmentsKey] = defaultGrouped[fragmentsKey] || {};
                object = defaultGrouped[fragmentsKey];
            }
            const key = `${itemType.id}-${itemType.index}`;
            object[key] = object[key] || [];
            if (itemType.items && Array.isArray(itemType.items)) {
                itemType.items.forEach((item) => {
                    object[key].push({ id: item.id, qty: item.qty });
                });
            }
            if (itemType.item) {
                object[key].push({
                    id: itemType.item.id,
                    qty: itemType.item.user_item_qty,
                });
            }
            if (itemType.fragments.length > 0) {
                const fragmentsKey = JSON.stringify(itemType.fragments);
                defaultGrouped[fragmentsKey] = object;
            }
        });

        const modifiedGroups: any[] = [];
        const allKeys = new Set([...Object.keys(defaultGrouped)]);
        allKeys.forEach((key) => {
            const currentItems = currentGrouped[key] || [];
            if (Array.isArray(currentItems)) {
                const [itemTypeIdStr, itemTypeIndexStr] = key.split("-");
                modifiedGroups.push({
                    item_type_id: Number(itemTypeIdStr),
                    item_ids: currentItems,
                    template_index: templateIndex,
                    fragment_index: fragmentIndex,
                    item_type_index: Number(itemTypeIndexStr),
                    template_id: templateId,
                    fragment_id: fragment.id,
                });
            } else {
                const arrayFragments: Array<{ id: number; index: number }> = JSON.parse(key);
                const allKeysDefaultItems = new Set([...Object.keys(defaultGrouped[key])]);
                let modifiedGroupsFragments: any[] = [];

                const pushFragments = (
                    index = 0,
                    propsFragments = { fragments: [] as any[] }
                ) => {
                    if (index === arrayFragments.length - 1) {
                        allKeysDefaultItems.forEach((keyCurrentItems) => {
                            const current = currentGrouped[key][keyCurrentItems] || [];
                            const [itemTypeIdStr, itemTypeIndexStr] = keyCurrentItems.split("-");
                            propsFragments.fragments.push({
                                item_type_id: Number(itemTypeIdStr),
                                item_ids: current,
                                fragment_index: arrayFragments[index].index,
                                fragment_id: arrayFragments[index].id,
                                item_type_index: Number(itemTypeIndexStr),
                            });
                        });
                        return propsFragments.fragments;
                    }

                    propsFragments.fragments.push({
                        fragment_index: arrayFragments[index].index,
                        fragment_id: arrayFragments[index].id,
                        fragments: [],
                    });

                    propsFragments.fragments = pushFragments(index + 1, {
                        fragments: propsFragments.fragments,
                    });
                    return propsFragments.fragments;
                };

                modifiedGroupsFragments = pushFragments();

                modifiedGroups.push({
                    fragment_index: fragmentIndex,
                    fragment_id: fragment.id,
                    template_id: templateId,
                    template_index: templateIndex,
                    fragments: modifiedGroupsFragments,
                });
            }
        });

        const body = modifiedGroups;

        let updatedProject;
        try {
            const response = await patch(
                `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item`,
                { data: body }
            );

            if (response.status === 200 || response.status === 201) {
                updatedProject = response.data;
                const itemOrderedQtyBody = selectedItems.map((selection) => {
                    if (!selection?.fragments || selection.fragments.length < 1) {
                        return ({
                            ordered_qty: selectedItemInfos[selection.clone_id]?.ordered_qty || 0,
                            template_index: templateIndex,
                            fragment_index: fragmentIndex,
                            item_type_index: selection.index,
                            template_id: templateId,
                            fragment_id: fragment.id,
                            item_type_id: selection.itemTypeId,
                            item_id: selection.itemId,
                        })
                    }
                    const arrayFragments = selection.fragments.reverse();
                    const parsFragments = (
                        index = 0,
                        propsFragments = { fragments: [] as any[] }
                    ) => {
                        if (index === arrayFragments.length -1) {

                            propsFragments.fragments.push({
                                ordered_qty: selectedItemInfos[selection.clone_id]?.ordered_qty || 0,
                                fragment_index: arrayFragments[index].index,
                                fragment_id: arrayFragments[index].id,
                                item_type_index: selection.index,
                                item_type_id: selection.itemTypeId,
                                item_id: selection.itemId,
                            });
                            return propsFragments.fragments;
                        }

                        propsFragments.fragments.push({
                            fragment_index: arrayFragments[index].index,
                            fragment_id: arrayFragments[index].id,
                            fragments: [],
                        });

                        propsFragments.fragments = parsFragments(index + 1, {
                            fragments: propsFragments.fragments,
                        });
                        return propsFragments.fragments;
                    }
                    return {
                        template_index: templateIndex,
                        fragment_index: fragmentIndex,
                        template_id: templateId,
                        fragment_id: fragment.id,
                        fragments: parsFragments()
                    };
                });
                const responseOrderedQTY = await patch(
                    `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty`,
                    { data: itemOrderedQtyBody }
                );

                if (responseOrderedQTY.status === 200 || responseOrderedQTY.status === 201) {
                    updatedProject = responseOrderedQTY.data;
                }
            } else {
                throw new Error("Failed to update project.");
            }
            handleCloseModal();
        } catch (error) {
            console.error("Failed to save selections:", error);
        } finally {
            if (updatedProject != null) {
                onUpdateProject(updatedProject);
                onToggle();
            }
        }
    };


    if (!fragment.item_types || !Array.isArray(fragment.item_types)) {
        return null;
    }

    const filteredItemTypes = fragment.item_types
        .map((itemType, index) => ({...itemType, originalIndex: index}))
        .filter(itemType => !itemType.labor && !itemType.tool && itemType.qty > 0);

    const laborItems = viewMode === 'Work Stage'
        ? fragment.item_types
            .map((itemType, index) => ({...itemType, originalIndex: index}))
            .filter(itemType => itemType.labor && itemType.qty > 0)
        : [];

    const toolItems = viewMode === 'Work Stage'
        ? fragment.item_types
            .map((itemType, index) => ({...itemType, originalIndex: index}))
            .filter(itemType => itemType.tool && itemType.qty > 0)
        : [];

    const handleSearch = (query: string) => {
        setSearchTerm(query);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, 1, query);
        }
    };
    return (
        <div>
            {filteredItemTypes.length > 0 && (
                <>
                    {viewMode === 'Work Stage' && <h3>Products name</h3>}
                    {filteredItemTypes.map((itemType: ItemTypeWithIndex) => {
                        const parsedItem = parsedItemTypes[itemType.originalIndex];
                        if (!parsedItem) return null;
                        const {qty, orderedQty, price, priceTotalQty} = parsedItem;
                        const selectedItemsForType = selectedItems.filter(
                            item => item.itemTypeId === itemType.id && item.index === itemType.index
                        );
                        return (
                            <div key={`${itemType.originalIndex}-${itemType.index}-${JSON.stringify(itemType.fragments)}`}>
                                <label
                                    className='static-item-type-info'
                                    htmlFor={`button-${fragment.id}-${itemType.originalIndex}-${itemType.index}`}
                                >
                                    <p className='static-item-type-info-title'>{itemType.title}</p>
                                    <p className='static-item-type-info-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'each'})</p>
                                </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                        <div className='step2-item-type-list'>
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${fragment.id}-${itemType.originalIndex}-${itemType.index}`}
                                                    onClick={() => handleButtonClick(itemType.originalIndex, itemType?.unit ? itemType.unit : '')}
                                                    disabled={loading}
                                                >
                                                    {loading && selectedItemTypeIndex === itemType.originalIndex
                                                        ? "Loading..."
                                                        : "Select Products"}
                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                </button>
                                                <p className="step2-amend-no-items-list">
                                                    R 0.00
                                                </p>
                                                <div className="step2-tooltip-container-amend-no-items">
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-width-list">R {parseInt(String(price * qty)).toFixed(2)}</p>
                                            </div>
                                    ) : (
                                        <div>
                                            {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const safeNumber = (value: any) => Number(value) || 0;

                                                const difPrice = safeNumber(item.ordered_qty) * safeNumber(item.price);
                                                const oldPrice = safeNumber(item.price) * safeNumber(item.user_item_qty || item.qty);
                                                const difTotalPrice = safeNumber(oldPrice - difPrice);
                                                const itemPackaging = item.packaging && typeof item.packaging === 'object' && Object.keys(item.packaging).length > 0
                                                    ? Object.keys(item.packaging)[0]
                                                    : item.unit ? item.unit : 'psc';
                                                const packagingValue = item.packaging && typeof item.packaging === 'object' && Object.values(item.packaging).length > 0
                                                    ? Object.values(item.packaging)[0]
                                                    : 1;
                                                const itemQty = item.user_item_qty || item.qty;
                                                const itemPackagingTotal = Math.ceil(itemQty * Number(packagingValue));
                                                const price = parseFloat(String(item.price)) || 0;

                                                const itemKey = generateItemKey(fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id);
                                                const visited = isVisited(itemKey);
                                                return (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${fragment.id}-${itemType.originalIndex}-${idx}`}
                                                                onClick={() => handleButtonClick(itemType.originalIndex, itemType?.unit ? itemType.unit : '')}
                                                                disabled={loading}
                                                            >
                                                                {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                    ? "Loading..."
                                                                    : item.title}
                                                                <div className='step2-select-arrow'>
                                                                    <IconStep2SelectArrow/></div>

                                                            </button>
                                                            <p className="step2-calc-amend-item-list">
                                                                {itemQty} (psc)/{itemPackagingTotal} ({itemPackaging})
                                                            </p>
                                                            <div className="step2-tooltip-container-amend-items">
                                                                <input
                                                                    type="number"
                                                                    className="step2-other-width-input"
                                                                    value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                    onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                    min="0"
                                                                />
                                                                <span className='packing-value-steps'>psc</span>
                                                            </div>
                                                            <p className="step2-amend-items-list-price">R {Number(formatNumber(price))}</p>

                                                            <p className="step2-amend-items-list-total">R {formatNumber(Math.max(0, difTotalPrice))}</p>
                                                            <div className='step2-amend-item-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <>
                                                                        <p
                                                                            onClick={()=>handleLogoClick(item.merchant_info?.link,fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id)}
                                                                            className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                        {!visited && <span><IconEyesStep3/></span>}
                                                                    </>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}

                                                            </div>
                                                        </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            {viewMode === 'Work Stage' && laborItems.length > 0 && (
                <>
                    <h3>Labor Items</h3>
                    {laborItems.map((itemType: ItemTypeWithIndex, index: number) => {
                        const parsedItem = parsedItemTypes[itemType.originalIndex];
                        if (!parsedItem) return null;

                        const {
                            qty,
                            orderedQty,
                            price,
                            priceTotalQty,
                        } = parsedItem;
                        const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === itemType.id && item.index === itemType.originalIndex);
                        const itemTypeKey = generateItemTypeKey(itemType);

                        return (
                            <div key={`${itemType.originalIndex}-${index}`}>
                                <label
                                    className='static-item-type-info'
                                    htmlFor={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                >
                                    <p className='static-item-type-info-title'>{itemType.title}</p>
                                    <p className='static-item-type-info-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'each'})</p>
                                </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                            <div className='step2-item-type-list'>
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                    onClick={() => handleButtonClick(itemType.originalIndex, itemType?.unit ? itemType.unit : '')}
                                                    disabled={loading}
                                                >
                                                    {loading && selectedItemTypeIndex === itemType.originalIndex
                                                        ? "Loading..."
                                                        : "Select Labors"}
                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                </button>
                                                <p className="step2-amend-no-items-list">
                                                    R 0.00
                                                </p>
                                                <div className="step2-tooltip-container-amend-no-items">
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-amend-no-items-total">R {parseInt(String(price * qty)).toFixed(2)}</p>
                                            </div>

                                    ) : (
                                        <div>
                                            {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const safeNumber = (value: any) => Number(value) || 0;

                                                const difPrice = safeNumber(item.ordered_qty) * safeNumber(item.price);
                                                const oldPrice = item?.price * (item.user_item_qty || item.qty);
                                                const difTotalPrice = isNaN(oldPrice - difPrice) ? 0 : oldPrice - difPrice;
                                                const itemTypeKey = generateItemTypeKey(itemType);
                                                const itemPackaging = item.packaging && typeof item.packaging === 'object' && Object.keys(item.packaging).length > 0
                                                    ? Object.keys(item.packaging)[0]
                                                    : item.unit ? item.unit : 'psc';
                                                const packagingValue = item.packaging && typeof item.packaging === 'object' && Object.values(item.packaging).length > 0
                                                    ? Object.values(item.packaging)[0]
                                                    : 1;
                                                const itemQty = item.user_item_qty || item.qty;
                                                const itemPackagingTotal = Math.ceil(itemQty * Number(packagingValue));
                                                const price = parseFloat(String(item.price)) || 0;
                                                const itemKey = generateItemKey(fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id);
                                                const visited = isVisited(itemKey);
                                                return (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${fragment.id}-${itemType.originalIndex}-${idx}`}
                                                                onClick={() => handleButtonClick(itemType.originalIndex, itemType?.unit ? itemType.unit : '')}
                                                                disabled={loading}
                                                            >
                                                                {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                    ? "Loading..."
                                                                    : item.title}
                                                                <div className='step2-select-arrow'>
                                                                    <IconStep2SelectArrow/></div>

                                                            </button>
                                                            <p className="step2-calc-amend-item-list">
                                                                {itemQty} (psc)/{itemPackagingTotal} ({itemPackaging})
                                                            </p>
                                                            <div className="step2-tooltip-container-amend-items">
                                                                <input
                                                                    type="number"
                                                                    className="step2-other-width-input"
                                                                    value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                    onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                    min="0"
                                                                />
                                                                <span
                                                                    className='packing-value-steps'>psc</span>

                                                            </div>
                                                            <p className="step2-amend-items-list-price">R {formatNumber(price)}</p>
                                                            <p className="step2-amend-items-list-total">R {formatNumber(Math.max(0, difTotalPrice))}</p>
                                                            <div className='step2-amend-item-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <>
                                                                        <p
                                                                            onClick={()=>handleLogoClick(item.merchant_info?.link,fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id)}
                                                                            className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                        {!visited && <span><IconEyesStep3/></span>}
                                                                    </>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}

                                                            </div>
                                                        </div>

                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            {viewMode === 'Work Stage' && toolItems.length > 0 && (
                <>
                    <h3>Tool Items</h3>
                    {toolItems.map((itemType: ItemTypeWithIndex, ) => {
                        const parsedItem = parsedItemTypes[itemType.originalIndex];
                        if (!parsedItem) return null;

                        const {
                            qty,
                            orderedQty,
                            price,
                            priceTotalQty,
                        } = parsedItem;
                        const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === itemType.id && item.index === itemType.originalIndex);
                        const itemTypeKey = generateItemTypeKey(itemType);

                        return (
                            <div key={`${itemType.originalIndex}-${itemType.index}`}>
                                <label
                                    className='static-item-type-info'
                                    htmlFor={`button-${fragment.id}-${itemType.originalIndex}-${itemType.index}`}
                                >
                                    <p className='static-item-type-info-title'>{itemType.title}</p>
                                    <p className='static-item-type-info-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'each'})</p>
                                </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                            <div className='step2-item-type-list'>
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${fragment.id}-${itemType.originalIndex}-${itemType.index}`}
                                                    onClick={() => handleButtonClick(itemType.originalIndex, itemType?.unit ? itemType.unit : '')}
                                                    disabled={loading}
                                                >
                                                    {loading && selectedItemTypeIndex === itemType.originalIndex
                                                        ? "Loading..."
                                                        : "Select Tools"}
                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                </button>
                                                <p className="step2-amend-no-items-list">R 0.00</p>
                                                <div className="step2-tooltip-container-amend-no-items">
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-width-list">R {parseInt(String(price * qty)).toFixed(2)}</p>
                                            </div>
                                    ) : (
                                        <div>
                                            {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const safeNumber = (value: any) => Number(value) || 0;

                                                const difPrice = safeNumber(item.ordered_qty) * safeNumber(item.price);
                                                const oldPrice = safeNumber(item.price) * safeNumber(item.user_item_qty || item.qty);
                                                const difTotalPrice = safeNumber(oldPrice - difPrice);
                                                const itemPackaging = item.packaging && typeof item.packaging === 'object' && Object.keys(item.packaging).length > 0
                                                    ? Object.keys(item.packaging)[0]
                                                    : item.unit ? item.unit : 'psc';
                                                const packagingValue = item.packaging && typeof item.packaging === 'object' && Object.values(item.packaging).length > 0
                                                    ? Object.values(item.packaging)[0]
                                                    : 1;
                                                const itemQty = item.user_item_qty || item.qty;
                                                const itemPackagingTotal = Math.ceil(itemQty * Number(packagingValue));
                                                const price = parseFloat(String(item.price)) || 0;
                                                const itemKey = generateItemKey(fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id);
                                                const visited = isVisited(itemKey);
                                                return (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${fragment.id}-${itemType.originalIndex}-${idx}`}
                                                                onClick={() => handleButtonClick(itemType.originalIndex, itemType?.unit ? itemType.unit : '')}
                                                                disabled={loading}
                                                            >
                                                                {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                    ? "Loading..."
                                                                    : item.title}
                                                                <div className='step2-select-arrow'>
                                                                    <IconStep2SelectArrow/></div>

                                                            </button>
                                                            <p className="step2-calc-amend-item-list">
                                                                {itemQty} (psc)/{itemPackagingTotal} ({itemPackaging})
                                                            </p>
                                                            <div className="step2-tooltip-container-amend-items">
                                                            <input
                                                                type="number"
                                                                className="step2-other-width-input"
                                                                value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                min="0"
                                                            />
                                                            <span
                                                                className='packing-value-steps'>psc</span>

                                                        </div>
                                                            <p className="step2-amend-items-list-price">R {formatNumber(price)}</p>

                                                            <p className="step2-amend-items-list-total">R {formatNumber(Math.max(0, difTotalPrice))}</p>
                                                            <div className='step2-amend-item-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <>
                                                                        <p
                                                                            onClick={()=>handleLogoClick(item.merchant_info?.link,fragment.id, fragmentIndex, templateId, templateIndex, itemType.id, item.id)}
                                                                            className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                        {!visited && <span><IconEyesStep3/></span>}
                                                                    </>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}

                                                            </div>
                                                        </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            {selectedItemTypeIndex !== null && <ItemSelectionModal
                itemTypeUnit={itemTypeUnit ? itemTypeUnit : ''}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                items={items}
                loading={loading}
                handleItemClick={(items) => handleItemClick(items, selectedItemTypeIndex)}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems[selectedItemTypeIndex] ? [selectedItems[selectedItemTypeIndex]] : []}
                onReload={handleReload}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                onSearch={handleSearch}
                totalSearchResults={totalItems}
                storeId={storeId}
            />}

            <div className='step2-bottom-block'>
                    <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button className='step2-bottom-block-btn-cart-list' onClick={handleSave}>Save</button>
                    </>

            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateLocalTemplate,
    setPageActiveCalculation
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypeListCalculation);
