import React, { useEffect, useRef, useState } from 'react';
import { fetchRevitToken } from "../../../api/APIWraper";
import '../ArhitectStyles/RevitComponent.css';

interface UniversalViewerProps {
    urn: string;
    fileType: 'revit' | 'dwg';
    showWizard: boolean;
    currentIndex: number;
    setTotalObjects: (count: number) => void;
    updateExternalIds: (externalIds: string[]) => void;
    setExternalIdToDbId: React.Dispatch<React.SetStateAction<Record<string, number>>>;
    setCurrentIndex: (index: number) => void;
    setDisableSavedIndex: React.Dispatch<React.SetStateAction<boolean>>;
}

const UniversalViewer: React.FC<UniversalViewerProps> = ({
                                                             urn,
                                                             fileType,
                                                             showWizard,
                                                             currentIndex,
                                                             setTotalObjects,
                                                             updateExternalIds,
                                                             setExternalIdToDbId,
                                                             setCurrentIndex,
                                                             setDisableSavedIndex
                                                         }) => {

    const viewerContainer = useRef<HTMLDivElement | null>(null);
    const viewerRef = useRef<Autodesk.Viewing.GuiViewer3D | null>(null);

    const [viewerObjects, setViewerObjects] = useState<{ externalId: string; dbId: number }[]>([]);
    const [isViewerReady, setIsViewerReady] = useState(false);
    const [readyToShow, setReadyToShow] = useState(false);

    const tokenRefreshTimer = useRef<NodeJS.Timeout | null>(null);

    const getRevitToken = async (): Promise<string> => {
        try {
            const response = await fetchRevitToken();
            if (response.status !== 200) {
                throw new Error(`Failed to refresh token. Status: ${response.status}`);
            }
            const newToken = response.data.token;
            localStorage.setItem('autodeskToken', newToken);

            if (tokenRefreshTimer.current) clearTimeout(tokenRefreshTimer.current);
            tokenRefreshTimer.current = setTimeout(() => {
                getRevitToken();
            }, 55 * 60 * 1000);

            return newToken;
        } catch (error) {
            console.error('Error fetching new token:', error);
            throw error;
        }
    };

    const initializeViewer = async (retry = false) => {
        if (typeof Autodesk === 'undefined') {
            console.error('Autodesk Viewer SDK not loaded');
            return;
        }

        let token = localStorage.getItem('autodeskToken');
        if (!urn || !token) {
            try {
                token = await getRevitToken();
            } catch (error) {
                console.error('Failed to get token:', error);
                return;
            }
        }

        let env = 'AutodeskProduction';
        let api = 'derivativeV2';
        if (fileType === 'dwg') {
            env = 'AutodeskProduction2';
            api = 'streamingV2';
        }

        const options = {
            env,
            api,
            accessToken: token,
        };

        Autodesk.Viewing.Initializer(options, () => {
            if (!viewerRef.current) {
                viewerRef.current = new Autodesk.Viewing.GuiViewer3D(viewerContainer.current as HTMLElement);
                viewerRef.current.start();
            }

            const viewer = viewerRef.current;
            const documentId = `urn:${urn}`;

            Autodesk.Viewing.Document.load(
                documentId,
                (viewerDocument) => {
                    onDocumentLoadSuccess(viewerDocument);
                },
                async (err) => {
                    console.error('Error loading document:', err);
                    if (err === 4 && !retry) {
                        try {
                            await getRevitToken();
                            initializeViewer(true);
                        } catch (tokenError) {
                            console.error('Failed to refresh token:', tokenError);
                        }
                    }
                }
            );
        });
    };

    const onDocumentLoadSuccess = (doc: Autodesk.Viewing.Document) => {
        const viewer = viewerRef.current;
        if (!viewer) return;
        if (fileType === 'dwg') {
            const root = doc.getRoot() as unknown as {
                search: (opts: { type?: string; role?: string }) => Autodesk.Viewing.BubbleNode[];
            };

            const viewables = root.search({ type: 'geometry', role: '3d' });
            if (!viewables || viewables.length === 0) {
                console.error('3D-модели не найдены в DWG.');
                return;
            }

            const viewable3D = viewables[0];

            const loadPromise = viewer.loadDocumentNode(doc, viewable3D) as unknown as Promise<Autodesk.Viewing.Model>;

            loadPromise
                .then(() => {
                    attachObjectTreeCreatedEvent(viewer);
                })
                .catch((loadError) => {
                    console.error('Error loading DWG model:', loadError);
                });
        } else {
            const defaultViewable = doc.getRoot().getDefaultGeometry();

            const loadPromise = viewer.loadDocumentNode(doc, defaultViewable) as unknown as Promise<Autodesk.Viewing.Model>;

            loadPromise
                .then(() => {
                    attachObjectTreeCreatedEvent(viewer);
                })
                .catch((err) => {
                    console.error('Error loading REVIT model:', err);
                });
        }
    };


    const attachObjectTreeCreatedEvent = (viewer: Autodesk.Viewing.GuiViewer3D) => {
        viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
            viewer.getObjectTree((tree: any) => {
                if (!tree) {
                    console.error("Object tree not found");
                    return;
                }
                const rootId = tree.getRootId();
                const leafDbIds = getAllLeafNodes(tree, rootId);

                setTotalObjects(leafDbIds.length);

                if (fileType === 'revit') {
                    collectRevitExternalIds(viewer, leafDbIds).then(() => {
                        setIsViewerReady(true);
                    });
                } else {
                    collectDWGExternalIds(viewer, leafDbIds).then(() => {
                        setIsViewerReady(true);
                    });
                }
            });
        });
    };

    const getAllLeafNodes = (tree: any, rootId: number): number[] => {
        const allLeafs: number[] = [];
        function recurse(dbId: number) {
            let childCount = 0;
            tree.enumNodeChildren(dbId, (childId: number) => {
                childCount++;
                recurse(childId);
            });
            if (childCount === 0) {
                allLeafs.push(dbId);
            }
        }
        recurse(rootId);
        return allLeafs;
    };

    const collectRevitExternalIds = async (
        viewer: Autodesk.Viewing.GuiViewer3D,
        leafDbIds: number[]
    ) => {
        const tempObjects: { externalId: string; dbId: number }[] = [];
        const promises: Promise<void>[] = [];

        for (const dbId of leafDbIds) {
            const p = new Promise<void>((resolve) => {
                viewer.getProperties(
                    dbId,
                    (props: any) => {
                        if (props?.externalId) {
                            tempObjects.push({
                                externalId: props.externalId,
                                dbId
                            });
                        }
                        resolve();
                    },
                    (err: any) => {
                        console.error(`Error getting properties for dbId ${dbId}:`, err);
                        resolve();
                    }
                );
            });
            promises.push(p);
        }

        await Promise.all(promises);

        setViewerObjects(tempObjects);
        updateExternalIds(tempObjects.map(obj => obj.externalId));
        setExternalIdToDbId(
            tempObjects.reduce<Record<string, number>>((acc, item) => {
                acc[item.externalId] = item.dbId;
                return acc;
            }, {})
        );
    };

    const collectDWGExternalIds = async (
        viewer: Autodesk.Viewing.GuiViewer3D,
        leafDbIds: number[]
    ) => {
        const tempObjects: { externalId: string; dbId: number }[] = [];
        const promises: Promise<void>[] = [];

        for (const dbId of leafDbIds) {
            const p = new Promise<void>((resolve) => {
                viewer.getProperties(
                    dbId,
                    (props: any) => {
                        const realId = props?.externalId || `dwg-${dbId}`;
                        tempObjects.push({
                            externalId: realId,
                            dbId
                        });
                        resolve();
                    },
                    (err: any) => {
                        console.error(`Error getting properties for dbId ${dbId}:`, err);
                        resolve();
                    }
                );
            });
            promises.push(p);
        }

        await Promise.all(promises);

        setViewerObjects(tempObjects);
        updateExternalIds(tempObjects.map(obj => obj.externalId));
        setExternalIdToDbId(
            tempObjects.reduce<Record<string, number>>((acc, item) => {
                acc[item.externalId] = item.dbId;
                return acc;
            }, {})
        );
    };

    useEffect(() => {
        initializeViewer();
        return () => {
            if (viewerRef.current) {
                viewerRef.current.tearDown();
                viewerRef.current.finish();
                viewerRef.current = null;
            }
            if (tokenRefreshTimer.current) {
                clearTimeout(tokenRefreshTimer.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urn, fileType]);

    useEffect(() => {
        if (viewerRef.current) {
            const timeout = setTimeout(() => {
                viewerRef.current?.resize();
                viewerRef.current?.fitToView();
            }, 500);
            return () => clearTimeout(timeout);
        }
    }, [showWizard]);

    useEffect(() => {
        if (!viewerRef.current || !isViewerReady) return;

        if (showWizard) {
            const timeout = setTimeout(() => setReadyToShow(true), 1000);
            return () => clearTimeout(timeout);
        } else {
            setReadyToShow(false);
        }
    }, [showWizard, isViewerReady]);

    useEffect(() => {
        const viewer = viewerRef.current;
        if (!viewer || !readyToShow) return;

        const currentObj = viewerObjects[currentIndex];
        if (currentObj) {
            viewer.hideAll();
            viewer.show([currentObj.dbId]);
            viewer.fitToView([currentObj.dbId]);
        } else {
            setTimeout(() => {
                try {
                    viewer.showAll();
                } catch (err) {
                    console.error("Error calling viewer.showAll()", err);
                }
            }, 1500);
        }
    }, [readyToShow, currentIndex, viewerObjects]);

    useEffect(() => {
        function onSelectionChanged(event: any) {
            const selection = event.dbIdArray;
            if (!selection || selection.length === 0) return;
            const selectedDbId = selection[0];
            const foundIndex = viewerObjects.findIndex(obj => obj.dbId === selectedDbId);

            if (foundIndex !== -1) {
                setCurrentIndex(foundIndex);
                setDisableSavedIndex(true);
            }
        }

        const viewer = viewerRef.current;
        viewer?.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionChanged);

        return () => {
            viewer?.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionChanged);
        };
    }, [viewerObjects, setCurrentIndex]);

    return (
        <div
            id={`forgeViewer${!showWizard ? '-wizard' : ''}`}
            ref={viewerContainer}
            className={`revit-container ${!showWizard ? 'wizard' : ''}`}
        />
    );
};

export default UniversalViewer;
