import React, { useEffect } from 'react';
import Modal from 'react-modal';
import '../DataProtection/DataProtection.css';

interface ModalDeleteProps {
    isOpen: boolean;
    onCancel: () => void;
}

Modal.setAppElement('#root');

const TermsAndConditions: React.FC<ModalDeleteProps> = ({ isOpen, onCancel }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    const termsText = `
1. Licensing Options
1. Freeware License
 A Freeware License allows one individual to use the Software on a single computer
or device at any given time.
 The Freeware version includes limited templates and features, suitable for both
evaluation and limited production use.
o No product support is provided under this license.
2. Pay-As-You-Use License
 This license permits an individual to use the Software on one computer or device at
a time, with a limited feature set.
 The Pay-As-You-Use version supports evaluation and production purposes and
includes access to product support.

3. Trial License
o The Trial License is intended solely for evaluation, demonstration, and review
purposes, for a limited period.
o Trial versions may have restricted features and will cease operation based on time
or usage limitations determined by GoBuild360.

4. Subscription License
o A Subscription License grants the use of one copy of the Software for the
subscription term, beginning on the chosen start date.
o The standard subscription term is 12 months, unless specified otherwise.
o Failure to comply with license terms or make timely payments may result in
termination of the license without a refund.
o The software may include mechanisms to enforce usage limits as per license terms.

2. Licensing Requirements and Restrictions
- Non-Human Devices: Each non-human device (e.g., virtual machines, build servers) using
the Software requires a separate license.
- Backups and Prior Versions: Users may maintain prior versions on the same device but only
as a substitute for the current licensed version.
- Audit: GoBuild360 reserves the right to audit Software use for compliance. Any non-
compliant usage discovered will result in fees for audit expenses and any additional
applicable liabilities.
3. Restrictions on Use
- The Software is licensed as a complete product, and its components may not be
disassembled, reverse-engineered, or redistributed separately.

- Modifying, translating, or creating derivative works from the Software is prohibited unless
specifically authorized by GoBuild360.
- Source code provided with the Software remains the property of GoBuild360 and may not
be redistributed, resold, or altered for distribution unless expressly permitted.
4. Usage and Distribution Limitations
- Prohibited Transfers: Licensees may not rent, lease, sublicense, sell, or otherwise transfer
the Software.
- Timesharing: Use on a timeshare or service bureau basis is prohibited unless explicitly
permitted by GoBuild360 in writing.
- Trial Version Limitations: Trial versions are strictly for evaluation, and use for production,
training, or commercial purposes is forbidden.
5. Competitive Use Restrictions
- Users may not use the Software to create competing products, directly or indirectly.
6. Export Compliance
- Exporting the Software to any country subject to international sanctions or restrictions is
strictly prohibited.
7. License Management for Server Installations
- Enterprise License Manager: For installations on physical or virtual servers, users must
purchase the correct version of the Enterprise License Manager. Installing the Software on
both physical and virtual servers will require purchasing the corresponding Enterprise
License Manager for each.
8. Multi-Version and Multi-Platform Licenses
- If the Software includes multiple versions or operating environment options, only one
version may be used at any given time.
9. Floating Seat Licenses
- Simultaneous users may not exceed the number of floating seats purchased.
10. Ancillary Services
- Ancillary Services (e.g., material and labour management) may be offered as optional add-
ons. GoBuild360 reserves the right to modify, discontinue, or charge additional fees for such
services.
11. Payment Terms
- Subscription fees are billed monthly or annually and may be subject to price changes at
GoBuild360&#39;s discretion.
- Taxes or duties applicable to your region are your responsibility. If GoBuild360 incurs any
tax-related expenses, you agree to reimburse these upon request.
12. Payment and Subscription Termination

- If a payment plan is chosen and payments are not completed, the license will be terminated,
and no refund will be issued. Licenses may contain mechanisms to limit usage in accordance
with payment terms.
13. Circumvention Prohibited
- Use of unauthorized devices or software to circumvent copy protection or usage restrictions
is strictly prohibited.
14. Pre-Release or Beta Software
- Pre-release versions are provided for evaluation only and may contain bugs or other issues.
Use is at the licensee’s own risk, and pre-release versions must be uninstalled upon request
by GoBuild360.
15. Upgrades and License Exchange
- Upgrades are provided as a license exchange, replacing the previous version’s license, which
is terminated upon use of the upgraded software.
GoBuild360 BoM Terms and Conditions: Support, Maintenance, Activation, and Liability
16. Support and Maintenance Services
- Maintenance Availability: Licensor may provide maintenance services for the Software,
subject to additional charges. Use of such services is governed by Licensor&#39;s policies, which
are subject to change. 16.2 Supplemental Materials: As part of maintenance, Licensor may
provide materials like bug lists or planned features. These materials come with no warranty,
and Licensor assumes no liability for any resulting damages. 16.3 Updates and Supplemental
Code: Any code or materials provided as part of maintenance are considered part of the
Software and are bound by these Terms. 16.4 Use of Technical Information: Licensor may
use any technical information provided by You during support for product development
purposes without restriction. 16.5 Previous Versions: Maintenance for older versions of the
Software may be discontinued upon new releases. 16.6 Auto-Naming Feature Access: If the
Software uses the Takeoff Auto-Naming feature, You authorize the Software to access image
data solely for analysis purposes.

17. Software Activation and Updates
17.1 Activation and License Validation: The Software may use network or internet connections to
transmit license data for validation to Licensor-operated servers during installation, registration, or
updates.
17.2 Update Notifications: Licensor may provide update notifications, which may periodically check
for Software updates and inform You when they are available.
17.3 Data Collection: Licensor may collect both personal and non-personal data to improve services,
facilitate updates, and provide support. Personal data may include names, contact information, IP
addresses, and Software usage statistics.
- 17.3.1 EU Users’ Consent: If applicable, users from EU member countries may &quot;Opt-In&quot;
during installation, allowing Licensor to collect data for product improvement.

- 17.3.2 Accuracy and Compliance: You agree that all submitted data is accurate, and You
indemnify Licensor from liabilities arising from inaccurate or incomplete data.
- 17.3.3 Accuracy Disclaimer and Indemnification: GoBuild360 BoM is a material
measurement and suggested labor cost library. The accuracy of calculations relies on
information provided by the licensee, particularly for manually entered data. Product costs
reflect prices displayed by affiliate partners and may vary. Labor costs are based on average
rates, which can differ across regions. For quantities extracted from Revit or AutoCAD
architectural digital drawings, accuracy is dependent on the architect’s correct use of
descriptions.
- GoBuild360 does not guarantee the accuracy of any measurements, costs, or estimates. By
using GoBuild360 BoM, the licensee agrees to indemnify and hold GoBuild360 harmless
from any claims or liabilities arising from inaccuracies in project calculations or cost
estimations.

18. Intellectual Property Rights
18.1 Licensor Rights: Licensor retains all intellectual property rights to the Software. Your rights are
limited to the license granted herein, with no ownership transferred.
18.2 Permitted Copies: Copies made must include copyright and proprietary notices. Copies are
limited to backup or as specified within this Agreement.
18.3 Trademark Use: Use of Licensor&#39;s trademarks is restricted to identifying printed output
generated by the Software.
18.4 Reverse Engineering: Use for purposes of reverse engineering, decompiling, or attempting to
discover the Software’s source code is prohibited.

19. Limited Warranty and Disclaimer
19.1 “As-Is” Software: The Software and related content are provided “As Is.” Licensor disclaims all
warranties, express or implied, including but not limited to non-infringement, merchantability, or
fitness for a specific purpose.
19.2 Advice Disclaimer: Oral or written advice from Licensor or its representatives does not create a
warranty.
19.3 Jurisdictional Compliance: Some jurisdictions may not permit these warranty exclusions,
granting You specific legal rights based on your location.

20. Limitation of Liability
20.1 Damage Exclusion: Licensor is not liable for damages, including but not limited to indirect,
incidental, or consequential damages related to use or inability to use the Software.
20.2 Liability Cap: None.

20.3 Data Protection: You are responsible for backing up data. Licensor is not responsible for data
loss, breaches, or damages due to network insecurities.
20.4 Liability Scope: Limitations on liability apply across all warranty disclaimers and provisions of
this Agreement.

21. Basis of Bargain
The limitations on warranties, liabilities, and damages form a fundamental basis of this Agreement,
allowing Licensor to provide the Software at current rates.

22. Notice to U.S. Government Users
The Software and Documentation are “commercial computer software” and “commercial computer
software documentation,” and subject to government usage restrictions as set forth in applicable
regulations.

23. Termination
This Agreement may be terminated by Licensor immediately if You fail to comply with any terms.
Upon termination, all Software copies must be uninstalled.

24. Privacy
Licensor’s Privacy Policy governs personal data handling. Licensor will comply with applicable privacy
laws, including GDPR.

25. Third-Party Software and Links
25.1 Third-Party Software: Additional terms may apply to third-party software components within
the Software. Access and use are subject to those terms.
25.2 External Links: Linked third-party websites are not endorsed by Licensor, which assumes no
responsibility for their content or privacy practices.

26. General Provisions
26.1 Entire Agreement: This Agreement contains the complete agreement regarding the Software.
26.2 Modification Authority: Modifications to this Agreement are only binding if made in writing by
an authorized Licensor officer.
26.3 Independent Contractor: No partnership or agency relationship is created by this Agreement.
26.4 Governing Law: This Agreement is governed by the laws of South Africa, without reference to
choice-of-law principles.

26.5 Waivers: Failure to exercise any rights does not constitute a waiver.
26.6 Export Compliance: You agree to comply with all export control laws in your use of the
Software.
`;

    return (
        <Modal
            isOpen={isOpen}
            className="terms-and-conditions"
            onRequestClose={onCancel}
            overlayClassName="overlay-terms-and-conditions"
        >
            <div className="warning">
                <button className="close-button" onClick={onCancel}>&times;</button>
            </div>
            <div className="terms-and-conditions-content">
                <h1 className='terms-and-conditions-h1'>GoBuild360 BoM Terms and Conditions: Licensing and Usage</h1>
                {termsText.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </div>
        </Modal>
    );
};

export default TermsAndConditions;
