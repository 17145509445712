import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import './Step1Bom.css';
import 'react-toastify/dist/ReactToastify.css';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {
    IconBomSettingItem,
    IconBomSettingItemType,
    IconPlusStpe2,
    IconRemoveItem,
    IconRemoveItemType, IconStep2Flush
} from "../../IconComponents/IconComponents";
import ItemTypeBomModal from "./ModalsBom/ItemTypeBomModal";
import ItemsBomModal from "./ModalsBom/ItemsBomModal";
import {Fragment, ItemType, TItem} from "../../types/TemplateType";
import {
    addItemsToFragments,
    deleteProjectBomItemType, flushBOMProject,
    get,
    getProjectBomById, minimumPriceBOMProject,
    patchCreateBom,
    post,
    postCreateBom
} from "../../api/APIWraper";
import CreateProjectBom, {AddressData, FileData} from "./CreateProjectBom/CreateProjectBom";
import {PostFormData} from "../../api/ApiWrapperImage";
import {toast} from "react-toastify";
import {BOMItem, BOMItemType, BOMProject} from "../../types/ProjectBOMInterface";
import {Vendor, vendorData} from "../../Enums/VendorEnum";
import FragmentBomModal from "./ModalsBom/FragmentBomModal";
import FragmentItemsBomModal from "./ModalsBom/FragmentItemsBomModal";

const BASE_URL_CONTRACTOR = process.env.REACT_APP_BASE_URL_CONTRACTOR;

export interface ModalItemState<T> {
    isOpen: boolean;
    loading: boolean;
    items: T[];
    selectedItems: T[];
    currentPage: number;
    totalItems: number;
    searchTerm: string;
    sortDirection: 'asc' | 'desc';
}

interface ModalState {
    fragments: ModalItemState<Fragment>
    itemType: ModalItemState<ItemType>;
    itemBom: ModalItemState<TItem>;
    FragmentItemBom: ModalItemState<TItem>
}

export interface SelectedItemsMap {
    [itemTypeIndex: number]: TItem[];
}

interface SelectedItemsMapByFragment {
    [fragmentId: number]: {
        [itemTypeId: number]: TItem[];
    };
}


const Step1BOMCreate: React.FC<PropsFromRedux> = () => {
    const navigate = useNavigate();

    const [modalState, setModalState] = useState<ModalState>({
        fragments: {
            isOpen: false,
            loading: false,
            items: [],
            selectedItems: [],
            currentPage: 1,
            totalItems: 0,
            searchTerm: '',
            sortDirection: 'asc',
        },
        itemType: {
            isOpen: false,
            loading: false,
            items: [],
            selectedItems: [],
            currentPage: 1,
            totalItems: 0,
            searchTerm: '',
            sortDirection: 'asc',
        },
        itemBom: {
            isOpen: false,
            loading: false,
            items: [],
            selectedItems: [],
            currentPage: 1,
            totalItems: 0,
            searchTerm: '',
            sortDirection: 'asc',
        },
        FragmentItemBom: {
            isOpen: false,
            loading: false,
            items: [],
            selectedItems: [],
            currentPage: 1,
            totalItems: 0,
            searchTerm: '',
            sortDirection: 'asc',
        },
    });

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [itemTypeQty, setItemTypeQty] = useState<number>(0);
    const [selectedItemsMap, setSelectedItemsMap] = useState<SelectedItemsMap>({});
    const [currentItemTypeIndex, setCurrentItemTypeIndex] = useState<number | null>(null);
    const [currentFragmentItemTypeIndex, setCurrentFragmentItemTypeIndex] = useState<number | null>(null);
    const [getProject, setGetProject] = useState<BOMProject | null>(null);
    const [getProjectHas, setGetProjectHas] = useState<boolean>(false);

    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [clientLink, setClientLink] = useState<string>('');

    const [, setRequiredQty] = useState<boolean>(false);
    const [projectData, setProjectData] = useState<AddressData>({
        address: {
            full_address: '',
            latitude: '',
            longitude: ''
        },
        target_date: '',
        title: ''
    });
    const [projectImg, setProjectImg] = useState<File[] | null>(null);
    const [projectId, setProjectId] = useState<number | null>(null);

    const [itemTypeCount, setItemTypeCount] = useState<{ [key: number]: number }>({});
    const [itemFragmentCount, setFragmentCount] = useState<{ [key: number]: number }>({});

    const [removedItemTypes, setRemovedItemTypes] = useState<any[]>([]);

    const [isLoadingFlush, setIsLoadingFlush] = useState(false);
    const [isLoadingPrice, setIsLoadingPrice] = useState(false);
    const [shouldSaveProject, setShouldSaveProject] = useState<boolean>(false);


    const [itemTypeFragmentId, setSelectedItemTypeFragmentId] = useState<number | null>(null);
    const [fragmentId, setFragmentId] = useState<number | null>(null);
    const [itemTypeQtyByFragment, setItemTypeQtyByFragment] = useState<number>(0);
    const [fragmentIndex, setSelectedFragmentIndex] = useState<number | null>(null);



    const [requestBodyAddItemFragment, setRequestBodyAddItemFragment] = useState<{
        fragments: {
            fragment_id: number;
            fragment_index: number;
            item_type_id: number;
            item_type_index: number;
            items: { id: number; qty: number }[];
        }[];
    }>({
        fragments: [],
    });


    const location = useLocation();
    const {id} = location.state || {};


    useEffect(() => {
        const totalQty = Object.values(selectedItemsMap)
            .flat()
            .reduce((sum, item) => sum + item.qty, 0);

        setRequiredQty(totalQty > 0);
    }, [selectedItemsMap]);

    useEffect(() => {
        const newItemTypeCount: { [key: number]: number } = {};
        modalState.itemType.selectedItems.forEach(itemType => {
            newItemTypeCount[itemType.id] = (newItemTypeCount[itemType.id] || 0) + 1;
        });
        setItemTypeCount(newItemTypeCount);
    }, [modalState.itemType.selectedItems]);

    useEffect(() => {
        const newFragmentCount: { [key: number]: number } = {};
        modalState.fragments.selectedItems.forEach(fragment => {
            newFragmentCount[fragment.id] = (newFragmentCount[fragment.id] || 0) + 1;
        });
        setFragmentCount(newFragmentCount);
    }, [modalState.fragments.selectedItems]);

    useEffect(() => {
        const storedProjectId = localStorage.getItem('projectId');
        if (storedProjectId || id) {
            const fetchProject = async () => {
                try {
                    const response = await getProjectBomById(storedProjectId || id);
                    setGetProject(response);
                    setGetProjectHas(!!response.link);
                    if (response.link) {
                        setClientLink(response.link);
                        setIsReadOnly(true);
                    }
                    setProjectId(Number(storedProjectId) || id);

                    if (!response.link) {
                        const selectedItemTypes: ItemType[] = response.data.item_types;
                        setModalState(prev => ({
                            ...prev,
                            itemType: {
                                ...prev.itemType,
                                selectedItems: selectedItemTypes,
                            }
                        }));

                        const initialItemTypeCount: { [key: number]: number } = {};
                        selectedItemTypes.forEach(itemType => {
                            initialItemTypeCount[itemType.id] = (initialItemTypeCount[itemType.id] || 0) + 1;
                        });
                        setItemTypeCount(initialItemTypeCount);

                        const initialSelectedItemsMap: SelectedItemsMap = {};
                        selectedItemTypes.forEach((itemType, index) => {
                            if (itemType.item) {
                                initialSelectedItemsMap[index] = [{
                                    ...itemType.item,
                                    source: 'add',
                                    qty: itemType.qty || 0,
                                }];
                            } else {
                                initialSelectedItemsMap[index] = [];
                            }

                            if (itemType.allowed_items && itemType.allowed_items.length > 0) {
                                const allowedItems = itemType.allowed_items.map((allowedItem: { qty: any; }) => ({
                                    ...allowedItem,
                                    source: 'checkbox',
                                    qty: allowedItem.qty || 0,
                                }));
                                initialSelectedItemsMap[index].push(...allowedItems);
                            }
                        });
                        setSelectedItemsMap(initialSelectedItemsMap);

                        const projectFragments = response.data.fragments;
                        if (projectFragments && projectFragments.length > 0) {
                            setModalState(prev => ({
                                ...prev,
                                fragments: {
                                    ...prev.fragments,
                                    selectedItems: projectFragments,
                                }
                            }));

                            const initialFragmentCount: { [key: number]: number } = {};
                            projectFragments.forEach((fragment: { id: number }) => {
                                initialFragmentCount[fragment.id] = (initialFragmentCount[fragment.id] || 0) + 1;
                            });
                            setFragmentCount(initialFragmentCount);
                        }
                    } else  {
                        const projectFragments = response.data.fragments;
                        if (projectFragments && projectFragments.length > 0) {
                            setModalState(prev => ({
                                ...prev,
                                fragments: {
                                    ...prev.fragments,
                                    selectedItems: projectFragments,
                                }
                            }));
                        }
                    }
                } catch (error) {
                    console.error("Error fetching project BOM:", error);
                }
            };

            fetchProject();
        }
    }, []);


    useEffect(() => {
        if (modalState.FragmentItemBom.isOpen && itemTypeFragmentId !== null && fragmentIndex !== null) {
            fetchItems(
                'FragmentItemBom',
                modalState.FragmentItemBom.currentPage,
                modalState.FragmentItemBom.searchTerm,
                modalState.FragmentItemBom.sortDirection,
                itemTypeFragmentId
            );
        }
    }, [modalState.FragmentItemBom.isOpen]);


    useEffect(() => {
        return () => {
            localStorage.removeItem('projectId');
            localStorage.removeItem('itemTypeFragmentId');
            localStorage.removeItem('fragmentIndex');
        };
    }, [projectId]);



    useEffect(() => {
        const updateRequestBody = () => {
            const newFragments = modalState.fragments.selectedItems
                .flatMap((fragment, fragmentIndex) => {
                    return (fragment.data.item_types as unknown as ItemType[])
                        .map((itemType, itemTypeIndex) => {
                            const items = itemType?.items || [];

                            if (items.length > 0) {
                                return {
                                    fragment_id: fragment.id,
                                    fragment_index: fragmentIndex,
                                    item_type_id: itemType.id,
                                    item_type_index: itemTypeIndex,
                                    items: items.map((item: { id: any; qty: any; }) => ({
                                        id: item.id,
                                        qty: item.qty,
                                    })),
                                };
                            } else {
                                return null;
                            }
                        })
                        .filter((itemTypeData) => itemTypeData !== null);
                })
                .filter((fragment): fragment is NonNullable<typeof fragment> => fragment !== null);

            setRequestBodyAddItemFragment({
                fragments: newFragments,
            });
        };

        updateRequestBody();
    }, [modalState.fragments.selectedItems]);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleItemTypeCountChange = (itemId: number, count: number) => {
        setItemTypeCount(prev => ({
            ...prev,
            [itemId]: (prev[itemId] || 0) + count
        }));
    };

    const handleFragmentCountChange = (fragmentId: number, count: number) => {
        setFragmentCount(prev => ({
            ...prev,
            [fragmentId]: (prev[fragmentId] || 0) + count
        }));
    };


    const navigateToAssistance = () => {
        clearLocalStorageExceptToken();
        navigate('/assistance');
    };

    const handleCloseModal = (modal: keyof ModalState) => {
        setModalState(prev => ({
            ...prev,
            [modal]: {...prev[modal], isOpen: false}
        }));
    };

    const handlePageChange = (modal: keyof ModalState, pageNumber: number) => {
        setModalState(prev => ({
            ...prev,
            [modal]: {...prev[modal], currentPage: pageNumber}
        }));

        let itemTypeId: number | undefined;

        if (modal === 'itemBom' && currentItemTypeIndex !== null) {
            itemTypeId = modalState.itemType.selectedItems[currentItemTypeIndex].id;
        }
        if (modal === 'FragmentItemBom' && currentFragmentItemTypeIndex !== null && fragmentIndex !== null && fragmentIndex !== undefined) {
            const itemTypes = modalState.fragments.selectedItems[fragmentIndex].data.item_types as unknown as ItemType[];
            if (Array.isArray(itemTypes)) {
                itemTypeId = itemTypes[currentFragmentItemTypeIndex].id;
                fetchItems(modal, pageNumber, modalState[modal].searchTerm, modalState[modal].sortDirection, modal === 'FragmentItemBom' ? itemTypeFragmentId : undefined);
            }
        }

        if (modal === 'fragments') {
            fetchItems(modal, pageNumber, modalState[modal].searchTerm, modalState[modal].sortDirection);
        } else {
            fetchItems(
                modal,
                pageNumber,
                modalState[modal].searchTerm,
                modalState[modal].sortDirection,
                modal === 'itemBom' ? itemTypeId : undefined
            );
        }
    };


    const handleReload = (modal: keyof ModalState) => {
        let itemTypeId: number | undefined;

        if (modal === 'itemBom' && currentItemTypeIndex !== null) {
            itemTypeId = modalState.itemType.selectedItems[currentItemTypeIndex].id;
        }
        if (modal === 'FragmentItemBom' && currentItemTypeIndex !== null) {
            fetchItems(modal, modalState[modal].currentPage, modalState[modal].searchTerm, modalState[modal].sortDirection, modal === 'FragmentItemBom' ? itemTypeFragmentId : undefined);
        }

        if (modal === 'fragments') {
            fetchItems(modal, modalState[modal].currentPage, modalState[modal].searchTerm, modalState[modal].sortDirection);
        } else {
            fetchItems(modal, modalState[modal].currentPage, modalState[modal].searchTerm, modalState[modal].sortDirection, modal === 'itemBom' ? itemTypeId : undefined);
        }
    };


    const handleSortChange = (modal: keyof ModalState, direction: 'asc' | 'desc') => {
        setModalState(prev => ({
            ...prev,
            [modal]: {...prev[modal], sortDirection: direction}
        }));
        let itemTypeId: number | undefined;
        if (modal === 'itemBom' && currentItemTypeIndex !== null) {
            itemTypeId = modalState.itemType.selectedItems[currentItemTypeIndex].id;
        }
        if (modal === 'FragmentItemBom' && currentFragmentItemTypeIndex !== null && fragmentIndex !== null && fragmentIndex !== undefined) {
            const itemTypes = modalState.fragments.selectedItems[fragmentIndex].data.item_types as unknown as ItemType[];
            if (Array.isArray(itemTypes)) {
                itemTypeId = itemTypes[currentFragmentItemTypeIndex].id;
                fetchItems(modal, modalState[modal].currentPage, modalState[modal].searchTerm, direction, modal === 'FragmentItemBom' ? itemTypeFragmentId : undefined);
            }
        }
        if (modal === 'fragments') {
            fetchItems(modal, modalState[modal].currentPage, modalState[modal].searchTerm, direction);
        } else {
            fetchItems(
                modal,
                modalState[modal].currentPage,
                modalState[modal].searchTerm,
                direction,
                modal === 'itemBom' ? itemTypeId : undefined
            );
        }
    };


    const fetchItems = useCallback(async (
        modal: keyof ModalState,
        page: number = 1,
        searchTerm: string = '',
        sortDir: "asc" | "desc" = 'asc',
        itemTypeId?: number | null | undefined
    ) => {
        setModalState(prev => ({
            ...prev,
            [modal]: {...prev[modal], loading: true}
        }));

        try {
            let url = '';
            if (modal === 'itemType') {
                url = searchTerm
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types?page=${page}&perPage=5&title=${encodeURIComponent(searchTerm)}&sortOrder=${sortDir}`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types?page=${page}&perPage=5&sortOrder=${sortDir}`;
            } else if (modal === 'itemBom') {
                if (!itemTypeId) {
                    console.error("itemTypeId is required for fetching itemBom");
                    return;
                }
                url = searchTerm
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5&title=${searchTerm}&sortOrder=${sortDir}`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5&sortOrder=${sortDir}`;
            } else if (modal === 'fragments') {
                url = searchTerm
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/fragments?page=${page}&perPage=5&title=${searchTerm}&sortOrder=${sortDir}`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/fragments?page=${page}&perPage=5&sortOrder=${sortDir}`;
            } else if (modal === 'FragmentItemBom') {
                if (!itemTypeFragmentId) {
                    return;
                }
                url = searchTerm
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeFragmentId}?page=${page}&perPage=5&title=${searchTerm}&sortOrder=${sortDir}`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeFragmentId}?page=${page}&perPage=5&sortOrder=${sortDir}`;
            }

            const response = await get(url);

            if (modal === 'itemType') {
                setModalState(prev => ({
                    ...prev,
                    itemType: {
                        ...prev.itemType,
                        items: response.data.data,
                        totalItems: response.data.pagination.lastPage,
                    }
                }));
            } else if (modal === 'itemBom') {
                setModalState(prev => ({
                    ...prev,
                    itemBom: {
                        ...prev.itemBom,
                        items: response.data.items.data,
                        totalItems: response.data.items.pagination.lastPage,
                    }
                }));
            } else if (modal === 'fragments') {
                setModalState(prev => ({
                    ...prev,
                    fragments: {
                        ...prev.fragments,
                        items: response.data.data,
                        totalItems: response.data.pagination.lastPage,
                    }
                }));
            } else if (modal === 'FragmentItemBom') {
                setModalState(prev => ({
                    ...prev,
                    FragmentItemBom: {
                        ...prev.FragmentItemBom,
                        items: response.data.items.data,
                        totalItems: response.data.items.pagination.lastPage,
                    }
                }));
            }
        } catch (error) {
            console.error(`Failed to get elements for ${modal}:`, error);
        } finally {
            setModalState(prev => ({
                ...prev,
                [modal]: {...prev[modal], loading: false}
            }));
        }
    }, [modalState, currentItemTypeIndex]);

    const handleSearch = (modal: keyof ModalState, query: string) => {
        setModalState(prev => ({
            ...prev,
            [modal]: {...prev[modal], searchTerm: query, currentPage: 1}
        }));
        let itemTypeId: number | undefined;
        if (modal === 'itemBom' && currentItemTypeIndex !== null) {
            itemTypeId = modalState.itemType.selectedItems[currentItemTypeIndex].id;
        }
        if (modal === 'FragmentItemBom' && currentFragmentItemTypeIndex !== null && fragmentIndex !== null && fragmentIndex !== undefined) {
            const itemTypes = modalState.fragments.selectedItems[fragmentIndex].data.item_types as unknown as ItemType[];
            if (Array.isArray(itemTypes)) {
                itemTypeId = itemTypes[currentFragmentItemTypeIndex].id;
                fetchItems(modal, 1, query, modalState[modal].sortDirection, modal === 'FragmentItemBom' ? itemTypeFragmentId : undefined);
            }
        }

        if (modal === 'fragments') {
            fetchItems(modal, 1, query, modalState[modal].sortDirection);
        } else {
            fetchItems(
                modal,
                1,
                query,
                modalState[modal].sortDirection,
                modal === 'itemBom' ? itemTypeId : undefined
            );
        }
    };


    const handleOpenModal = (modal: keyof ModalState, itemTypeIndex?: number, itemTypeQty?: number) => {
        if (modal === 'itemBom' && itemTypeIndex !== undefined) {
            setCurrentItemTypeIndex(itemTypeIndex);
        }
        setItemTypeQty(itemTypeQty ? itemTypeQty : 0);


        if (modal === 'FragmentItemBom' && itemTypeIndex !== undefined) {
            setCurrentFragmentItemTypeIndex(itemTypeIndex);
            setModalState(prev => ({
                ...prev,
                [modal]: {...prev[modal], isOpen: true}
            }));
            return
        }

        if (modal === 'itemType') {
            const selectedItems = modalState.itemType.selectedItems.reduce<ItemType[]>((acc, itemType) => {
                const count = itemTypeCount[itemType.id] || 0;
                for (let i = 0; i < count; i++) {
                    acc.push(itemType);
                }
                return acc;
            }, []);

            setModalState(prev => ({
                ...prev,
                itemType: {...prev.itemType, isOpen: true, initialSelectedItems: selectedItems}
            }));
        } else if (modal === 'fragments') {
            const selectedItems = modalState.fragments.selectedItems.reduce<Fragment[]>((acc, fragment) => {
                const count = itemTypeCount[fragment.id] || 0;
                for (let i = 0; i < count; i++) {
                    acc.push(fragment);
                }
                return acc;
            }, []);

            setModalState(prev => ({
                ...prev,
                fragments: {...prev.fragments, isOpen: true, initialSelectedItems: selectedItems}
            }));
        } else {
            setModalState(prev => ({
                ...prev,
                [modal]: {...prev[modal], isOpen: true}
            }));
        }

        const itemTypeId = itemTypeIndex !== undefined ? modalState.itemType.selectedItems[itemTypeIndex].id : undefined;

        if (modal === 'fragments') {
            fetchItems(modal, 1, modalState[modal].searchTerm, modalState[modal].sortDirection, undefined);
        } else {
            fetchItems(modal, 1, modalState[modal].searchTerm, modalState[modal].sortDirection, modal === 'itemBom' ? itemTypeId : undefined);
        }
    };


    const handleSelectItemsItemType = (selected: ItemType[]) => {
        setModalState(prev => ({
            ...prev,
            itemType: {...prev.itemType, selectedItems: selected}
        }));

        const updatedSelectedItemsMap: SelectedItemsMap = {};
        selected.forEach((itemType, index) => {
            updatedSelectedItemsMap[index] = [];

            if (itemType.item) {
                updatedSelectedItemsMap[index].push({
                    ...itemType.item,
                    source: 'add',
                    qty: itemType.qty || 0,
                });
            }

            if (itemType.allowed_items && itemType.allowed_items.length > 0) {
                const checkboxItems = itemType.allowed_items.map((item: { qty: any; }) => ({
                    ...item,
                    source: 'checkbox',
                    qty: item.qty || 0,
                }));
                updatedSelectedItemsMap[index].push(...checkboxItems);
            }
        });
        setSelectedItemsMap(updatedSelectedItemsMap);
    };


    const handleSelectFragments = (selected: Fragment[]) => {
        setModalState(prev => ({
            ...prev,
            fragments: {...prev.fragments, selectedItems: selected}
        }));
        setShouldSaveProject(true);
    };

    useEffect(() => {
        if (shouldSaveProject && modalState.fragments.selectedItems.length > 0) {
            handleSaveProject()
                .then(() => setShouldSaveProject(false))
                .catch(() => setShouldSaveProject(false));
        }
    }, [modalState.fragments.selectedItems, shouldSaveProject]);

    const handleSelectItemsItemBom = (selected: TItem[]) => {
        if (currentItemTypeIndex !== null) {
            setSelectedItemsMap(prev => ({
                ...prev,
                [currentItemTypeIndex]: selected
            }));
        }
    };

    const handleSelectFragmentItemsBom = (fragmentId: number, itemTypeId: number, selected: TItem[]) => {
        setModalState(prevState => {
            const updatedFragments = prevState.fragments.selectedItems.map(fragment => {
                if ('id' in fragment && fragment.id === fragmentId) {
                    const updatedItemTypes = (fragment.data.item_types as unknown as ItemType[]).map(itemType => {
                        if (itemType.id === itemTypeId) {
                            return {
                                ...itemType,
                                items: selected,
                            };
                        }
                        return itemType;
                    });

                    return {
                        ...fragment,
                        data: {
                            ...fragment.data,
                            item_types: updatedItemTypes,
                        }
                    };
                }
                return fragment;
            }) as Fragment[];

            return {
                ...prevState,
                fragments: {
                    ...prevState.fragments,
                    selectedItems: updatedFragments,
                },
            };
        });
    };


    const handleRemoveItemFragment = (
        fragmentId: number | undefined,
        itemTypeId: number | undefined,
        itemId: number
    ) => {
        if (fragmentId !== undefined && itemTypeId !== undefined) {

            setModalState(prev => {
                const updatedFragments = prev.fragments.selectedItems.map(fragment => {
                    if (fragment.id === fragmentId) {
                        const updatedItemTypes = (fragment.data.item_types as unknown as ItemType[]).map(itemType => {
                            if (itemType.id === itemTypeId) {
                                return {
                                    ...itemType,
                                    items: itemType.items.filter(item => item.id !== itemId)
                                };
                            }
                            return itemType;
                        });

                        return {
                            ...fragment,
                            data: {
                                ...fragment.data,
                                item_types: updatedItemTypes
                            }
                        } as unknown as Fragment;
                    }
                    return fragment;
                });

                return {
                    ...prev,
                    fragments: {
                        ...prev.fragments,
                        selectedItems: updatedFragments as Fragment[]
                    }
                };
            });
        }
    };

    const handleRemoveSelectedItem = (itemTypeIndex: number, itemIndex: number) => {
        setSelectedItemsMap(prev => {
            const updatedItems = prev[itemTypeIndex].filter((_, idx) => idx !== itemIndex);
            return {
                ...prev,
                [itemTypeIndex]: updatedItems,
            };
        });
    };

    const handleRemoveFragmentItemType = (
        fragmentId: number | undefined,
        fragmentIndex: number | undefined,
        itemTypeId: number,
        itemTypeIndex: number | undefined
    ) => {
        const removalData = {
            fragment_id: fragmentId,
            fragment_index: fragmentIndex,
            item_type_id: itemTypeId,
            item_type_index: itemTypeIndex,
        };
        setRemovedItemTypes((prev) => [...prev, removalData]);
    };


    const handleDataReady = (addressData: AddressData, fileData: FileData) => {
        setProjectData(addressData);
        setProjectImg(fileData.file);
    };

    const handleSaveProject = async () => {
        const itemTypesData = modalState.itemType.selectedItems.map((selectedItemType, index) => {
            const selectedItems = selectedItemsMap[index];

            if (selectedItems && selectedItems.length > 0) {
                const qtyItemType = selectedItems.find((item: TItem) => item.source === 'add')?.qty || 0;
                const itemDefaultId = selectedItems.find((item: TItem) => item.source === 'add')?.id;
                const allowedItemId = selectedItems.map((item: TItem) => item.id);
                return {
                    id: selectedItemType.id,
                    qty: qtyItemType,
                    item: itemDefaultId,
                    allowed_items: allowedItemId,
                };
            } else {
                return {
                    id: selectedItemType.id,
                    allowed_items: [],
                };
            }
        });

        const fragmentData = modalState.fragments.selectedItems.map((fragment) => {
            const questions = Object.entries(fragment.data)
                .filter(([_, data]) => data.key && data.value && data.question)
                .map(([_, data]) => ({
                    key: data.key,
                    value: data.value,
                }));

            return {
                id: fragment.id,
                questions: questions,
            };
        });

        const body = {
            address: projectData.address,
            title: projectData.title,
            target_date: projectData.target_date,
            data: {
                item_types: itemTypesData,
                fragments: fragmentData,
            },
        };

        try {
            let response: { status: any; data: any; };
            if (projectId === null) {
                response = await postCreateBom(body);

                if (response.status === 400) {
                    toast.error('You need to add the product for the product type and enter the qty', { autoClose: 5000 });
                }

                if (response.status === 201) {
                    setGetProject(response.data)
                    setProjectId(response.data.id);

                    setModalState((prev) => ({
                        ...prev,
                        fragments: {
                            ...prev.fragments,
                            selectedItems: response.data.data.fragments,
                        },
                        itemType: {
                            ...prev.itemType,
                            selectedItems: response.data.data.item_types,
                        }
                    }));

                    const newSelectedItemsMap: SelectedItemsMap = {};
                    response.data.data.item_types.forEach((itemType: any, index: number) => {
                        if (itemType.item) {
                            newSelectedItemsMap[index] = [{
                                ...itemType.item,
                                source: 'add',
                                qty: itemType.qty || 0,
                            }];
                        } else {
                            newSelectedItemsMap[index] = [];
                        }

                        if (itemType.allowed_items && itemType.allowed_items.length > 0) {
                            const allowedItems = itemType.allowed_items.map((allowedItem: { qty: any; }) => ({
                                ...allowedItem,
                                source: 'checkbox',
                                qty: allowedItem.qty || 0,
                            }));
                            newSelectedItemsMap[index].push(...allowedItems);
                        }
                    });
                    setSelectedItemsMap(newSelectedItemsMap);

                    const newSelectedFragmentItemsMap: SelectedItemsMapByFragment = {};
                    response.data.data.fragments.forEach((fragment: any) => {
                        newSelectedFragmentItemsMap[fragment.id] = {};
                        fragment.data.item_types.forEach((itemType: any) => {
                            newSelectedFragmentItemsMap[fragment.id][itemType.id] = itemType.items || [];
                        });
                    });

                    if (removedItemTypes.length > 0 && response.data.id) {
                        await deleteRemovedItemTypes(response.data.id);
                    }
                    if (requestBodyAddItemFragment.fragments.length > 0 && response.data.id) {
                        const responseAddItems = await addItemsToFragments(response.data.id, requestBodyAddItemFragment);
                        if (responseAddItems.status === 200) {
                            setModalState((prev) => ({
                                ...prev,
                                fragments: {
                                    ...prev.fragments,
                                    selectedItems: responseAddItems.data.data.fragments,
                                },
                            }));
                        }
                    }
                }

                if (response.status === 201 && projectImg) {
                    for (const imageFile of projectImg) {
                        await uploadProjectImage(response.data.id, imageFile);
                    }
                }
            } else {
                response = await patchCreateBom(body, projectId);
                if (response.status === 400) {
                    toast.error('You need to add the product for the product type and enter the qty', { autoClose: 5000 });
                }

                if (response.status === 200) {
                    toast.success('Project updated successfully');
                    setGetProject(response.data)

                    setModalState((prev) => ({
                        ...prev,
                        fragments: {
                            ...prev.fragments,
                            selectedItems: response.data.data.fragments,
                        },
                        itemType: {
                            ...prev.itemType,
                            selectedItems: response.data.data.item_types,
                        }
                    }));
                    const newSelectedItemsMap: SelectedItemsMap = {};
                    response.data.data.item_types.forEach((itemType: any, index: number) => {
                        if (itemType.item) {
                            newSelectedItemsMap[index] = [{
                                ...itemType.item,
                                source: 'add',
                                qty: itemType.qty || 0,
                            }];
                        } else {
                            newSelectedItemsMap[index] = [];
                        }

                        if (itemType.allowed_items && itemType.allowed_items.length > 0) {
                            const allowedItems = itemType.allowed_items.map((allowedItem: { qty: any; }) => ({
                                ...allowedItem,
                                source: 'checkbox',
                                qty: allowedItem.qty || 0,
                            }));
                            newSelectedItemsMap[index].push(...allowedItems);
                        }
                    });
                    setSelectedItemsMap(newSelectedItemsMap);

                    if (removedItemTypes.length > 0) {
                        await deleteRemovedItemTypes(response.data.id);
                    }
                    if (requestBodyAddItemFragment.fragments.length > 0 && response.data.id) {
                        const responseAddItems = await addItemsToFragments(response.data.id, requestBodyAddItemFragment);
                        if (responseAddItems.status === 200) {
                            setModalState((prev) => ({
                                ...prev,
                                fragments: {
                                    ...prev.fragments,
                                    selectedItems: responseAddItems.data.data.fragments,
                                },
                            }));
                        }
                    }
                }
                if (response.status === 200 && projectImg && response.data.id) {
                    for (const imageFile of projectImg) {
                        await uploadProjectImage(projectId, imageFile);
                    }
                }
            }
            return response.data.id;
        } catch (error) {
            if (projectId === null) {
                console.error("Error in handleCreateBomProject:", error);
                toast.error('Error creating project.');
            } else {
                console.error("Error in handleUpdateProject:", error);
                toast.error('Error updating project.');
            }
            throw error;
        }
    };


    const uploadProjectImage = async (projectId: number, imageFile: File) => {
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("projectId", projectId.toString());

        try {
            const response = await PostFormData(
                BASE_URL_CONTRACTOR || "",
                `/bom_projects/${projectId}/media`,
                formData
            );

            if (response.status !== 200 && response.status !== 201) {
                throw new Error("Failed to upload image");
            }
            return response.data;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const deleteRemovedItemTypes = async (projectId: number) => {
        try {
            const fragments = removedItemTypes.map(removedItem => ({
                fragment_id: removedItem.fragment_id,
                fragment_index: removedItem.fragment_index,
                item_type_id: removedItem.item_type_id,
                item_type_index: removedItem.item_type_index,
            }));
            if (fragments.length > 0) {
                const body = {
                    fragments,
                };
                await deleteProjectBomItemType(projectId, body);

                toast.success('Deleted removed item types successfully.');
            } else {
                console.error('No removed item types to delete.');
            }
        } catch (error) {
            console.error("Error deleting removed item types:", error);
            toast.error('Error deleting removed item types.');
        }
    };

    const handleGetLink = async (projectId: number) => {
        try {
            const response = await post(`${BASE_URL_CONTRACTOR}/bom_projects/${projectId}/link`);

            if (response.status === 200) {
                setGetProject(response.data)
                const link = response.data.link;
                const fullLink = `${window.location.origin}/create/step2Bom/${link}`;

                 navigator.clipboard.writeText(fullLink);

                toast.success('Link received. Enter it in the URL field');

                setIsReadOnly(true);
                setClientLink(link);
            }
        } catch (error) {
            toast.error('Couldn\'t get the link');
            throw error;
        }
    };

    const handleQtyChange = (itemTypeIndex: number, itemId: number, qty: number) => {
        setSelectedItemsMap(prev => ({
            ...prev,
            [itemTypeIndex]: prev[itemTypeIndex].map(item =>
                item.id === itemId ? {...item, qty} : item
            )
        }));
    };

    const totalPayable = useMemo(() => {
        const selectedItemsTotal = Object.values(selectedItemsMap).flat().reduce((acc, item) => {
            return acc + (item.price * item.qty);
        }, 0);
        const fragmentsTotal = modalState.fragments.selectedItems?.length > 0
            ? modalState.fragments.selectedItems.reduce((total, fragment) => {
                const itemTypes = fragment.data?.item_types as unknown as ItemType[] || [];

                const fragmentItemTotal = itemTypes.reduce((subTotal, itemType) => {
                    const items = itemType.items || [];

                    const itemsTotal = items.reduce((itemSum, item) => {
                        return itemSum + (item.qty * parseFloat(String(item.price)));
                    }, 0);

                    return subTotal + itemsTotal;
                }, 0);

                return total + fragmentItemTotal;
            }, 0)
            : 0;

        return selectedItemsTotal + fragmentsTotal;
    }, [selectedItemsMap, modalState.fragments.selectedItems]);

    const totalPayableGet = () => {
        if (getProject) {
            const itemTypeTotal = (getProject.data.item_types || []).reduce((total, itemType) => {
                const price = parseFloat(itemType.item?.price || '0');
                const quantity = itemType.qty || 0;
                return total + price * quantity;
            }, 0);

            const fragmentTotal = (getProject.data.fragments || []).reduce((total, fragment) => {
                const itemTypes = (fragment.data?.item_types || []) as unknown as ItemType[];
                const fragmentItemTotal = itemTypes.reduce((subTotal, itemType) => {
                    const itemsTotal = (itemType.items || []).reduce((itemSum, item) => {
                        const price = parseFloat(String(item.price || 0));
                        const quantity = item.qty || 0;
                        return itemSum + price * quantity;
                    }, 0);
                    return subTotal + itemsTotal;
                }, 0);
                return total + fragmentItemTotal;
            }, 0);

            const total = itemTypeTotal + fragmentTotal;
            return total.toFixed(2);
        }

        return '0.00';
    };



    const handleRemoveItemType = (itemTypeIndex: number) => {
        const newSelectedItems = modalState.itemType.selectedItems.filter((_, idx) => idx !== itemTypeIndex);
        setModalState(prev => ({
            ...prev,
            itemType: {
                ...prev.itemType,
                selectedItems: newSelectedItems,
                totalItems: newSelectedItems.length,
            }
        }));

        setSelectedItemsMap(prev => {
            const updatedMap: SelectedItemsMap = {};
            Object.keys(prev).forEach(key => {
                const keyNum = parseInt(key, 10);
                if (keyNum === itemTypeIndex) {
                    return;
                } else if (keyNum > itemTypeIndex) {
                    updatedMap[keyNum - 1] = prev[keyNum];
                } else {
                    updatedMap[keyNum] = prev[keyNum];
                }
            });
            return updatedMap;
        });

        setItemTypeCount(prevCount => {
            const updatedCount: { [key: number]: number } = {};
            Object.keys(prevCount).forEach(key => {
                const keyNum = parseInt(key, 10);
                if (keyNum === itemTypeIndex) {
                    return;
                } else if (keyNum > itemTypeIndex) {
                    updatedCount[keyNum - 1] = prevCount[keyNum];
                } else {
                    updatedCount[keyNum] = prevCount[keyNum];
                }
            });
            return updatedCount;
        });
    };

    const handleRemoveFragment = (fragmentIndex: number) => {
        const newSelectedFragments = modalState.fragments.selectedItems.filter((_, idx) => idx !== fragmentIndex);
        setModalState(prev => ({
            ...prev,
            fragments: {
                ...prev.fragments,
                selectedItems: newSelectedFragments,
                totalItems: newSelectedFragments.length,
            }
        }));

        setFragmentCount(prevCount => {
            const updatedCount: { [key: number]: number } = {};

            Object.keys(prevCount).forEach(key => {
                const keyNum = parseInt(key, 10);
                if (keyNum < fragmentIndex) {
                    updatedCount[keyNum] = prevCount[keyNum];
                } else if (keyNum > fragmentIndex) {
                    updatedCount[keyNum - 1] = prevCount[keyNum];
                }
            });

            return updatedCount;
        });
    };

    const handleCopyLink = (link: string) => {
        navigator.clipboard.writeText(link);
        toast.success('Link copied to clipboard!');
    };
    const handleSaveThenAction = async (action: (projectId: number) => Promise<void>) => {
        if (!projectId) {
            const savedProjectId = await handleSaveProject();
            if (savedProjectId) {
                setProjectId(savedProjectId);
                await action(savedProjectId);
            } else {
                toast.error('Failed to save project before performing the action.');
            }
        } else {
            await action(projectId);
        }
    };

    const handleFlushProject = async (savedProjectId: number) => {
        setIsLoadingFlush(true);
        try {
            const response = await flushBOMProject(savedProjectId);
            if (response.status === 200) {
                toast.success('You have successfully reset the project!');
                setModalState((prev) => ({
                    ...prev,
                    fragments: {
                        ...prev.fragments,
                        selectedItems: response.data.data.fragments,
                    },
                }));
            } else {
                toast.error('Failed to reset the project.');
            }
        } catch (error) {
            console.error("Error in handleFlushProject:", error);
            toast.error('An error occurred while resetting the project.');
        } finally {
            setIsLoadingFlush(false);
        }
    };

    const handleMinimumPriceProject = async (savedProjectId: number) => {
        setIsLoadingPrice(true);
        try {
            const response = await minimumPriceBOMProject(savedProjectId);
            if (response.status === 200) {
                toast.success('You have successfully added products with minimum price to the project');
                setModalState((prev) => ({
                    ...prev,
                    fragments: {
                        ...prev.fragments,
                        selectedItems: response.data.data.fragments,
                    },
                }));
            } else {
                toast.error('Failed to add products with minimum price.');
            }
        } catch (error) {
            console.error("Error in handleMinimumPriceProject:", error);
            toast.error('An error occurred while adding products with minimum price.');
        } finally {
            setIsLoadingPrice(false);
        }
    };

    const handleFlush = () => {
        handleSaveThenAction(handleFlushProject);
    };

    const handleMinimumPrice = () => {
        handleSaveThenAction(handleMinimumPriceProject);
    };

    const renderSelectedFragments = () => {
        const fragments =  modalState.fragments.selectedItems;


        if (fragments.length === 0) {
            return null;
        }
        return fragments.map((fragment: Fragment, index: number) => (
            <div key={index} className="selected-item-type-section">
                <div className="selected-item-type">
                    <div className="selected-item-type-info">
                        <img
                            src={
                                fragment.galleries &&
                                fragment.galleries.length > 0 &&
                                fragment.galleries[0].medias.length > 0
                                    ? fragment.galleries[0].medias[fragment.galleries[0].medias.length - 1].url
                                    : "/img_vendor/item.png"
                            }
                            alt={fragment.title}
                            className="selected-item-type-image"
                        />
                        <div>
                            <p className="selected-item-type-qty">Name</p>
                            <p className="selected-item-type-title">{fragment.title}</p>
                        </div>
                    </div>

                    {!isReadOnly && (
                        <div className="selected-item-type-actions">
                            <div
                                title="Remove product type"
                                onClick={() => handleRemoveFragment(index)}
                                className="btn-selected-item-button"
                            >
                                <IconRemoveItemType/>
                            </div>
                        </div>
                    )}
                </div>
                {renderFragmentItemTypesMap(index)}
            </div>
        ));
    };
    const handleCombinedClick = (fragmentId:number,itemTypeIndex: number, qty: number, id: number, fragmentIndex: number) => {
        setFragmentId(fragmentId)
        setItemTypeQtyByFragment(qty)
        setSelectedItemTypeFragmentId(id);
        setSelectedFragmentIndex(fragmentIndex);
        handleOpenModal('FragmentItemBom', itemTypeIndex, qty);
    };

    const hasItemTypesInFragments = modalState.fragments.selectedItems.some(
        (fragment) => (fragment.data.item_types as unknown as ItemType[])?.length > 0
    ) || (getProject?.data?.fragments?.some(
        (fragment) => (fragment.data.item_types as unknown as ItemType[])?.length > 0
    ));

    const renderFragmentItemTypesMap = (fragmentIndex: number) => {
        const fragment =  modalState.fragments.selectedItems[fragmentIndex];
        if (!fragment) {
            return null;
        }
        const itemTypes = fragment.data?.item_types as unknown as ItemType[];
        if (!itemTypes || itemTypes.length === 0) {
            return null;
        }

        return (
            <div key={`fragment-${fragmentIndex}`}
                 className={isReadOnly ? 'selected-get-bom-section' : 'selected-item-bom-section'}>
                {itemTypes.map((itemType, itemTypeIndex) => {
                    const isRemoved = removedItemTypes.some(
                        (removed) =>
                            removed.fragment_index === fragmentIndex &&
                            removed.item_type_index === itemTypeIndex
                    );
                    if (isRemoved) {
                        return null;
                    }

                    const fragmentItems = itemType.items;

                    if (fragmentItems && fragmentItems.length > 0) {
                        const from = 'fragment';
                        return (
                            <div key={`itemType-${itemTypeIndex}`}>
                                {renderGetProjectItemFragment(fragmentItems, itemType, from, itemTypeIndex, fragment.id, fragmentIndex)}
                            </div>
                        );
                    }
                    return (
                        <div key={`itemType-${itemTypeIndex}`} className="selected-item">
                            <div className="selected-item-info">
                                <img
                                    className="selected-item-image"
                                    src={
                                        itemType.vendor === 'cashbuild'
                                            ? itemType.merchant_info?.image ||
                                            (itemType.galleries && itemType.galleries.length > 0 && itemType.galleries[0].medias.length > 0 && itemType.galleries[0].medias[itemType.galleries[0].medias.length - 1]?.url) ||
                                            '/img_vendor/item.png'
                                            : (itemType.galleries && itemType.galleries.length > 0 && itemType.galleries[0].medias.length > 0 && itemType.galleries[0].medias[itemType.galleries[0].medias.length - 1]?.url) ||
                                            '/img_vendor/item.png'
                                    }
                                    alt="Item"
                                />

                                <div className='selected-item-name'>
                                    <p className="selected-item-type-qty">Name</p>
                                    <p className="selected-item-type-title">{itemType.title}</p>
                                </div>

                                <div className='selected-item-type-block'>
                                    <p className="selected-item-type-qty">Unit price</p>
                                    <p className="selected-item-type-title">
                                        R {isNaN(Number(itemType.price)) || itemType.price === null || itemType.price === undefined ? '0.00' : Number(itemType.price).toFixed(2)}
                                    </p>
                                </div>

                                <div className='selected-item-type-block'>
                                    <p className="selected-item-type-qty">Vendor</p>
                                    <p className="selected-item-type-title">
                                        {itemType.vendor !== null && vendorData[itemType.vendor as Vendor] ? (
                                            <p className='item-selection-modal-vendor'>{vendorData[itemType.vendor as Vendor].displayName}</p>
                                        ) : (
                                            <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                        )}
                                    </p>
                                </div>

                                <div className='selected-item-type-block'>
                                    <p className="selected-item-type-qty">QTY to order</p>
                                    <input
                                        className="input-bom-item-type"
                                        value={itemType.qty}
                                        disabled={true}
                                    />
                                </div>

                                <div className='selected-item-type-block'>
                                    <p className="selected-item-type-qty">Total</p>
                                </div>
                            </div>
                            {!isReadOnly && (
                            <div className='selected-item-actions-block'>
                                    <div className="selected-item-actions">
                                        <div
                                            className="btn-selected-item-button"
                                            onClick={() => handleCombinedClick(fragment.id,itemTypeIndex, itemType.qty, itemType.id, fragmentIndex)}
                                        >
                                            <IconBomSettingItemType/>
                                        </div>
                                        <div
                                            title='Remove product type'
                                            className="btn-selected-item-button"
                                            onClick={() => handleRemoveFragmentItemType(fragment.id, fragmentIndex, itemType.id, itemTypeIndex)}
                                        >
                                            <IconRemoveItem/>
                                        </div>
                                    </div>
                            </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };


    const renderSelectedItemTypes = () => {

        const itemTypes = isReadOnly && getProject
            ? (getProject.data?.item_types as unknown as ItemType[]) || []
            : (modalState.itemType.selectedItems && modalState.itemType.selectedItems.length > 0
                ? modalState.itemType.selectedItems
                : []);
        if (itemTypes && itemTypes.length === 0) {
            return null;
        }

        return itemTypes.map((itemType: ItemType, index: number) => (
            <div key={index} className="selected-item-type-section">
                <div className="selected-item-type">
                    <div className="selected-item-type-info">
                        <img
                            src={
                                itemType.galleries &&
                                itemType.galleries.length > 0 &&
                                itemType.galleries[0].medias.length > 0
                                    ? itemType.galleries[0].medias[itemType.galleries[0].medias.length - 1].url
                                    : "/img_vendor/item.png"
                            }
                            alt={itemType.title}
                            className="selected-item-type-image"
                        />
                        <div>
                            <p className="selected-item-type-qty">Name</p>
                            <p className="selected-item-type-title">{itemType.title}</p>
                        </div>
                    </div>

                    {!isReadOnly && (
                        <div className="selected-item-type-actions">
                            <div
                                className="btn-selected-item-button"
                                onClick={() => handleOpenModal('itemBom', index, itemType.qty)}
                            >
                                <IconBomSettingItemType/>
                            </div>
                            <div
                                title="Remove product type"
                                onClick={() => handleRemoveItemType(index)}
                                className="btn-selected-item-button"
                            >
                                <IconRemoveItemType/>
                            </div>
                        </div>
                    )}
                </div>

                {renderSelectedItemsMap(index)}
            </div>
        ));
    };

    const renderSelectedItemsMap = (itemTypeIndex: number) => {
        const items = selectedItemsMap[itemTypeIndex];
        if (!items || items.length === 0) {
            return null;
        }

        return (
            <div>
                {items
                    .filter((item: TItem) => item.source === 'add')
                    .map((item: TItem, itemIndex: number) => {
                        return (
                            <div key={itemIndex}
                                 className={isReadOnly ? 'selected-get-bom-section' : 'selected-item-bom-section'}>
                                <div className="selected-item">
                                    <div className="selected-item-info">
                                        <img
                                            className="selected-item-image"
                                            src={
                                                item.vendor === 'cashbuild'
                                                    ? item.merchant_info?.image ||
                                                    (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                    '/img_vendor/item.png'
                                                    : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                    '/img_vendor/item.png'
                                            }
                                            alt="Item"
                                        />

                                        <div className='selected-item-name'>
                                            <p className="selected-item-type-qty">Name</p>
                                            <p className="selected-item-type-title">{item.title}</p>
                                        </div>

                                        <div className='selected-item-type-block'>
                                            <p className="selected-item-type-qty">Unit price</p>
                                            <p className="selected-item-type-title">R {Number(item.price).toFixed(2)}</p>
                                        </div>

                                        <div className='selected-item-type-block'>
                                            <p className="selected-item-type-qty">Vendor</p>
                                            <p className="selected-item-type-title">
                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                ) : (
                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                )}
                                            </p>
                                        </div>

                                        <div className='selected-item-type-block'>
                                            <p className="selected-item-type-qty">QTY to order</p>
                                            <input
                                                className="input-bom-item-type"
                                                value={item.qty}
                                                onChange={(e) =>
                                                    handleQtyChange(
                                                        itemTypeIndex,
                                                        item.id,
                                                        parseInt(e.target.value, 10) || 0
                                                    )
                                                }
                                                disabled={isReadOnly}
                                            />
                                        </div>

                                        <div className='selected-item-type-block'>
                                            <p className="selected-item-type-qty">Total</p>
                                            <p className="selected-item-type-total">R {(item.qty * item.price).toFixed(2)}</p>
                                        </div>
                                    </div>

                                    <div className='selected-item-actions-block'>
                                        {!isReadOnly && (
                                            <div className="selected-item-actions">
                                                <div className="btn-selected-item-button">
                                                    <IconBomSettingItem/>
                                                </div>
                                                <div
                                                    title='Remove product'
                                                    className="btn-selected-item-button"
                                                    onClick={() => handleRemoveSelectedItem(itemTypeIndex, itemIndex)}
                                                >
                                                    <IconRemoveItem/>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const renderGetProjectItemTypes = () => {
        const from = 'itemType'
        if(!getProject?.data.item_types){
            return  null
        }
        return getProject?.data.item_types.map((itemType: BOMItemType, index: number) => (
            <div key={index} className="selected-item-type-section">
                <div className="selected-item-type">
                    <div className="selected-item-type-info">
                        <img
                            src={
                                "/img_vendor/item.png"
                            }
                            alt={itemType.title}
                            className="selected-item-type-image"
                        />
                        <div>
                            <p className="selected-item-type-qty">Name</p>
                            <p className="selected-item-type-title">{itemType.title}</p>
                        </div>
                    </div>
                </div>

                {renderGetProjectItemFragment([itemType.item], itemType, from)}
            </div>
        ));
    };
    const renderGetProjectItemFragment = (
        item: BOMItem[] | TItem[],
        itemType: BOMItemType | ItemType,
        from: string,
        itemTypeIndex?: number,
        fragmentId?: number,
        fragmentIndex?: number
    ) => {
        return (
            item.map(item=>(
            <div key={item.id} className={from === 'itemType' ? 'selected-get-bom-section' : undefined}>
                <div className="selected-item">
                    <div className="selected-item-info">
                        <img
                            className="selected-item-image"
                            src={
                                !item.vendor
                                    ? '/img_vendor/item.png'
                                    : item.vendor === 'cashbuild'
                                        ? item.merchant_info?.image ||
                                        (item.galleries?.length > 0 &&
                                            item.galleries[0].medias?.length > 0 &&
                                            item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                        '/img_vendor/item.png'
                                        : (item.galleries?.length > 0 &&
                                            item.galleries[0].medias?.length > 0 &&
                                            item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                        '/img_vendor/item.png'
                            }
                            alt="Item"
                        />

                        <div className='selected-item-name'>
                            <p className="selected-item-type-qty">Name</p>
                            <p className="selected-item-type-title">{item.title}</p>
                        </div>

                        <div className='selected-item-type-block'>
                            <p className="selected-item-type-qty">Unit price</p>
                            <p className="selected-item-type-title">R {Number(item.price).toFixed(2)}</p>
                        </div>

                        <div className='selected-item-type-block'>
                            <p className="selected-item-type-qty">Vendor</p>
                            <p className="selected-item-type-title">
                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                ) : (
                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                )}
                            </p>
                        </div>

                        <div className='selected-item-type-block'>
                            <p className="selected-item-type-qty">QTY to order</p>
                            <input
                                className="input-bom-item-type"
                                type="number"
                                value={item.qty ? item.qty : itemType.qty}
                                disabled={true}
                            />
                        </div>

                        <div className='selected-item-type-block'>
                            <p className="selected-item-type-qty">Total</p>
                            <p className="selected-item-type-total">
                                R {((item.qty ? item.qty : itemType.qty) * parseFloat(Number(item.price).toFixed(2))).toFixed(2)}
                            </p>
                        </div>
                    </div>
                    {!isReadOnly && (
                        <div className='selected-item-actions-block'>
                            <div className="selected-item-actions">
                                <div
                                    className="btn-selected-item-button"
                                    onClick={() => {
                                        if (from === 'fragment' && fragmentId !== undefined && typeof itemTypeIndex === 'number' && typeof fragmentIndex === "number") {
                                            handleCombinedClick(fragmentId,itemTypeIndex, itemType.qty, itemType.id, fragmentIndex);
                                        }
                                    }}
                                >
                                    <IconBomSettingItem/>
                                </div>
                                <div
                                    title='Remove product'
                                    className="btn-selected-item-button"
                                    onClick={() => handleRemoveItemFragment(
                                        fragmentId,
                                        itemType.id,
                                        item.id
                                    )}
                                >
                                    <IconRemoveItem/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            ))
        );
    };
    return (
        <>
            <div className='template-name-content'>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            {to: '/', label: 'Cashbuild'},
                            {to: '', label: '>'},
                            {to: '/', label: 'Home'},
                            {to: '', label: '>'},
                            {to: '/assistance', label: 'Project assistance'},
                            {to: '', label: '>'},
                            {to: '', label: 'Create new project space'}
                        ]}
                    />
                ) : (
                    <div className='template-blog-placeholder'>
                        <p>Home/</p>
                        <p onClick={() => navigateToAssistance()} className='template-link-assistance'>Project group
                            assistance</p>
                        <p className='name-link'>/Create new project space</p>
                    </div>
                )}
            </div>
            <div className='step-info'>
                <div className='step-info-block'>
                    <h3>Step 1</h3>
                    <span>/</span>
                    <p className='step-info-block-name'>Specify parameters of building</p>
                </div>
            </div>
            <CreateProjectBom
                onDataReady={handleDataReady}
                getProject={getProject || undefined}
                getProjectHas={getProjectHas}
                isReadOnlyLink={isReadOnly}
            />

            {projectData.title !== '' &&
                !getProjectHas && !isReadOnly && (
                <div className='step1-bom-add-building-btn-block'>
                    <div
                        className="step1-bom-add-building"
                        onClick={() => handleOpenModal('itemType')}
                    >
                        <div><IconPlusStpe2/></div>
                        <p>Add Product Type</p>
                    </div>

                    <div
                        className="step1-bom-add-building"
                        onClick={() => handleOpenModal('fragments')}
                    >
                        <div><IconPlusStpe2/></div>
                        <p>Add Fragment</p>
                    </div>

                </div>
            )}

            <div>
                <>{isReadOnly && modalState.fragments.selectedItems.length > 0 && (
                    <p>Fragments:</p>
                )}
                    {!isReadOnly && modalState.fragments.selectedItems.length > 0 && hasItemTypesInFragments && (
                        <div className='bom-project-action-price-flush'>
                            <p>Fragments:</p>
                            <div className='bom-project-action-block'>
                                <div
                                    title='Adding items to fragments with a minimum price'
                                    onClick={() => handleMinimumPrice()}
                                    className={`minimum-price-project-bom ${isLoadingPrice ? 'is-loading' : ''}`}>
                                    <div><IconStep2Flush/></div>
                                    <p>Minimum price</p>
                                </div>
                                <div
                                    title='Reset project'
                                    onClick={() => handleFlush()}
                                    className={`flush-project-bom ${isLoadingFlush ? 'is-loading' : ''}`}>
                                    <div><IconStep2Flush/></div>
                                    <p>Flush</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {renderSelectedFragments()}
                    {(modalState.itemType.items.length > 0 || (getProject?.data?.item_types && getProject?.data?.item_types.length > 0)) && (
                        <p>Product types:</p>
                    )}
                    {!isReadOnly ? renderSelectedItemTypes() : renderGetProjectItemTypes()}
                </>


                <div className='step1-bom-total-secrion'>
                    <div className='step1-bom-total-block'>
                        <p className='step1-bom-total-text'>Total payable:</p>
                        <p className='step1-bom-total-price'>R {getProjectHas ? totalPayableGet() : totalPayable.toFixed(2)}</p>
                    </div>
                </div>
                <section className='create-bom-section-btn'>
                    <div className='create-bom-section-btn-block'>
                        {projectData.title !== '' && !isReadOnly && ((Object.values(selectedItemsMap).some(itemsArray => itemsArray.length > 0)) ||  modalState.fragments.selectedItems.length > 0) && (
                            <button
                                className='create-bom-bottom-btn'
                                onClick={handleSaveProject}
                            >
                                Save
                            </button>
                        )}
                        {projectId !== null &&
                            (
                                clientLink ? (
                                    <div className='copy-link-container'>
                                        <p className='link-text'>
                                            {`${window.location.origin}/create/step2Bom/${clientLink}`}
                                        </p>
                                        <button
                                            className='copy-link-btn'
                                            onClick={() => handleCopyLink(`${window.location.origin}/create/step2Bom/${clientLink}`)}
                                        >
                                            Copy link
                                        </button>
                                    </div>
                                ) : (

                                    (() => {
                                        const fragmentState = modalState.fragments.selectedItems.length;
                                        const projectItemType = (getProject && getProject.data.item_types) ? getProject?.data.item_types.length : 0;
                                        const ItemTypeState = modalState.itemType.selectedItems.length;


                                        if (
                                            (fragmentState > 0 && projectItemType === 0 && ItemTypeState === 0) ||
                                            (fragmentState > 0 && projectItemType >= ItemTypeState) ||
                                            (fragmentState === 0 && ItemTypeState === 0 && projectItemType > 0)
                                        ) {
                                            return (
                                                <button
                                                    className='create-bom-bottom-btn'
                                                    onClick={() => handleGetLink(projectId)}
                                                >
                                                    Get client link
                                                </button>
                                            );
                                        }

                                        return null;
                                    })()
                                )
                            )}

                    </div>
                </section>
            </div>

            <ItemTypeBomModal
                isOpen={modalState.itemType.isOpen}
                onClose={() => handleCloseModal('itemType')}
                itemTypes={modalState.itemType.items}
                loading={modalState.itemType.loading}
                onReload={() => handleReload('itemType')}
                currentPage={modalState.itemType.currentPage}
                totalItems={modalState.itemType.totalItems}
                itemsPerPage={5}
                onPageChange={(page) => handlePageChange('itemType', page)}
                onSearch={(query) => handleSearch('itemType', query)}
                onSortChange={(direction) => handleSortChange('itemType', direction)}
                sortDirection={modalState.itemType.sortDirection}
                totalSearchResults={modalState.itemType.totalItems}
                onSelectItems={handleSelectItemsItemType}
                initialSelectedItems={modalState.itemType.selectedItems}
                onItemTypeCountChange={handleItemTypeCountChange}
                itemTypeCount={itemTypeCount}
            />

            <FragmentBomModal
                handleSaveProject={handleSaveProject}
                isOpen={modalState.fragments.isOpen}
                onClose={() => handleCloseModal('fragments')}
                fragments={modalState.fragments.items}
                loading={modalState.fragments.loading}
                onReload={() => handleReload('fragments')}
                currentPage={modalState.fragments.currentPage}
                totalItems={modalState.fragments.totalItems}
                itemsPerPage={5}
                onPageChange={(page) => handlePageChange('fragments', page)}
                onSearch={(query) => handleSearch('fragments', query)}
                onSortChange={(direction) => handleSortChange('fragments', direction)}
                sortDirection={modalState.fragments.sortDirection}
                totalSearchResults={modalState.fragments.totalItems}
                onSelectItems={handleSelectFragments}
                initialSelectedItems={modalState.fragments.selectedItems}
                onFragmentCountChange={handleFragmentCountChange}
                fragmentCount={itemFragmentCount}
            />

            <ItemsBomModal
                isOpen={modalState.itemBom.isOpen}
                onClose={() => handleCloseModal('itemBom')}
                items={modalState.itemBom.items}
                loading={modalState.itemBom.loading}
                onReload={() => handleReload('itemBom')}
                currentPage={modalState.itemBom.currentPage}
                totalItems={modalState.itemBom.totalItems}
                itemsPerPage={5}
                onPageChange={(page) => handlePageChange('itemBom', page)}
                onSearch={(query) => handleSearch('itemBom', query)}
                onSortChange={(direction) => handleSortChange('itemBom', direction)}
                sortDirection={modalState.itemBom.sortDirection}
                totalSearchResults={modalState.itemBom.totalItems}
                onSelectItems={handleSelectItemsItemBom}
                initialSelectedItems={currentItemTypeIndex !== null ? selectedItemsMap[currentItemTypeIndex] : []}
                itemTypeQty={itemTypeQty}
            />

            <FragmentItemsBomModal
                isOpen={modalState.FragmentItemBom.isOpen}
                onClose={() => handleCloseModal('FragmentItemBom')}
                items={modalState.FragmentItemBom.items}
                loading={modalState.FragmentItemBom.loading}
                onReload={() => handleReload('FragmentItemBom')}
                currentPage={modalState.FragmentItemBom.currentPage}
                totalItems={modalState.FragmentItemBom.totalItems}
                itemsPerPage={5}
                onPageChange={(page) => handlePageChange('FragmentItemBom', page)}
                onSearch={(query) => handleSearch('FragmentItemBom', query)}
                onSortChange={(direction) => handleSortChange('FragmentItemBom', direction)}
                sortDirection={modalState.FragmentItemBom.sortDirection}
                totalSearchResults={modalState.FragmentItemBom.totalItems}
                onSelectItems={(selected) => handleSelectFragmentItemsBom(fragmentId!, itemTypeFragmentId!, selected)}
                initialSelectedItems={
                    currentFragmentItemTypeIndex !== null && fragmentId !== null && itemTypeFragmentId !== null
                        ? (
                        modalState.fragments.selectedItems
                            ?.find((fragment) => fragment.id === fragmentId)
                            ?.data?.item_types as unknown as ItemType[] || []
                    )
                        .find((itemType) => itemType.id === itemTypeFragmentId)
                        ?.items || []
                        : []
                }

                itemTypeQtyByFragment={itemTypeQtyByFragment}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
});


const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step1BOMCreate);