import {Gear} from "../../../../IconComponents/IconComponents";
import React, {FC, useEffect, useRef, useState} from "react";
import './SelectFragmentStyleButton.css';
import {Fragment} from "../../../../types/TemplateType";
import {Gallery, Project} from "../../../../types/ProjectTypes";
import {getFragmentStyleById, patchChooseFragmentStyle} from "../../../../api/APIWraper";
import {Loader} from "../../../../components/Loader/Loader";
import SelectFragmentModal from "../SelectFragmentModal/SelectFragmentModal";
import {TQuestion} from "../../../../types/QuestionTypes/TQuestion";
import {useParams} from "react-router-dom";

interface SelectFragmentStyleButtonProps {
	id: number;
	index: number;
	questions: TQuestion[];
	onUpdateProject: (newProject: Project) => void;
}

const SelectFragmentStyleButton: FC<SelectFragmentStyleButtonProps> = ({
	id,
	index,
	questions,
    onUpdateProject,
}) => {
	const [fragmentsList, setFragmentsList] = useState<boolean>(false);
	const [fragments, setFragments] = useState<Fragment[]>([]);
	const [hoverFragment, setHoverFragment] = useState<{id:number | null, title:string | null}>({id:null, title: null});
	const fragmentsListContainer = useRef<HTMLDivElement | null>(null);
	const [fragmentsModal, setFragmentsModal] = useState<boolean>(false);
	const [defaultQueries, setDefaultQueries] = useState<string>('');
	const {projectId, indexTemplate} = useParams<{ projectId: string; indexTemplate: string }>();
	const [templateIndex] = useState<number>(Number(indexTemplate));

	const clearStates = () => {
		setFragments([]);
		setHoverFragment({id:null, title: null});
	}

	const handleFragmentsList = async () => {
		setFragmentsList(!fragmentsList);
		if (!fragmentsList) {
			try {
				const response = await getFragmentStyleById(id, defaultQueries);
				if(response.status === 200) {
					setFragments(response.data.fragments);
					if (response.data.fragments[0] || null) {
						setHoverFragment(response.data.fragments[0])
					}
				} else{
					console.error('Error when getting minimum price');
				}
			} catch (error) {
				console.error('Error when getting minimum price:', error);
			}
		}
	}

	const handleViewAll = () => {
		handlerFragmentsModal();
		setFragmentsList(false);
	}

	const handlerFragmentsModal = () => {
		setFragmentsModal(!fragmentsModal);
	}

	const handleClickOutside = (event: MouseEvent) => {
		if (
			fragmentsList &&
			fragmentsListContainer.current &&
			!fragmentsListContainer.current.contains(event.target as Node)
		) {
			handleFragmentsList();
		}
	};

	const handleSelectFragment = async (fragmentId: number) => {
		if (!projectId || templateIndex === undefined) return;
		try {
			const response = await patchChooseFragmentStyle(projectId, {
				template_index: templateIndex,
				fragment_style_index: index,
				fragment_style_id: id,
				fragment_id: fragmentId,
			});
			if(response.status === 200 && response.data) {
				onUpdateProject(response.data);
			} else {
				console.error('Error when getting minimum price');
			}
		} catch (error) {
			console.error('Error when getting minimum price:', error);
		}
	};

	useEffect(() => {
		if (!fragmentsList) {
			clearStates();
		}
	}, [fragmentsList])

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [fragmentsList]);

	useEffect(() => {
		let queries: string = '';

		questions
			.filter((question) => question?.required_question || false)
			.forEach((question) => {
				queries += `&queries[${question.key}]=${question?.value || 0}`;
			});
		setDefaultQueries(queries);
	}, [questions]);

	const getImage = (gallery: Gallery | null): string => {
		if (gallery && gallery.medias.length > 0) {
			return gallery.medias[gallery.medias.length - 1].url;
		}
		return '/img_vendor/item.png';
	}

	return (<>
		{!fragmentsList
			? <div className='select-fragment-style-btn' onClick={handleFragmentsList}>
				<Gear/> Select fragment style
			</div>
			: <div className='select-fragment-style-fragments-lost-container' ref={fragmentsListContainer}>
				<div className='select-fragment-style-fragments-lost-input'>
					{hoverFragment.title}
				</div>
				<div className='select-fragment-style-fragments-lost-block'>
					<div className='select-fragment-style-fragments-lost-content-block'>
						{fragments.length > 0 ? <> {
							fragments.map((fragment) => (
								<div
									className={`select-fragment-style-fragments-lost-content ${hoverFragment.id === fragment.id && 'select-fragment-style-fragments-lost-content-active'}`}
									key={fragment.id}
									onMouseEnter={() => setHoverFragment(fragment)}
									onClick={() => handleSelectFragment(fragment.id)}
								>
									<img className='select-fragment-style-fragments-lost-content-img'
										 src={getImage(fragment.galleries[0] || null)}
										 alt={'fragment-img'}
									/>
									<p className='select-fragment-style-fragments-lost-content-text'>{fragment.title}</p>
								</div>
							))
						} </>
						: <Loader />
						}
					</div>
					<div className='select-fragment-style-fragments-lost-button' onClick={handleViewAll}>
						View all
					</div>
				</div>
			</div>
		}
		<SelectFragmentModal
			isOpen={fragmentsModal}
			onClose={handlerFragmentsModal}
			id={id}
			defaultQueries={defaultQueries}
			questions={questions}
			chooseFragmentStyle={handleSelectFragment}
		/>
	</>
	)
}
export default SelectFragmentStyleButton;