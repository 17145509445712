import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { connect, ConnectedProps } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import GetPermission from "../../RoleComponent/Role";
import { User, Project } from "../../../types/ProjectTypes";
import { PermissionEdit } from "../../RoleComponent/EnumRole";
import './userInformationModal.css';
import ModalUserInformationRole from "./ModalUserInformationRole";
import { deleteUserPermissions } from "../../../Slice/updateProject/updateProjectSlice";
import { IconSettingsUserInformation} from "../../../IconComponents/IconComponents";
import {getAllProjectsAssistance} from "../../../api/APIWraper";
import {toast} from "react-toastify";
import {Loader} from "../../Loader/Loader";

interface ModalUserInformationProps {
    isOpen: boolean;
    onClose: () => void;
    user: User | null;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type CombineProps = ModalUserInformationProps & PropsFromRedux;

const ModalUserInformation: React.FC<CombineProps> = ({  isOpen, onClose, user, deleteUserPermissions }) => {
    const [projectsToDelete, setProjectsToDelete] = useState<number[]>([]);
    const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [allProjects, setAllProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (isOpen) {
            if (allProjects.length === 0) {
                fetchProjects()
            }
        }
    }, [isOpen]);

    const fetchProjects = async () => {
        setLoading(true)
        try {
            const {
                allProjects,
                status
            } = await getAllProjectsAssistance(1, 1000, undefined, undefined, '&with[]=title');
            if (status === 200) {
                setAllProjects(allProjects)
                setLoading(false)
            } else {
                toast.error('Failed to get projects')
                setAllProjects([])
                setLoading(false)
            }
        } catch (error) {
            console.error(error);
            setLoading(false)
            setAllProjects([])
        }
    }

    const handleDeleteClick = (projectId: number) => {
        setProjectsToDelete(prevState =>
            prevState.includes(projectId) ? prevState.filter(id => id !== projectId) : [...prevState, projectId]
        );
    };

    const handleSave = () => {
        if (user) {
            deleteUserPermissions({ userId: user.id, projects_id: projectsToDelete }).then(() => {
                setAllProjects(prevProjects => prevProjects.filter(project => !projectsToDelete.includes(project.id)));
                setProjectsToDelete([]);
                onClose()
            });
        }
    };

    const handleRoleEdit = (project: Project) => {
        setSelectedProject(project);
        setIsRoleModalOpen(true);
    };

    const handleRoleModalClose = () => {
        setIsRoleModalOpen(false);
        setSelectedProject(null);
    };

    const handlePermissionsUpdated = (updatedProject: Project) => {
        setAllProjects((prevProjects) => {
            return prevProjects.map((project) => (project.id === updatedProject.id ? updatedProject : project));
        });
    };

    if (!user) {
        return null;
    }
    if (loading){
        return <Loader/>
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className='user-information-modal' overlayClassName='overlay'>
            <div className='user-information-modal-header'>
                <button onClick={onClose} className="user-information-close-button">&times;</button>
                <div className="user-information-header-content">
                    <p>Name</p>
                    <p>Date</p>
                    <p>Access right</p>
                    <p>Action</p>
                </div>
            </div>
            <div className="user-information-modal-content">
                {allProjects.map(project => {
                    const userPermissions = project.permissions.find(permission => permission.user_id === user.id);
                    const parsedPermissions = userPermissions ? userPermissions.level.filter((permission: any) => permission !== null) as PermissionEdit[] : [];

                    return (
                        <div key={project.id} className={`user-information-project-item ${projectsToDelete.includes(project.id) ? 'to-delete' : ''}`}>
                            <div className="user-information-project-details">
                                <p className="user-information-project-title">{project.title}</p>
                                <p className="user-information-project-date">{new Date(project.created_at).toLocaleDateString()}</p>
                                <p className='user-information-role'>{userPermissions ? GetPermission(parsedPermissions) : 'No permissions'}</p>
                                <div className='user-information-action-buttons'>
                                    <button className='team-action-btn-info'
                                            onClick={() => handleRoleEdit(project)}>
                                        <div title='Permission settings' className='setting-team-info'><IconSettingsUserInformation/></div>
                                    </button>
                                    <button className='team-action-btn-info'
                                            onClick={() => handleDeleteClick(project.id)}>
                                        <div title='Remove a user from the project' className='delete-team-info'><img src={'/img/del.png'}/></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='user-information-save-btn'>
                <button onClick={handleSave} className="user-information-save-button">Save</button>
            </div>
            {selectedProject && (
                <ModalUserInformationRole
                    isOpen={isRoleModalOpen}
                    onClose={handleRoleModalClose}
                    user={user}
                    project={selectedProject}
                    onPermissionsUpdated={handlePermissionsUpdated}
                />
            )}
        </Modal>
    );
};



const mapDispatchToProps = {
    deleteUserPermissions,
};

const connector = connect(null, mapDispatchToProps);

export default connector(ModalUserInformation);
