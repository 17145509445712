import React, {useState} from 'react';
import Modal from 'react-modal';
import './PricingInfoModal.css';
import {
    IconAboutUsCheckLanding,
    IconAboutUsUnCheckLanding, IconPricing3DLanding, IconPricingAddLanding,
    IconPricingBomCostsLanding,
    IconPricingBoMLanding, IconPricingContractorsLanding,
    IconPricingMeasurementLanding, IconPricingPDFLanding,
    IconPricingProductLanding,
    IconPricingProjectLanding, IconPricingShareLanding,
    IconPricingTemplateLanding
} from "../../../IconComponents/IconComponents";
import {useAutoHideScrollbar} from "./ScrollHook";

interface ModalDeleteProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onCancel: () => void;
}

const tabs = ['Foundation', 'Structural', 'Architect'] as const;
const [defaultTab] = tabs;

const basicFeatures = [
    { title: "Project creation", icon: <IconPricingProjectLanding width={'30px'} height={'30px'} /> },
    { title: "Template selection", icon: <IconPricingTemplateLanding width={'30px'} height={'30px'}/> },
    { title: "Measurement insertion", icon: <IconPricingMeasurementLanding width={'30px'} height={'30px'}/> },
    { title: "BoM creation", icon: <IconPricingBoMLanding width={'30px'} height={'30px'}/> },
    { title: "Product selection", icon: <IconPricingProductLanding width={'30px'} height={'30px'}/> },
    { title: "BoM cost estimate", icon: <IconPricingBomCostsLanding width={'30px'} height={'30px'}/> },
    { title: "Checkout at builders merchant of choice", icon: <IconPricingBomCostsLanding width={'30px'} height={'30px'}/> },
];

const contractorsWorkspace = [
    { title: "Custom Bill of Materials Creation", icon: <IconPricingProjectLanding width={'30px'} height={'30px'}/> },
    { title: "Add own list of products required", icon: <IconPricingTemplateLanding width={'30px'} height={'30px'}/> },
    { title: "Add Preliminary and Generals", icon: <IconPricingAddLanding width={'30px'} height={'30px'}/> },
    { title: "Add Profit and Attendance", icon: <IconPricingAddLanding width={'30px'} height={'30px'}/> },
    { title: "All inclusive BoM Cost Estimate", icon: <IconPricingBomCostsLanding width={'30px'} height={'30px'}/> },
    { title: "Share BoM as PDF", icon: <IconPricingPDFLanding /> },
    { title: "Share link for payment with Client", icon: <IconPricingShareLanding width={'30px'} height={'30px'}/> },
    { title: "Cost to complete management", icon: <IconPricingBomCostsLanding width={'30px'} height={'30px'}/> },
];
const digitalPlansIntegration = [
    { title: "Revit 3D Digital Plans Upload", icon: <IconPricing3DLanding width={'30px'} height={'30px'}/> },
    { title: "Cost to complete management", icon: <IconPricingBomCostsLanding width={'30px'} height={'30px'}/> },
    { title: "AutoCAD 3D Digital Plans Upload", icon: <IconPricing3DLanding width={'30px'} height={'30px'}/> },
    { title: "Extracting of all plan details", icon: <IconPricingContractorsLanding width={'30px'} height={'30px'}/> },
    { title: "Extracting of all measurements", icon: <IconPricingContractorsLanding width={'30px'} height={'30px'}/> },
    { title: "Creation of BoM from plans uploaded", icon: <IconPricingAddLanding width={'30px'} height={'30px'}/> },
];




const statusByTabStructural: Record<typeof tabs[number], boolean[]> = {
    Foundation: [false, false, false, false, false, true,false,true],
    Structural: [true, true, true, true, true, true, true,true],
    Architect: [true, true, true, true, true, true, true,true],
};

const statusByTabArchitect: Record<typeof tabs[number], boolean[]> = {
    Foundation: [false, false, false, false, false, false],
    Structural: [false, false, false, false, false, false],
    Architect: [true, true, true, true, true, true],
};

const PricingInfoModal: React.FC<ModalDeleteProps> = ({ isOpen, onRequestClose, onCancel }) => {
    const [activeTab, setActiveTab] = useState<typeof tabs[number]>(defaultTab);
    const [contentEl, setContentEl] = useState<HTMLElement | null>(null);
    useAutoHideScrollbar(contentEl, isOpen);
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="pricing-info-modal"
            overlayClassName="overlay-pricing-info-modal"
        >
            <button className="pricing-info-modal-close-button" onClick={onCancel}>&times;</button>

            <div className='pricing-info-modal-content-title'>
                {tabs.map(tab => (
                    <p
                        key={tab}
                        className={`pricing-info-modal-content-text ${activeTab === tab ? 'active-btn' : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </p>
                ))}
            </div>
            <div className="pricing-info-modal-content" ref={setContentEl}>
            <p className='pricing-info-modal-basic-block-text'>Basic Features</p>
                {basicFeatures.map((feature, index) => {
                    const statusIcon =  <IconAboutUsCheckLanding />
                    const isLast = index === basicFeatures.length - 1;
                    const blockClassName = `pricing-info-modal-content-block ${isLast ? 'last-item' : ''}`;
                    return (
                        <div key={index} className={blockClassName}>
                            <div className='pricing-info-modal-content-name-check'>
                                <p className='pricing-info-modal-content-name-text'>{feature.title}</p>
                                {statusIcon}
                            </div>
                            <div className='pricing-info-modal-content-icon'>
                                {feature.icon}
                            </div>
                        </div>
                    );
                })}

            <p className='pricing-info-modal-basic-block-text'>Contractors Workspace</p>
                {contractorsWorkspace.map((contractor, index) => {
                    const isChecked = statusByTabStructural[activeTab][index];
                    const statusIcon = isChecked ? <IconAboutUsCheckLanding /> : <IconAboutUsUnCheckLanding />;
                    const isLast = index === contractorsWorkspace.length - 1;
                    const blockClassName = `pricing-info-modal-content-block ${isLast ? 'last-item' : ''}`;
                    return (
                        <div key={index} className={blockClassName}>
                            <div className='pricing-info-modal-content-name-check'>
                                <p className='pricing-info-modal-content-name-text'>{contractor.title}</p>
                                {statusIcon}

                            </div>
                            <div className='pricing-info-modal-content-icon'>
                                {contractor.icon}
                            </div>
                        </div>
                    );
                })}

            <p className='pricing-info-modal-basic-block-text'>3D Digital Plans Integration</p>
                {digitalPlansIntegration.map((itemIntegration, index) => {
                    const isChecked = statusByTabArchitect[activeTab][index];
                    const statusIcon = isChecked ? <IconAboutUsCheckLanding /> : <IconAboutUsUnCheckLanding />;
                    const isLast = index === digitalPlansIntegration.length - 1;
                    const blockClassName = `pricing-info-modal-content-block ${isLast ? 'last-item' : ''}`;
                    return (
                        <div key={index} className={blockClassName}>
                            <div className='pricing-info-modal-content-name-check'>
                                <p className='pricing-info-modal-content-name-text'>{itemIntegration.title}</p>
                                {statusIcon}

                            </div>
                            <div className='pricing-info-modal-content-icon'>
                                {itemIntegration.icon}
                            </div>
                        </div>
                    );
                })}
            </div>

        </Modal>
    );
};

export default PricingInfoModal;
