import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AuthStyle.css';
import { PuffLoader } from "react-spinners";
import { post } from "../../api/APIWraper";
import {useAppDispatch} from "../../store";
import {setUser} from "../../Slice/tokenSlice/userSlice";
import {useSmartNavigate} from "../../Hooks/useNavigationHook";

interface AuthFields {
    password: string;
    confirmPassword: string;
}

const NewPasswordPage: React.FC = () => {
    const smartNavigate = useSmartNavigate();
    const [userEmail, setUserEmail] = useState("");
    const [userToken, setUserToken] = useState("");

    const [authFields, setAuthFields] = useState<AuthFields>({
        password: "",
        confirmPassword: "",
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token') || '';
        const email = queryParams.get('email') || '';
        setUserToken(token);
        setUserEmail(email);
    }, []);

    const [isBadConfirmPassword, setIsBadConfirmPassword] = useState(false);
    const [isWaitingForSignIn, setIsWaitingForSignIn] = useState(false);

    const dispatch = useAppDispatch();


    const validPasswordRegex = /^(?!\s*$).+/;
    const cyrillicRegex = /[а-яА-ЯЁё]/;

    const [isPasswordTooShort, setIsPasswordTooShort] = useState(false);
    const [isPasswordCyrillic, setIsPasswordCyrillic] = useState(false);

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setAuthFields({
            ...authFields,
            password: newPassword
        });

        if (newPassword.length < 6) {
            setIsPasswordTooShort(true);
        } else {
            setIsPasswordTooShort(false);
        }

        if (cyrillicRegex.test(newPassword)) {
            setIsPasswordCyrillic(true);
        } else {
            setIsPasswordCyrillic(false);
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthFields({
            ...authFields,
            confirmPassword: e.target.value
        });
    };

    const handleConfirmPasswordBlur = () => {
        if (authFields.password !== authFields.confirmPassword) {
            setIsBadConfirmPassword(true);
        } else {
            setIsBadConfirmPassword(false);
        }
    };

    const validateForm = () => {
        const { password, confirmPassword } = authFields;

        if (!validPasswordRegex.test(password)) {
            toast.error("The password cannot be empty");
            return false;
        }

        if (password.length < 6) {
            toast.error("Password must contain a minimum of 6 characters");
            return false;
        }

        if (cyrillicRegex.test(password)) {
            toast.error("The password must not contain Cyrillic characters");
            return false;
        }

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsWaitingForSignIn(true);
        try {
            const { password } = authFields;
            const response = await post(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/reset_password`, { email: userEmail, password: password, token: userToken });

            if (response.status === 200) {
                dispatch(setUser(null))
                toast.success("Password successfully restored");
                setTimeout(() =>smartNavigate("/auth/login"), 2000);

            } else {
                toast.error("You have already recovered your password, if you need another try. Return to the page with \"FORGOT PASSWORD\"");
            }
        } catch (error) {
            toast.error("An error occurred during registration");
        } finally {
            setIsWaitingForSignIn(false);
        }
    };

    return (
        <div className="layout-login">
            <div className="reset-card">
                <div className="login-card-top">
                    <div className="login-card-svg-container">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24"
                             width="24"
                             height="24"
                             fill="white">
                            <path
                                d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/>
                        </svg>
                    </div>
                </div>

                <form className="reset-card-form" onSubmit={handleSubmit}>
                    <div>
                        <div className="login-card-form-input-container">
                            <input
                                className="login-card-form-input"
                                type="password"
                                value={authFields.password}
                                onChange={handlePasswordChange}
                                placeholder="Password *"
                            />
                        </div>
                        {isPasswordTooShort && (
                            <p className="login-card-form-input-message">Password must contain a minimum of 6 characters</p>
                        )}
                        {isPasswordCyrillic && (
                            <p className="login-card-form-input-message">The password must not contain Cyrillic characters</p>
                        )}
                    </div>

                    <div>
                        <div className="login-card-form-input-container">
                            <input
                                className={`login-card-form-input ${isBadConfirmPassword ? 'red-font-error' : ''}`}
                                type="password"
                                value={authFields.confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                onBlur={handleConfirmPasswordBlur}
                                placeholder="Confirm Password *"
                            />
                        </div>
                        {isBadConfirmPassword && (
                            <p className="login-card-form-input-message">Passwords do not match</p>
                        )}
                    </div>

                    <button
                        className={`login-card-form-button ${isWaitingForSignIn ? 'gray-background' : ''}`}
                        type="submit"
                    >
                        {!isWaitingForSignIn && "RESET PASSWORD"}
                        {isWaitingForSignIn &&
                            <div className="login-loading-spin">
                                <PuffLoader size={25} />
                            </div>
                        }
                    </button>
                </form>
            </div>

            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                    backgroundColor: "#333",
                    color: "#fff",
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
            />
        </div>
    );
};

export default NewPasswordPage;
