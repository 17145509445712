import React, { useState } from "react";
import {
    IconBomSettingItemType,
    IconPlusStpe2,
    IconRemoveItemType,
    IconSearch
} from "../../../../IconComponents/IconComponents";
import "./ItemTypesSelected.css";
import { ItemType } from "../../../../types/TemplateType";
import { toast } from "react-toastify";
import {skipData} from "../../ArсhitectPage";

interface IItemTypesSelectedProps {
    handleRemoveItemType: (itemType: ItemType) => void;
    currentIndex:number
    fragmentData: skipData | null
    selectedItemTypes: ItemType[];
    setIsOpen: (value: boolean) => void;
    setSelectedItemTypes: (selectedItemTypes: (prevSelected: ItemType[]) => any) => void;
    handleNextObjectCustom: (newFragment: {
        currentIndex: number;
        id: number;
        name: string;
        custom: boolean;
        itemTypes: ItemType[];
        autodesk_object_id: number | null,
        inProjectFragmentId:number
    }) => void;
}

const ItemTypesSelected: React.FC<IItemTypesSelectedProps> = ({
                                                                  fragmentData,
                                                                  selectedItemTypes,
                                                                  handleRemoveItemType,
                                                                  setIsOpen,
                                                                  handleNextObjectCustom,
                                                                  setSelectedItemTypes,
                                                                  currentIndex
                                                              }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleRemove = (itemType: ItemType) => {
        toast.info(`You removed the Item type: ${itemType.title ? itemType.title : ""}`);
        handleRemoveItemType(itemType);
    };

    const filteredItemTypes = selectedItemTypes.filter((itemType) =>
        itemType.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedItemTypes = searchTerm
        ? [...filteredItemTypes, ...selectedItemTypes.filter((itemType) => !filteredItemTypes.includes(itemType))]
        : selectedItemTypes;

    const highlightText = (text: string, highlight: string) => {
        if (!highlight) return text;

        const regex = new RegExp(`(${highlight})`, "gi");
        const parts = text.split(regex);

        return parts.map((part, index) =>
            regex.test(part) ? (
                <span key={index} className="highlight">{part}</span>
            ) : (
                part
            )
        );
    };

    const addCustomFragment = () =>{
        const fragment = {
            currentIndex: currentIndex,
            id: fragmentData?.id ? fragmentData.id : 0,
            name: fragmentData?.name ? fragmentData.name : "",
            custom: true,
            itemTypes: selectedItemTypes,
            autodesk_object_id:fragmentData?.autodesk_object_id ? fragmentData.autodesk_object_id : 0,
            inProjectFragmentId:0
        };
        handleNextObjectCustom(fragment)
    }

    const handleQtyChange = (index: number, value: string) => {
        const newQty = Math.max(0, Number(value));

        setSelectedItemTypes(prevSelected => {
            return prevSelected.map((item:ItemType, i:number) =>
                i === index ? { ...item, qty: newQty } : item
            );
        });
    };


    return (
        <section className="revit-item-types-container">
            <div className="revit-item-types-container-header">
                <div className="revit-item-types-header-block">
                    <div className="revit-item-types-container-header-block-search">
                        <div className="revit-item-types-container-header-block-search-icon">
                            <IconSearch />
                        </div>
                        <input
                            className="revit-item-types-container-header-block-search-input"
                            placeholder="Search"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearchInput}
                        />
                    </div>
                </div>
                <div
                    className="revit-add-item-type"
                    onClick={() => setIsOpen(true)}
                >
                    <div><IconPlusStpe2/></div>
                    <p>Add Product Type</p>
                </div>
            </div>
            <div className="revit-item-types-section-block">
                <div className="revit-item-types-inner">
                    {sortedItemTypes.map((itemType, index) => (
                        <div key={index} className="revit-item-type-section">
                            <div className="revit-selected-item-type">
                                <div className="revit-selected-item-type-info">
                                    <img
                                        src={
                                            itemType.galleries &&
                                            itemType.galleries.length > 0 &&
                                            itemType.galleries[0].medias.length > 0
                                                ? itemType.galleries[0].medias[itemType.galleries[0].medias.length - 1].url
                                                : "/img_vendor/item.png"
                                        }
                                        alt={itemType.title}
                                        className="revit-selected-item-type-image"
                                    />
                                    <div>
                                        <p className="selected-item-type-qty">Name</p>
                                        <p className="selected-item-type-title">
                                            {highlightText(itemType.title, searchTerm)}
                                        </p>
                                    </div>
                                </div>
                                <div className="revit-qty-item-type-container">
                                    <p className="selected-item-type-qty">QTY to order</p>
                                    <input
                                        type="number"
                                        className="revit-item-type-input"
                                        value={itemType.qty ?? ""}
                                        onChange={(e) => handleQtyChange(index, e.target.value)}
                                        min="0"
                                    />

                                    <span
                                        className='revit-packing-value-steps'>psc</span>
                                </div>
                                <div className="selected-item-type-actions">
                                    <div className="btn-selected-item-button">
                                        <IconBomSettingItemType />
                                    </div>
                                    <div
                                        title="Remove product type"
                                        onClick={() => handleRemove(itemType)}
                                        className="btn-selected-item-button"
                                    >
                                        <IconRemoveItemType />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
                <div className='revit-container-next-object'>
                    <p
                        onClick={() => addCustomFragment()}
                        className='revit-container-next-object-text'>Next object -{'>'}</p>
                </div>
        </section>
    );
};

export default ItemTypesSelected;