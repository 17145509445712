import React, {useEffect, useState} from 'react';
import {BOMItemType} from "../../../types/ProjectBOMInterface";
import {IconSettingsStep2Products} from "../../../IconComponents/IconComponents";
import {toast} from "react-toastify";

interface ItemTypesComponentProps {
    handleSave:() => void;
    itemTypes: BOMItemType[];
    replace: boolean;
    onToggle: (ItemType: string) => void;
    selectedItems: { [key: string]: any };
    selectedItemsStep3: { [key: string]: BOMItemType };
    handleToggleSelect: (uniqueKey: string, itemType: BOMItemType, index: number) => void;
    openModal: (uniqueKey: string, allowedItems: any[]) => void;
}

const ItemTypesComponent: React.FC<ItemTypesComponentProps> = ({
                                                                   handleSave,
                                                                   itemTypes,
                                                                   replace,
                                                                   selectedItems,
                                                                   selectedItemsStep3,
                                                                   handleToggleSelect,
                                                                   openModal,
                                                                   onToggle
                                                               }) => {
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const calculateTotal = () => {
            return Object.values(selectedItemsStep3).reduce((total, itemType) => {
                const price = parseFloat(String(itemType.item?.price || 0));
                const quantity = itemType.qty || 0;
                return total + quantity * price;
            }, 0);
        };

        setTotalPrice(calculateTotal());
    }, [selectedItemsStep3]);

    const handleAddProductsToCart = () => {
        itemTypes.forEach((itemType, index) => {
            const uniqueKey = `${itemType.id}-${index}`;
            handleToggleSelect(uniqueKey, itemType, index);
        });
        if(Object.values(selectedItemsStep3).length > 0){
            toast.info(`Products removed from cart.`);
        } else{
            toast.success(`Products added to cart.`);
        }
    };

    const renderHeaderList = () => (
        <div className='step2-bom-header-info'>
            <p className='step2-bom-item-title-checkbox-info'>Product</p>
            <p className='step2-bom-qty-ordered-info'>QTY ordered</p>
            <p className='step2-bom-calculated-qty-info'>Calculated QTY</p>
            <p className='step2-bom-price-info'>Unit price</p>
            <p className='step2-bom-total-info'>Total</p>
            <p className='step2-bom-total-orderd-info'>Total ordered</p>
        </div>
    );

    return (
        <>
            <div className='step2-bom-bom-project-title'>
                <p className='step2-bom-title'>Bill of materials products list</p>
                <div onClick={() => onToggle('ItemType')} className='material-setting-block'>
                    <div><IconSettingsStep2Products/></div>
                    <p>Select Products</p>
                </div>
            </div>
            {renderHeaderList()}
            {itemTypes.map((itemType, index) => {
                const uniqueKey = `${itemType.id}-${index}`;
                const isFixed = itemType.allowed_items.length === 1;
                const displayItem = isFixed
                    ? itemType.item
                    : (selectedItems[uniqueKey] || itemType.item);
                const qty_ordered = selectedItems[uniqueKey] ? 0 : (itemType.item.ordered_qty || 0);

                return (
                    <div key={uniqueKey} className='step2-bom-item-container'>


                        {!replace ? (
                            <div className='step2-bom-items'>
                                <div className='step2-bom-item-title-checkbox'>
                                    <input
                                        type='checkbox'
                                        className='step2-bom-input-checkbox'
                                        checked={!!selectedItemsStep3[uniqueKey]}
                                        onChange={() => handleToggleSelect(uniqueKey, itemType, index)}
                                    />
                                    <p className='step2-bom-item-title'>{displayItem.title}</p>
                                </div>
                                <p className='step2-bom-calculated-qty'>{qty_ordered}</p>
                                <p className='step2-bom-qty-ordered'>{itemType.qty}</p>
                                <p className='step2-bom-price'>R {parseFloat(displayItem.price).toFixed(2)}</p>
                                <p className='step2-bom-total'>R {(parseFloat(displayItem.price) * itemType.qty).toFixed(2)}</p>
                                <p className='step2-bom-total-orderd'>R {(parseFloat(displayItem.price) * itemType.qty).toFixed(2)}</p>
                            </div>
                        ) : (
                            <div className='step2-bom-items-container'>
                                <div className='step2-bom-items'>
                                    <div
                                        className='step2-bom-item-title-replace'
                                        onClick={() => {
                                            if (!isFixed && itemType.allowed_items.length > 1) {
                                                openModal(uniqueKey, itemType.allowed_items);
                                            }
                                        }}
                                    >
                                        <p
                                            title={itemType.allowed_items.length === 1 ? 'No replacement products available' : ''}
                                            className='step2-bom-item-title'>{displayItem.title}</p>
                                        {!isFixed && itemType.allowed_items.length > 1 && (
                                            <span className={`arrow-right`}></span>
                                        )}
                                    </div>
                                    <p className='step2-bom-calculated-qty'>{qty_ordered}</p>
                                    <p className='step2-bom-qty-ordered'>{itemType.qty}</p>
                                    <p className='step2-bom-price'>R {parseFloat(displayItem.price).toFixed(2)}</p>
                                    <p className='step2-bom-total'>R {(parseFloat(displayItem.price) * itemType.qty).toFixed(2)}</p>
                                    <p className='step2-bom-total-orderd'>R {(parseFloat(displayItem.price) * itemType.qty).toFixed(2)}</p>
                                </div>
                            </div>
                        )}
                    </div>

                );
            })}
            {replace && (
                <div className='save-button-step2bom-block'>
                    <button onClick={handleSave} className='save-button-step2bom'>Save</button>
                </div>
            )}
            <div className='step3-bom-bottom-total-block-price'>
                <p>Total:</p>
                {isSmallScreen && (<span
                    className="dashed-line"></span>)}
                <span>R {totalPrice.toFixed(2)}</span>
                <button
                    onClick={handleAddProductsToCart}
                    className='step2-bottom-block-btn-cart-list'>
                    Add products to cart
                </button>
            </div>
        </>
    );
};

export default ItemTypesComponent;
