import React from 'react';
import {IconClientsLanding, IconProjectLanding, IconTabletLanding} from "../../IconComponents/IconComponents";

interface countInfoSectionProps {
    iconVisible:boolean
}

const CountInfoSection:React.FC<countInfoSectionProps> = ({iconVisible}) => {
    return (
        <section className='landing-middle-info'>
            {iconVisible && (
                <div className='middle-block-text-info'>
                    <div className='middle-block-info-tablet'>
                        <div>
                            <IconTabletLanding/>
                        </div>
                        <div>
                            <p className='middle-block-text-info-number'>5895</p>
                            <p className='middle-block-text-info-text'>Project done</p>
                        </div>
                    </div>
                    <div className='middle-block-info-clients'>
                        <div>
                            <IconClientsLanding/>
                        </div>
                        <div>
                            <p className='middle-block-text-info-number'>220</p>
                            <p className='middle-block-text-info-text'>Clients</p>
                        </div>
                    </div>
                    <div className='middle-block-info-project'>
                        <div>
                            <IconProjectLanding/>
                        </div>
                        <div>
                            <p className='middle-block-text-info-number'>250</p>
                            <p className='middle-block-text-info-text'>Running projects</p>
                        </div>
                    </div>
                </div>
            )}

            <div className='middle-block-text'>
                <p>
                    From the construction site to the drawing board, we've walked in your shoes. With over 30 years of combined experience as builders, construction managers, quantity surveyors, and architects, we've transformed our industry frustrations into your game changing tool. We've tackled the age-old challenge head-on: automating quantity measurements from building plans, whether they’re PDFs or cutting-edge 3D models.
                </p>
                <p>Our solution? A robust, comprehensive Bill of Materials (BoM) library that's redefining efficiency in construction and DIY projects alike. By bridging the gap between professional blueprints and home improvement dreams, we’re not just solving a problem we're revolutionizing how projects come to life. Experience the power of precision, the ease of automation, and the confidence of cost effective planning, all in one innovative platform. Welcome to the future of construction and home improvement planning.</p>
            </div>
        </section>
    );
};

export default CountInfoSection;