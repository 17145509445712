import {NavigateOptions, useNavigate} from 'react-router-dom';
import {startTransition, useCallback} from 'react';

const lazyRoutes = [
    '/assistance',
    '/home',
    '/projects',
    '/order_history',
    '/auth',
    '/users/:projectId',
    '/costs/:projectId',
    '/assistance/step1/template/:templateId/:indexTemplate/project/:projectId'
];

const matchPath = (pattern: string, path: string): boolean => {
    const patternSegments = pattern.split('/').filter(Boolean);
    const pathSegments = path.split('/').filter(Boolean);

    const isOptional = (segment: string) => segment.endsWith('?');

    if (
        pathSegments.length < patternSegments.filter(s => !isOptional(s)).length ||
        pathSegments.length > patternSegments.length
    ) {
        return false;
    }

    for (let i = 0; i < patternSegments.length; i++) {
        const patternSegment = patternSegments[i];
        const pathSegment = pathSegments[i];

        if (!pathSegment && !isOptional(patternSegment)) {
            return false;
        }

        if (patternSegment.startsWith(':')) {
            continue;
        }

        if (patternSegment !== pathSegment) {
            return false;
        }
    }

    return true;
};

export const useSmartNavigate = () => {
    const navigate = useNavigate();

    return useCallback(
        (to: string, options?: NavigateOptions) => {
            const isLazy = lazyRoutes.some(pattern => matchPath(pattern, to));
            if (isLazy) {
                startTransition(() => navigate(to, options));
            } else {
                navigate(to, options);
            }
        },
        [navigate]
    );
};

