import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from "react-redux";
import {ItemType, TItem, TMerchanInfo} from "../../../types/TemplateType";
import { Project } from "../../../types/ProjectTypes";
import { get, patch } from "../../../api/APIWraper";
import { updateLocalTemplate } from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import ItemSelectionModal from "../ItemSelectionModal";
import IToolsItemType from "../../../Interfaces/ProjectInterfaces/IToolsItemType";
import { Vendor, vendorData} from "../../../Enums/VendorEnum";
import {filterLaborAndToolsItemTypes} from "../../../utils/FilterAndSetLaborToolItemTypes";
import {IconArrowLightStpe2} from "../../../IconComponents/IconComponents";

interface ItemTypeListCalculationProps {
    toolsItemTypes: IToolsItemType[];
    templateIndex: number;
    templateId: number;
    project: Project;
    onUpdateProject: (newProject: Project) => void;
    onToggle: () => void;
    relatedProjectItemTypes?: ItemType[];
    viewModeTools: 'Recommended' | 'Minimum';
}

interface SelectedItemInfo {
    vendor: Vendor | null;
    merchant_info: TMerchanInfo;
    user_item_qty?: number;
    title: string;
    id: number;
    qty: number;
    ordered_qty: number;
    unit: string;
    price: number;
}

interface SelectedItem {
    itemTypeId: number;
    itemId: number;
    index: number;
    user_item_qty: number;
    clone_id: string;
}

interface GroupedItem {
    item_type_id: number;
    item_ids: { id: number, qty: number }[];
    template_index: number;
    item_type_index: number;
    template_id: number;
    fragment_id: number;
    fragment_index: number;
}

const ToolsItemTypeListCalculation: React.FC<ItemTypeListCalculationProps & PropsFromRedux> = ({
                                                                                                   toolsItemTypes,
                                                                                                   project,
                                                                                                   onUpdateProject,
                                                                                                   onToggle,
                                                                                                   templateId,
                                                                                                   viewModeTools,
                                                                                                   templateIndex
                                                                                               }) => {
    const [selectedItemTypeIndex, setSelectedItemTypeIndex] = useState<number | null>(null);
    const [items, setItems] = useState<TItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedItemInfos, setSelectedItemInfos] = useState<{ [id: string]: SelectedItemInfo }>({});
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<{ [id: string]: string }>({});
    const [parsedItemTypes, setParsedItemTypes] = useState<{ [index: number]: any }>({});

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});

    const generateItemTypeKey = (itemType: TItem, idx: number) => {
        return `${itemType.id}-${idx}-${itemType.clone_id}-${itemType.title}`;
    };

    const generateItemKeyVisible = (item: SelectedItemInfo,selectedItem:SelectedItem, idx: number) => {
        return `${item.id}-${item.title}-${selectedItem.index}-${idx}`;
    };

    const toggleVisibility = (itemTypeKey: string) => {
        setVisibilityMap((prevMap) => ({
            ...prevMap,
            [itemTypeKey]: !prevMap[itemTypeKey],
        }));
    };

    const isVisible = (itemTypeKey: string) => {
        return visibilityMap[itemTypeKey] || false;
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const mergeItemTypes = () => {
            const mergedItemsMap: { [key: number]: IToolsItemType } = {};

            toolsItemTypes.forEach((item) => {
                const itemTypeId = item.item_type.id;

                if (mergedItemsMap[itemTypeId]) {
                    const existingItemType = mergedItemsMap[itemTypeId];

                    item.item_type.items?.forEach((toolItem) => {
                        const existingToolItem = existingItemType.item_type.items?.find(
                            (ti) => ti.id === toolItem.id
                        );

                        if (existingToolItem) {
                            existingToolItem.qty = viewModeTools === 'Minimum'
                                ? Math.max(existingToolItem.qty, toolItem.qty)
                                : existingToolItem.qty + toolItem.qty;

                            existingToolItem.ordered_qty = viewModeTools === 'Minimum'
                                ? Math.max(existingToolItem.ordered_qty ?? 0, toolItem.ordered_qty ?? 0)
                                : (existingToolItem.ordered_qty ?? 0) + (toolItem.ordered_qty ?? 0);
                        } else {
                            existingItemType.item_type.items?.push({ ...toolItem });
                        }
                    });
                } else {
                    mergedItemsMap[itemTypeId] = JSON.parse(JSON.stringify(item));
                }
            });

            return Object.values(mergedItemsMap);
        };


        const mergedItemTypes = mergeItemTypes();

        const initialSelectedItems = mergedItemTypes.flatMap((laborItem, idx) =>
            (laborItem.item_type.items || []).map((item, itemIdx) => ({
                itemTypeId: laborItem.item_type.id,
                itemId: item.id,
                index: idx,
                user_item_qty: item.qty,
                clone_id: `${item.id}-${idx}-${itemIdx}`
            }))
        );

        setSelectedItems(initialSelectedItems);

        const initialSelectedItemInfos = initialSelectedItems.reduce((acc, selectedItem) => {
            const laborItemType = mergedItemTypes[selectedItem.index].item_type;
            const item = laborItemType.items.find((it) => it.id === selectedItem.itemId);
            if (laborItemType && item) {
                acc[selectedItem.clone_id] = {
                    merchant_info: {} as TMerchanInfo,
                    vendor: item.vendor,
                    user_item_qty: selectedItem.user_item_qty,
                    title: item.title,
                    id: item.id,
                    qty: item.qty || 0,
                    ordered_qty: item.ordered_qty || 0,
                    unit: item.unit,
                    price: item.price || 0
                };
            }
            return acc;
        }, {} as { [id: string]: SelectedItemInfo });

        setSelectedItemInfos(initialSelectedItemInfos);

        const parsedItemTypes = mergedItemTypes.reduce((acc, laborItem, index) => {
            const clone_id = `${laborItem.item_type.id}-${index}-${index}`;
            const item = initialSelectedItemInfos[clone_id];
            const title = laborItem.item_type.title;

            let qty = item ? item.qty : laborItem.qty;
            if (!qty) {
                qty = 0;
            }

            let orderedQty = item ? item.ordered_qty : laborItem.item_type.ordered_qty;
            if (!orderedQty) {
                orderedQty = 0;
            }

            const price = laborItem.item_type.items && laborItem.item_type.items.length > 0
                ? laborItem.item_type.items[0].price
                : 0;
            const priceCurrentQty = item ? item.price * orderedQty : 0;
            const priceTotalQty = item ? item.price * qty : 0;
            const isItemSelected = laborItem.item_type.items && laborItem.item_type.items.length > 0;
            const unit = laborItem.item_type.items && laborItem.item_type.items.length > 0
                ? laborItem.item_type.items[0].unit
                : '';

            acc[index] = {
                index,
                title,
                qty,
                orderedQty,
                price,
                priceCurrentQty,
                priceTotalQty,
                isItemSelected,
                unit
            };

            return acc;
        }, {} as { [index: number]: any });

        setParsedItemTypes(parsedItemTypes);
        calculateTotalPrice();
    }, [toolsItemTypes, viewModeTools]);

    useEffect(() => {
        calculateTotalPrice();
    }, [selectedItemInfos]);

    useEffect(() => {
        const handler = setTimeout(() => {
            Object.keys(debouncedOrderedQty).forEach((id) => {
                setSelectedItemInfos((prev) => {
                    const newOrderedQty = parseInt(debouncedOrderedQty[id]) || 0;
                    return {
                        ...prev,
                        [id]: {
                            ...prev[id],
                            ordered_qty: newOrderedQty,
                        },
                    };
                });
            });
            calculateTotalPrice();
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedOrderedQty, selectedItems]);

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        selectedItems.forEach((selectedItem) => {
            const item = selectedItemInfos[selectedItem.clone_id];
            if (item) {
                const generalPrice = item.price * (item.user_item_qty || item.qty);
                const difTotalPrice = Math.max(0, item.price * Math.min(item.ordered_qty, item.user_item_qty || item.qty));
                totalPrice += generalPrice - difTotalPrice;
            }
        });
        setTotalPrice(totalPrice);
    };

    const storeId = project?.store_id ? project.store_id : 0;

    const fetchItems = async (index: number, page: number, searchTerm: string = '') => {
        setSelectedItemTypeIndex(index);
        setLoading(true);
        try {
            const url = searchTerm
                ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${toolsItemTypes[index].item_type.id}?page=${page}&perPage=${itemsPerPage}&title=${searchTerm}&storeIdFromProjects=${storeId}`
                : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${toolsItemTypes[index].item_type.id}?page=${page}&perPage=${itemsPerPage}&storeIdFromProjects=${storeId}`;

            const response = await get(url);
            setTotalItems(response.data.items.pagination.lastPage);
            const itemsWithQty = (Array.isArray(response.data.items.data) ? response.data.items.data : [response.data.items.data]).map(
                (item: TItem) => {
                    let qty_item_type = 0;

                    if (viewModeTools === 'Minimum') {
                        qty_item_type = Math.max(
                            ...toolsItemTypes
                                .filter(labor => labor.item_type.id === toolsItemTypes[index].item_type.id)
                                .map(labor => labor.qty)
                        );
                    } else {
                        qty_item_type = toolsItemTypes
                            .filter(labor => labor.item_type.id === toolsItemTypes[index].item_type.id)
                            .reduce((totalQty, currentLabor) => totalQty + currentLabor.qty, 0);
                    }

                    return {
                        ...item,
                        qty_item_type: qty_item_type,
                    };
                }
            );

            setItems(itemsWithQty);
        } catch (error) {
            console.error("Failed to get elements:", error);
        } finally {
            setLoading(false);
        }
    };


    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, pageNumber, searchTerm);
        }
    };

    const handleButtonClick = (index: number) => {
        setIsModalOpen(true);
        fetchItems(index, currentPage);
    };

    const handleReload = () => {
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, currentPage);
        }
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleItemClick = (items: TItem[], itemTypeIndex: number | null) => {
        if (itemTypeIndex !== null) {
            const updatedSelectedItems = [
                ...selectedItems.filter((selectedItem) => selectedItem.index !== itemTypeIndex),
                ...items.map((item, idx) => ({
                    itemTypeId: toolsItemTypes[itemTypeIndex].item_type.id,
                    itemId: item.id,
                    index: itemTypeIndex,
                    user_item_qty: item.user_item_qty,
                    clone_id: `${item.id}-${itemTypeIndex}-${idx}`,
                })),
            ];

            const updatedItemInfos = items.reduce((acc, item, idx) => {
                const clone_id = `${item.id}-${itemTypeIndex}-${idx}`;
                acc[clone_id] = {
                    merchant_info: {} as TMerchanInfo,
                    vendor: item.vendor,
                    title: item.title,
                    id: item.id,
                    qty: item.qty,
                    ordered_qty: item.ordered_qty || 0,
                    user_item_qty: item.user_item_qty || 0,
                    unit: item.unit,
                    price: item.price || 0,
                };
                return acc;
            }, { ...selectedItemInfos });

            setSelectedItemInfos(updatedItemInfos);
            setSelectedItems(updatedSelectedItems);
            setParsedItemTypes((prev) => ({
                ...prev,
                [itemTypeIndex]: {
                    ...prev[itemTypeIndex],
                    isItemSelected: items.length > 0,
                    qty: items.length > 0 ? items[0].qty : 0,
                    ordered_qty: items.length > 0 ? items[0].ordered_qty || 0 : 0,
                    user_item_qty: items.length > 0 ? items[0].user_item_qty || 0 : 0,
                    unit: items.length > 0 ? items[0].unit : '',
                    price: items.length > 0 ? items[0].price || 0 : 0,
                    priceCurrentQty: items.length > 0 ? (items[0].price || 0) * (items[0].user_item_qty || 0) : 0,
                    priceTotalQty: items.length > 0 ? (items[0].price || 0) * items[0].qty : 0,
                },
            }));

            calculateTotalPrice();
        }
        setIsModalOpen(false);
    };

    const handleQtyChange = (clone_id: string, newQty: string) => {
        const parsedQty = parseInt(newQty);
        if (!isNaN(parsedQty)) {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: newQty,
            }));
        } else {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: '',
            }));
        }
        setSelectedItemInfos((prev) => ({
            ...prev,
            [clone_id]: {
                ...prev[clone_id],
                ordered_qty: parsedQty,
            },
        }));
        calculateTotalPrice();
    };
    const handleSave = async () => {
        function isMergedItemType(itemTypeId: number): boolean {
            return toolsItemTypes.filter(toolItem => toolItem.item_type.id === itemTypeId).length > 1;
        }

        function generateCascadeIndexes(itemTypeId: number) {
            return toolsItemTypes
                .filter(toolItem => toolItem.item_type.id === itemTypeId)
                .map(toolItem => ({
                    fragment_id: toolItem.fragment_id,
                    fragment_index: toolItem.fragment_index,
                    item_type_index: toolItem.item_type_index,
                    qty: toolItem.qty,
                }));
        }

        async function handleItemOrderedQty(updatedProject: Project, isCascade: boolean) {
            const newToolsItemTypes = filterLaborAndToolsItemTypes(updatedProject, templateIndex, 'tool');

            const itemOrderedQtyBody = selectedItems
                .filter(selection => isMergedItemType(selection.itemTypeId) === isCascade)
                .map(selection => {
                    const userOrderedQtyStr = debouncedOrderedQty[selection.clone_id];
                    const userOrderedQty = userOrderedQtyStr !== undefined && userOrderedQtyStr !== '' ? parseInt(userOrderedQtyStr, 10) : undefined;
                    const initialInfo = selectedItemInfos[selection.clone_id];
                    const existingOrderedQty = initialInfo?.ordered_qty || 0;
                    const ordered_qty = userOrderedQty !== undefined ? userOrderedQty : existingOrderedQty;

                    return {
                        ordered_qty,
                        template_index: newToolsItemTypes.toolsItemTypes?.[selection.index]?.template_index ?? 0,
                        item_type_index: newToolsItemTypes.toolsItemTypes?.[selection.index]?.item_type_index ?? 0,
                        template_id: templateId,
                        item_type_id: selection.itemTypeId,
                        item_id: selection.itemId,
                        fragment_id: newToolsItemTypes.toolsItemTypes?.[selection.index]?.fragment_id ?? 0,
                        fragment_index: newToolsItemTypes.toolsItemTypes?.[selection.index]?.fragment_index ?? 0,
                        ...(isCascade && { indexes: generateCascadeIndexes(selection.itemTypeId) }),
                    };
                });

            const filteredItemOrderedQtyBody = itemOrderedQtyBody.filter(item => {
                const key = `${item.item_id}-${item.item_type_index}-${item.fragment_index}`;
                const initialInfo = selectedItemInfos[key];
                return initialInfo?.ordered_qty !== item.ordered_qty;
            });

            if (filteredItemOrderedQtyBody.length > 0) {
                const url = isCascade
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty_cascade`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty`;

                try {
                    await patch(url, { data: filteredItemOrderedQtyBody });
                } catch (error) {
                    console.error(`Failed to update ordered_qty ${isCascade ? 'cascade' : ''}:`, error);
                }
            }
        }

        const groupedItemsForAddItem = selectedItems.reduce((acc: { [key: string]: GroupedItem }, selection) => {
            const key = `${selection.itemTypeId}-${selection.index}`;
            if (!acc[key]) {
                acc[key] = {
                    item_type_id: selection.itemTypeId,
                    item_ids: [],
                    template_index: toolsItemTypes[selection.index].template_index,
                    item_type_index: toolsItemTypes[selection.index].item_type_index,
                    template_id: templateId,
                    fragment_id: toolsItemTypes[selection.index].fragment_id,
                    fragment_index: toolsItemTypes[selection.index].fragment_index,
                };
            }
            if (selection.itemId && selection.user_item_qty > 0) {
                acc[key].item_ids.push({ id: selection.itemId, qty: selection.user_item_qty });
            }
            return acc;
        }, {} as { [key: string]: GroupedItem });

        const deletedItems = toolsItemTypes.map((item, index) => ({
            item_type_id: item.item_type.id,
            item_ids: [],
            template_index: item.template_index,
            item_type_index: item.item_type_index,
            template_id: templateId,
            fragment_id: item.fragment_id,
            fragment_index: item.fragment_index,
        })).filter(item => !selectedItems.some(selection => selection.itemTypeId === item.item_type_id));

        const filteredGroupedItems = Object.values(groupedItemsForAddItem).filter(item => item.item_ids.length > 0);

        let updatedProject: Project | undefined;

        try {
            if (filteredGroupedItems.length > 0 || deletedItems.length > 0) {
                const response = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item`, {
                    data: [...filteredGroupedItems, ...deletedItems],
                });
                if (response.status === 200 || response.status === 201) {
                    updatedProject = response.data;
                    await handleItemOrderedQty(updatedProject!, false);
                } else {
                    throw new Error("Failed to update project with ordinary items.");
                }
            }
        } catch (error) {
            console.error("Failed to save items for add_item:", error);
        }

        const mergedItems = Object.values(groupedItemsForAddItem).filter(item => isMergedItemType(item.item_type_id));

        try {
            if (mergedItems.length > 0) {
                const mergedItemsWithIndexes = mergedItems.map(item => ({
                    ...item,
                    indexes: generateCascadeIndexes(item.item_type_id),
                }));
                const responseCascade = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item_cascade`, {
                    data: mergedItemsWithIndexes,
                });

                if (responseCascade.status === 200 || responseCascade.status === 201) {
                    updatedProject = responseCascade.data;
                    const newToolsItemTypes = filterLaborAndToolsItemTypes(updatedProject!, templateIndex, 'tool');

                    const itemOrderedQtyCascadeBody = selectedItems
                        .filter(selection => isMergedItemType(selection.itemTypeId))
                        .map(selection => {
                            const initialInfo = selectedItemInfos[selection.clone_id];
                            const newOrderedQty = debouncedOrderedQty[selection.clone_id]
                                ? parseInt(debouncedOrderedQty[selection.clone_id], 10)
                                : initialInfo?.ordered_qty ?? 0;

                            const generateOrderedQtyCascadeIndexes = (selection: SelectedItem) => {
                                const toolItems = newToolsItemTypes.toolsItemTypes?.filter(toolItem => toolItem.item_type.id === selection.itemTypeId) ?? [];

                                const indexes = toolItems.map(toolItem => {
                                    const correspondingItem = toolItem.item_type.items.find(item => item.id === selection.itemId);
                                    if (correspondingItem) {
                                        return {
                                            fragment_id: toolItem.fragment_id,
                                            fragment_index: toolItem.fragment_index,
                                            item_type_index: toolItem.item_type_index,
                                            qty: correspondingItem.qty,
                                        };
                                    }
                                    return null;
                                }).filter(index => index !== null);

                                return indexes;
                            };

                            return {
                                ordered_qty: newOrderedQty,
                                template_index: newToolsItemTypes.toolsItemTypes?.[selection.index]?.template_index ?? 0,
                                item_type_index: newToolsItemTypes.toolsItemTypes?.[selection.index]?.item_type_index ?? 0,
                                template_id: templateId,
                                item_type_id: selection.itemTypeId,
                                item_id: selection.itemId,
                                indexes: generateOrderedQtyCascadeIndexes(selection),
                            };
                        });

                    if (itemOrderedQtyCascadeBody.length > 0) {
                        await patch(
                            `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty_cascade`,
                            { data: itemOrderedQtyCascadeBody }
                        );
                    }
                } else {
                    throw new Error("Failed to update project with merged items.");
                }
            }
        } catch (error) {
            console.error("Failed to save items for add_item_cascade:", error);
        }

        handleCloseModal();
        if (updatedProject) {
            onUpdateProject(updatedProject);
            onToggle();
        }
    };

    if (!toolsItemTypes || !Array.isArray(toolsItemTypes)) {
        return null;
    }

    const handleSearch = (query: string) => {
        setSearchTerm(query);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, 1, query);
        }
    };
    return (
        <div>
            {toolsItemTypes.length > 0 && (
                <>
                    {Object.values(parsedItemTypes).map((parsedItem, index) => {
                        if (!parsedItem) return null;

                        const {title, price, priceTotalQty} = parsedItem;
                        const totalQty = toolsItemTypes
                            .filter(tool => tool.item_type.id === toolsItemTypes[parsedItem.index].item_type.id)
                            .reduce((sum, tool) => sum + tool.qty, 0);

                        const selectedItemsForType = selectedItems.filter(
                            item => item.itemTypeId === toolsItemTypes[parsedItem.index].item_type.id
                        );
                        const itemTypeKey = generateItemTypeKey(parsedItem,index);

                        return (
                            <div
                                key={`${toolsItemTypes[parsedItem.index].item_type.id}-${index}`}>
                                <label
                                    className="step2-item-type-label"
                                    htmlFor={`button-${toolsItemTypes[parsedItem.index].item_type.id}-${index}`}
                                    style={{color: "#828282"}}
                                >
                                    {title}
                                </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                        !isSmallScreen ? (
                                            <div className='step2-item-type-list'>
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${toolsItemTypes[parsedItem.index].item_type.id}-${index}`}
                                                    onClick={() => handleButtonClick(parsedItem.index)}
                                                    disabled={loading}
                                                >
                                                    {loading && selectedItemTypeIndex === parsedItem.index
                                                        ? "Loading..."
                                                        : "Select Tools"}
                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                              stroke="#0053A0" strokeWidth="1.5" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                        <circle cx="13" cy="13" r="12" stroke="#0053A0" strokeWidth="2"/>
                                                    </svg>
                                                </button>
                                                <div className="step2-tooltip-container">
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-width-list">{totalQty}</p>
                                                <p className="step2-other-width-list">R {parseInt(price).toFixed(2)}</p>
                                                <p className="step2-other-width-list">
                                                    {isNaN(totalQty * price) || totalQty === 0 ? 0 : (totalQty * price).toFixed(2)}</p>
                                                <p className="step2-other-width-list-total">R {Math.max(0, priceTotalQty).toFixed(2)}</p>
                                                <div className='step2-other-width-vendor-list'></div>
                                            </div>
                                        ): (
                                            <div className='step2-item-type-list'>
                                                <div className='step2-item-type-list-block-arrow'>
                                                    <button
                                                        className="step2-item-button-list"
                                                        id={`button-${toolsItemTypes[parsedItem.index].item_type.id}-${index}`}
                                                        onClick={() => handleButtonClick(parsedItem.index)}
                                                        disabled={loading}
                                                    >
                                                        {loading && selectedItemTypeIndex === parsedItem.index
                                                            ? "Loading..."
                                                            : 'Select Tools'}
                                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13 7L13 18M13 18L9 14M13 18L17 14"
                                                                  stroke="#0053A0" strokeWidth="1.5"
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"/>
                                                            <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                    strokeWidth="2"/>
                                                        </svg>
                                                    </button>
                                                    <div onClick={() => toggleVisibility(itemTypeKey)}
                                                         className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                        <IconArrowLightStpe2/>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                    <div className="step2-tooltip-container">
                                                        <span>QTY to order:</span><span className="dashed-line"></span>
                                                        <input
                                                            type="number"
                                                            className="step2-other-width-input"
                                                            min="0"
                                                            disabled={!loading}
                                                        />
                                                    </div>
                                                    <p className="step2-other-width-list">
                                                        <span>QTY ordered:</span><span
                                                        className="dashed-line"></span>{totalQty}
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Unit price:</span><span
                                                        className="dashed-line"></span>R {parseInt(price).toFixed(2)}
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Total:</span><span className="dashed-line"></span>
                                                        {isNaN(totalQty * price) || totalQty === 0 ? 0 : (totalQty * price).toFixed(2)}
                                                    </p>
                                                    <p className="step2-other-width-list-total total-ordred-bold">
                                                        <span>Total ordered:</span><span
                                                        className="dashed-line"></span>{Math.max(0, priceTotalQty).toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className="step2-item-column">
                                            {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;

                                                const difPrice = item.user_item_qty ? item.price * item.user_item_qty : 0;
                                                const oldPrice = item.price * (item.user_item_qty || item.qty);
                                                const difTotalPrice = isNaN(oldPrice - difPrice) ? 0 : oldPrice - difPrice;
                                                const userQty = item.user_item_qty ? item.user_item_qty : 0;
                                                const toolKeyVisible = generateItemKeyVisible(item,selectedItem,idx)
                                                return (
                                                    !isSmallScreen ? (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${toolsItemTypes[parsedItem.index].item_type.id}-${index}-${idx}`}
                                                                onClick={() => handleButtonClick(parsedItem.index)}
                                                                disabled={loading}
                                                            >
                                                                {loading && selectedItemTypeIndex === parsedItem.index
                                                                    ? "Loading..."
                                                                    : item.title}
                                                                <svg width="26" height="26" viewBox="0 0 26 26"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                                          stroke="#0053A0" strokeWidth="1.5"
                                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                            strokeWidth="2"/>
                                                                </svg>
                                                            </button>
                                                            <div className="step2-tooltip-container">
                                                                <input
                                                                    type="number"
                                                                    className="step2-other-width-input"
                                                                    value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                    onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                    min="0"
                                                                />
                                                            </div>
                                                            <p className="step2-other-width-list">{item.user_item_qty || totalQty} ({item.unit})</p>
                                                            <p className="step2-other-width-list"> R {parseInt(String(item.price)).toFixed(2)}</p>
                                                            <p className="step2-other-width-list">
                                                                R {isNaN(userQty * item.price) ? 0 : (userQty * item.price).toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list-total"> R {Math.max(0, difTotalPrice).toFixed(2)}</p>
                                                            <div className='step2-other-width-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div key={`${selectedItem.clone_id}`}
                                                             className='step2-item-type-list'>
                                                            <div className='step2-item-type-list-block-arrow'>
                                                                <button
                                                                    className="step2-item-button-list"
                                                                    id={`button-${toolsItemTypes[parsedItem.index].item_type.id}-${index}-${idx}`}
                                                                    onClick={() => handleButtonClick(parsedItem.index)}
                                                                    disabled={loading}
                                                                >
                                                                    {loading && selectedItemTypeIndex === parsedItem.index
                                                                        ? "Loading..."
                                                                        : item.title}
                                                                    <svg width="26" height="26" viewBox="0 0 26 26"
                                                                         fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M13 7L13 18M13 18L9 14M13 18L17 14"
                                                                              stroke="#0053A0" strokeWidth="1.5"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round"/>
                                                                        <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                                strokeWidth="2"/>
                                                                    </svg>
                                                                </button>
                                                                <div onClick={() => toggleVisibility(toolKeyVisible)}
                                                                     className={isVisible(toolKeyVisible) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                                    <IconArrowLightStpe2/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`step2-content-list ${isVisible(toolKeyVisible) ? 'visible' : 'hidden'}`}>
                                                                <div className="step2-tooltip-container">
                                                                    <span>QTY to order:</span><span
                                                                    className="dashed-line"></span>
                                                                    <input
                                                                        type="number"
                                                                        className="step2-other-width-input"
                                                                        value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                        onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                        min="0"
                                                                    />
                                                                </div>
                                                                <p className="step2-other-width-list">
                                                                    <span>QTY ordered:</span><span
                                                                    className="dashed-line"></span>{item.user_item_qty || item.qty} ({item.unit})
                                                                </p>
                                                                <p className="step2-other-width-list">
                                                                    <span>Unit price:</span><span
                                                                    className="dashed-line"></span> R {parseInt(String(item.price)).toFixed(2)}
                                                                </p>
                                                                <p className="step2-other-width-list">
                                                                    <span>Total:</span><span
                                                                    className="dashed-line"></span>
                                                                    R {isNaN(userQty * item.price) ? 0 : (userQty * item.price).toFixed(2)}
                                                                </p>
                                                                <p className="step2-other-width-list-total total-ordred-bold">
                                                                    <span>Total ordered:</span><span
                                                                    className="dashed-line"></span>R {Math.max(0, difTotalPrice).toFixed(2)}
                                                                </p>
                                                                <div className='step2-other-width-vendor-list'>
                                                                    {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                        <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                    ) : (
                                                                        <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>

            )}
            <ItemSelectionModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                items={items}
                loading={loading}
                handleItemClick={(items) => handleItemClick(items, selectedItemTypeIndex)}
                selectedItems={selectedItems.filter(item => item.index === selectedItemTypeIndex)}
                onReload={handleReload}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                onSearch={handleSearch}
                totalSearchResults={totalItems}
                storeId={storeId}
            />
            <div className='step2-bottom-block'>
                {!isSmallScreen ? (
                    <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button className='step2-bottom-block-btn-cart-list' onClick={handleSave}>Save</button>
                    </>
                ) : (
                    <>
                        <div className='step2-bottom-block-mobile'>
                            <p>Total: </p>
                            <span className="dashed-line"></span>
                            {totalPrice ? (
                                <span className='mobile-total-price-red'> R {totalPrice.toFixed(2)}</span>
                            ) : (
                                <span className='mobile-total-price-red'> R 0</span>
                            )}
                        </div>
                        <button className='step2-bottom-block-btn-cart-list' onClick={handleSave}>Save</button>
                    </>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateLocalTemplate,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ToolsItemTypeListCalculation);
