import React, { useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import './Styles/Step1.css';
import 'react-toastify/dist/ReactToastify.css';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {clearTemplates, updateLocalTemplate} from '../../Slice/LocalTemplate/LocalTemplateSlice';
import {Template} from '../../types/TemplateType';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {Loader} from "../../components/Loader/Loader";
import {fetchProjectById} from "../../Slice/projectById/projectByIdSlice";
import {useSmartNavigate} from "../../Hooks/useNavigationHook";
import useIsSmallScreen from "../../Hooks/SmallScreen/useIsSmallScreen";
import StepCalcDesktop from "./AssisStepCalc/StepCalcDesktop";

const Step1: React.FC<PropsFromRedux> = ({
                                             selectedTemplates,
                                             project,
                                             fetchProjectById,
                                             clearTemplates,
                                             isAuthenticated
                                         }) => {
    const smartNavigate = useSmartNavigate();
    const [template, setTemplate] = useState<Template | null>(null);
    const [isReady, setIsReady] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState<Record<string, string>>({});
    const isSmallScreen = useIsSmallScreen();

    const {projectId, indexTemplate, templateId} = useParams<{
        projectId: string;
        templateId: string;
        indexTemplate: string
    }>();

    const projectIdNumber = projectId ? parseInt(projectId, 10) : NaN;
    const templateIdNumber = templateId ? parseInt(templateId, 10) : NaN;
    const templateIndex = indexTemplate ? parseInt(indexTemplate, 10) : NaN;


    useEffect(() => {
        if (isAuthenticated) {
            if (projectIdNumber) {
                fetchProjectById({projectId: String(projectId)});
            }
        } else {
            smartNavigate('/home')
        }
    }, [projectId, isAuthenticated]);

    useEffect(() => {
        if (project?.data?.templates) {
            const storedTemplate = project.data.templates.find(template => template.id === templateIdNumber);
            if (storedTemplate) {
                setTemplate(storedTemplate);
                setIsReady(true);
            }
        }
    }, [templateIdNumber, project]);

    useEffect(() => {
        return () => {
            localStorage.removeItem('selectedFragments')
        }
    }, []);


    useEffect(() => {
        if (isReady) {
            document.body.style.overflow = 'unset';
        }
    }, [isReady]);

    if (!isReady) {
        return <Loader/>
    }
    if (!template) return null;

    const descriptionText = template.short_description || 'No description';

    const handleCalculateClick = () => {
        if (template) {
            smartNavigate(`/assistance/create/${projectId}/from/projectCosts`, {state: {from: 'projectCosts'}});
        }
    };

    const navigateTo = (path: string) => {
        switch (path) {
            case 'home': {
                clearLocalStorageExceptToken();
                clearTemplates();
                smartNavigate('/assistance');
                break
            }
            case 'createProject': {
                handleCalculateClick()
                break
            }
        }
    }

    return (
        <>
            <div className='template-name-content'>
                {!isSmallScreen && (
                    <div className='template-blog-placeholder'>
                        <p onClick={() => navigateTo('home')} className='template-link-assistance'>Home/</p>
                        <p onClick={() => navigateTo('createProject')} className='template-link-assistance'>Create new
                            project</p>
                        <p className='name-link'>/Step1</p>
                    </div>
                )}
            </div>

            <div className='step-info'>
                <div className='step-info-block'>
                    <h3>Step 1</h3>
                    <span>/</span>
                    <p className='step-info-block-name'>Specify parameters of building</p>
                </div>
            </div>

            {!isSmallScreen ? (
                <div className='step-template-container'>
                    <div className='step-template-block left'>
                        <h2>Type:</h2>
                        <p className='step-template-block-title'>{template.title}</p>
                    </div>
                    <div className='step-template-block right-block-step'>
                        <h2>Description:</h2>
                        <p className='step-template-block-desc'>{descriptionText}</p>
                    </div>

                    <div className='step-template-block-percent'>
                        <div className='progress-bar-wrapper'>
                            <CircularProgressbar
                                value={project?.progress ? project.progress : 0}
                                styles={buildStyles({
                                    pathColor: 'var(--basic-btn-dark-color)',
                                    textColor: '#000000',
                                    trailColor: '#d6d6d6',
                                    textSize: '24px',
                                    pathTransitionDuration: 0.5,
                                })}
                            />
                            <div className='progress-bar-text'>
                                {project?.progress ? project.progress : 0}%
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='step-template-container'>
                    <div className='step-template-block left'>
                        <div className='progress-bar-wrapper'>
                            <CircularProgressbar
                                value={project?.progress ? project.progress : 50}
                                strokeWidth={12}
                                styles={buildStyles({
                                    pathColor: 'var(--basic-color)',
                                    trailColor: '#d6d6d6',
                                    textSize: '24px',
                                    strokeLinecap: 'round',
                                })}
                            />
                            <div className='progress-bar-text'>
                                {project?.progress ? project.progress : 0}%
                            </div>
                        </div>
                        <div className='step1-mobile-header-block'>
                            <h2>Type:</h2>
                            <p className='step-template-block-title'>{template.title}</p>
                        </div>
                    </div>
                    <div className='step-template-block right-block-step'>
                        <h2>Description:</h2>
                        <p className='step-template-block-desc'>{descriptionText}</p>
                    </div>


                </div>
            )}


            <p className='step1-info-text'>This building project requires you to insert dimensions for the following
                sections below:</p>
                <StepCalcDesktop
                    templIndex={templateIndex}
                    selectedTemplates={selectedTemplates}
                    template={template}
                    selectedAnswers={selectedAnswers}
                    setSelectedAnswers={setSelectedAnswers}
                />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedTemplates: state.localTemplate.templates,
    project: state.projectById.project,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    updateLocalTemplate,
    fetchProjectById,
    clearTemplates
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step1);
