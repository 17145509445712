import React from 'react';
import {
    IconAccuracyLanding,
    IconCustomizationLanding,
    IconSettingLanding,
    IconTimeSavingSolution
} from "../../IconComponents/IconComponents";

interface InfoBlockBlogProps {
    isSmallScreen?: boolean;
}

const InfoBlockBlog:React.FC<InfoBlockBlogProps> = ({isSmallScreen}) => {
    return (
        <main>
            <section className='middle-content2'>
                <div>
                    <div className='middle-block1-info1'>
                        <div className='middle-block1-info1-content'>
                            <IconSettingLanding/>
                            <p className='middle-block1-info1-name'>Seamless Integration</p>
                            <p className='middle-block1-info1-text width-text-middle'>Upload your Revit or AutoCAD plans directly or input project measurements manually</p>
                        </div>
                    </div>
                    <div className='middle-block1-section'>
                        <div className='middle-block1-info2'>
                            <div className='middle-block1-info2-content'>
                                <IconCustomizationLanding/>
                                <p className='middle-block1-info1-name'>Instant Calculations</p>
                                <p className='middle-block1-info1-text'>Generate a comprehensive Bill of Materials, labor estimates, and associated costs in real-time </p>
                            </div>
                        </div>
                        <div className='middle-block1-info2'>
                            <div className='middle-block1-info1-content'>
                                <IconAccuracyLanding/>
                                <p className='middle-block1-info1-name'>Accuracy & Precision</p>
                                <p className='middle-block1-info1-text width-text-short'>Leverage automated measurements and cost estimations from 3D models or user inputs for reliable results</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='middle-block2-info1-last'>
                    <div className='middle-block2-info1'>
                        <div className='middle-block2-info1-content'>
                            <IconTimeSavingSolution/>
                            <p className='middle-block1-info1-name'>Time-Saving Solution</p>
                            <p className='middle-block1-info1-text width-text-middle1'>Eliminate manual calculations and reduce the risk of errors in your construction planning </p>
                        </div>
                        {!isSmallScreen && (
                            <div className='middle-section-image-block'>
                                <img className='middle-section-image-screen1' src='/img_landing/screenBig.png'
                                     alt="Image pop up"/>
                                <div className='middle-section-image-block-twice'>
                                    <img className='middle-section-image-screen2' src='/img_landing/screenSmall1.png'
                                         alt='Image select product'/>
                                    <img className='middle-section-image-screen3' src='/img_landing/screenSmall2.png'
                                         alt='Image select project'/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>


        </main>
    );
};

export default InfoBlockBlog;