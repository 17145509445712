import React from 'react';
import './WhatWeOfferComponent.css'
import {
    IconCostConsultations,
    IconFuncGuidance,
    IconMaterialCalculation,
    IconSupplierRecommendedations
} from "../../../IconComponents/IconComponents";

function WhatWeOfferComponent() {
    return (
        <main>
            <h3 className='offer-component-text'>What we offer</h3>

            <section className='offer-component-section'>
                <img className='offer-component-image' src='/img_landing/WhatWeOffer.png' alt='Constructions'/>
                <div className='offer-component-block'>
                    <div className='offer-component-block-text'>
                        <p className='offer-component-block-text-partners'>By harnessing the power of GoBuild BoM, you're
                            not just calculating materials—you're optimizing your entire construction planning process.
                            From architects working on complex projects to homeowners planning renovations, GoBuild BoM
                            provides the clarity and efficiency needed to bring your construction vision to life. </p>

                        <div className='offer-component-block-info'>

                            <div className='offer-component-block-info-two-block'>
                                <div className='offer-component-block-info-two-block-info'>
                                    <div className='offer-component-block-info-two-block-icon'>
                                        <IconFuncGuidance/>
                                    </div>
                                    <div className='offer-component-block-info-two-block-info-text-block'>
                                        <p className='offer-component-block-info-two-block-info-text-name'>Functionality Guidance </p>
                                        <p className='offer-component-block-info-two-block-info-text-info'>Learn how to maximize our tool's capabilities for your specific needs.</p>
                                    </div>
                                </div>

                                <div className='offer-component-block-info-two-block-info'>
                                    <div>
                                        <IconCostConsultations/>
                                    </div>
                                    <div className='offer-component-block-info-two-block-info-text-block'>
                                        <p className='offer-component-block-info-two-block-info-text-name'>Cost Consultations</p>
                                        <p className='offer-component-block-info-two-block-info-text-info'>Receive advice on budgeting and cost effective solutions for your construction or renovation plans.</p>
                                    </div>
                                </div>

                            </div>

                            <div className='offer-component-block-info-two-block'>
                                <div className='offer-component-block-info-two-block-info'>
                                    <div className='offer-component-block-info-two-block-icon'>
                                        <IconMaterialCalculation/>
                                    </div>
                                    <div className='offer-component-block-info-two-block-info-text-block'>
                                        <p className='offer-component-block-info-two-block-info-text-name'>Material Calculations</p>
                                        <p className='offer-component-block-info-two-block-info-text-info'>Get expert help in accurately estimating the materials required for your project.</p>
                                    </div>
                                </div>

                                <div className='offer-component-block-info-two-block-info'>
                                    <div>
                                        <IconSupplierRecommendedations/>
                                    </div>
                                    <div className='offer-component-block-info-two-block-info-text-block'>
                                        <p className='offer-component-block-info-two-block-info-text-name'>Supplier Recommendations</p>
                                        <p className='offer-component-block-info-two-block-info-text-info'>Benefit from our industry knowledge to connect with reliable and cost-effective suppliers.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    );
}

export default WhatWeOfferComponent;