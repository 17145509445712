import React, {useEffect, useMemo} from 'react';
import { AllFragments, skipData } from "../../ArсhitectPage";
import { Loader } from "../../../../components/Loader/Loader";

interface ExtractObjectBlockProps {
    skipsObjectsRevitProject: { index: number; status: string }[];
    allFragments: AllFragments[];
    extractObjects: skipData[];
    loadingExtract: boolean;
    setCurrentIndex: (index: number) => void;
    currentIndex: number;
    selectedExternalId: string | null;
    setSelectedExternalId: React.Dispatch<React.SetStateAction<string | null>>;
    disableSavedIndex?: boolean;
    externalIds:string[]
}

const ExtractObjectBlock: React.FC<ExtractObjectBlockProps> = ({
                                                                   skipsObjectsRevitProject,
                                                                   allFragments,
                                                                   extractObjects,
                                                                   loadingExtract,
                                                                   setCurrentIndex,
                                                                   currentIndex,
                                                                   selectedExternalId,
                                                                   setSelectedExternalId,
                                                                   disableSavedIndex = false,
                                                                   externalIds
                                                               }) => {



    const sortedExtractObjects = useMemo(() => {
        return externalIds
            .map(extId => extractObjects.find(obj => obj.externalId === extId))
            .filter((obj): obj is skipData => !!obj);
    }, [externalIds, extractObjects]);

    const handleSelect = (externalId: string, index: number) => {
        const savedIndex = localStorage.getItem("SavedIndexRight");
        if (selectedExternalId === externalId) {
            if (savedIndex && !disableSavedIndex) {
                setCurrentIndex(Number(savedIndex));
                localStorage.removeItem("SavedIndexRight");
            }
            setSelectedExternalId(null);
        } else {
            if (!disableSavedIndex && !savedIndex) {
                localStorage.setItem("SavedIndexRight", String(currentIndex));
            }
            setSelectedExternalId(externalId);
            setCurrentIndex(index);
        }
    };

    useEffect(() => {
        if (selectedExternalId) {
            const indexInSorted = sortedExtractObjects.findIndex(
                obj => obj.externalId === selectedExternalId
            );
            if (indexInSorted !== -1) {
                setCurrentIndex(indexInSorted);
            }
        }
    }, [selectedExternalId, sortedExtractObjects, setCurrentIndex]);

    return (
        <>
            {loadingExtract ? <Loader /> : (
                sortedExtractObjects.map((deletedObject, index) => {
                    const isSelected = index === currentIndex;

                    const isSuccessful = allFragments.some(fragment => fragment.currentIndex === index);

                    const foundSkipObject = skipsObjectsRevitProject.find(obj => obj.index === index);
                    const statusClass = foundSkipObject
                        ? foundSkipObject.status === "skip"
                            ? "revit-skipped"
                            : foundSkipObject.status === "deleted"
                                ? "revit-deleted"
                                : ""
                        : "";

                    const className = `right-block-section-revit ${isSelected ? "selected" : ""} 
                        ${isSuccessful ? "revit-successful" : ""} ${statusClass}`.trim();

                    return (
                        <div
                            className={className}
                            key={index}
                            onClick={() => handleSelect(deletedObject.externalId, index)}
                        >
                            <div className="right-block-section-revit-content">
                                <p className="right-block-section-revit-content-text">Name</p>
                                <p>{deletedObject.name}</p>
                            </div>
                            <div className="right-block-section-revit-content">
                                <p className="right-block-section-revit-content-text">Length</p>
                                <p>{deletedObject.measurements?.length ?? '-'}</p>
                            </div>
                            <div className="right-block-section-revit-content">
                                <p className="right-block-section-revit-content-text">Area</p>
                                <p>{deletedObject.measurements?.area ?? '-'}</p>
                            </div>
                            <div className="right-block-section-revit-content">
                                <p className="right-block-section-revit-content-text">Volume</p>
                                <p>{deletedObject.measurements?.volume ?? '-'}</p>
                            </div>
                            <div className="right-block-section-revit-content">
                                <p className="right-block-section-revit-content-text">Width</p>
                                <p>{deletedObject.measurements?.width ?? '-'}</p>
                            </div>
                        </div>
                    );
                })
            )}
        </>
    );
};

export default ExtractObjectBlock;
